import { Text } from 'wikr-core-components';
import { CSSProperties } from 'react';

import * as S from './styled';

export interface StepProps {
    stepNumber: string;
    text: string;
    img: string;
    imgAlt: string;
    width?: number;
    style?: CSSProperties;
}
export const Step = ({ stepNumber, text, img, imgAlt, width, style }: StepProps) => {
    return (
        <S.Step>
            <S.StepHeader>
                <S.StepNumber color="on-primary" text={stepNumber} />
                <Text text={text} bold />
            </S.StepHeader>
            <S.StepImage src={img} width={width} style={style} alt={imgAlt} />
        </S.Step>
    );
};
