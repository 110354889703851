import { ModalName, ModalPropsType } from 'store/modals/types';
import { selectModalProps } from 'store/modals/selectors';
import { closeModal as closeModalAction, openModal as openModalAction } from 'store/modals/actions';

import { useDispatch, useSelector } from 'hooks';

// M is current modalId
export const useModals = <M extends ModalName>() => {
    const dispatch = useDispatch();
    const modalProps: ModalPropsType[M] = useSelector(selectModalProps);

    // T is modalId which need to be open
    const openModal = <T extends ModalName>(modalId: T, props?: ModalPropsType[T]) => {
        dispatch(openModalAction(modalId, props));
    };
    const closeModal = () => {
        dispatch(closeModalAction());
    };

    return { openModal, closeModal, modalProps };
};
