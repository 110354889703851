import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

// mainTrackers
import { trackClick } from './mainTrackers';

const DASHBOARD_START_POPUP_ID = 'dashboard_start_popup';

const sendAnalyticNextOnboardingClick = (index: number) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.next,
        eventLabel: String(index),
    });
};

const sendAnalyticSkipOnboardingClick = (index: number) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.skip,
        eventLabel: String(index),
    });
};

const sendAnalyticStartClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'start',
    });
};

const sendAnalyticToggleDemoClick = (index: number) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'demo',
        eventLabel: String(index),
    });
};

const sendAnalyticStartInAppPopupClick = () => {
    trackClick({
        screenIdParam: DASHBOARD_START_POPUP_ID,
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'start',
    });
};

const sendAnalyticShowDemoPopupClick = () => {
    trackClick({
        screenIdParam: DASHBOARD_START_POPUP_ID,
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'demo',
    });
};

const sendAnalytichInsightCardClick = (blockId: number, storyId: number) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'story',
        eventLabel: `${blockId}__${storyId}`,
    });
};

const sendAnalyticLogWeightClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'log_weight',
    });
};

const sendAnalyticViewInAppClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.app,
    });
};

const sendAnalyticResetOnboardingClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'onboarding',
    });
};

export {
    sendAnalyticNextOnboardingClick,
    sendAnalyticSkipOnboardingClick,
    sendAnalyticStartClick,
    sendAnalyticToggleDemoClick,
    sendAnalyticStartInAppPopupClick,
    sendAnalyticShowDemoPopupClick,
    sendAnalytichInsightCardClick,
    sendAnalyticLogWeightClick,
    sendAnalyticViewInAppClick,
    sendAnalyticResetOnboardingClick,
};
