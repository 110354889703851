import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const PlanCard = styled.button`
    border-radius: 12px;
    padding: 8px;
    margin-bottom: 12px;
    background-color: ${color('surface-main')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const BtnBlockWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const ImgWrap = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
`;
