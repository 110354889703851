import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { useFormik } from 'formik';

import { selectCurrentUser } from 'store/user/selectors';
import { UserStore } from 'store/user/reducer';
import { UPDATE_USER } from 'store/user/actionTypes';
import { updateUserRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';

import { initialValues } from './constants';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticProfileUpdateClick } from 'services/analytics';

import { useSelector, useDispatch } from 'hooks/store';

import { getUserUpdatedKeys } from './helpers';

import ProfileForm from './components/ProfileForm';

import { formValues } from './types';

import getValidationSchema from './validationScheme';

const ProfileContainer = () => {
    useScreenLoad('account');

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);
    const isLoading: boolean = useSelector(createProcessingSelector([UPDATE_USER]));

    const onSubmit = (values: formValues) => {
        const { name, yearOfBirth, defaultCycleLength, defaultPeriodLength, defaultCycleRegularity } = values;

        const payload = {
            year_of_birth: Number(yearOfBirth),
            default_cycle_length: Number(defaultCycleLength),
            default_period_length: Number(defaultPeriodLength),
            default_cycle_regularity: defaultCycleRegularity,
            name: name.trim(),
        };

        const userInitialValues = getFormDataFromUser(currentUser);
        const updatedUserValueKeys = getUserUpdatedKeys(userInitialValues, values);

        sendAnalyticProfileUpdateClick(updatedUserValueKeys);

        dispatch(
            updateUserRequest({
                payload,
                onSuccess: () => {
                    // sendAnalyticProfileUpdateSuccess(updatedUserValueKeys);
                    formik.setErrors({});
                    formik.setTouched({});
                },
                // onError: sendAnalyticProfileUpdateError,
            })
        );
    };

    const getFormDataFromUser = (user: UserStore) => {
        const {
            name = initialValues.name,
            year_of_birth: yearOfBirth,
            default_cycle_length: defaultCycleLength,
            default_period_length: defaultPeriodLength,
            default_cycle_regularity: defaultCycleRegularity,
        } = user;

        return {
            ...initialValues,
            name,
            yearOfBirth,
            defaultCycleLength,
            defaultPeriodLength,
            ...(defaultCycleRegularity && { defaultCycleRegularity }),
        };
    };

    const formik = useFormik({
        validateOnBlur: false,
        initialValues: getFormDataFromUser(currentUser) as formValues,
        validationSchema: getValidationSchema(t),
        onSubmit,
    });

    const setCycleRegularity = (event: ChangeEvent) => {
        const el = event.target as HTMLSelectElement;

        formik.setFieldValue('defaultCycleRegularity', el.value);
        formik.setFieldTouched('defaultCycleRegularity');
    };

    const onCycleRegularityBlur = (event: ChangeEvent) => {
        const el = event.target as HTMLSelectElement;

        formik.setFieldValue('defaultCycleRegularity', el.value);
        formik.setFieldTouched('defaultCycleRegularity');
    };

    return (
        <ProfileForm
            setCycleRegularity={setCycleRegularity}
            onCycleRegularityBlur={onCycleRegularityBlur}
            isLoading={isLoading}
            {...formik}
        />
    );
};

export default ProfileContainer;
