import { ActionEventAction, ElementEventAction, EventTrigger, Main } from 'wikr-core-analytics';

// mainTrackers
import { trackClick } from './mainTrackers';

const LIFETIME_ACCESS_MODAL = 'cancel_offer_lt';
const LIFETIME_ACCESS_MODAL_FINAL = 'cancel_offer_lt_f';

export const sendAnalyticLifeTimeQuestionScreenLoad = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId: LIFETIME_ACCESS_MODAL },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const sendAnalyticGetLifeTimeAccessClick = () => {
    trackClick({
        actionEvent: ActionEventAction.continue,
        elementEvent: ElementEventAction.button,
        screenIdParam: LIFETIME_ACCESS_MODAL,
    });

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId: LIFETIME_ACCESS_MODAL_FINAL },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const sendAnalyticCancelAfterLifeTimeOfferClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'continue_cancel',
        elementEvent: ElementEventAction.button,
        screenIdParam: LIFETIME_ACCESS_MODAL,
    });
};

export const sendAnalyticCancelFromLifeTimeFinalClick = () => {
    trackClick({
        actionEvent: ActionEventAction.cancelSubscription,
        elementEvent: ElementEventAction.button,
        screenIdParam: LIFETIME_ACCESS_MODAL_FINAL,
    });
};

export const sendAnalyticDiscardCancelFromLifeTimeFinalClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'keep_subscription',
        elementEvent: ElementEventAction.button,
        screenIdParam: LIFETIME_ACCESS_MODAL_FINAL,
    });
};
