import { Box, Image, Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getLanguage } from 'api/utils/languageManagment';

import { selectCurrentUser } from 'store/user/selectors';

import { PATHS } from 'constants/routerPaths';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import {
    sendAnalyticCTAButtonClickMyFastingPlan,
    sendAnalyticGuideClick,
} from 'services/analytics/trackers/myFastingPlan';

import { useSelector } from 'hooks';

import { getBMI } from 'helpers/getBmi';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import TargetZonesBlock from './components/TargetZonesBlock';
import ProtocolBlock from './components/ProtocolBlock';
import MobileAppCook from './components/MobileAppCook';
import MealsBlock from './components/MealsBlock';
import GuideBlock from './components/GuideBlock';
import BmiBlock from './components/BmiBlock';

import * as S from './styled';

import title from './img/title.webp';

const PAGE_ID = 'my_fasting_plan';

const MyFastingPlan = () => {
    useScreenLoad();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const lang = getLanguage();

    const { weight, height, target_zone, gender } = useSelector(selectCurrentUser);

    const bmi = getBMI(Number(weight), Number(height));

    const handleStartClick = () => {
        sendAnalyticCTAButtonClickMyFastingPlan(PAGE_ID);

        navigate(PATHS.MAIN);
    };

    const handleGuideClick = () => {
        sendAnalyticGuideClick(PAGE_ID);
    };

    return (
        <Box backgroundColor="surface-secondary">
            <S.BackgroundImage>
                <Image isForceVisible once maxWidth={1099} center alt="title" src={title} />
            </S.BackgroundImage>
            <S.ContentWrap dataLocator="myFastingPlanContainer">
                <Text center type="h5" text={t('sidebar.myFastingPlan')} mb={35} />
                <GuideBlock lang={lang} handleGuideClick={handleGuideClick} />
                <ProtocolBlock />
                <MealsBlock lang={lang} handleGuideClick={handleGuideClick} />
                <MobileAppCook />
                <BmiBlock bmi={bmi} />
                {target_zone && <TargetZonesBlock targetZones={target_zone} gender={gender} />}
                <S.BtnWrap>
                    <SecondaryDefaultButton
                        text={t('welcome.startFastingBtn')}
                        onClick={handleStartClick}
                        dataLocator="startFastingButton"
                    />
                </S.BtnWrap>
            </S.ContentWrap>
        </Box>
    );
};

export default MyFastingPlan;
