import { Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { selectIsAppLibraryWebView } from 'store/uiEffects/selectors';
import { ModalName } from 'store/modals/types';
import { selectChallenge75 } from 'store/challenge75/selectors';
import { fetchChallenge75 } from 'store/challenge75/actions';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticModalClick } from 'services/analytics/trackers/challenge75';

import { useDispatch, useModals, useSelector } from 'hooks';

import WebViewBackArrow from 'components/WebViewNavBar';
import { IChallengeLevel } from './components/LevelBlock/types';
import LevelBlock from './components/LevelBlock';

import * as S from './styled';

import rocket from './img/rocket.webp';
import { ReactComponent as Check } from './img/Check.svg';

const Challenge = () => {
    useScreenLoad();
    const { t } = useTranslation();

    const isAppLibraryWebView = useSelector(selectIsAppLibraryWebView);

    const challengeData: IChallengeLevel[] = useSelector(selectChallenge75);

    const [openLevelInd, setOpenLevelInd] = useState<number | null>(0);

    const { openModal } = useModals();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchChallenge75());
    }, []);

    useEffect(() => {
        // @ts-ignore
        const lastActiveInd = challengeData && challengeData.findLastIndex((data) => data.active);

        lastActiveInd !== -1 && setOpenLevelInd(lastActiveInd);
    }, [challengeData]);

    const handleModalsClick = ({ eventLabel, modalNameArg }: { eventLabel: string; modalNameArg: ModalName }) => {
        sendAnalyticModalClick(eventLabel);

        openModal(modalNameArg);
    };

    const handleAppBackClick = () => navigate(-1);

    return (
        <S.ChallengeContainer paddingTop={32} paddingX={16} backgroundColor="surface-secondary">
            {isAppLibraryWebView && <WebViewBackArrow onClick={handleAppBackClick} text={t('basics.library')} />}
            <S.ChallengeContent>
                <S.BannerContainer>
                    <Text type="h3" medium text={t('challenge.banner.title')} />
                    <S.Banner>
                        <S.BannerBG alt="rocket" src={rocket} maxWidth={343} />
                        <S.BannerContent>
                            <Text type="h4" medium text={t('challenge.banner.text')} />
                            <S.StatusLabel data-locator="challengeAcceptedButton">
                                <Check />
                                <Text type="large-text" medium text={t('challenge.banner.labelText')} />
                            </S.StatusLabel>
                        </S.BannerContent>
                    </S.Banner>
                </S.BannerContainer>
                <Text type="h6" color="secondary-default" mb={9} text={t('challenge.journey.title')} />
                <Text type="large-text" mb={20} text={t('challenge.journey.text')} />
                <S.LinksWrap>
                    <S.Link
                        role="button"
                        onClick={() =>
                            handleModalsClick({
                                eventLabel: 'more_about_challenge',
                                modalNameArg: ModalName.challengeMoreModal,
                            })
                        }
                        data-locator="75DayChallengeMoreAboutButton"
                    >
                        <Text type="small-button" uppercase text={t('challenge.link.moreAbout')} />
                    </S.Link>
                    <S.Link
                        role="button"
                        onClick={() =>
                            handleModalsClick({
                                eventLabel: 'challenge_rules',
                                modalNameArg: ModalName.challengeRulesModal,
                            })
                        }
                        data-locator="75DayChallengeRulesButton"
                    >
                        <Text type="small-button" uppercase text={t('challenge.link.rules')} />
                    </S.Link>
                </S.LinksWrap>
                {challengeData &&
                    challengeData.map((challengeLevel, index) => (
                        <LevelBlock
                            key={challengeLevel.name}
                            challengeLevel={challengeLevel}
                            isOpen={index === openLevelInd}
                            onToggle={() => setOpenLevelInd(index === openLevelInd ? null : index)}
                        />
                    ))}
            </S.ChallengeContent>
        </S.ChallengeContainer>
    );
};

export default Challenge;
