import { Box } from 'wikr-core-components';
import styled, { css, keyframes } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const ReasonContainer = styled(Box)`
    position: relative;
    max-width: 432px;
    padding: 20px 16px 28px;
    margin: 0 auto;
    text-align: center;
`;

export const loaderAnimation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

export const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;

    > div {
        animation: ${loaderAnimation} 2s linear infinite;
        width: fit-content;
        height: 24px;
    }
`;

export const ReasonItem = styled.button<{ isSelected: boolean }>`
    background: ${color('surface-default')};
    width: 100%;
    border-radius: 16px;
    text-align: left;
    margin-bottom: 12px;
    cursor: pointer;
    overflow: hidden;
    //transition: 0.2s ease;
    height: 64px;
    padding: 12px 16px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);

    > * {
        //transition: 0.2s ease;
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            background: ${color('primary-hovered')};
            font-weight: 600;

            > * {
                color: ${color('primary-active')};
            }
        `}
    ${mediaQueries.laptop} {
        &:hover {
            background: ${color('primary-hovered')};
            font-weight: 600;

            > * {
                color: ${color('primary-active')};
            }
        }
    }
`;
