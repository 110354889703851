import { Box } from 'wikr-core-components';
import React from 'react';

import { sendAnalyticSubscriptionContactUsClick } from 'services/analytics';

import { useSubscriptions, useRestoreSubscription } from 'hooks';

import ContactUsLink from 'components/ContactUsLink';
import SubscriptionsSkeleton from './components/SubscriptionsSkeleton';
import SubscriptionCard from './components/SubscriptionCard';

import { ContactUsWrapper } from './styled';

import { ISubscription } from 'types/subscription';

import useSubscriptionScreenLoad from './useSubscriptionScreenLoad';
import useCancelOfferFlow from './useCancelOfferFlow';

const SubscriptionsTab = () => {
    const { isLoading, subscriptions } = useSubscriptions();
    const { onCancelSubscriptionFlow } = useCancelOfferFlow();
    const { showRestoreCandidate } = useRestoreSubscription();

    useSubscriptionScreenLoad(subscriptions);

    const cancelFlowHandler = onCancelSubscriptionFlow;

    const displaySkeleton = isLoading && !subscriptions?.length;

    return (
        <Box paddingTop={40} paddingBottom={40}>
            {displaySkeleton && <SubscriptionsSkeleton />}

            {!displaySkeleton &&
                subscriptions.map((subscription: ISubscription) => (
                    <SubscriptionCard
                        key={subscription.id}
                        subscription={subscription}
                        onCancelSubscription={cancelFlowHandler}
                        showRestoreCandidate={showRestoreCandidate}
                    />
                ))}

            <ContactUsWrapper>
                <ContactUsLink
                    onClick={sendAnalyticSubscriptionContactUsClick({ tariff: subscriptions[0]?.product.id })}
                    data-locator="subscriptionFaqBtn"
                    subject="subscription"
                />
            </ContactUsWrapper>
        </Box>
    );
};

export default SubscriptionsTab;
