import { Image, Text } from 'wikr-core-components';
import React from 'react';

import * as S from './styled';

import back from './img/back.webp';

interface WebViewBackArrowProps {
    onClick: () => void;
    text: string;
}

const WebViewBackArrow = ({ onClick, text }: WebViewBackArrowProps) => {
    return (
        <S.WebViewNavBar onClick={onClick}>
            <Image alt="back-arrow" src={back} maxWidth={20} />
            <Text type="small-text" medium text={text} />
        </S.WebViewNavBar>
    );
};

export default WebViewBackArrow;
