import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import ratingStars from 'assets/img/ratingStars.svg';

interface RatingBlockProps {
    rating: {
        downloads: string;
        ratingKey: string;
    }[];
}

const RatingBlock = ({ rating }: RatingBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.RatingContainer>
            {rating.map(({ ratingKey, downloads }) => (
                <S.RatingItem key={ratingKey}>
                    <Image alt="rating" src={ratingStars} maxWidth={88} />
                    <Text text={downloads} type="h6" center />
                    <Text text={t(ratingKey)} type="small-text" medium center />
                </S.RatingItem>
            ))}
        </S.RatingContainer>
    );
};

export default RatingBlock;
