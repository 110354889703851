import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const ValidationList = styled(Box)`
    display: flex;
    flex-direction: row;
    font-size: 12px;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: -20px;
    margin-bottom: 12px;
    span {
        color: #4e4b66;
        margin-bottom: unset;
        font-weight: 600;
    }
`;
