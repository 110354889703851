import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Wrapper = styled.div`
    background-color: ${color('surface-main')};
    position: fixed;
    top: 56px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
`;

export const Container = styled.div`
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    ${mediaQueries.theSmallestDesktop} {
        margin: 0 60px;
        flex-direction: row;
        justify-content: center;
        gap: 80px;
    }
`;

export const ImageWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-width: 570px;
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    max-height: 30vh;

    ${mediaQueries.theSmallestDesktop} {
        max-height: unset;
    }
`;

export const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    width: 100%;
    max-width: 360px;
    text-align: center;
`;

export const FeedbackWrapper = styled.div`
    display: flex;
    gap: 32px;
    margin-bottom: 40px;
`;

export const FeedbackIconWrapper = styled.div<{ $active?: boolean }>`
    width: 64px;
    height: 64px;
    border: 2px solid ${({ $active }) => color($active ? 'secondary-default' : 'border-subdued')};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    transition: border-color 0.2s;

    & > img {
        width: 32px;
        height: 32px;
    }
`;

export const FeedbackButton = styled.button`
    border: none;
    padding: 0;
    cursor: pointer;
`;
