import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 20px;
    width: 100%;
    min-height: 148px;
`;

export const Title = styled(Text)`
    width: 100%;
`;

export const Footer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
`;
