import styled from 'styled-components';

import { color } from 'theme/selectors';

export const PaginationContainer = styled.div<{ background?: string }>`
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
        padding: 0 32px;
    }
`;

export const SpanPagination = styled.span<{ isFilled: boolean }>`
    width: 100%;
    height: 4px;
    margin-right: 6px;
    border-radius: 2px;
    background-color: ${({ isFilled }) => (isFilled ? color('secondary-default') : color('border-subdued'))};

    &:last-of-type {
        margin-right: 0;
    }
`;
