import { Navigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';

import { PATHS } from 'constants/routerPaths';

interface ProtectedRouteProps {
    redirectPath?: string;
    validators?: Array<() => boolean>;
}
const ProtectedRoute = ({ redirectPath, validators, children }: PropsWithChildren<ProtectedRouteProps>) => {
    if (validators && !validators.every((validate) => validate())) {
        return <Navigate to={redirectPath ?? PATHS.LOGIN} />;
    }

    return children;
};

export default ProtectedRoute;
