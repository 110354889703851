import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

// mainTrackers
import { trackClick } from './mainTrackers';

export const sendAnalyticCTAButtonClick = (btnIndex: number) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
        eventLabel: `${btnIndex}`,
    });
};
export const sendAnalyticUserChoiceButtonClick = (isPositive: boolean) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.select,
        eventLabel: `${isPositive ? 1 : 0}`,
    });
};
