import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
    sendAnalyticInsCategoryNextClick,
    sendAnalyticInsCategoryPrevClick,
} from 'services/analytics/trackers/insights';

import PrevButtonInsights from 'components/PrevButtonInsights';
import NextButtonInsights from 'components/NextButtonInsights';

import {
    ButtonsWrapper,
    ButtonWrapper,
    PaginationWrap,
    StackContainer,
    StackStories,
    StackStoriesBackground,
} from './styled';

import { IBlockByIdArticlesItem } from 'types/insights';

import SkeletonBlockPreviewLayout from './SkeletonBlockPreviewLayout';
import ArticleHeader from './ArticleHeader';

interface IBlockPreviewProps {
    articles: IBlockByIdArticlesItem[];
    blockPreviewColor: string;
    skeletonColor: string;
    isPreviewLayoutLoading?: boolean;
    onNextClick?: () => void;
    onPrevClick?: () => void;
    withStoryEnd?: boolean;
}

function BlockPreview({
    blockPreviewColor,
    articles,
    onNextClick,
    onPrevClick,
    isPreviewLayoutLoading,
    skeletonColor,
    withStoryEnd = true,
}: IBlockPreviewProps) {
    const { categoryId, blockId } = useParams();
    const [currentStory, setCurrentStory] = useState(1);

    useEffect(() => setCurrentStory(1), [articles]);

    const isLast = currentStory === (articles.length || 1) - 1;
    const isFirst = currentStory <= 1;

    const handleNextStory = () => {
        sendAnalyticInsCategoryNextClick(categoryId, blockId);
        !isLast && setCurrentStory(currentStory + 1);

        isLast && onNextClick && onNextClick();
    };
    const handlePrevStory = () => {
        sendAnalyticInsCategoryPrevClick(categoryId, blockId);
        !isFirst && setCurrentStory(currentStory - 1);

        isFirst && onPrevClick && onPrevClick();
    };

    return (
        <StackContainer background={blockPreviewColor} data-locator="insightStoryContainer">
            <PaginationWrap>
                <ArticleHeader
                    articlesLength={articles?.length}
                    currentIndex={articles ? currentStory : 0}
                    dataLocator="insightStoryStep"
                />
            </PaginationWrap>
            {isPreviewLayoutLoading ? (
                <SkeletonBlockPreviewLayout skeletonColor={skeletonColor} />
            ) : (
                <>
                    {articles.map(
                        (article, index, array) =>
                            index !== 0 && (
                                <StackStories
                                    key={article.id}
                                    isPrev={index < currentStory}
                                    zIndex={array.length - index}
                                    background={blockPreviewColor}
                                >
                                    <iframe width="100%" height="100%" srcDoc={article.template} title="block" />
                                </StackStories>
                            )
                    )}
                    <StackStoriesBackground key={0} background={blockPreviewColor} isPrev={false} zIndex={0} />
                    <ButtonsWrapper>
                        <ButtonWrapper>
                            <PrevButtonInsights
                                disabled={withStoryEnd && isFirst}
                                onClick={handlePrevStory}
                                dataLocator="insightStoryPreviousStepButton"
                            />
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <NextButtonInsights
                                disabled={withStoryEnd && isLast}
                                onClick={handleNextStory}
                                dataLocator="insightStoryNextStepButton"
                            />
                        </ButtonWrapper>
                    </ButtonsWrapper>
                </>
            )}
        </StackContainer>
    );
}

export default BlockPreview;
