import * as action from './actions';

import * as actionTypes from './actionTypes';

import { WorkoutsState } from 'types/workouts/workoutsApiInterface';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: WorkoutsState = {
    isShowWorkoutsNotification: null,
    filtersData: null,
    isCloseLocalisationInfoBanner: false,
};

const workoutsReducer = (state = initialState, action: ActionType): WorkoutsState => {
    switch (action.type) {
        case actionTypes.SET_IS_SHOW_WORKOUTS_NOTIFICATION:
            return { ...state, isShowWorkoutsNotification: action.payload };

        case actionTypes.SET_FILTERS_DATA:
            return { ...state, filtersData: action.payload };

        case actionTypes.SET_IS_CLOSE_LOCALISATION_INFO_BANNER:
            return { ...state, isCloseLocalisationInfoBanner: action.payload };

        default:
            return state;
    }
};

export default workoutsReducer;
