export const getContent = () => {
    return [
        {
            dayName: 'week.mon',
            dataLocator: 'monProgress',
        },
        {
            dayName: 'week.tue',
            dataLocator: 'tueProgress',
        },
        {
            dayName: 'week.wed',
            dataLocator: 'wedProgress',
        },
        {
            dayName: 'week.thu',
            dataLocator: 'thuProgress',
        },
        {
            dayName: 'week.fri',
            dataLocator: 'friProgress',
        },
        {
            dayName: 'week.sat',
            dataLocator: 'satProgress',
        },
        {
            dayName: 'week.sun',
            dataLocator: 'sunProgress',
        },
    ];
};
