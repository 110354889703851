import { Colors, Theme } from 'wikr-core-components';

import { withAlpha } from './helpers';

export const color =
    (colorKey: Colors, alpha?: number) =>
    ({ theme }: { theme: Theme }) => {
        const themeColor = theme[colorKey];

        return alpha ? withAlpha(themeColor, alpha) : themeColor;
    };
