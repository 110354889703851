import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';

import * as S from './styled';

import { ReactComponent as Cross } from './img/Cross.svg';

import { IOnePointProps } from './types';

export const OnePoint = ({ text }: IOnePointProps) => {
    return (
        <S.OnePointWrap>
            <S.CheckWrap>
                <Cross />
            </S.CheckWrap>
            <Text type="large-text">
                <Trans i18nKey={text} components={{ bold: <b /> }} />
            </Text>
        </S.OnePointWrap>
    );
};
