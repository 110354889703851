export enum GuideStatus {
    free = 'free',
    purchased = 'purchased',
    offer = 'offer',
}

export interface IGuide {
    guide_code: string;
    name: string;
    preview: string;
    download_url: string;
    internal_name?: string;
    status: GuideStatus;
}
