import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import cross from '../../img/crossSmall.svg';

import { AppliedFiltersProps } from '../../types';

const AppliedFilters = ({ activeOptions, clearAll, removeOption }: AppliedFiltersProps) => {
    const { t } = useTranslation();

    return (
        <S.Wrapper>
            {activeOptions.length > 1 && (
                <S.ClearButton onClick={clearAll} data-locator="selectedFilterClearAllButton">
                    <Text text={t('workouts.filters.clearAll')} type="small-text" />
                </S.ClearButton>
            )}
            {activeOptions.map((option, index) => (
                <S.Option key={index}>
                    <Text
                        text={t(option.title)}
                        dataLocator="workoutLibrarySelectedFilter"
                        type="small-text"
                        color="text-secondary"
                    />
                    <img
                        src={cross}
                        alt="remove"
                        onClick={() => removeOption(option)}
                        data-locator="workoutLibrarySelectedFilterRemoveButton"
                    />
                </S.Option>
            ))}
        </S.Wrapper>
    );
};

export default AppliedFilters;
