import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import mediaQueries from 'theme/mediaQueries';

export const SkeletonWrapper = styled.div`
    ${mediaQueriesGrid.tablet} {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    ${mediaQueries.theSmallestDesktop} {
        gap: 20px;
    }
`;

export const SkeletonItem = styled.div`
    flex-shrink: 0;
    margin: 0 auto 16px;
    background: ${color('on-primary')};
    border-radius: 16px;
    overflow: hidden;

    ${mediaQueriesGrid.tablet} {
        width: calc(50% - 8px);
        margin: unset;
    }

    ${mediaQueries.theSmallestDesktop} {
        width: calc(33.3% - 14px);
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    aspect-ratio: 16 / 9;
    margin-bottom: 16px;
`;

export const InfoBlock = styled.div`
    padding: 16px 20px 20px;
`;
