import { Input } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { measure } from 'constants/units';

import { isEmpty } from 'helpers/utils';

import * as S from './styled';

import { FormikInputType, InputType } from 'types/ui';

const { TALL_UNIT } = measure;

const FormikInput = ({
    values,
    errors,
    initialValues,
    setFieldTouched,
    setFieldValue,
    setFieldFocused,
    touched,
    field,
    type = 'text',
    label,
    unit,
    className,
    leftIcon,
    innerRef,
    rightIcon,
    dataLocator,
    placeholder,
    theme,
    mb,
    showErrorMessage = true,
}: FormikInputType) => {
    const [isFocus, setIsFocus] = useState(false);

    const { t } = useTranslation();

    const errorStatus = /* !isFocus && */ Boolean(errors[field] && touched[field]);
    const successStatus = !errors[field] && Boolean(touched[field]) && !isEmpty(values[field]);

    const withoutInchLabel = unit === TALL_UNIT.imperial.in ? '' : t(label || field);

    const onFocusHandler = () => {
        setFieldFocused?.();

        setIsFocus(true);
    };

    return (
        <S.StyledWrapper mb={mb}>
            <Input
                label={withoutInchLabel}
                name={field}
                type={type as InputType}
                value={`${values[field]}`}
                unit={unit}
                placeholder={placeholder && t(placeholder)}
                initialValue={`${initialValues[field]}`}
                errorStatus={errorStatus}
                successMessage={t('validation.perfect')}
                onBlur={() => {
                    setIsFocus(false);
                    setFieldTouched(field);
                }}
                successStatus={successStatus}
                onClear={() => setFieldValue(field, '')}
                className={className}
                onFocus={onFocusHandler}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                ref={innerRef}
                onChange={(e) => {
                    setFieldValue(field, e.target.value);
                    setFieldTouched(field);
                }}
                dataLocator={dataLocator}
                theme={theme}
                isFocus={isFocus}
                errorMessage={showErrorMessage ? t(errors[field] || '') : ''}
            />
        </S.StyledWrapper>
    );
};

export default withTheme(FormikInput);
