import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { useReturnOffer } from 'hooks';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';

import * as S from './styled';

import { ISubscriptionCardCommonProps } from '../types';

import SubscriptionData from './SubscriptionData';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';

const ExpiresSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    expirationDate,
    subscriptionType,
    isDiscountOfferAvailable,
}: ISubscriptionCardCommonProps) => {
    const { t } = useTranslation();
    const { onShowReturnOffer } = useReturnOffer();

    const subscriptionData = {
        subscriptionPeriod,
        subscriptionType,
        price,
        expirationDate,
        isCancelled: true,
    };

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <SubscriptionData data={subscriptionData} />
            <S.ExpiresOnBanner mb={isDiscountOfferAvailable ? 20 : 0}>
                <Text medium text={t('subscription.cancellation.expiresSubscription.bannerTitle')} />
                <Text
                    text={t('subscription.cancellation.expiresSubscription.bannerText', {
                        expirationDate,
                    })}
                />
            </S.ExpiresOnBanner>
            {isDiscountOfferAvailable && (
                <SecondaryDefaultButton
                    dataLocator="discountOfferChangePlanButton"
                    text={t('subscription.cancellation.showReturnOffer')}
                    onClick={onShowReturnOffer}
                />
            )}
        </SubscriptionCardWrapper>
    );
};

export default ExpiresSubscription;
