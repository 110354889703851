interface TALL_UNIT {
    imperial: Record<string, string>;
    metric: string;
}

interface MEASURE {
    MEASURE_UNITS: Record<string, string>;
    WEIGHT_UNIT: Record<string, string>;
    TALL_UNIT: TALL_UNIT;
}

export const measure: MEASURE = {
    MEASURE_UNITS: {
        imperial: 'lbs, in',
        metric: 'kg, cm',
    },
    WEIGHT_UNIT: { imperial: 'lbs', metric: 'kg' },
    TALL_UNIT: {
        imperial: {
            ft: 'ft',
            in: 'in',
        },
        metric: 'cm',
    },
};
