type Props = {
    level: 'beginner' | 'intermediate' | 'advanced';
};

const FILLED_COLOR = '#454E57';
const UNFILLED_COLOR = '#D4DAE0';

const IconLevel = ({ level }: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
            <rect
                width="5.714"
                height="4.286"
                x="4.286"
                y="11.073"
                fill={FILLED_COLOR}
                rx="2.143"
                transform="rotate(90 4.286 11.073)"
            />
            <rect
                width="10"
                height="4.286"
                x="10.714"
                y="6.783"
                fill={level === 'intermediate' || level === 'advanced' ? FILLED_COLOR : UNFILLED_COLOR}
                rx="2.143"
                transform="rotate(90 10.714 6.783)"
            />
            <rect
                width="14.286"
                height="4.286"
                x="17.143"
                y="2.499"
                fill={level === 'advanced' ? FILLED_COLOR : UNFILLED_COLOR}
                rx="2.143"
                transform="rotate(90 17.143 2.499)"
            />
        </svg>
    );
};

export default IconLevel;
