import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const StyledWrapper = styled(Box)`
    > * {
        margin-bottom: 24px;
    }
`;

export const Textarea = styled.textarea<{ isFocus: boolean }>`
    border-radius: 10px;
    border: 1.5px solid;
    background: ${color('on-primary')};
    border-color: ${({ isFocus }) => (isFocus ? color('primary-default') : color('border-default'))};
    min-height: 140px;
    padding: 10px 12px;
    width: 100%;
    resize: none;
    outline: none;

    &::placeholder {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.408px;
    }
`;
