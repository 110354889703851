import { Box } from 'wikr-core-components';
import styled from 'styled-components';

// const
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Container = styled(Box)`
    padding: 24px 16px;
    max-width: 432px;
    margin: 0 auto;

    ${mediaQueriesGrid.tablet} {
        padding: 24px 0;
    }
`;

export const ProductsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
`;
