import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

import Modal from 'components/Modal';

import * as S from './styled';

import { getContent } from './config';

const ChallengeMoreModal = ({ onClose }: { onClose: () => void }) => {
    const { t } = useTranslation();

    const { title, challengePoints, levelsDiffPoints, howItWorks } = getContent();

    return (
        <Modal
            isOpen
            onClose={onClose}
            dataLocator="moreAboutChallengeModal"
            closeOnOverlayClick
            isChallengeModal
            challengeTitle={t(title)}
        >
            {challengePoints.map((challengePoint) => (
                <>
                    <Text
                        dataLocator={challengePoint.titleDataLocator}
                        type="large-text"
                        bold
                        mb={8}
                        text={t(challengePoint.title)}
                    />
                    <Text dataLocator={challengePoint.textDataLocator} mb={20} text={t(challengePoint.text)} />
                </>
            ))}
            <S.BannerContainer paddingTop={4}>
                <S.BannerBlock>
                    <Text type="large-text" bold text={t(levelsDiffPoints.title)} />
                    {levelsDiffPoints.points.map((point) => (
                        <Text key={point} text={t(point)} />
                    ))}
                </S.BannerBlock>
                <S.BannerBlock>
                    <Text type="large-text" bold text={t(howItWorks.title)} />
                    <Text text={t(howItWorks.text)} />
                </S.BannerBlock>
            </S.BannerContainer>
        </Modal>
    );
};

export default memo(ChallengeMoreModal);
