import { Text, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from './styled';

import { ISubscriptionCardHeader } from '../types';

const SubscriptionCardHeader = ({ icon, heading, isExpired }: ISubscriptionCardHeader) => {
    const { t } = useTranslation();

    return (
        <S.SubscriptionCardHeader isExpired={isExpired}>
            <S.Name>
                <Image src={icon} alt={heading} maxWidth={32} />
                <Text
                    type="large-text"
                    medium
                    color={isExpired ? 'text-secondary' : 'text-main'}
                    text={t(heading)}
                    dataLocator="subscriptionTitle"
                />
            </S.Name>
        </S.SubscriptionCardHeader>
    );
};

export default SubscriptionCardHeader;
