import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    height: 88px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 12px;
`;

export const GuideImg = styled.img`
    height: 68px;
`;

export const DownloadGuideLink = styled.a``;

export const GuideTitleContainer = styled.div`
    width: 100%;
    margin-left: 20px;

    p {
        font-weight: 700;
    }
`;
