import { Box, Image } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const MainContainer = styled(Box)`
    width: 100%;
    height: calc(100vh - 65px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Container = styled(Box)`
    width: 100%;
    height: 100%;
    max-width: unset;
    border-radius: unset;
    box-shadow: unset;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: relative;
    ${mediaQueries.tabletM} {
        max-width: 580px;
        height: 480px;
        border-radius: 32px;
        box-shadow: 0px 0px 16px 0px #00000014;
    }
`;
export const ImgBox = styled(Box)`
    width: 100%;
    height: 50%;
    position: relative;
`;
export const BackgroundImg = styled(Image)`
    width: 100%;
    min-height: 450px;
    height: 100%;
    object-fit: cover;
    ${mediaQueries.tabletM} {
        height: 240px;
        min-height: unset;
    }
`;
export const MainImg = styled(Image)`
    width: 100%;
    max-width: 200px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0%);
    ${mediaQueries.tabletM} {
        bottom: 0;
    }
`;
export const InfoBlock = styled(Box)`
    width: 200%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    z-index: 1;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, 100%);
    left: 50%;
    ${mediaQueries.tabletM} {
        height: auto;
    }
`;
