import { useCallback } from 'react';

import { fetchDiscountSubscriptions } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DISCOUNT_TYPES } from 'constants/subscriptions';

import { CancelCandidate } from 'hooks/subscriptions/types';
import { useCancelSubscription, useModals, useDispatch } from 'hooks';

const useCancelOfferFlow = () => {
    const { openModal } = useModals();
    const { cancellationCandidate: candidateFromStore } = useCancelSubscription();

    const dispatch = useDispatch();
    // const handleVipSupportCancel = () => {
    //     openModal(ModalName.secondSubscriptionCancelModal, {
    //         modalData: getVipSupportCancelModalData(t),
    //     });
    // };

    // const handleSecondSubscriptionCancel = (productCode: string, expirationDateUI: string) => {
    //     openModal(ModalName.secondSubscriptionCancelModal, {
    //         modalData: getSecondSubscriptionCancelModalData({
    //             t,
    //             productCode,
    //             expirationDateUI,
    //         }),
    //     });
    // };

    const handleMainSubscriptionCancelFlow = () => {
        openModal(ModalName.alertModal);
        // openModal(ModalName.reasonModal);
    };

    // const handleDiscountOfferFlow = () => {
    //     openModal(ModalName.specialOfferModal);
    // };

    const onCancelSubscriptionFlow = useCallback(
        (candidateFromEvent?: CancelCandidate) => {
            const cancellationCandidate = candidateFromEvent || candidateFromStore;

            if (!cancellationCandidate) {
                return;
            }

            const hasDiscount = Boolean(cancellationCandidate?.isDiscount);
            // const isDiscountAvailable = Boolean(cancellationCandidate.discounts.isDiscountOfferAvailable);

            // const isVipSupport = cancellationCandidate.productCode === PRODUCT_CODES.APP_VIP_SUPPORT;

            // const isSecondSubscription =
            //     cancellationCandidate.productCode !== PRODUCT_CODES.APP_VIP_SUPPORT &&
            //     cancellationCandidate.productCode !== PRODUCT_CODES.APP_FULL_ACCESS;

            // if (isVipSupport) {
            //     handleVipSupportCancel();
            //
            //     return;
            // }

            // if (isSecondSubscription) {
            //     handleSecondSubscriptionCancel(
            //         cancellationCandidate.productCode,
            //         cancellationCandidate.expirationDateUI
            //     );
            //
            //     return;
            // }

            // if (!isDiscountAvailable) {
            //     onCancelSubscriptionOneClick(cancellationCandidate);
            //
            //     return;
            // }

            !hasDiscount &&
                dispatch(
                    fetchDiscountSubscriptions({
                        discount_type: DISCOUNT_TYPES.base,
                        external_id: cancellationCandidate.externalId,
                    })
                );

            // const hasOffer = Boolean(
            //     cancellationCandidate.discounts.pause ||
            //         cancellationCandidate.discounts.free_month ||
            //         cancellationCandidate.reminder ||
            //         guides_offer
            // );

            // if (!hasOffer && !hasDiscount) {
            //     handleMainSubscriptionCancelFlow();
            // } else if (hasOffer && !hasDiscount) {
            //     handleDiscountOfferFlow();
            // } else if (hasDiscount) {
            //     onCancelSubscriptionOneClick(cancellationCandidate);
            // }

            handleMainSubscriptionCancelFlow();
        },
        [candidateFromStore]
    );

    return { onCancelSubscriptionFlow };
};

export default useCancelOfferFlow;
