import * as S from './styled';

export const getContent = (isDemo: boolean) => {
    return [
        {
            i18nComponentSpan: () => <S.HoursStyled />,
            hours: isDemo ? 51 : 0,
            caption: 'dashboard.weeklyProgress.hour.totalFasts',
            dataLocator: 'totalFasts',
        },
        {
            i18nComponentSpan: () => <S.HoursStyled />,
            hours: isDemo ? 16 : 0,
            caption: 'dashboard.weeklyProgress.hour.averageFasts',
            dataLocator: 'averageFast',
        },
        {
            i18nComponentSpan: () => <S.HoursStyled />,
            hours: isDemo ? 18 : 0,
            caption: 'dashboard.weeklyProgress.hour.longestFasts',
            dataLocator: 'longestFast',
        },
    ];
};
