import { trackClickCustom, customTrackDecorator } from './mainTrackers';

export const sendAnalyticModalClick = (eventLabel?: string) => {
    trackClickCustom({
        event_label: eventLabel,
    });
};

export const sendAnalyticDownloadChallengeClick = (eventLabel: string) => {
    customTrackDecorator('challenge_download__click', '', eventLabel);
};
