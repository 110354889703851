import config from 'config';

import Sentry from './Sentry';

const tracesSampleRate = config.ENV === 'prod' ? 0.05 : 1;

export default new Sentry({
    release: `${config.AWS_DELIVERY_STREAM_NAME}_${config.ENV}_${config.RELEASE_DATE}`,
    sentryDSN: config.SENTRY_DSN,
    env: config.ENV,
    tracesSampleRate,
    autoSessionTracking: true,
});
