import { RouterProvider } from 'react-router-dom';

import NotificationsContainer from 'containers/Notifications';

import NoConnectionModal from 'components/Modal/NoConnectionModal';

import ThemeProvider from './theme/provider';
import router from './router';

function App() {
    return (
        <ThemeProvider>
            <NoConnectionModal />
            <NotificationsContainer />
            <RouterProvider router={router} />
        </ThemeProvider>
    );
}

export default App;
