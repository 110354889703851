import { Theme } from 'wikr-core-components';
import { createGlobalStyle } from 'styled-components';

import resetStyles from './styles/reset';
import common from './styles/common';
import { color } from './selectors';

const GlobalStyle = createGlobalStyle<{ rootVariables: string; theme: Theme }>`
    :root {
        ${({ rootVariables }) => rootVariables}
    }

    body {
        background: ${color('surface-main')};

        &.ReactModal__Body--open {
            overflow: hidden;
        }
        
        font-family: 'Sora', sans-serif !important;
    }


    ${resetStyles}
    ${common}

`;

export default GlobalStyle;
