import { useEffect, useRef } from 'react';

import { trackScreenLoad } from 'services/analytics';

import { ISubscription } from 'types/subscription';

const useSubscriptionScreenLoad = (subscriptions: ISubscription[] | undefined) => {
    const isMounted = useRef(false);

    useEffect(() => {
        if (!subscriptions?.length || isMounted.current) return;

        const sub = subscriptions[0];

        const { product } = sub;

        trackScreenLoad('subscription', { tariff: product.id, event_label: sub.status });

        isMounted.current = true;
    }, [subscriptions]);
};

export default useSubscriptionScreenLoad;
