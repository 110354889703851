import styled from 'styled-components';

import { color } from 'theme/selectors';

export const AppPreviewContainer = styled.div`
    background: #f2efee;
    background: ${color('surface-default')};
    border-radius: 20px;
    padding: 32px 12px;
    margin-bottom: 16px;
`;
