import { ButtonHTMLAttributes } from 'react';

import * as S from './styled';

import { ReactComponent as SVGArrow } from './assets/arrow-left-long.svg';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    onClick?: () => void;
    withShadow?: boolean;
};

const BackButton = ({ withShadow, ...buttonProps }: Props) => {
    return (
        <S.RoundedButton id="roundedBackButton" $withShadow={Boolean(withShadow)} {...buttonProps}>
            <SVGArrow />
        </S.RoundedButton>
    );
};

export default BackButton;
