import {
    sendAnalyticCancelFromLifeTimeFinalClick,
    sendAnalyticDiscardCancelFromLifeTimeFinalClick,
} from 'services/analytics';

import { useCancelSubscription } from 'hooks';

import SurveyThankYouModal from '../SurveyThankYouModalContainer/SurveyThankYouModal';

const LifeTimeAccessFinalModalContainer = () => {
    const { onCancelSubscription, discardCancellation } = useCancelSubscription();

    const onDiscardCancelHandler = () => {
        sendAnalyticDiscardCancelFromLifeTimeFinalClick();

        discardCancellation();
    };
    const onSubmitHandler = () => {
        sendAnalyticCancelFromLifeTimeFinalClick();

        onCancelSubscription();
    };

    return <SurveyThankYouModal onSubmit={onSubmitHandler} onCancel={onDiscardCancelHandler} isLifetime />;
};

export default LifeTimeAccessFinalModalContainer;
