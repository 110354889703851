import { Text } from 'wikr-core-components';
import React from 'react';

import { isSafari } from 'helpers/utils';
import { getWindowSize } from 'helpers/getWindowSize';

import * as S from './styled';

export interface OneInsight {
    item: { id: number; color: string; name: string; cover: string; position: number; category: number };
    onBlockClick?: (value: number) => void;
}

function OneInsight({ item }: OneInsight) {
    const isDesktop = getWindowSize(905);

    return (
        <S.OneInsight
            data-locator="insightCard"
            // TODO:this is tem solution, we don't need to delete this code
            // onClick={() => onBlockClick(item.id)}
            isSafari={isSafari()}
            backgroundColor={item.color}
        >
            <Text
                text={item.name}
                color="text-main"
                type={isDesktop ? 'large-text' : 'small-text'}
                medium
                dataLocator="insightTitle"
            />
            <S.ImageWrapper>
                <S.ImageStyled alt="InsightImg" src={item.cover} />
            </S.ImageWrapper>
        </S.OneInsight>
    );
}

export default OneInsight;
