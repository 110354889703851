import * as actionTypes from './actionTypes';

import { FiltersData } from 'types/workouts/workoutsApiInterface';

export function setIsShowWorkoutsNotification(status: boolean | null) {
    return {
        type: actionTypes.SET_IS_SHOW_WORKOUTS_NOTIFICATION,
        payload: status,
    } as const;
}

export function setFiltersData(payload: FiltersData) {
    return {
        type: actionTypes.SET_FILTERS_DATA,
        payload,
    } as const;
}

export function setIsCloseLocalisationInfoBanner(payload: boolean) {
    return {
        type: actionTypes.SET_IS_CLOSE_LOCALISATION_INFO_BANNER,
        payload,
    } as const;
}
