import { trackClickCustom } from './mainTrackers';

export const sendAnalyticLibraryDownloadMealClick = (eventLabel?: string) => {
    trackClickCustom({
        event_label: eventLabel,
        event_action: 'meal_plan_download',
    });
};
export const sendAnalyticLibraryButtonClick = (eventLabel?: string) => {
    trackClickCustom({
        event_label: eventLabel,
        event_action: 'button_click',
    });
};
