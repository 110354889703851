import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

export const SkeletonDashboardInsight = styled(ContentLoader)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(25% - 15px);
    position: relative;
    border-radius: 20px;
    overflow: hidden;
`;
