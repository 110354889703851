import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const SecondaryDefaultButton = styled(Button)`
    background-color: ${color('secondary-default')};
    box-shadow: 0 0 0 0 ${color('secondary-default')};

    &:hover {
        background-color: ${color('secondary-hovered')} !important;
    }

    &:active {
        background-color: ${color('secondary-active')} !important;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${color('secondary-disabled')} !important;

        p {
            color: ${color('text-secondary')} !important;
        }
    }
`;
