import { Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_HEADINGS } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';

import { sendAnalyticsRestoreSubscriptionConfirmClick } from 'services/analytics';

import useRestoreSubscription from 'hooks/subscriptions/hooks/useRestoreSubscription';

import { getSubscriptionTitle } from 'components/SubscriptionsTab/components/SubscriptionCard/helpers';

import Modal from 'components/Modal';
import FeedbackModalsNavBar from 'components/FeedbackModalsNavBar';

import * as S from './styled';

import cardIcon from './img/cardIcon.webp';

import { IRestoringSubscriptionModalProps } from '../../types';

const RestoringSubscriptionModal = ({
    productCode,
    subscriptionPeriod,
    price,
    currencySign,
}: IRestoringSubscriptionModalProps) => {
    const { t } = useTranslation();

    const paymentLabel = getSubscriptionTitle(subscriptionPeriod, false);
    const { onRestoreSubscription } = useRestoreSubscription();

    const onConfirmRestoreSubscription = () => {
        sendAnalyticsRestoreSubscriptionConfirmClick(productCode === PRODUCT_CODES.APP_FULL_ACCESS);

        onRestoreSubscription();
    };

    return (
        <Modal dataLocator="updatingSubscriptionPlanModal" isOpen style={{ content: { padding: '16px' } }}>
            <S.NavBarWrapper mb={20}>
                <Image alt="icon" src={cardIcon} maxWidth={44} />
                <FeedbackModalsNavBar dataLocator="updatingSubscriptionPlanCloseButton" />
            </S.NavBarWrapper>
            <Text type="h6" medium text={t('subscription.restoringModal.title')} />
            <Text type="h5" mb={12} text={t(SUBSCRIPTION_HEADINGS[productCode])} />
            <S.Details mb={12}>
                <S.DateInfo>
                    <S.TextColumn text={t('subscription.cancellation.text.price')} />
                    <S.TextColumn medium text={`${price} ${currencySign}`} dataLocator="subscriptionPrice" />
                </S.DateInfo>
                <S.DateInfo>
                    <S.TextColumn text={t('subscription.cancellation.subscriptionCard.billingPeriod.title')} />
                    <S.TextColumn
                        medium
                        text={t('subscription.cancellation.subscriptionCard.billingPeriod.value', {
                            count: Number(paymentLabel?.count),
                            context: paymentLabel?.context,
                        })}
                        dataLocator="subscriptionBillingPeriod"
                    />
                </S.DateInfo>
            </S.Details>
            <Text mb={24} text={t('subscription.restoringModal.disclaimer', { price, currencySign })} />
            <Button
                backgroundColor="secondary-default"
                hoverColor="secondary-hovered"
                text={t('subscription.text.restore')}
                onClick={onConfirmRestoreSubscription}
                dataLocator="restoreSubscriptionButton"
            />
        </Modal>
    );
};

export default RestoringSubscriptionModal;
