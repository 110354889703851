import { Colors, Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const BackArrowIcon = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const fill = theme[color || 'primary-default'];

    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-chevron-left">
                <path
                    id="Vector"
                    d="M13.593 4.875L7.50024 10.9678L13.593 17.0605"
                    stroke={fill}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default withTheme(BackArrowIcon);
