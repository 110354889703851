export const LIFE_TIME_PRICE_INT = '49';
export const LIFE_TIME_PRICE_CENTS = '99';
export const MODAL_OVERLAY_STYLES = { zIndex: '999999999' };
export const MODAL_CONTENT_STYLES = {
    padding: '0',
    margin: '0 auto',
};

export const MOBILE_MODAL_CONTENT_STYLES = {
    borderRadius: '20px',
    overflow: 'hidden',
    maxWidth: '400px',
};
