import * as actionTypes from './actionTypes';

import { IChallengeResponse } from 'types/challenge75';

export const fetchChallenge75 = () => {
    return {
        type: actionTypes.FETCH_CHALLENGE_75,
    } as const;
};

export const setChallenge75 = (payload: IChallengeResponse) => {
    return {
        type: actionTypes.SET_CHALLENGE_75,
        payload,
    } as const;
};
