import { Text, Box, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import reviewer from './img/reviewer.webp';

export const TitleBlock = () => {
    const { t } = useTranslation();

    return (
        <S.TitleBlock>
            <S.Title type="h2" mb={12} medium text={t('welcome.benefitsForHealth.title')} />
            <S.ReviewedByBlock>
                <S.ReviewedTip borderRadius={12} backgroundColor="secondary-default">
                    <Text medium color="on-primary" type="caption" text={t('welcome.intermittentFasting.reviewedBy')} />
                </S.ReviewedTip>
                <S.ReviewerBlock>
                    <S.ReviewerImageWrap>
                        <Image once isForceVisible alt="reviewer" src={reviewer} />
                    </S.ReviewerImageWrap>
                    <Box>
                        <Text bold mb={2} text="Theresa Moutafis" />
                        <Text type="small-text" mb={2} text={t('welcome.intermittentFasting.reviewerSpecialisation')} />
                    </Box>
                </S.ReviewerBlock>
            </S.ReviewedByBlock>
        </S.TitleBlock>
    );
};
