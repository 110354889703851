import styled from 'styled-components';

import { color } from 'theme/selectors';

export const HiddenButton = styled.button`
    cursor: pointer;
    position: relative;
    padding: 11px 24px;
    margin-top: 12px;

    p {
        color: ${color('primary-default')};
        text-transform: unset;
        letter-spacing: unset;
    }
`;
