// mainTrackers
import { trackClick, trackEventFixed } from './mainTrackers';

const LOGIN_SCREEN_ID = 'login';

export const sendAnalyticLoginButtonClick = () => {
    trackClick({
        screenIdParam: LOGIN_SCREEN_ID,
        eventLabel: 'login',
    });
};

export const sendAnalyticResetPasswordClick = () => {
    trackClick({
        screenIdParam: LOGIN_SCREEN_ID,
        eventLabel: 'forgot_password',
    });
};

export const sendAnalyticLoginError = () => {
    trackEventFixed('login__error__load');
};

export const sendAnalyticLogoClick = () => {
    trackClick({
        screenIdParam: LOGIN_SCREEN_ID,
        eventLabel: 'logo',
    });
};

