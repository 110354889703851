import styled from 'styled-components';

import { color } from 'theme/selectors';

export const RoundedButton = styled.button<{
    $withShadow?: boolean;
}>`
    background-color: ${color('surface-main')};
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    padding: 0;
    margin: 0;
    ${(props) =>
        props.$withShadow &&
        `
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    `}
`;
