import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const DemoStatusContainer = styled(Box)`
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
`;

export const Description = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 4px;

    ${mediaQueriesGrid.tablet} {
        margin-right: 39px;
    }
`;

export const TruncatedMeaning = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
`;

export const Footer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        gap: 0;
    }
`;
