type GetSplittedListsArgs = { img: string; text: string }[];
interface GetSplittedListsReturn {
    secondFoodHalf: GetSplittedListsArgs;
    firstFoodHalf: GetSplittedListsArgs;
}

export const getSplittedLists = (foodList: GetSplittedListsArgs): GetSplittedListsReturn =>
    foodList.reduce<GetSplittedListsReturn>(
        (res, current, idx) => {
            idx % 2 === 0 ? res.firstFoodHalf.push(current) : res.secondFoodHalf.push(current);

            return res;
        },
        { secondFoodHalf: [], firstFoodHalf: [] }
    );
