import { Text, Box } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import { PATHS } from 'constants/routerPaths';

import * as S from './styled';

import arrowUp from '../../img/arrowUp.svg';
import arrowDown from '../../img/arrowDown.svg';

import { IGoalData } from '../ChartBlock/types';

interface ChartInfoProps {
    infoBlockData: IGoalData;
}

const ChartInfoBlock = ({ infoBlockData }: ChartInfoProps) => {
    const navigate = useNavigate();

    const handleStartInApp = () => {
        navigate(PATHS.MAIN);
    };

    const { curResultTitle, curResult, isArrowUp, logTodayTitle, todayResult, logTodayBtnText } = infoBlockData;

    const imgPath = isArrowUp ? arrowUp : arrowDown;

    return (
        <S.GoalContainer>
            <Box>
                <Text text={curResultTitle} type="overline" color="text-secondary" mb={6} />
                <S.ResultWeightWrapper>
                    <Text text={curResult} type="h3" dataLocator="weightLost" />
                    <S.ResultImgWrapper src={imgPath} alt="result" />
                </S.ResultWeightWrapper>
            </Box>
            <S.Divider />
            <Box>
                <Text text={logTodayTitle} type="overline" color="text-secondary" mb={12} />
                {todayResult && <Text type="h3" text={todayResult} />}
                {logTodayBtnText && (
                    <S.SmallButton
                        onClick={handleStartInApp}
                        backgroundColor="secondary-default"
                        hoverColor="secondary-hovered"
                        size="small"
                        text={logTodayBtnText}
                    />
                )}
            </Box>
        </S.GoalContainer>
    );
};

export default ChartInfoBlock;
