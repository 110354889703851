import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    ${mediaQueriesGrid.largeTablet} {
        flex-direction: row;
        height: 332px;
        margin-bottom: 20px;
    }
`;

export const GraphicContainer = styled.div`
    flex: 1;
    width: 100%;
    padding: 16px 16px 8px 0;
    border-radius: 24px;
    background-color: ${color('surface-main')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mediaQueriesGrid.largeTablet} {
        flex: 2;
    }
`;

export const GraphicWrapper = styled.div`
    width: 100%;
    height: 280px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
`;

export const StatisticsWrapper = styled.div`
    display: flex;
    gap: 20px;
`;
