import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';
import { useRouteError } from 'react-router';
import { useEffect } from 'react';
import { SeverityLevel } from '@sentry/react';

import sentry from 'services/Sentry/SentryInstance';
import { errorType, Extras, IError } from 'services/Sentry/interfaces';

import { ErrorPageContent } from './ErrorPageContent';

export const sentryCaptureEvent = (error: Error, type: errorType, level: SeverityLevel, extra?: Extras): void => {
    // @ts-ignore
    sentry.logError(error, type, level, extra);
};

export const ErrorPage = () => {
    const error = useRouteError();

    useEffect(() => {
        sentry.logError(error as IError, SENTRY_CABINET, ERROR_LEVELS.CRITICAL);
    }, [error]);

    return <ErrorPageContent />;
};
