const mediaQueries = {
    // TODO: check usage of every breakpoint - on design we should use only a few
    mobile: '@media (min-width: 321px)',
    tablet: '@media (min-width: 429px)',
    tabletM: '@media (min-width: 600px)',
    tabletL: '@media (min-width: 905px)',
    laptop: '@media (min-width: 768px)',
    tabletLandscape: '@media (min-width: 1024px)',
    theSmallestDesktop: '@media (min-width: 1240px)',
    smallDesktop: '@media (min-width: 1280px)',
    desktop: '@media (min-width: 1480px)',
    largeDesktop: '@media (min-width: 1900px)',
};

export default mediaQueries;
