import { combineReducers } from 'redux';

import { PartialPreloadedState } from './types';

import { configureStore } from '@reduxjs/toolkit';
import WorkoutsReducer from './workouts/reducer';
import UserReducer from './user/reducer';
import UiReducer from './uiEffects/reducer';
import SubscriptionsReducer from './subscriptions/reducer';
import { RESET_STATE } from './rootActionTypes';
import ModalsReducer from './modals/reducer';
import LoadingReducer from './loading/reducer';
import InsightsReducer from './insights/reducer';
import GuidesReducer from './guides/reducer';
import ErrorsReducer from './errors/reducer';
import DeepLinkReducer from './deepLink/reducer';
import DashboardReducer from './dashboard/reducer';
import Challenge75Reducer from './challenge75/reducer';
import AuthReducer from './auth/reducer';

const appReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    subscriptions: SubscriptionsReducer,
    guides: GuidesReducer,
    ui: UiReducer,
    loading: LoadingReducer,
    errors: ErrorsReducer,
    deepLink: DeepLinkReducer,
    insights: InsightsReducer,
    dashboard: DashboardReducer,
    modals: ModalsReducer,
    workouts: WorkoutsReducer,
    challenge75: Challenge75Reducer,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === RESET_STATE) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export const setupTestingStore = (preloadedState?: PartialPreloadedState) => {
    return configureStore({
        reducer: rootReducer,
        // @ts-ignore
        preloadedState,
    });
};

export type RootReducer = ReturnType<typeof rootReducer>;
export default rootReducer;
