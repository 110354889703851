import { Box, Image } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const ChartImgContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 24px 8px 16px 24px;
    align-items: flex-end;
`;

export const AxisY = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
    gap: 24px;
    min-width: 27px;
    margin-bottom: 28px;
`;

export const AxisXBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const EmptyXImage = styled.div`
    width: 44px;
    height: 136px;
    left: 0;
    top: 0;
    background: ${color('surface-secondary')};
    border-radius: 12px;
    margin-bottom: 12px;
`;

export const AxisXImage = styled(Image)`
    position: relative;
    z-index: 1;
`;

export const Chart = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    padding-left: 24px;
    justify-content: space-between;
    position: relative;
`;

export const Line = styled.div`
    position: absolute;
    height: 0;
    left: 24px;
    right: 0;
    top: 70px;
    border: 1px dashed ${color('secondary-hovered')};
`;
