import { selectModals } from 'store/modals/selectors';

import { MODAL_LIST } from './constants';

import { useSelector } from 'hooks';

const Modals = () => {
    const { modalId, modalProps } = useSelector(selectModals);

    const ModalComponent = modalId ? MODAL_LIST[modalId] : null;

    if (!ModalComponent) {
        return null;
    }

    return <ModalComponent {...modalProps} />;
};

export default Modals;
