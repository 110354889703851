import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from './styled';

import { ICancelButton } from '../types';

const CancelButton = ({ onCancel }: ICancelButton) => {
    const { t } = useTranslation();

    return (
        <S.CancelButton onClick={onCancel} data-locator="cancelRenewalButton">
            <Text type="overline" text={t('subscription.text.cancelRenewal')} />
        </S.CancelButton>
    );
};

export default CancelButton;
