import thumbUp from 'assets/img/subscriptionIcons/thumbUp.webp';
import exclamationMark from 'assets/img/subscriptionIcons/exclamationMark.webp';

import { IRestoreSuccessData, IRestoreErrorData } from './types';
import { RestoreCandidate } from '../../types';

export const getRestoreCandidateData = (restoreCandidate: RestoreCandidate) => {
    return {
        productCode: restoreCandidate.productCode,
        subscriptionPeriod: restoreCandidate.subscriptionPeriod,
        price: restoreCandidate.price,
        currencySign: restoreCandidate.currency,
    };
};

export const getRestoreSuccessData = ({ closeModal, t }: IRestoreSuccessData) => {
    return {
        img: thumbUp,
        imgMb: 12,
        title: t('subscription.restore.success.title'),
        btnText: t('basics.continue'),
        btnDataLocator: 'updatingSubscriptionPlanContinueButton',
        onBtnClick: closeModal,
    };
};

export const getRestoreErrorData = ({ onTryAgain, onSupportLinkClick, t }: IRestoreErrorData) => {
    return {
        img: exclamationMark,
        title: t('message.error.somethingWentWrong'),
        mainBtnText: t('basics.tryAgain'),
        mainBtnDataLocator: 'updatingSubscriptionPlanTryAgainButton',
        onMainBtnClick: onTryAgain,
        supportLinkDataLocator: 'updatingSubscriptionPlanContactSupportButton',
        onSupportLinkClick: onSupportLinkClick,
    };
};
