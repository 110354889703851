import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import 'slick-carousel/slick/slick.css';

import { getWindowSize } from 'helpers/getWindowSize';

import Modal from 'components/Modal';
import Ob1Slider from './components/Ob1Slider';
import DiscoverButton from './components/DiscoverButton';

import { getSliderImg } from './config';

function Onboarding1Modal({ isOpen, onDiscoverClick }: { isOpen: boolean; onDiscoverClick: () => void }) {
    const isBigModal = getWindowSize(768);

    const MODAL_STYLES = {
        maxWidth: isBigModal ? '690px' : '343px',
        padding: isBigModal ? '32px' : '20px',
        display: 'flex',
        flexDirection: 'column',
    };

    const { t } = useTranslation();

    const imagesForSlider = getSliderImg(isBigModal);

    return (
        <Modal dataLocator="onboardingModalContainer" style={{ content: MODAL_STYLES }} isOpen={isOpen}>
            <Text center type="h4" medium text={t('dashboard.onboarding1.title')} mb={8} />
            <Text center text={t('dashboard.onboarding1.subtitle')} mb={32} />
            <Ob1Slider imagesForSlider={imagesForSlider} />
            <DiscoverButton dataLocator="discoverDashboardButton" onClick={onDiscoverClick} />
        </Modal>
    );
}

export default Onboarding1Modal;
