import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { selectIsCalifornia } from 'store/user/selectors';
import { selectCancellationCandidate, selectSubscriptions } from 'store/subscriptions/selectors';
import { fetchDiscountSubscriptions, resetSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DEFAULT_UPDATE_SUBSCRIPTION_ERROR, DISCOUNT_TYPES } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';
import { SUBSCRIPTION_ACTIVE } from 'components/SubscriptionsTab/components/SubscriptionCard/constants';

import {
    sendAnalyticCancelOfferFScreenLoad,
    sendAnalyticReturnOfferFScreenLoad,
    sendAnalyticReturnOfferGetDiscountClick,
    sendAnalyticShowReturnOfferClick,
    sendAnalyticSpecialOfferGetDiscountClick,
    sendAnalyticSubscriptionUpdateError,
} from 'services/analytics';

import { getUpdatingSubscriptionLoadingData } from 'hooks/subscriptions/helpers';
import { useSelector } from 'hooks/store';
import { useModals } from 'hooks/modals';

import { getUpdatingErrorData, getUpdatingSuccessData } from './helpers';

const useReturnOffer = (isCRM = false) => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { t } = useTranslation();

    const { discountSubscription, subscriptions, resetCRMCandidateSubscriptionId } = useSelector(selectSubscriptions);
    const cancellationCandidate = useSelector(selectCancellationCandidate);
    const isCalifornia = useSelector(selectIsCalifornia);

    const getResetCandidate = () =>
        subscriptions.find(({ state, product_code, internal_cancel_at }) => {
            return (
                internal_cancel_at && state === SUBSCRIPTION_ACTIVE && product_code === PRODUCT_CODES.APP_FULL_ACCESS
            );
        });

    const getResetCRMCandidate = () =>
        subscriptions.find(({ external_id, product_code, internal_cancel_at }) => {
            return (
                internal_cancel_at &&
                external_id === resetCRMCandidateSubscriptionId &&
                product_code === PRODUCT_CODES.APP_FULL_ACCESS
            );
        });

    const resetCandidate = isCRM ? getResetCRMCandidate() : getResetCandidate();

    const onShowReturnOffer = () => {
        !isCalifornia && sendAnalyticShowReturnOfferClick();

        !discountSubscription &&
            resetCandidate &&
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.extra,
                    external_id: resetCandidate?.external_id,
                })
            );

        if (!isCRM) {
            openModal(ModalName.returnOfferModal);
        }
    };

    useEffect(() => {
        if (isCRM) {
            onShowReturnOffer();
        }
    }, [resetCandidate]);

    const onResetSubscription = useCallback(() => {
        openModal(ModalName.withLoaderModal, {
            modalData: getUpdatingSubscriptionLoadingData(t),
        });

        if ((resetCandidate || cancellationCandidate) && discountSubscription) {
            isCalifornia ? sendAnalyticSpecialOfferGetDiscountClick() : sendAnalyticReturnOfferGetDiscountClick();

            dispatch(
                resetSubscription({
                    external_id: resetCandidate?.external_id || cancellationCandidate?.externalId || '',
                    product: discountSubscription,
                    onSuccess: () => {
                        isCalifornia ? sendAnalyticCancelOfferFScreenLoad() : sendAnalyticReturnOfferFScreenLoad();

                        discountSubscription &&
                            openModal(ModalName.successFeedbackModal, {
                                modalData: getUpdatingSuccessData({ closeModal, discountSubscription, t }),
                            });
                    },
                    onError: (error?: string) => {
                        sendAnalyticSubscriptionUpdateError(error ?? DEFAULT_UPDATE_SUBSCRIPTION_ERROR);

                        openModal(ModalName.errorFeedbackModal, {
                            modalData: getUpdatingErrorData({ onResetSubscription, t }),
                        });
                    },
                })
            );
        }
    }, [resetCandidate, discountSubscription]);

    return {
        resetCandidate,
        onShowReturnOffer,
        onResetSubscription,
    };
};

export default useReturnOffer;
