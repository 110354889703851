import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    max-width: 620px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;
`;

export const FlexContainer = styled(Box)`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
`;

export const ExpertWrapper = styled(Box)`
    display: flex;
    width: calc(50% - 6px);
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding: 16px 12px 24px 12px;
`;
