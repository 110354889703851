import styled, { css } from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const StackContainer = styled.div<{ background?: string }>`
    display: grid;
    position: relative;
    height: 100%;
    width: 100%;

    ${mediaQueries.laptop} {
        height: auto;
        width: auto;
    }
`;

export const StackStoriesSkeleton = styled.div<{ background?: string }>`
    overflow: hidden;
    padding: 60px 0 32px;
    background: ${({ background = '#ddcdd0' }) => `${background}`};
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
    position: relative;
    transition: all 0.3s;

    ${mediaQueries.laptop} {
        padding: 60px 20px 32px;
        width: 552px;
        height: 584px;
        border-radius: 24px;
    }
`;

export const StackStoriesBackgroundSkeleton = styled(StackStoriesSkeleton)`
    display: none;
    z-index: 0;

    ${mediaQueries.laptop} {
        display: flex;
        transform: rotate(-1.87deg);
        filter: brightness(80%);
    }
`;

export const SkeletonTexts = styled.div`
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 62px;
    left: 16px;

    ${mediaQueries.laptop} {
        left: 32px;
    }
`;

export const StackStories = styled.div<{ isPrev: boolean; zIndex: number; background?: string }>`
    height: 100%;
    overflow: hidden;
    padding: 60px 0 32px;
    background: ${({ background }) => `${background}`};
    grid-column: 1;
    grid-row: 1;
    z-index: ${({ zIndex }) => `${zIndex}`};
    position: relative;
    transition: all 0.3s;

    ${({ isPrev = false }) =>
        isPrev &&
        css`
            transform: translate(-75px, 50px) rotate(5deg);
            opacity: 0;
        `};

    ${mediaQueries.laptop} {
        padding: 60px 20px 32px;
        width: 552px;
        height: 584px;
        border-radius: 24px;
    }
`;

export const PaginationWrap = styled.div<{ withShadow?: boolean }>`
    position: absolute;
    padding-bottom: 20px;
    border-radius: 24px 24px 12px 12px;
    top: 32px;
    left: 0;
    right: 0;
    z-index: 100;
`;

export const StackStoriesBackground = styled(StackStories)`
    display: none;

    ${mediaQueries.laptop} {
        display: flex;
        background: ${({ background }) => `${background}`};
        transform: rotate(-1.87deg);
        filter: brightness(80%);
    }
`;

export const ButtonsWrapper = styled.div`
    position: absolute;
    padding: 0 16px;
    width: 100%;
    z-index: 100;
    bottom: 12px;
    display: flex;
    justify-content: space-between;

    ${mediaQueries.laptop} {
        padding: 0 32px;
        bottom: 32px;
        justify-content: flex-end;
    }
`;

export const ButtonWrapper = styled.div`
    margin-right: 20px;

    &:last-of-type {
        margin-right: 0;
    }
`;
