import { useEffect, useState } from 'react';

const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState('up');
    const [scrolledFirstScreen, setScrolledFirstScreen] = useState(false);

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? 'down' : 'up';

            if (direction !== scrollDirection) {
                setScrollDirection(direction);
            }

            setScrolledFirstScreen(scrollY > window.innerHeight);

            lastScrollY = scrollY > 0 ? scrollY : 0;
        };

        window.addEventListener('scroll', updateScrollDirection);

        return () => {
            window.removeEventListener('scroll', updateScrollDirection);
        };
    }, [scrollDirection]);

    return { scrollDirection, scrolledFirstScreen };
};

export default useScrollDirection;
