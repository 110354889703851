import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const HelpfulOption = styled.label`
    cursor: pointer;
    position: relative;
    width: 64px;
    margin-right: 32px;

    &:last-of-type {
        margin-right: 0;
    }
`;

export const OptionInput = styled.input`
    height: 0;
    width: 0;
    margin: 0;
    visibility: hidden;

    &:checked ~ div {
        border: 2px solid ${color('primary-default')};
        background-color: ${color('primary-default')};
    }
`;

export const CustomOption = styled.div`
    position: absolute;
    top: 0;
    width: fit-content;
    padding: 10px;
    border-radius: 50%;
    border: 2px solid ${color('border-subdued')};
    background-color: ${color('surface-main')};
`;

export const SVGWrap = styled.div`
    width: 40px;
    height: 40px;
`;

export const OptionText = styled(Text)`
    margin-top: 72px;
    font-size: 18px;
    line-height: 28px;

    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
    }
`;
