import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const OneSubPageWrap = styled.div<{ emptyBlock: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        min-height: ${({ emptyBlock }) => (emptyBlock ? '60vh' : '100vh')};
        justify-content: center;
    }
`;

export const Content = styled.div<{ flexStart: boolean; isFoodList: boolean }>`
    margin: 17vh 0;
    height: 100%;

    ${mediaQueriesGrid.desktop} {
        margin: 0;
        align-self: ${({ flexStart }) => (flexStart ? 'flex-start' : 'flex-end')};
        width: ${({ isFoodList }) => isFoodList && '100%'};
        max-width: ${({ isFoodList }) => !isFoodList && '295px'};
    }
`;

export const AnimationWrap = styled.div<{ isInView: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;

    -webkit-animation: ${({ isInView }) => (isInView ? 'fadein' : 'fadeout')} 1s ease;
    -moz-animation: ${({ isInView }) => (isInView ? 'fadein' : 'fadeout')} 1s ease;
    animation: ${({ isInView }) => (isInView ? 'fadein' : 'fadeout')} 1s ease;

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeout {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    ${mediaQueriesGrid.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }
`;
