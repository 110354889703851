import { Box } from 'wikr-core-components';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ComponentWrap = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 24px;
    padding: 24px;

    ${mediaQueriesGrid.tablet} {
        border-radius: 32px;
        padding: 32px 24px;
    }
`;

export const OneHoursPart = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const HoursStyled = styled.span`
    font-size: 18px;
    line-height: 28px;
`;

export const VerticalLine = styled.span`
    height: 100%;
    min-height: 78px;
    border-left: 1px solid ${color('border-default')};
    margin: 0 4px;
`;

export const HourSkeleton = styled(ContentLoader)`
    height: 40px;
    width: 40px;
    border-radius: 6px;
    margin-bottom: 8px;
`;
