import { Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { selectIsAppLibraryWebView } from 'store/uiEffects/selectors';

import { LIVE_EVENTS, YOGA_SESSIONS } from './constants';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import { useSelector } from 'hooks';

import WebViewBackArrow from 'components/WebViewNavBar';
import YogaSession from './components/YogaSession';
import LiveEvent from './components/LiveEvent';

import * as S from './styled';

const FlatBelly = () => {
    useScreenLoad();
    const { t } = useTranslation();

    const isAppLibraryWebView = useSelector(selectIsAppLibraryWebView);

    const navigate = useNavigate();

    const handleAppBackClick = () => navigate(-1);

    return (
        <S.FlatBellyContainer paddingTop={32} paddingX={16} backgroundColor="surface-secondary">
            {isAppLibraryWebView && <WebViewBackArrow onClick={handleAppBackClick} text={t('basics.library')} />}
            <S.FlatBellyContent>
                <Text dataLocator="flatBellyTitle" type="h3" medium text={t('flatBelly.title')} mb={24} />
                <Text type="h6" text={t('flatBelly.liveEvents')} mb={16} />
                <S.VideosSectionsWrap mb={40}>
                    {LIVE_EVENTS.map((livEvent, index) => (
                        <LiveEvent
                            key={index}
                            title={livEvent.title}
                            text={livEvent.text}
                            expertImg={livEvent.expertImg}
                            expertName={livEvent.expertName}
                            expertPosition={livEvent.expertPosition}
                            previewImg={livEvent.previewImg}
                            video264={livEvent.video264}
                            video265={livEvent.video265}
                            videoWebm={livEvent.videoWebm}
                        />
                    ))}
                </S.VideosSectionsWrap>
                <Text type="h6" text={t('flatBelly.yogaSessions')} mb={16} />
                <S.VideosSectionsWrap>
                    {YOGA_SESSIONS.map((yogaSession, index) => (
                        <YogaSession
                            key={index}
                            name={yogaSession.name}
                            previewImg={yogaSession.previewImg}
                            video264={yogaSession.video264}
                            video265={yogaSession.video265}
                            videoWebm={yogaSession.videoWebm}
                        />
                    ))}
                </S.VideosSectionsWrap>
            </S.FlatBellyContent>
        </S.FlatBellyContainer>
    );
};

export default FlatBelly;
