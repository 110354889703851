import * as action from './actions';

import * as actionTypes from './actionTypes';

import { CancelCandidate, RestoreCandidate } from 'hooks/subscriptions/types';

import { ISubscription, IDiscountSubscription } from 'types/subscription';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

export interface SubscriptionsState {
    subscriptions: ISubscription[];
    isLoading: boolean;
    isDiscountLoading: boolean;
    discountSubscription: IDiscountSubscription | null;
    cancellationCandidate: CancelCandidate | null;
    restoreCandidate: RestoreCandidate | null;
    resetCRMCandidateSubscriptionId: string | null;
}

const initialState: SubscriptionsState = {
    subscriptions: [],
    isLoading: true,
    isDiscountLoading: false,
    discountSubscription: null,
    cancellationCandidate: null,
    restoreCandidate: null,
    resetCRMCandidateSubscriptionId: null,
};

const subscriptionsReducer = (state = initialState, action: ActionType): SubscriptionsState => {
    switch (action.type) {
        case actionTypes.SET_FETCHING_STATUS:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_USER_SUBSCRIPTIONS:
            return { ...state, isLoading: false, subscriptions: action.payload };

        case actionTypes.SET_IS_DISCOUNT_LOADING:
            return { ...state, isDiscountLoading: action.payload };

        case actionTypes.SET_DISCOUNT_SUBSCRIPTION:
            return { ...state, discountSubscription: action.payload };

        case actionTypes.SET_CANCELLATION_CANDIDATE:
            return { ...state, cancellationCandidate: action.payload };

        case actionTypes.SET_RESTORE_CANDIDATE:
            return { ...state, restoreCandidate: action.payload };

        case actionTypes.SET_RESET_CRM_CANDIDATE_SUBSCRIPTION_ID:
            return { ...state, resetCRMCandidateSubscriptionId: action.payload };

        case actionTypes.RESET_SUBSCRIPTIONS:
            return { ...initialState };

        default:
            return state;
    }
};

export default subscriptionsReducer;
