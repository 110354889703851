import { Text } from 'wikr-core-components';
import { useState } from 'react';

import { sendAnalyticWorkoutSelect } from 'services/analytics/trackers/workouts';

import { getWindowSize } from 'helpers/getWindowSize';
import { BLOCK_DATA_LOCATOR, getBlockName } from './helpers';

import {
    Block,
    ExercisesList,
    ExerciseWrapper,
    ExerciseImage,
    ExerciseRestPlaceholder,
    BlockRepeats,
    BlockTitle,
    ExerciseDuration,
} from './styled';

import { ReactComponent as SVGRounds } from './assets/round-arrow.svg';

import { ExercisesBlock, Exercise } from 'types/workouts/workoutsApiInterface';

import ExerciseInfoModal from '../ExerciseInfoModal';

interface WorkoutExercisesProps {
    exercisesBlocks: ExercisesBlock[];
}

const isLaptop = getWindowSize(768);

const WorkoutExercises = ({ exercisesBlocks: blocks }: WorkoutExercisesProps) => {
    const [currentExerciseData, setCurrentExerciseData] = useState<Exercise | null>(null);

    const handleExerciseClick = (exercise: Exercise) => {
        sendAnalyticWorkoutSelect(exercise.name);

        setCurrentExerciseData(exercise);
    };

    const handleCloseExerciseInfoModal = () => setCurrentExerciseData(null);

    return (
        <>
            <div>
                {blocks.map((block, index) => (
                    <Block key={index}>
                        <BlockTitle
                            type={isLaptop ? 'large-text' : 'medium-text'}
                            dataLocator={BLOCK_DATA_LOCATOR[block.type]}
                        >
                            {getBlockName(block.type, index)}
                            {block.repeats > 1 && (
                                <BlockRepeats data-locator="workoutPreviewExerciseRoundsAmount">
                                    <SVGRounds data-locator="workoutPreviewExerciseRoundsIcon" /> {block.repeats} rounds
                                </BlockRepeats>
                            )}
                        </BlockTitle>
                        <ExercisesList>
                            {block.exercises.map((exercise, index) => {
                                const isRest = exercise.type === 'rest';

                                return (
                                    <ExerciseWrapper
                                        key={index}
                                        onClick={!isRest ? () => handleExerciseClick(exercise) : undefined}
                                        role={!isRest ? 'button' : undefined}
                                        tabIndex={!isRest ? 0 : undefined}
                                        $isRest={isRest}
                                        data-locator="workoutPreviewExerciseCard"
                                    >
                                        {isRest ? (
                                            <ExerciseRestPlaceholder data-locator="workoutPreviewExerciseRestPlaceholder" />
                                        ) : (
                                            <ExerciseImage
                                                src={exercise.preview_link ?? undefined}
                                                alt={exercise.name}
                                                data-locator="workoutPreviewExerciseImage"
                                            />
                                        )}
                                        <Text
                                            dataLocator="workoutPreviewExerciseTitle"
                                            type={isLaptop ? 'large-text' : 'small-text'}
                                            color="text-main"
                                        >
                                            {exercise.name}
                                        </Text>
                                        {exercise.duration && (
                                            <ExerciseDuration
                                                type={isLaptop ? 'medium-text' : 'caption'}
                                                dataLocator="workoutPreviewExerciseDuration"
                                            >
                                                {exercise.duration} sec
                                            </ExerciseDuration>
                                        )}
                                    </ExerciseWrapper>
                                );
                            })}
                        </ExercisesList>
                    </Block>
                ))}
            </div>
            <ExerciseInfoModal
                currentExerciseData={currentExerciseData as Exercise}
                isOpen={Boolean(currentExerciseData as Exercise)}
                onClose={handleCloseExerciseInfoModal}
            />
        </>
    );
};

export default WorkoutExercises;
