import { useEffect, useMemo } from 'react';

import { fetchGuides } from 'store/guides/actions';

import { useDispatch, useSelector } from 'hooks';

import { GuideStatus, IGuide } from 'types/guide';

type FilteredGuides = {
    [key in GuideStatus]: IGuide[];
};

const DEFAULT_GUIDES = ['free', 'fat_burning_gd', 'weight_loss_gd', 'offer'];
// const DEFAULT_GUIDES = ['fat_burning_gd', 'weight_loss_gd'];

const useGuides = (filter = DEFAULT_GUIDES) => {
    const { userGuides, isLoading } = useSelector((state) => state.guides);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchGuides(filter));
    }, []);

    const { free, purchased, offer } = useMemo<FilteredGuides>(
        () =>
            userGuides.reduce(
                (acc, guide): FilteredGuides => {
                    acc[guide.status].push(guide);

                    return acc;
                },
                { [GuideStatus.free]: [], [GuideStatus.purchased]: [], [GuideStatus.offer]: [] } as FilteredGuides
            ),
        [userGuides]
    );

    return {
        isLoading,
        userGuides,
        freeGuides: free,
        purchasedGuides: purchased,
        offerGuides: offer,
    };
};

export default useGuides;
