import styled from 'styled-components';

import { IWorkout } from 'types/workouts/workoutsApiInterface';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import mediaQueries from 'theme/mediaQueries';

export const Wrapper = styled.div`
    background-color: ${color('surface-default')};
`;

export const Container = styled.div`
    ${mediaQueries.laptop} {
        padding-bottom: 64px;
    }

    ${mediaQueriesGrid.largeTablet} {
        max-width: 840px;
        margin: 0 auto;
    }

    ${mediaQueries.theSmallestDesktop} {
        padding: 24px 0 64px;
        max-width: unset;
        margin: unset;
    }
`;

export const BackButtonWrapper = styled.div<{ isOpenHeader?: boolean }>`
    position: fixed;
    top: ${({ isOpenHeader }) => `${isOpenHeader ? 72 : 16}px}`};
    left: 16px;
    z-index: 10;

    ${mediaQueries.laptop} {
        left: 32px;
    }

    ${mediaQueries.theSmallestDesktop} {
        left: 64px;
        top: ${({ isOpenHeader }) => `${isOpenHeader ? 72 : 32}px}`};
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    ${mediaQueries.theSmallestDesktop} {
        flex-direction: row-reverse;
        width: 1028px;
        margin: 0 auto;
        gap: 20px;
    }
`;

export const CoverWrapper = styled.div`
    width: 100%;
    height: 280px;
    position: relative;
    overflow: hidden;

    ${mediaQueries.laptop} {
        height: 434px;
    }

    ${mediaQueries.theSmallestDesktop} {
        height: 363px;
        border-radius: 20px;
    }
`;

export const Cover = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const BodyZoneWrapper = styled.div`
    padding: 6px 12px;
    background-color: ${color('surface-secondary')};
    border-radius: 12px;
    position: absolute;
    top: 16px;
    right: 16px;

    ${mediaQueries.laptop} {
        right: 32px;
    }
`;

export const PreviewDetails = styled.div`
    padding: 24px 24px 16px 24px;
    background-color: ${color('surface-main')};
    display: flex;
    flex-direction: column;

    ${mediaQueries.laptop} {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }

    ${mediaQueries.theSmallestDesktop} {
        flex-direction: column;
        align-items: flex-start;
        border-radius: 20px;
        padding-bottom: 24px;
    }
`;

export const NameWrapper = styled.div<{
    $workoutType?: IWorkout['type'];
}>`
    display: flex;
    align-items: center;
    margin-top: 7px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 12px;
        height: 22px;
        ${({ $workoutType }) => $workoutType === 'strength' && 'background: #fe3838;'}
        ${({ $workoutType }) => $workoutType === 'cardio' && 'background: #ff8501;'}
        ${({ $workoutType }) => $workoutType === 'recovery' && 'background: #53e19d;'}
        border-radius: 0 5px 5px 0;
    }
`;
export const LevelDurationWrapper = styled.div`
    display: flex;
    margin-top: 12px;
    gap: 16px;

    & > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 4px;
        text-transform: capitalize;
    }
`;

export const EquipmentWrapper = styled.div`
    display: flex;
    margin-top: 32px;
    gap: 12px;

    ${mediaQueries.theSmallestDesktop} {
        margin-top: 85px;
    }
`;

export const EquipmentItem = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 8px 4px 4px;
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
    background-color: ${color('surface-main')};

    & > img {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
`;

export const TopButtonWrapper = styled.div`
    display: none;
    ${mediaQueries.laptop} {
        display: block;
        width: 328px;
    }
`;

export const Main = styled.div`
    margin-top: 32px;
    padding: 0 16px 128px;

    ${mediaQueries.laptop} {
        padding: 0 32px 0;
        margin-top: 40px;
    }

    ${mediaQueriesGrid.largeTablet} {
        padding: 0;
    }

    ${mediaQueries.theSmallestDesktop} {
        width: 1028px;
        margin: 40px auto 0;
    }
`;

export const BottomButtonWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 128px;
    left: 0;
    bottom: 0;
    padding: 44px 16px 20px;
    background: linear-gradient(
        180deg,
        rgba(243, 245, 247, 0) 0%,
        rgba(243, 245, 247, 0.11) 6.25%,
        rgba(243, 245, 247, 0.21) 11.98%,
        #f3f5f7 38.54%
    );

    ${mediaQueries.laptop} {
        position: relative;
        padding: 0;
        margin: 40px auto 0;
        background: none;
        height: auto;
        width: 400px;
    }
`;
