import { Text, Button, Box } from 'wikr-core-components';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, FormEvent } from 'react';
import { useFormik } from 'formik';

import appConfig from 'appConfig';

import { createProcessingSelector } from 'store/loading/selectors';
import { createErrorSelector } from 'store/errors/selectors';
import { SIGN_IN } from 'store/auth/actionTypes';
import { signInRequest } from 'store/auth/actions';

import { PATHS } from 'constants/routerPaths';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import {
    sendAnalyticLoginButtonClick,
    sendAnalyticLoginError,
    sendAnalyticResetPasswordClick,
} from 'services/analytics';

import { useDispatch, useSelector } from 'hooks/store';

import { isEmpty } from 'helpers/dataStructuresUtils';

import * as S from 'containers/AuthFlow/styled';

import FormikInput from 'components/FormikInput';

import { CONTENT } from './config';

import { LoginSchema } from './validationSchema';

type FormValue = {
    email: string;
    password: string;
};

export const Login = () => {
    useScreenLoad();

    const { t } = useTranslation();
    const [isFetching, setFetchingStatus] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoading = useSelector(createProcessingSelector([SIGN_IN]));
    const error = useSelector(createErrorSelector(SIGN_IN));
    const { redirectUrl } = useSelector((state) => state.auth);

    useEffect(() => {
        if (typeof error === 'object' && error?.message) {
            CONTENT.forEach((field) => setFieldError(field.name, error?.message));
        }
    }, [error]);

    const handleSuccessLogin = () => {
        // sendAnalyticLoginSuccess();

        navigate(redirectUrl || PATHS.MAIN);
    };

    const {
        errors,
        dirty,
        values,
        initialValues,
        setFieldTouched,
        setFieldValue,
        touched,
        handleSubmit,
        setFieldError,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        validateOnBlur: false,
        onSubmit: (data: FormValue) => {
            setFetchingStatus(true);

            const userData = {
                appsflyer_id: null,
                idfa: null,
                push_token: null,
                ...data,
            };

            dispatch(
                signInRequest({
                    userData,
                    onSuccess: handleSuccessLogin,
                    onError: handleLoginError,
                })
            );
        },
    });

    const onSubmitHandler = (event?: FormEvent<HTMLFormElement> | undefined) => {
        handleSubmit(event);

        sendAnalyticLoginButtonClick();
    };

    const handleLoginError = () => {
        CONTENT.forEach((field) => setFieldError(field.name, t('Email or password is invalid')));

        setFetchingStatus(false);

        sendAnalyticLoginError();
    };

    return (
        <S.AuthContainer>
            {/* <S.AuthImage>*/}
            {/*    <Image src={ResetPassword} alt="reset-password" isForceVisible />*/}
            {/* </S.AuthImage>*/}
            <S.AuthForm>
                <form>
                    <Box paddingBottom={32} paddingX={16}>
                        <Text type="h5" bold center mb={12} text={t('login.title')} />
                        <Text center mb={4} text={t('login.subTitle')} />

                        <Text type="overline" bold center>
                            <a href={appConfig.links.onboarding} data-locator="surveyButton">
                                {t('login.survey')}
                            </a>
                        </Text>
                        <Box mb={44}>
                            {CONTENT.map(({ name, label, type, icon, dataLocator, placeholder }) => (
                                <FormikInput
                                    key={name}
                                    mb={32}
                                    type={type}
                                    field={name}
                                    label={label}
                                    setFieldTouched={setFieldTouched}
                                    initialValues={initialValues}
                                    setFieldValue={setFieldValue}
                                    touched={touched}
                                    values={values}
                                    errors={errors}
                                    leftIcon={icon}
                                    dataLocator={dataLocator}
                                    placeholder={placeholder}
                                />
                            ))}
                        </Box>
                        <Button
                            onClick={onSubmitHandler}
                            disabled={!dirty || !isEmpty(errors) || isLoading || isFetching}
                            mb={24}
                            text={t('basics.login')}
                            isLoading={isLoading || isFetching}
                            dataLocator="loginButton"
                        />

                        <Link
                            onClick={sendAnalyticResetPasswordClick}
                            to={PATHS.FORGOT_PASSWORD}
                            data-locator="forgotPasswordBtn"
                        >
                            <Text type="overline" center bold mb={20} text={t('basics.forgotPassword')} />
                        </Link>
                    </Box>
                </form>
            </S.AuthForm>
        </S.AuthContainer>
    );
};
