import { PASSWORD_MATCH_REGEXP } from 'constants/RegExp';

import { passwordRequirements } from 'components/PasswordValidationList/config';

import * as Yup from 'yup';

export const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .matches(PASSWORD_MATCH_REGEXP, 'validation.password.requirements')
        .required('validation.password.required'),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'validation.password.match')
        .required('validation.password.required'),
});

export const validationSchema = Yup.object({
    newPassword: Yup.string()
        .required('Password is required')
        .matches(passwordRequirements.minLength)
        .matches(passwordRequirements.uppercase)
        .matches(passwordRequirements.lowercase)
        .matches(passwordRequirements.number),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'validation.password.match')
        .required('validation.password.required'),
});
