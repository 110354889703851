import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { discardSubscriptionCancellation, setDiscountSubscription } from 'store/subscriptions/actions';

import { useDispatch, useModals } from 'hooks';

import WaitingModal from './components/WaitingModal';

const WaitingModalContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { closeModal } = useModals();

    const captions = {
        title: t('subscription.cancelDialog.waiting'),
        discardButton: t('basics.back'),
    };

    const discardCancellationHandler = () => {
        closeModal();

        dispatch(setDiscountSubscription(null));
    };

    const handleDiscardCancelling = useCallback(() => {
        dispatch(discardSubscriptionCancellation());
    }, []);

    return (
        <WaitingModal
            isOpen
            onDiscard={handleDiscardCancelling}
            onClose={discardCancellationHandler}
            captions={captions}
        />
    );
};

export default WaitingModalContainer;
