import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import { useState } from 'react';

import { useNetworkStatus } from 'hooks/common';

import Modal from 'components/Modal/index';

import * as S from './styled';

const NoConnectionModal = () => {
    const [isLoading, setIsLoading] = useState(false);

    const isOnline = useNetworkStatus();

    const onClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <Modal isOpen={!isOnline} style={{ content: { padding: '24px 24px 0' } }}>
            <Text type="h1" center text="🌐" mb={20} />
            <Text type="h5" bold center mb={12}>
                <Trans i18nKey="networkErrorModal.title" />
            </Text>
            <Text color="text-secondary" center mb={24}>
                <Trans i18nKey="networkErrorModal.subtitle" />
            </Text>
            <S.OutlinedBtn
                isLoading={isLoading}
                disabled={isLoading}
                backgroundColor="surface-main"
                hoverColor="surface-hovered"
                textColor="text-main"
                onClick={onClick}
                mb={20}
            >
                <Trans i18nKey="basics.tryAgain" />
            </S.OutlinedBtn>
        </Modal>
    );
};

export default NoConnectionModal;
