import React from 'react';

import { useModals } from 'hooks';

import * as S from './styled';

import { ReactComponent as CloseIcon } from './img/CloseIcon.svg';

const FeedbackModalsNavBar = ({ dataLocator }: { dataLocator: string }) => {
    const { closeModal } = useModals();

    return (
        <S.NavBarContainer>
            <S.Icon data-locator={dataLocator} onClick={closeModal}>
                <CloseIcon />
            </S.Icon>
        </S.NavBarContainer>
    );
};

export default FeedbackModalsNavBar;
