import { Text, Colors } from 'wikr-core-components';
import { FC } from 'react';

import { sendAnalyticDownloadGuideClick } from 'services/analytics';

import { Download } from 'components/Icon';

import * as S from './styled';

interface GuideProps {
    img?: string;
    link?: string;
    title: string;
    name?: string;
    dataLocator?: string;
    backgroundColor?: Colors;
}

export const Guide: FC<GuideProps> = ({ title, img, link, name, dataLocator, backgroundColor }) => {
    const onDownloadClick = () => name && sendAnalyticDownloadGuideClick(name);

    return (
        <S.Container backgroundColor={backgroundColor || 'surface-default'}>
            {img && <S.GuideImg alt="guide-img" src={img} />}
            <S.GuideTitleContainer>
                <Text center={!img} type="medium-text" text={title} />
            </S.GuideTitleContainer>
            {link && (
                <S.DownloadGuideLink
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={onDownloadClick}
                    data-locator={dataLocator}
                >
                    <Download />
                </S.DownloadGuideLink>
            )}
        </S.Container>
    );
};
