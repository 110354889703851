import React, { Fragment } from 'react';

import { usePauseSubscription } from 'hooks';

import PremiumFeedbackModalContainer from '../PremiumFeedbackModalContainer';
import PauseOfferModalContainer from '../PauseOfferModalContainer';

const NoLongerNeedModalContainer = () => {
    const { isCanAddPause } = usePauseSubscription();

    return (
        <Fragment>
            {isCanAddPause ? <PauseOfferModalContainer /> : <PremiumFeedbackModalContainer reasonLocal="no_app_need" />}
        </Fragment>
    );
};

export default NoLongerNeedModalContainer;
