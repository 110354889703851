import { Box, Image } from 'wikr-core-components';
import styled from 'styled-components';

import { CONTENT_5 } from 'constants/customContentColors';

export const Container = styled(Box)`
    background-color: ${CONTENT_5};
`;

export const BannerTitle = styled.div`
    display: flex;
    gap: 4px;
`;

export const Icon = styled(Image)`
    width: 24px;
    padding: 6px 0;
`;
