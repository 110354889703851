import { Image } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Article = styled.div`
    display: flex;
    padding: 20px 16px 12px 16px;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    border-radius: 20px;
    background: ${color('surface-hovered')};
    position: relative;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
        padding-right: 24px;
        align-items: center;
        gap: 32px;
        justify-content: space-between;
    }
`;

export const ArticleTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const NewsIcon = styled(Image)`
    width: 24px;
`;

export const Label = styled.div`
    display: flex;
    padding: 2px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    background: ${color('surface-main')};
    position: absolute;
    top: -8px;
    left: 52px;
`;

const link = css`
    min-height: 44px;
    padding: 4px 16px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    ${mediaQueriesGrid.tablet} {
        width: auto;
    }
`;

export const DownloadLink = styled.a`
    ${link};
    background: ${color('surface-hovered')};
    justify-content: flex-start;
    min-height: 44px;
    padding: 4px 16px 4px 36px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    ${mediaQueriesGrid.tablet} {
        width: auto;
    }
`;
