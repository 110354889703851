import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const OnePointWrap = styled.div`
    width: 100%;
    max-width: 343px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 48px;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 100%;
        padding: 0 63px;
        margin-bottom: 0;
    }
`;

export const CheckWrap = styled.div`
    margin-bottom: 24px;
`;
