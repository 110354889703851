import { Button, Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { emailConfirmationRequest } from 'store/user/actions';

import { PATHS } from 'constants/routerPaths';

import { useDispatch } from 'hooks';

import { getParamFromUrl } from 'helpers/utils';
import isMobile from 'helpers/isMobile';

import * as S from './styled';

import confirmedEmail from './img/confirmed-email.svg';
import confirmedEmailBackground from './img/confirmed-email-background.svg';

const PLAY_STORE_URL = 'intent://femia.io/#Intent;scheme=app;package=femia.menstruationtracker.fertilityapp;end';
const IOS_STORE_URL = 'femia://';

export const EmailConfirmation = () => {
    const isMobileDevice = isMobile.android() || isMobile.iOS();
    const btnText = isMobileDevice ? 'mobile.email.confirmation.btn.title' : 'pagesTitles.login';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = () => {
        if (!isMobileDevice) {
            navigate(PATHS.LOGIN);
        } else {
            if (isMobile.android()) {
                window.location.href = PLAY_STORE_URL;
            }
            if (isMobile.iOS()) {
                window.location.href = IOS_STORE_URL;
            }
        }
    };

    useEffect(() => {
        const token = getParamFromUrl('token') || '';

        dispatch(
            emailConfirmationRequest({
                payload: {
                    confirmation_code: token,
                },
                onError: () => {
                    navigate(PATHS.ERROR_PAGE, { state: { path: PATHS.EMAIL_CONFIRMATION } });
                },
            })
        );
    }, []);

    return (
        <S.MainContainer>
            <S.Container>
                <S.ImgBox>
                    <S.BackgroundImg src={confirmedEmailBackground} alt="confirmed-email-background" isForceVisible />
                    <S.MainImg src={confirmedEmail} alt="confirmed-email" isForceVisible />
                </S.ImgBox>
                <S.InfoBlock>
                    <Text center mb={20} type="h5" text={t('email.confirmation.title')} />
                    <Text center mb={20} type="h5" text={t('main.title.thankYou')} />
                    <Button onClick={handleClick} size="small" text={t(btnText)} mb={24} />
                </S.InfoBlock>
            </S.Container>
        </S.MainContainer>
    );
};
