import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ButtonWrapper = styled(Box)`
    display: flex;
    flex-direction: row;

    ${mediaQueriesGrid.tablet} {
        justify-content: flex-end;
    }
`;
