import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const BannerContainer = styled(Box)`
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 24px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.5);
`;

export const BannerBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
