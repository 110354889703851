import { useCancelSubscription, useModals } from 'hooks';

import SecondSubscriptionCancelModal from './components/SecondSubscriptionCancelModal';

import { ISecondSubscriptionCancelModalContainerProps } from './types';

const SecondSubscriptionCancelModalContainer = ({ modalData }: ISecondSubscriptionCancelModalContainerProps) => {
    const { closeModal } = useModals();

    const { onCancelSubscription } = useCancelSubscription();

    return (
        <SecondSubscriptionCancelModal
            modalData={modalData}
            onMainBtnClick={onCancelSubscription}
            onOutlineBtnClick={closeModal}
        />
    );
};

export default SecondSubscriptionCancelModalContainer;
