import { DEVICE_OS } from 'constants/devices';

import { getDeviceBrowser, getDeviceOS } from 'helpers/utils';

const deviceOS = getDeviceOS();
const deviceBrowser = getDeviceBrowser();

type HintVariantReturn = 'ios' | 'android' | 'safari' | 'chrome';

export const getHintVariant = (): HintVariantReturn | undefined => {
    switch (true) {
        case deviceOS === DEVICE_OS.IOS:
            return 'ios';

        case deviceOS === DEVICE_OS.ANDROID:
            return 'android';

        case deviceBrowser === 'Safari':
            return 'safari';

        case deviceBrowser === 'Chrome':
            return 'chrome';
    }
};
