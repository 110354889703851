import React from 'react';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import ForgotPasswordForm from 'components/ForgotPasswordForm';

import * as S from './styled';

export const AuthorisedForgotPassword = () => {
    useScreenLoad();

    return (
        <S.ForgotPassContainer>
            <S.FormWrap>
                <ForgotPasswordForm />
            </S.FormWrap>
        </S.ForgotPassContainer>
    );
};
