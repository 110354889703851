import { Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useInView } from '@react-spring/web';

import * as S from './styled';

export const OneDefinition = ({ img, text }: { img?: string; text: string }) => {
    const { t } = useTranslation();

    const [ref, springs] = useInView(() => ({
        from: {
            opacity: 0,
            y: 100,
        },
        to: {
            opacity: 1,
            y: 0,
        },
        config: {
            duration: 500,
        },
    }));

    return (
        <S.OneDefinitionWrap ref={ref} style={springs}>
            {img && (
                <S.DefinitionImgWrap>
                    <Image once isForceVisible src={img} alt="definition img" />
                </S.DefinitionImgWrap>
            )}
            <S.DefinitionText type="h5" medium text={t(text)} />
        </S.OneDefinitionWrap>
    );
};
