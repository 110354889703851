import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';

import config from 'config';

import { AQA_KEY, DEV_KEY } from '../constants/dev';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const composeEnhancers = (config.ENV !== 'prod' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']) || compose;

    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

    const urlParams = new URLSearchParams(window.location.search);
    const refParams = urlParams.get(DEV_KEY);

    if (refParams === AQA_KEY || localStorage.getItem(DEV_KEY)) {
        localStorage.setItem(DEV_KEY, AQA_KEY);

        window['__getState'] = () => store.getState();
    }

    sagaMiddleware.run(rootSaga);

    return store;
};

export const store = configureStore();

export type Store = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
