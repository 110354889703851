import { Text } from 'wikr-core-components';
import { TooltipRenderProps } from 'react-joyride';
import { MouseEvent } from 'react';

import {
    sendAnalyticNextOnboardingClick,
    sendAnalyticSkipOnboardingClick,
} from 'services/analytics/trackers/dashboard';

import NextButtonInsights from 'components/NextButtonInsights';

import * as S from './styled';

interface TooltipProps extends TooltipRenderProps {
    onSkip: () => void;
    onLastClick: () => void;
}

const Tooltip = ({
    step: { title, content },
    tooltipProps,
    primaryProps,
    skipProps,
    isLastStep,
    onSkip,
    onLastClick,
    index,
}: TooltipProps) => {
    const onSkipHandler = (e?: MouseEvent<HTMLElement>) => {
        e && skipProps.onClick(e);
        sendAnalyticSkipOnboardingClick(index + 1);
        onSkip();
    };

    const onLastClickHandler = (e?: MouseEvent<HTMLElement>) => {
        e && primaryProps.onClick(e);
        sendAnalyticNextOnboardingClick(index + 1);
        isLastStep && onLastClick();
    };

    return (
        <S.Container {...tooltipProps}>
            <Text type="h6" mb={8}>
                {title}
            </Text>
            <Text type="medium-text" mb={24}>
                {content}
            </Text>
            <S.ButtonsWrapper>
                <S.ButtonSkip {...skipProps} onClick={onSkipHandler}>
                    skip tips
                </S.ButtonSkip>
                <NextButtonInsights onClick={onLastClickHandler} minWidth={100} />
            </S.ButtonsWrapper>
        </S.Container>
    );
};

export default Tooltip;
