import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { CONTENT_2, CONTENT_5 } from 'constants/customContentColors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const MobileAppCookBlock = styled(Box)`
    width: 100%;
    padding: 200px 16px 32px;
    background-color: ${CONTENT_2};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const ImgWrap = styled.div`
    position: absolute;
    top: -64px;
    left: 51.5px;

    ${mediaQueriesGrid.desktop} {
        top: -80px;
        left: 184px;
    }
`;

export const SmallOval = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 12px 20px;
    border-radius: 100px;
    background-color: ${CONTENT_5};
`;
