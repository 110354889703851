import styled from 'styled-components';

export const NavBarContainer = styled.div`
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
`;

export const NavBarLogo = styled.div`
    width: 100%;
    margin: 7px auto;
    text-align: center;
`;

export const Icon = styled.button`
    min-width: 44px;
    height: 44px;
    padding: 12px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: translateX(-12px);
`;

export const EmptyDiv = styled.div`
    min-width: 44px;
    padding: 12px;
`;
