import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import Video from 'components/Video';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import mediaQueries from 'theme/mediaQueries';

const HEADER_HEIGHT = 57;

export const Wrapper = styled.div`
    background-color: ${color('surface-default')};
`;

export const Container = styled.div`
    position: relative;
    min-height: calc(100vh - ${HEADER_HEIGHT}px);
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    margin: 0 16px;
    overflow: hidden;

    ${mediaQueriesGrid.tablet} {
        grid-template-columns: repeat(12, 1fr);
        margin: 0 32px;
    }

    ${mediaQueries.smallDesktop} {
        max-width: 1416px;
        margin: 0 auto;
    }
`;

export const Main = styled.div`
    height: 100%;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${mediaQueriesGrid.tablet} {
        height: auto;
    }
    ${mediaQueries.theSmallestDesktop} {
        grid-column: 2 / -2;
        margin-top: 24px;
        justify-content: flex-start;
    }
`;

export const BackButtonWrapper = styled.div<{ isOpenHeader?: boolean }>`
    position: fixed;
    top: ${({ isOpenHeader }) => `${isOpenHeader ? 72 : 16}px}`};
    left: 16px;
    z-index: 10;

    ${mediaQueriesGrid.tablet} {
        left: 32px;
    }

    ${mediaQueries.theSmallestDesktop} {
        left: 40px;
        top: ${({ isOpenHeader }) => `${isOpenHeader ? 72 : 32}px}`};
    }
`;

export const PlayerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-top: -57px;

    ${mediaQueriesGrid.tablet} {
        flex-grow: 0;
        margin-top: 0;
    }
`;

export const Player = styled(Video)`
    max-height: 800px;
    border-radius: 20px;
    border: solid 1px ${color('surface-main')};
    aspect-ratio: 1920 / 1080;

    & > div[role='button'] {
        border-radius: 18px;
    }
`;

export const ActionsWrapper = styled.div`
    padding: 32px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    ${mediaQueriesGrid.tablet} {
        padding: 20px 0;
        position: relative;
    }
`;

export const CompleteButton = styled(Button)`
    border: solid 1px ${color('border-default')};

    &:active:not(:disabled) {
        background-color: ${color('surface-default')};
    }

    ${mediaQueriesGrid.tablet} {
        width: 360px;
    }
`;
