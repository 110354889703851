import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { getWindowSize } from 'helpers/getWindowSize';
import { getWeeksDateFormatted } from 'helpers/date';

import SkeletonOneDay from './components/SkeletonOneDay';

import * as S from './styled';

import { ReactComponent as DayCheckSmall } from './img/dayCheckSmall.svg';
import { ReactComponent as DayCheckBig } from './img/dayCheckBig.svg';

import { getContent } from './config';

import dayjs from 'dayjs';

interface IWeekComponentProps {
    isDemo: boolean;
    isSkeleton: boolean;
}

const DEFAULT_DAYS_AMOUNT_FOR_DEMO = 4;

function WeekComponent({ isDemo, isSkeleton }: IWeekComponentProps) {
    const { t } = useTranslation();

    const days = getContent();

    const firstDayOfWeek = dayjs().day(1);

    const dateLabels = getWeeksDateFormatted(firstDayOfWeek);

    const isTablet = getWindowSize(600);

    return (
        <S.ComponentWrap
            paddingBottom={20}
            paddingTop={20}
            paddingX={28}
            backgroundColor="surface-main"
            dataLocator="weeklyProgressContainer"
        >
            <S.Header mb={20}>
                <Text type="small-text" medium text={t('dashboard.weeklyProgress.week.title')} />
                <Text
                    color="text-secondary"
                    type="small-text"
                    text={`${dateLabels[0].formatDate} – ${dateLabels.at(-1)?.formatDate}`}
                    dataLocator="dateRange"
                />
            </S.Header>
            <S.Footer>
                {isSkeleton
                    ? Array.from(Array(7).keys()).map((_, index) => (
                          <S.OneDay key={index}>
                              <SkeletonOneDay />
                          </S.OneDay>
                      ))
                    : days.map((day, index) => (
                          <S.OneDay dataLocator={day.dataLocator} key={index}>
                              <S.SvgWrap filled={isDemo && index < DEFAULT_DAYS_AMOUNT_FOR_DEMO}>
                                  {isTablet ? <DayCheckBig /> : <DayCheckSmall />}
                              </S.SvgWrap>
                              <Text
                                  type="caption"
                                  color={
                                      isDemo && index < DEFAULT_DAYS_AMOUNT_FOR_DEMO ? 'text-main' : 'text-secondary'
                                  }
                                  text={t(day.dayName)}
                              />
                          </S.OneDay>
                      ))}
            </S.Footer>
        </S.ComponentWrap>
    );
}

export default WeekComponent;
