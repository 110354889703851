import { selectSubscriptions } from 'store/subscriptions/selectors';
import { ModalName } from 'store/modals/types';

import { PROGRESS_FEEDBACK_MODAL } from '../ProgressModalContainer/components/ProgressModal/constants';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticSendFeedbackClick } from 'services/analytics';

import { useSelector, useCancelSubscription, useModals, usePauseSubscription } from 'hooks';

import PremiumFeedbackModal from './components/PremiumFeedbackModal';

interface ModalProps {
    reason: string;
}

interface Props {
    reasonLocal?: string;
}

const PremiumFeedbackModalContainer = ({ reasonLocal }: Props) => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription, discardCancellation } = useCancelSubscription();
    const { onGetFreeMonthPause, isCanAddPremium } = usePauseSubscription();

    const { openModal, modalProps } = useModals();
    const { reason } = modalProps as ModalProps;

    const isTooExpensiveFlow = reasonLocal === 'too_expensive';
    const isNoLongerNeedFlow = reasonLocal === 'no_app_need';

    if (!cancellationCandidate) {
        return null;
    }

    const getEventName = () => {
        if (isTooExpensiveFlow || isNoLongerNeedFlow) return 'cancel_feedback';
        if (isCanAddPremium) return 'cancel_feedback_1m_free';

        return 'cancel_feedback';
    };

    const eventName = getEventName();

    useScreenLoad(eventName);

    const cancelSubscriptionHandler = () => {
        onCancelSubscription();
    };

    const sendFeedbackHandler = (value: string) => {
        sendAnalyticSendFeedbackClick(eventName, value);

        discardCancellation();

        if (isTooExpensiveFlow || isNoLongerNeedFlow) {
            openModal(ModalName.progressModal, { type: PROGRESS_FEEDBACK_MODAL });
        } else if (isCanAddPremium) {
            onGetFreeMonthPause();
        } else {
            openModal(ModalName.progressModal, { type: PROGRESS_FEEDBACK_MODAL });
        }
    };

    return (
        <PremiumFeedbackModal
            reason={reason}
            isCanAddPremium={isCanAddPremium}
            onCancelSubscription={cancelSubscriptionHandler}
            onSendFeedback={sendFeedbackHandler}
        />
    );
};

export default PremiumFeedbackModalContainer;
