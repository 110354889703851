import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    gap: 16px;
    align-items: center;
    max-width: 432px;
    margin: 0 auto;
`;

export const ContentBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 0;
`;
