import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from '../../theme/mediaQueries';

export const FlatBellyContainer = styled(Box)`
    min-height: 100vh;
    padding-bottom: 80px;
`;

export const FlatBellyContent = styled(Box)`
    max-width: 828px;
    margin: 0 auto;
`;

export const VideosSectionsWrap = styled(Box)`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    ${mediaQueries.tabletL} {
        justify-content: flex-start;
    }
`;
