import { getWeeksDateFormatted } from '../helpers/helpers';

import steps3 from '../img/steps3.webp';
import steps2 from '../img/steps2.webp';
import steps1 from '../img/steps1.webp';
import hidration3 from '../img/hidration3.webp';
import hidration2 from '../img/hidration2.webp';
import hidration1 from '../img/hidration1.webp';
import fasting3 from '../img/fasting3.webp';
import fasting2 from '../img/fasting2.webp';
import fasting1 from '../img/fasting1.webp';

import dayjs from 'dayjs';

const yLabelsFasting = ['20 h', '16 h', '12 h', '6 h', '0 h'];
const fastingImgs = [fasting1, fasting2, fasting3, fasting1, fasting2, fasting1, fasting2];

const yLabelsHidration = ['2,0', '1,8', '1,6', '1,4', '1,2'];
const hidrationImgs = [hidration1, hidration1, hidration2, hidration1, hidration3, hidration3, hidration3];

const yLabelsSteps = ['12000', '11000', '10000', '9000', '8000'];
const stepsImgs = [steps1, steps2, steps1, steps3, steps2, steps1, steps2];

export const useChartsData = () => {
    const firstDayOfWeek = dayjs().day(1);
    const dateLabels = getWeeksDateFormatted(firstDayOfWeek);
    const dateLabelsFormat = dateLabels.map(({ formatDate }) => formatDate);

    const xDataFasting = dateLabelsFormat.map((date, index) => {
        return {
            date,
            img: fastingImgs[index],
        };
    });

    const xDataHidration = dateLabelsFormat.map((date, index) => {
        return {
            date,
            img: hidrationImgs[index],
        };
    });

    const xDataSteps = dateLabelsFormat.map((date, index) => {
        return {
            date,
            img: stepsImgs[index],
        };
    });

    return {
        fastingData: {
            yLabels: yLabelsFasting,
            xData: xDataFasting,
        },
        hidrationData: {
            yLabels: yLabelsHidration,
            xData: xDataHidration,
        },
        stepsData: {
            yLabels: yLabelsSteps,
            xData: xDataSteps,
        },
    };
};
