import { Text } from 'wikr-core-components';
import React from 'react';

import * as S from './styled';

interface IFeatureCardProps {
    title: string;
    text: string;
    icon: string;
}

const FeatureCard = ({ title, text, icon }: IFeatureCardProps) => {
    return (
        <S.FeatureCardContainer>
            <S.FeatureCardTitle>
                {/* TO-DO: replace with ImageLocal component */}
                <S.Image alt="icon" src={icon} />
                <Text type="large-text" medium text={title} />
            </S.FeatureCardTitle>
            <Text type="small-text" text={text} />
        </S.FeatureCardContainer>
    );
};

export default FeatureCard;
