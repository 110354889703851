import { useEffect } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';

import {
    FREE_MONTH_OFFER_MODAL,
    sendAnalyticCancelModalCancelClick,
    sendAnalyticCancelModalOfferClick,
    sendAnalyticCancelModalScreenLoad,
} from 'services/analytics/trackers/cancelOffers';

import { usePauseSubscription, useSelector, useCancelSubscription } from 'hooks';

import FreeMonthModal from './components/FreeMonthModal';

const FreeMonthModalContainer = () => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription } = useCancelSubscription();

    const { onGetFreeMonthPause } = usePauseSubscription();

    if (!cancellationCandidate) {
        return null;
    }

    const cancelSubscriptionHandler = () => {
        sendAnalyticCancelModalCancelClick(FREE_MONTH_OFFER_MODAL);

        onCancelSubscription();
    };
    const getFreeMonthHandler = () => {
        sendAnalyticCancelModalOfferClick({ modalId: FREE_MONTH_OFFER_MODAL });

        onGetFreeMonthPause();
    };

    useEffect(() => {
        sendAnalyticCancelModalScreenLoad(FREE_MONTH_OFFER_MODAL);
    }, []);

    return (
        <FreeMonthModal
            externalId={cancellationCandidate?.externalId}
            onCancelSubscription={cancelSubscriptionHandler}
            onGetFreeMonth={getFreeMonthHandler}
        />
    );
};

export default FreeMonthModalContainer;
