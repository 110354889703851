import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const FeatureCardContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 20px;
    border: 1px solid ${color('border-default')};
    gap: 8px;
`;

export const FeatureCardTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Image = styled.img`
    width: 21px;
`;
