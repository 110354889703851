import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import * as React from 'react';

import * as S from './styled';

import { ReactComponent as Arrow } from './img/arrowNext.svg';

interface IPrevButtonInsightsProps {
    onClick: (e?: React.MouseEvent<HTMLElement>) => void;
    dataLocator?: string;
    disabled?: boolean;
    minWidth?: number;
}

const NextButtonInsights = ({ onClick, dataLocator, disabled = false, minWidth }: IPrevButtonInsightsProps) => {
    const { t } = useTranslation();

    return (
        <S.Button data-locator={dataLocator} onClick={onClick} disabled={disabled} minWidth={minWidth}>
            <Text text={t('insights.nextBtn')} color="on-primary" type="small-button" />
            <Arrow />
        </S.Button>
    );
};

export default NextButtonInsights;
