import { Text } from 'wikr-core-components';
import React from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';
import FeatureCard from './components/FeatureCard';
import BlockTitle from './components/BlockTitle';

import * as S from './styled';

import reminderIcon from './img/reminderIcon.webp';
import fullAccessIcon from './img/fullAccessIcon.webp';
import chargeIcon from './img/chargeIcon.webp';

import { getConfig } from './config';

interface ReminderOfferModalProps {
    onCancelSubscription: () => void;
    onSetReminder: () => void;
}

const ReminderOfferModal = ({ onSetReminder, onCancelSubscription }: ReminderOfferModalProps) => {
    const { title, subtitle, fullAccessBlock, reminderBlock, chargeBlock, buttons } = getConfig();

    return (
        <Modal isOpen fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar />
            <S.ReminderOfferContainer dataLocator="reminderOfferContainer">
                <S.Title>
                    <Text type="h4" medium center text={title} />
                    <Text type="large-text" center text={subtitle} />
                </S.Title>
                <S.ReminderOfferContent>
                    <S.VertcalLine />
                    <S.Block>
                        <BlockTitle
                            icon={fullAccessIcon}
                            iconWidth={24}
                            text={fullAccessBlock.title}
                            dataLocator="reminderOfferFullAccessStep"
                        />
                        <S.BlockContent>
                            {fullAccessBlock.features.map((feature) => {
                                return <FeatureCard key={feature.title} {...feature} />;
                            })}
                        </S.BlockContent>
                    </S.Block>
                    <S.Block>
                        <BlockTitle
                            icon={reminderIcon}
                            iconWidth={28}
                            text={reminderBlock.title}
                            dataLocator="reminderOfferReminderStep"
                        />
                        <S.BlockContent>
                            <Text type="small-text" text={reminderBlock.text} />
                        </S.BlockContent>
                    </S.Block>
                    <S.Block>
                        <BlockTitle
                            icon={chargeIcon}
                            iconWidth={28}
                            text={chargeBlock.title}
                            dataLocator="reminderOfferChargeDateStep"
                        />
                        <S.BlockContent>
                            <Text type="small-text" text={chargeBlock.text} />
                        </S.BlockContent>
                    </S.Block>
                </S.ReminderOfferContent>
                <S.Buttons>
                    <SecondaryDefaultButton
                        text={buttons.setReminderBtnText}
                        onClick={onSetReminder}
                        dataLocator="reminderOfferSetReminderButton"
                    />
                    <OutlinedButton
                        text={buttons.cancelBtnText}
                        onClick={onCancelSubscription}
                        dataLocator="reminderOfferCancelSubscriptionButton"
                    />
                </S.Buttons>
            </S.ReminderOfferContainer>
        </Modal>
    );
};

export default ReminderOfferModal;
