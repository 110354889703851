import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 32px;
    border: 1px solid #D4DAE0;
    border-radius: 14px;
    padding: 12px;
    display: flex;
    align-items: center;
    
    & > img {
        margin-right: 16px;
        width: 76px;
    }
`;


export const Description = styled.div`
    font-weight: 700;
    
    & div {
        margin-bottom: 8px;
    }
    
    & div:last-of-type {
        margin-bottom: 0;
    }
`;
