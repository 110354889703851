import styled from 'styled-components';

import { color } from 'theme/selectors';

export const FiltersButton = styled.button<{ $scrollDirection: string }>`
    position: fixed;
    z-index: 100;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: ${color('on-primary')};
    border-radius: 12px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
    display: ${({ $scrollDirection }) => ($scrollDirection === 'up' ? 'flex' : 'none')};
`;
