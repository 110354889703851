import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import timer from './img/timer.webp';

const ProtocolBlock = () => {
    const { t } = useTranslation();

    return (
        <S.ContentWrap backgroundColor="surface-secondary">
            <S.ImgWrap>
                <Image isForceVisible once maxWidth={84} alt="timer" src={timer} />
            </S.ImgWrap>
            <S.TextWrap>
                <Text type="h1" medium text="16:8" />
                <Text type="small-text" medium text={t('myFastingPlan.protocol.recommended')} />
            </S.TextWrap>
        </S.ContentWrap>
    );
};

export default ProtocolBlock;
