import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import * as React from 'react';

import * as S from './styled';

import { ReactComponent as Arrow } from './img/arrowNext.svg';

interface IDiscoverButtonProps {
    onClick: () => void;
    dataLocator?: string;
    disabled?: boolean;
}

function DiscoverButton({ onClick, dataLocator, disabled = false }: IDiscoverButtonProps) {
    const { t } = useTranslation();

    return (
        <S.Button data-locator={dataLocator} onClick={onClick} disabled={disabled}>
            <Text text={t('dashboard.onboarding1.btnTitle')} color="on-primary" type="small-button" />
            <S.SVGWrap>
                <Arrow />
            </S.SVGWrap>
        </S.Button>
    );
}

export default DiscoverButton;
