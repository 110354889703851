import { TFunction } from 'i18next';

import { ContentConfig } from './types';

export const getModalConfig = (t: TFunction): ContentConfig => {
    return {
        title: t('subscription.cancellation.additionalQuestion.title'),
        optionsData: [
            {
                title: t('subscription.cancellation.additionalQuestion.option1.title'),
                subtitle: t('subscription.cancellation.additionalQuestion.option1.subtitle'),
                value: 'Chat with fasting experts',
                titleType: 'medium-text',
                dataLocator: 'option',
                mb: 12,
                borderColor: 'border-default',
                rightCheckbox: true,
            },
            {
                title: t('subscription.cancellation.additionalQuestion.option2.title'),
                subtitle: t('subscription.cancellation.additionalQuestion.option2.subtitle'),
                value: 'Community',
                titleType: 'medium-text',
                dataLocator: 'option',
                mb: 12,
                borderColor: 'border-default',
                rightCheckbox: true,
            },
            {
                title: t('subscription.cancellation.additionalQuestion.option3.title'),
                subtitle: t('subscription.cancellation.additionalQuestion.option3.subtitle'),
                value: 'Success Stories',
                titleType: 'medium-text',
                dataLocator: 'option',
                mb: 12,
                borderColor: 'border-default',
                rightCheckbox: true,
            },
            {
                title: t('subscription.cancellation.additionalQuestion.option4.title'),
                subtitle: t('subscription.cancellation.additionalQuestion.option4.subtitle'),
                value: 'Workouts for fasting',
                titleType: 'medium-text',
                dataLocator: 'option',
                mb: 12,
                borderColor: 'border-default',
                rightCheckbox: true,
            },
            {
                title: t('subscription.cancellation.additionalQuestion.option5.title'),
                subtitle: t('subscription.cancellation.additionalQuestion.option5.subtitle'),
                value: 'Yoga for fasting',
                titleType: 'medium-text',
                dataLocator: 'option',
                mb: 12,
                borderColor: 'border-default',
                rightCheckbox: true,
            },
            {
                title: t('subscription.cancellation.additionalQuestion.option6.title'),
                subtitle: t('subscription.cancellation.additionalQuestion.option6.subtitle'),
                value: 'Video courses',
                titleType: 'medium-text',
                dataLocator: 'option',
                mb: 12,
                borderColor: 'border-default',
                rightCheckbox: true,
            },
        ],
    };
};
