import { Text, Button } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { PATHS } from 'constants/routerPaths';

import { sendAnalyticViewInAppClick } from 'services/analytics/trackers/dashboard';

import * as S from './styled';

import logoWithProtocol from 'assets/images/logo-with-protocol.webp';
import imgStatictics from './img/imgStatistics.webp';

const DashboardBannerCTABlock = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        sendAnalyticViewInAppClick();
        navigate(PATHS.MAIN);
    };

    return (
        <S.Container>
            <S.LogoBlock>
                <S.Title>
                    <S.LogoIcon alt="logo" src={logoWithProtocol} />
                    <Text type="h3" medium text={t('dashboard.bottomBanner.title')} />
                </S.Title>
                <Button
                    backgroundColor="secondary-default"
                    textColor="surface-main"
                    size="medium"
                    text={t('dashboard.bottomBanner.buttonText')}
                    onClick={handleClick}
                    dataLocator="viewInAppButton"
                />
            </S.LogoBlock>
            <S.ImageBlock>
                <S.StatisticsImg alt="statistics-image" src={imgStatictics} />
            </S.ImageBlock>
        </S.Container>
    );
};

export default DashboardBannerCTABlock;
