import { Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import ContactUsLink from 'components/ContactUsLink';

import fail from '../img/fail.svg';
import success from '../img/done.svg';

const successTitleKey = 'account.settings.deletionModal.successTitle';
const successSubtitleKey = 'account.settings.deletionModal.successSubtitle';
const errorTitleKey = 'account.settings.deletionModal.failTitle';
const errorSubtitleKey = 'account.settings.deletionModal.failSubtitle';

const AUTO_REDIRECT_DELAY = 5000;

interface DeleteResultProps {
    isSuccess: boolean;
    isError: boolean;
    onSuccess: () => void;
    onError: () => void;
}

const DeleteResult = ({ isSuccess, isError, onSuccess, onError }: DeleteResultProps) => {
    const { t } = useTranslation();

    const autoRedirectHandler = () => {
        setTimeout(() => onSuccess(), AUTO_REDIRECT_DELAY);
    };

    useEffect(() => {
        isSuccess && autoRedirectHandler();
    }, []);

    const title = isSuccess ? successTitleKey : errorTitleKey;
    const subtitle = isSuccess ? successSubtitleKey : errorSubtitleKey;
    const buttonTextKey = 'basics.tryAgain';

    return (
        <>
            <Image maxWidth={48} center alt="result umage" src={isSuccess ? success : fail} mb={20} isForceVisible />
            <Text center type="h5" text={t(title)} mb={12} />
            <Text center type="medium-text" text={t(subtitle)} color="text-secondary" mb={32} />
            {isError && <Button text={t(buttonTextKey)} mb={24} onClick={onError} dataLocator="" />}
            {isError && <ContactUsLink subject={t('account.settings.deletionModal.title')} />}
        </>
    );
};

export default DeleteResult;
