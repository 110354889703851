import { hotjar } from 'react-hotjar';

import config from 'config';

const HOTJAR_NUMBER_ID = +config.HOTJAR_ID;
const HOTJAR_VERSION = 6;

export const initHotjar = () => {
    HOTJAR_NUMBER_ID && hotjar.initialize(HOTJAR_NUMBER_ID, HOTJAR_VERSION);
};
