import { ANDROID, IOS } from 'sentry-utils';

import { DEFAULT_LANGUAGE } from 'constants/localization';
import { ZENDESK_ANDROID_URL_PART, ZENDESK_IOS_URL_PART, ZENDESK_URL } from 'constants/links';

import { getLocalizationFromAvailable } from 'helpers/localization';

export const getFaqLinks = (deviceOs?: string) => {
    const lang = getLocalizationFromAvailable() || DEFAULT_LANGUAGE;
    const isMobileDevice = deviceOs === IOS || deviceOs === ANDROID;
    const deviceLink = deviceOs === ANDROID ? ZENDESK_ANDROID_URL_PART : ZENDESK_IOS_URL_PART;

    const urlWithLang = `${ZENDESK_URL}${lang}`;

    if (!isMobileDevice) return urlWithLang;

    return `${urlWithLang}/categories/${deviceLink}`;
};

export const getPolicyLink = (policyLink: string) => {
    const lang = getLocalizationFromAvailable() || DEFAULT_LANGUAGE;

    return `${policyLink}?language=${lang}`;
};
