import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
`;

export const AuthImage = styled(Box)`
    padding-bottom: 20px;
    width: 100%;
    max-width: 190px;
    margin: 0 auto;
`;
