import { useTranslation } from 'react-i18next';

import protocolIcon6 from './img/protocolIcon6.webp';
import protocolIcon5 from './img/protocolIcon5.webp';
import protocolIcon4 from './img/protocolIcon4.webp';
import protocolIcon3 from './img/protocolIcon3.webp';
import protocolIcon2 from './img/protocolIcon2.webp';
import protocolIcon1 from './img/protocolIcon1.webp';

export const getContent = () => {
    const { t } = useTranslation();

    return {
        titleMain: t('dashboard.fastingProtocols.titleMain'),
        titleItem: t('dashboard.fastingProtocols.titleItem'),
        expertName: 'Amelia Ti',
        expertSubtitle: t('dashboard.fastingProtocols.expertSubtitle'),
        foodAdviceTitle: t('dashboard.fastingProtocol.foodAdviceTitle'),
        protocols: [
            {
                id: '1',
                hours: '14',
                fullHours: '14:10',
                hoursKey: t('welcome.yourFasting.option.hours'),
                title: t('welcome.yourFasting.option.title1'),
                description: t('welcome.yourFasting.option.modal.description1'),
                image: protocolIcon1,
                expertAdvice: t('dashboard.fastingProtocol.expertAdvice1'),
                foodAdvice: t('dashboard.fastingProtocol.foodAdvice1'),
            },
            {
                id: '2',
                hours: '16',
                fullHours: '16:8',
                hoursKey: t('welcome.yourFasting.option.hours'),
                title: t('welcome.yourFasting.option.title2'),
                description: t('welcome.yourFasting.option.modal.description2'),
                image: protocolIcon2,
                expertAdvice: t('dashboard.fastingProtocol.expertAdvice2'),
                foodAdvice: t('dashboard.fastingProtocol.foodAdvice2'),
            },
            {
                id: '3',
                hours: '18',
                fullHours: '18:6',
                hoursKey: t('welcome.yourFasting.option.hours'),
                title: t('welcome.yourFasting.option.title3'),
                description: t('welcome.yourFasting.option.modal.description3'),
                image: protocolIcon3,
                expertAdvice: t('dashboard.fastingProtocol.expertAdvice3'),
                foodAdvice: t('dashboard.fastingProtocol.foodAdvice3'),
            },
            {
                id: '4',
                hours: '20',
                fullHours: '20:4',
                hoursKey: t('welcome.yourFasting.option.hours'),
                title: t('welcome.yourFasting.option.title4'),
                description: t('welcome.yourFasting.option.modal.description4'),
                image: protocolIcon4,
                expertAdvice: t('dashboard.fastingProtocol.expertAdvice4'),
                foodAdvice: t('dashboard.fastingProtocol.foodAdvice4'),
            },
            {
                id: '5',
                hours: '23',
                fullHours: '23:1',
                hoursKey: t('welcome.yourFasting.option.hours'),
                title: t('welcome.yourFasting.option.title5'),
                description: t('welcome.yourFasting.option.modal.description5'),
                image: protocolIcon5,
                expertAdvice: t('dashboard.fastingProtocol.expertAdvice5'),
                foodAdvice: t('dashboard.fastingProtocol.foodAdvice5'),
            },
            {
                id: '6',
                hours: '12-23',
                fullHours: '12-23',
                hoursKey: t('welcome.yourFasting.option.custom'),
                title: t('welcome.yourFasting.option.title6'),
                description: t('welcome.yourFasting.option.modal.description6'),
                image: protocolIcon6,
            },
        ],
    };
};
