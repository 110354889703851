import { Icon, Text } from 'wikr-core-components';
import ReactModal from 'react-modal';
import React, { memo } from 'react';

import { Cross, RoundCross } from 'components/Icon';

import {
    ContentStyle,
    ChallengeContentStyle,
    OverlayStyle,
    CloseButton,
    RoundCloseButton,
    ChallengeModalHeading,
    CloseButtonWrapChallenge,
} from './styled';

import { IModal } from './types';

ReactModal.defaultStyles = {};

const Modal = ({
    children,
    isOpen,
    onClose,
    closeOnOverlayClick,
    withCloseButton,
    withCloseBtnForInsights,
    isChallengeModal,
    challengeTitle,
    fullscreen,
    padding,
    dataLocator,
    style,
    ...reactModalProps
}: IModal) => (
    <ReactModal
        style={style}
        shouldCloseOnOverlayClick={Boolean(closeOnOverlayClick)}
        onRequestClose={onClose}
        shouldCloseOnEsc={false}
        isOpen={isOpen}
        contentLabel="Modal"
        contentElement={(props, content) =>
            isChallengeModal ? (
                <ChallengeContentStyle {...props}>{content}</ChallengeContentStyle>
            ) : (
                <ContentStyle {...props} padding={padding} fullscreen={Boolean(fullscreen)}>
                    {content}
                </ContentStyle>
            )
        }
        overlayElement={(props, contentElement) => (
            <OverlayStyle data-locator={dataLocator} {...props}>
                {contentElement}
            </OverlayStyle>
        )}
        ariaHideApp={false}
        {...reactModalProps}
    >
        {withCloseButton &&
            (withCloseBtnForInsights ? (
                <RoundCloseButton onClick={onClose}>
                    <RoundCross color="on-primary" />
                </RoundCloseButton>
            ) : (
                <CloseButton onClick={onClose}>
                    <Cross />
                </CloseButton>
            ))}
        {isChallengeModal && (
            <ChallengeModalHeading>
                <Text type="h6" bold text={challengeTitle || ''} />
                <CloseButtonWrapChallenge data-locator="modalCloseButton" onClick={onClose}>
                    <Icon name="close" size={24} libColor="action-main" />
                </CloseButtonWrapChallenge>
            </ChallengeModalHeading>
        )}
        {children}
    </ReactModal>
);

export default memo(Modal);
