import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    max-width: 432px;
`;

export const FlexContainer = styled(Box)`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
`;

export const GuideWrapper = styled(Box)`
    display: flex;
    width: calc(50% - 6px);
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding: 12px;
`;
