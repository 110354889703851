import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { CONTENT_2 } from 'constants/customContentColors';

import { color } from 'theme/selectors';

export const WebViewContainer = styled(Box)`
    background: ${color('surface-default')};
    display: flex;
    padding: 32px 16px 64px;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
`;

export const MealPlanWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 16px;
    background: ${CONTENT_2};
    width: 100%;
`;

export const DownloadBtn = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 20px;
    background: ${color('secondary-default')};
    border-radius: 12px;
    z-index: 2;
    width: 100%;

    :hover {
        background: ${color('secondary-hovered')};
    }
`;
