import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import mediaQueries from 'theme/mediaQueries';

export const CastControlsWrapper = styled.div`
    display: flex;
    margin-top: 18px;

    ${mediaQueriesGrid.tablet} {
        margin-top: 0;
    }
`;

export const ChromecastButton = styled.button`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    gap: 4px;
    height: 32px;
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
    margin-right: 20px;

    ${mediaQueries.theSmallestDesktop} {
        margin-right: 40px;
    }
`;

export const CastHintButton = styled.button`
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: transparent;
    & > p {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;
