import styled from 'styled-components';

export const Container = styled.div`
    padding: 32px 16px 62px;
`;

export const GuidesList = styled.ul`
    margin-bottom: 18px;
`;

export const GuidesListWrapper = styled.div`
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
`;

export const GuidesListItem = styled.li`
    margin-bottom: 12px;
`;
