import { Text, Box } from 'wikr-core-components';
import useI18next from 'services/i18n';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'helpers/utils';

import OutlinedButton from 'components/OutlinedButton';
import ContactSupportLink from 'components/ContactSupportLink';

import * as S from './styled';

export const ErrorPageContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { path } = location.state || '';

    useI18next();

    const handleClick = () => (isEmpty(path) ? window.location.reload() : navigate(path));

    return (
        <S.ErrorBoundaryWrapper>
            <Box>
                <Text type="h1" center text="😵‍💫" mb={20} dataLocator="wentWrongPageEmoji" />
                <Text type="h5" center text={t('errorPage.title')} mb={12} dataLocator="wentWrongPageTitle" />
                <Text
                    color="text-secondary"
                    text={t('errorPage.subtitle')}
                    center
                    mb={32}
                    dataLocator="wentWrongPageSubTitle"
                />
                <OutlinedButton
                    text={t('basics.tryAgain')}
                    dataLocator="wentWrongPageTryAgainButton"
                    onClick={handleClick}
                    mb={20}
                />
                <ContactSupportLink dataLocator="wentWrongPageContactSupportButton" />
            </Box>
        </S.ErrorBoundaryWrapper>
    );
};
