import { Box, Button, Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import { ChangeEvent, FormEvent } from 'react';
import { FormikProps } from 'formik';

import { isEmpty } from 'helpers/dataStructuresUtils';

import FormikInput from 'components/FormikInput';

import * as S from '../styled';

import { formValues } from '../types';

interface IProfileForm {
    setCycleRegularity: (event: ChangeEvent) => void;
    onCycleRegularityBlur: (event: ChangeEvent) => void;
    isLoading?: boolean;
}

function ProfileForm({
    setFieldTouched,
    values,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    isLoading,
    setCycleRegularity,
    onCycleRegularityBlur,
}: IProfileForm & FormikProps<formValues>) {
    const { t } = useTranslation();

    const handleSubmitForm: (e?: FormEvent<HTMLFormElement> | undefined) => void = (values) => {
        // sendAnalyticSaveChangesAccountClick();

        handleSubmit(values);
    };

    return (
        <Box paddingTop={40}>
            <FormikInput
                mb={34}
                key="name"
                field="name"
                label={t('account.profile.name')}
                type="text"
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                dataLocator="nameInput"
                placeholder={t('account.profile.name')}
            />
            <FormikInput
                field="yearOfBirth"
                mb={34}
                label={t('account.profile.yearOfBirth')}
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                type="number"
                dataLocator="yearOfBirthInput"
                placeholder={t('account.profile.yearOfBirth')}
            />
            <FormikInput
                field="defaultCycleLength"
                mb={34}
                label={t('account.profile.defaultCycleLength')}
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                type="number"
                dataLocator="defaultCycleLengthInput"
                placeholder={t('account.profile.defaultCycleLength')}
            />
            <FormikInput
                field="defaultPeriodLength"
                mb={34}
                label={t('account.profile.defaultPeriodLength')}
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                type="number"
                dataLocator="defaultPeriodLengthInput"
                placeholder={t('account.profile.defaultPeriodLength')}
            />
            <Box mb={34}>
                <Box paddingX={12} mb={4}>
                    <Text
                        bold
                        text={t('account.profile.defaultCycleRegularity')}
                        type="caption"
                        color="text-secondary"
                    />
                </Box>
                <Box>
                    <S.SelectWrapper>
                        <S.InputWrapper
                            touched={!errors['defaultCycleRegularity'] && Boolean(touched['defaultCycleRegularity'])}
                        >
                            <select
                                name="defaultCycleRegularity"
                                id="defaultCycleRegularity"
                                value={values.defaultCycleRegularity}
                                onChange={setCycleRegularity}
                                data-locator="defaultCycleRegularityInput"
                                onBlur={onCycleRegularityBlur}
                                required
                            >
                                <optgroup>
                                    {[
                                        { value: 'regular', name: 'Regular' },
                                        { value: 'irregular', name: 'Irregular' },
                                        { value: 'very_irregular', name: 'Very irregular' },
                                    ].map((item, index) => (
                                        <option key={index} value={item.value} label={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </optgroup>
                            </select>
                            {/*<div className={styles.dropDownIco}>*/}
                            {/*    <DropDownIco />*/}
                            {/*</div>*/}
                            <S.SuccessMessage
                                visible={
                                    !errors['defaultCycleRegularity'] && Boolean(touched['defaultCycleRegularity'])
                                }
                            >
                                <Trans i18nKey="validation.perfect" />
                            </S.SuccessMessage>
                        </S.InputWrapper>
                    </S.SelectWrapper>
                </Box>
            </Box>
            <Button
                onClick={handleSubmitForm}
                isLoading={isLoading}
                dataLocator="profileSettingsSaveBtn"
                backgroundColor="primary-default"
                disabled={!Object.keys(touched).length || !isEmpty(errors)}
                text={t('account.profile.button.save')}
            />
        </Box>
    );
}

export default ProfileForm;
