import { Text, Box } from 'wikr-core-components';
import React from 'react';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';

import * as S from './styled';

import { IWaitingModal } from './types';

const WaitingModal = ({ isOpen, onDiscard, onClose, captions, dataLocator }: IWaitingModal) => (
    <Modal isOpen={isOpen} onClose={onClose} dataLocator={dataLocator}>
        <Box paddingTop={20} paddingBottom={20}>
            <Text type="large-text" color="text-main" bold center mb={24} text={captions.title} />
            <Box>
                <S.Spinner />
            </Box>
            {onDiscard && (
                <OutlinedButton text={captions.discardButton} onClick={onDiscard} dataLocator="stopCancelBtn" />
            )}
        </Box>
    </Modal>
);

export default WaitingModal;
