import * as S from './styled';

interface ImageLocalProps {
    src: string;
    alt: string;
    maxWidth?: number;
}

const ImageLocal = ({ src, alt, maxWidth }: ImageLocalProps) => {
    return <S.ImageWrapper src={src} alt={alt} maxWidth={maxWidth} />;
};

export default ImageLocal;
