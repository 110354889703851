import { Text } from 'wikr-core-components';
import { memo } from 'react';

import OverlineButton from 'components/OverlineButton';
import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';

import * as S from './styled';

import warning from 'assets/img/warning.svg';

import { Captions } from '../../types';

interface AlertModalProps {
    isOpen: boolean;
    onDiscard: () => void;
    onSubmit: () => void;
    captions: Captions;
}

const AlertModal = ({ isOpen, onDiscard, onSubmit, captions }: AlertModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onDiscard} closeOnOverlayClick dataLocator="cancelSubModal">
            <S.Img src={warning} alt="warning" />
            <Text type="h5" center color="text-main" text={captions.title} mb={12} />
            <Text type="medium-text" center color="text-secondary" text={captions.description} mb={32} />

            <OutlinedButton onClick={onSubmit} text={captions.submitButton} mb={20} dataLocator="cancelSubTrueBtn" />
            <OverlineButton onClick={onDiscard} text={captions.discardButton} mb={4} dataLocator="cancelSubFalseBtn" />
        </Modal>
    );
};

export default memo(AlertModal);
