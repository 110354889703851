import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

import { trackClickCustom, trackClick, customTrackDecorator } from './mainTrackers';

export const sendAnalyticWorkoutCardContinue = (eventLabel: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
        eventLabel,
    });
};

export const sendAnalyticWorkoutStartSession = (eventLabel: string) => {
    customTrackDecorator('webview__session__start', '', eventLabel);
};

export const sendAnalyticLibraryStartSession = (eventLabel: string) => {
    customTrackDecorator('library__session__start', '', eventLabel);
};

export const sendAnalyticWorkoutStart = () => {
    trackClickCustom({ event_action: 'button__start' });
};

export const sendAnalyticWorkoutSelect = (event_label: string) => {
    trackClickCustom({ event_action: 'button__select', event_label });
};

export const sendAnalyticWorkoutPlayClick = () => {
    trackClickCustom({ event_action: 'button__play' });
};

export const sendAnalyticWorkoutInfoClick = () => {
    trackClickCustom({ event_action: 'button__info' });
};

export const sendAnalyticWorkoutCastClick = () => {
    trackClickCustom({ event_action: 'button__cast' });
};

export const sendAnalyticWorkoutPlayerBackClick = () => {
    trackClickCustom({ event_action: 'button__back' });
};

export const sendAnalyticWorkoutCompleteBtnClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
    });
};

export const sendAnalyticWorkoutExit = (eventLabel: '0' | '1') => {
    customTrackDecorator('workout_exit', '', eventLabel);
};

export const sendAnalyticWorkoutComplete = () => {
    trackClickCustom({ event_action: 'workout_complete' });
};

export const sendAnalyticWorkoutVideoProgress = (eventLabel: number) => {
    customTrackDecorator('video_progress', '', eventLabel);
};

export const sendAnalyticWorkoutFinishClick = (eventLabel: string) => {
    trackClick({
        screenIdParam: 'workout_finish',
        eventLabel,
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
    });
};
export const sendAnalyticWorkoutFilterOptionClick = (eventLabel: string[]) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.select,
        eventLabel,
    });
};
