import wellBeing from './img/wellBeing.webp';
import sport from './img/sport.webp';
import nutrition from './img/nutrition.webp';

export const RULES_CONTENT = [
    {
        rules: [
            {
                pointNum: 1,
                pointText: 'challenge.rulesModal.rule1',
            },
            {
                pointNum: 2,
                pointText: 'challenge.rulesModal.rule2',
            },
        ],
        img: sport,
        imgDataLocator: 'sportImage',
    },
    {
        rules: [
            {
                pointNum: 3,
                pointText: 'challenge.rulesModal.rule3',
            },
            {
                pointNum: 4,
                pointText: 'challenge.rulesModal.rule4',
            },
            {
                pointNum: 5,
                pointText: 'challenge.rulesModal.rule5',
            },
            {
                pointNum: 6,
                pointText: 'challenge.rulesModal.rule6',
            },
        ],
        img: nutrition,
        imgDataLocator: 'nutritionImage',
    },
    {
        rules: [
            {
                pointNum: 7,
                pointText: 'challenge.rulesModal.rule7',
            },
            {
                pointNum: 8,
                pointText: 'challenge.rulesModal.rule8',
            },
        ],
        img: wellBeing,
        imgDataLocator: 'wellBeingImage',
    },
];
