import { createSelector } from 'reselect';

import { SubscriptionsState } from './reducer';
import { RootReducer } from '../rootReducer';

export const selectSubscriptions = (state: RootReducer) => state.subscriptions;

const selectCancellationCandidate = createSelector(
    selectSubscriptions,
    (subscriptions: SubscriptionsState) => subscriptions.cancellationCandidate
);

const selectRestoreCandidate = createSelector(
    selectSubscriptions,
    (subscriptions: SubscriptionsState) => subscriptions.restoreCandidate
);

export { selectCancellationCandidate, selectRestoreCandidate };
