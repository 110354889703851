import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { fetchBlockPreview } from 'store/insights/actions';

import { PATHS } from 'constants/routerPaths';

import { sendAnalytichInsightCardClick } from 'services/analytics/trackers/dashboard';

import { useDispatch } from 'hooks';

export const useDashboardInsights = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentBlockId, setCurrentBlockId] = useState<number | null>(null);

    const getHandleBlockClick = (insightId: number) => (blockId: number) => {
        sendAnalytichInsightCardClick(insightId, blockId);
        setCurrentBlockId(blockId);
        dispatch(fetchBlockPreview(blockId));

        navigate(`${PATHS.DASHBOARD}/${insightId}/${blockId}`);
    };

    return {
        currentBlockId,
        setCurrentBlockId,
        getHandleBlockClick,
    };
};
