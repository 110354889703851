import { Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { getGuideForMyFastingPlan } from 'helpers/getGuideForMyFastingPlan';

import * as S from './styled';

import guideImg from './img/guideImg.webp';
import { ReactComponent as DownloadSVG } from './img/Download.svg';

interface IGuideBlockProps {
    handleGuideClick: () => void;
    lang: string;
}

const GuideBlock = ({ handleGuideClick, lang }: IGuideBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.BlockWrap mb={49}>
            <S.ImgWrap>
                <Image isForceVisible once maxWidth={343} center mb={28} alt="guide" src={guideImg} />
            </S.ImgWrap>
            <S.PDFLink
                href={getGuideForMyFastingPlan(lang)}
                rel="noopener noreferrer"
                target="_blank"
                onClick={handleGuideClick}
                data-locator="downloadButton"
            >
                <S.LinkText>{t('main.progress.download')}</S.LinkText>
                <DownloadSVG />
            </S.PDFLink>
        </S.BlockWrap>
    );
};

export default GuideBlock;
