import { FilterKeyType, FilterOption, FilterType } from '../types';

const DEFAULT_OPTIONS: Record<string, FilterOption> = {
    beginner: {
        title: 'workouts.filters.category.option1',
        value: FilterType.beginner,
        filterKey: FilterKeyType.level,
        dataLocator: 'fitnessLevelBeginner',
    },
    intermediate: {
        title: 'workouts.filters.category.option2',
        value: FilterType.intermediate,
        filterKey: FilterKeyType.level,
        dataLocator: 'fitnessLevelIntermediate',
    },
    advanced: {
        title: 'workouts.filters.category.option3',
        value: FilterType.advanced,
        filterKey: FilterKeyType.level,
        dataLocator: 'fitnessLevelAdvanced',
    },
};

export const getDefaultOptions = (userFitnessLevel: string | null) => {
    return userFitnessLevel ? [DEFAULT_OPTIONS[userFitnessLevel]] : [DEFAULT_OPTIONS['beginner']];
};
