const percentBreakpoints = [95, 75, 50, 25];

export class VideoTiming {
    private prevTrigger = 0;

    getPercentsOnce = (currentTime: number, duration: number) => {
        const percent = Math.floor((currentTime / duration) * 100);

        const higherBreakpoint = percentBreakpoints.find((breakpoint) => percent > breakpoint) ?? 0;

        if (higherBreakpoint && higherBreakpoint > this.prevTrigger) {
            this.prevTrigger = higherBreakpoint;

            return higherBreakpoint;
        }
    };
}
