import { Text } from 'wikr-core-components';
import { ReactNode } from 'react';

import { IModal } from 'components/Modal/types';
import Modal from 'components/Modal';

import * as S from './styled';

import { ReactComponent as SVGClose } from './assets/close.svg';

type DialogAction = {
    text: string;
    onClick: () => void;
    icon?: ReactNode;
    dataLocator: string;
};

type DialogProps = Omit<IModal, 'children'> & {
    title: string;
    actions: DialogAction[];
};

const Dialog = ({ title, isOpen, onClose, actions }: DialogProps) => {
    return (
        <Modal
            isOpen={isOpen}
            closeOnOverlayClick={Boolean(onClose)}
            contentElement={(props, content) => (
                <S.Content {...props} data-locator="workoutConfirmationModalContainer">
                    {content}
                </S.Content>
            )}
            onRequestClose={onClose}
            style={{ overlay: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }}
        >
            <S.CloseButton onClick={onClose} data-locator="workoutConfirmationModalClsoeButton">
                <SVGClose />
            </S.CloseButton>
            <S.Header>
                <Text text={title} type="large-text" bold />
            </S.Header>
            <div>
                {actions.map((action, index) => (
                    <S.Action role="button" key={index} onClick={action.onClick} data-locator={action.dataLocator}>
                        {action.icon}
                        <Text text={action.text} />
                    </S.Action>
                ))}
            </div>
        </Modal>
    );
};

export default Dialog;
