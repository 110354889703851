import { PasswordRequirementsType } from 'types/changePassword';

export const checkPasswordCondition = (condition: RegExp, value: string) => condition.test(value);

export const passwordRequirements: PasswordRequirementsType = {
    minLength: /^(?=.{10,})/,
    uppercase: /^(?=.*[A-Z])/,
    lowercase: /^(?=.*[a-z])/,
    number: /^(?=.*[0-9])/,
};

export const getColor = (rule: keyof PasswordRequirementsType, value: string) =>
    checkPasswordCondition(passwordRequirements[rule], value) ? '#7FB89D' : '#f84763';
