import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const UpdatingContainer = styled(Box)`
    position: relative;
    max-width: 432px;
    padding: 20px 16px 28px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 40%;
    transform: translateY(-50%);
`;
