import { put, takeLatest, all, call } from 'redux-saga/effects';

import api from 'api';

import {
    setInsights,
    setLoadingState,
    fetchBlockByCategory,
    fetchNextBlockByCategory,
    setCategoryBlocks,
    setCategoryNextBlocks,
    fetchBlockPreview,
    setBlockPreview,
    fetchBlockPreviewLayout,
    setBlockPreviewLayout,
    setNextBlockPreview,
    setPrevBlockPreview,
    fetchNextBlockPreview,
    fetchPrevBlockPreview,
    resetBlockPreview,
    setNextLoadingState,
    setPrevLoadingState,
    setPreviewLayoutLoadingState,
    setNextBlockByCategoryLoading,
} from './actions';
import { notifyError } from '../notifications/actions';

import * as actionTypes from './actionTypes';

import { IBlocks, ICategories, IBlockById } from 'types/insights';

const IGNORED_IDS_OF_CATEGORIES = [5, 41];

function* getInsights() {
    try {
        const response: ICategories = yield call(api.user.getInsights);

        const filteredResponse = response.filter(({ id }) => !IGNORED_IDS_OF_CATEGORIES.includes(id));

        yield put(setInsights(filteredResponse));
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError('getInsights error');
    }
}

function* getBlockByCategory({ payload: categoryId }: ReturnType<typeof fetchBlockByCategory>) {
    try {
        const response: IBlocks = yield call(api.user.getBlockByCategory, categoryId);

        yield put(setCategoryBlocks(response.items));
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError('getBlockByCategory error');
    }
}

function* getNextBlockByCategory({ payload }: ReturnType<typeof fetchNextBlockByCategory>) {
    const { categoryId, pageNumber } = payload;

    try {
        yield put(setNextBlockByCategoryLoading(true));
        const response: IBlocks = yield call(api.user.getNextBlockByCategory, { categoryId, pageNumber });

        yield put(setCategoryNextBlocks(response.items));
        yield put(setNextBlockByCategoryLoading(false));
    } catch (error) {
        notifyError('getBlockByCategory error');
    }
}

function* getBlockPreview({ payload: blockId }: ReturnType<typeof fetchBlockPreview>) {
    try {
        const response: IBlockById = yield call(api.user.getBlockPreview, blockId);

        yield put(setBlockPreview(response));
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError('getBlockByCategory error');
    }
}

function* getBlockPreviewLayout({ payload: blockId }: ReturnType<typeof fetchBlockPreviewLayout>) {
    try {
        const response: IBlockById = yield call(api.user.getBlockPreview, blockId);

        yield put(setBlockPreviewLayout(response));
        yield put(setLoadingState(false));
        yield put(setPreviewLayoutLoadingState(false));
    } catch (error) {
        notifyError('getBlockByCategoryForLayout error');
    }
}

function* getNextBlockPreview({ payload: blockId }: ReturnType<typeof fetchNextBlockPreview>) {
    try {
        const response: IBlockById = yield call(api.user.getBlockPreview, blockId);

        yield put(setNextBlockPreview(response));
        yield put(setNextLoadingState(false));
    } catch (error) {
        notifyError('getBlockByCategory error');
    }
}

function* getPrevBlockPreview({ payload: blockId }: ReturnType<typeof fetchPrevBlockPreview>) {
    try {
        const response: IBlockById = yield call(api.user.getBlockPreview, blockId);

        yield put(setPrevBlockPreview(response));
        yield put(setPrevLoadingState(false));
    } catch (error) {
        notifyError('getBlockByCategory error');
    }
}

function* resetBlockPreviewSaga() {
    try {
        yield put(resetBlockPreview());
    } catch (error) {
        notifyError('getBlockByCategory error');
    }
}

export default function* watchInsights() {
    yield all([
        takeLatest(actionTypes.FETCH_INSIGHTS, getInsights),
        takeLatest(actionTypes.FETCH_BLOCKS_BY_CATEGORY, getBlockByCategory),
        takeLatest(actionTypes.FETCH_BLOCK_PREVIEW, getBlockPreview),
        takeLatest(actionTypes.FETCH_NEXT_BLOCKS_BY_CATEGORY, getNextBlockByCategory),
        takeLatest(actionTypes.FETCH_BLOCK_PREVIEW, getBlockPreview),
        takeLatest(actionTypes.FETCH_BLOCK_PREVIEW_LAYOUT, getBlockPreviewLayout),
        takeLatest(actionTypes.FETCH_NEXT_BLOCK_PREVIEW, getNextBlockPreview),
        takeLatest(actionTypes.FETCH_PREV_BLOCK_PREVIEW, getPrevBlockPreview),
        takeLatest(actionTypes.RUN_RESET_PREVIEW, resetBlockPreviewSaga),
    ]);
}
