import { Text } from 'wikr-core-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { webTokenSelector } from 'store/auth/selectors';
import { webTokenRequest } from 'store/auth/actions';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import LoginInstructions from './components/LoginInstructions';
import ListWithCircleNumbers from './components/ListWithCircleNumbers';
import FemiaRatingPlate from './components/FemiaRatingPlate';
import DownloadBlock from './components/DownloadBlock';
// import FooterMenu from './components/FooterMenu';

import * as S from './styled';

export const InstallApp = () => {
    useScreenLoad();

    const webToken = useSelector(webTokenSelector);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (webToken) return;

        dispatch(webTokenRequest());
    }, [webToken]);

    // const handleDownloadApp = () => {
    //     // sendAnalyticDownloadAppClick();
    //
    //     // Workaround for window.open method since it doesn't work for Safari browser
    //     Object.assign(document.createElement('a'), {
    //         target: '_blank',
    //         rel: 'noopener noreferrer',
    //         href: deepLinkUrl,
    //     }).click();
    // };

    const DONE_STEPS = [
        {
            number: 3,
            label: t('main.steps1.title'),
        },
        {
            number: 4,
            label: t('main.steps2.title'),
        },
    ];

    return (
        <>
            <S.Container backgroundColor="surface-secondary">
                <S.ContentContainer>
                    <Text type="h5" mb={32} bold center text={t('main.title.thankYou')} />
                    <Text type="medium-text" center text={t('main.subtitle.canDownload')} mb={4} />
                    <ListWithCircleNumbers list={DONE_STEPS} isCustomNumbered />
                    <DownloadBlock webToken={webToken} />
                    <FemiaRatingPlate />
                    <LoginInstructions />
                    {/* <FooterMenu />*/}
                </S.ContentContainer>
            </S.Container>
        </>
    );
};
