import { useTranslation } from 'react-i18next';

import { ModalName } from 'store/modals/types';

import { sendAnalyticSurveyModalContinueClick } from 'services/analytics';

import { useModals, useCancelSubscription } from 'hooks';

import SurveyModal from './SurveyModal';

interface SurveyModalContainerProps {
    projectName: string;
}

const SurveyModalContainer = ({ projectName }: SurveyModalContainerProps) => {
    const { t } = useTranslation();
    const { openModal } = useModals();
    const { discardCancellation } = useCancelSubscription();

    if (!projectName) {
        return null;
    }

    const title = t('subscription.cancellation.survey.title', { productName: projectName });

    const onSubmitHandler = (answer: string) => {
        sendAnalyticSurveyModalContinueClick(answer);

        openModal(ModalName.surveyThankYou);
    };

    return <SurveyModal title={title} onSubmit={onSubmitHandler} onDiscard={discardCancellation} />;
};

export default SurveyModalContainer;
