import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { ContentModal } from './components/ContentModal/ContentModal';
import { ContentOption } from './components';

import * as S from './styled';

import { OptionContent } from './types';

import { BLOCK_CONTENT } from './content';

export const YourFastingBlock = () => {
    const { t } = useTranslation();

    const [modalContent, setModalContent] = useState<OptionContent | null>(null);

    const handleOptionClick = (optionNumber: number) => {
        setModalContent(BLOCK_CONTENT[optionNumber] ?? null);
    };

    const handleModalClose = () => setModalContent(null);

    return (
        <S.YourFastingBlock backgroundColor="surface-secondary">
            <S.ContentContainer>
                <S.Text text={t('welcome.yourFasting.title')} center type="h2" medium mb={40} />

                <S.ContentOptionsContainer>
                    {BLOCK_CONTENT.map((content, index) => (
                        <ContentOption key={index} content={content} onClick={() => handleOptionClick(index)} />
                    ))}
                </S.ContentOptionsContainer>
            </S.ContentContainer>
            <S.BannerContent backgroundColor="surface-hovered">
                <S.Text text={t('welcome.yourFasting.banner.title.question')} type="h3" medium center />
                <S.Text text={t('welcome.yourFasting.banner.title.answer')} type="h3" medium center mb={20} />
                <S.BannerSubTitleText text={t('welcome.yourFasting.banner.subtitle')} type="large-text" medium center />
            </S.BannerContent>
            {modalContent && (
                <ContentModal isOpen={Boolean(modalContent)} content={modalContent} onClose={handleModalClose} />
            )}
        </S.YourFastingBlock>
    );
};
