import imageSafari2 from './assets/safari2.webp';
import imageSafari1 from './assets/safari1.webp';
import imageIos2 from './assets/ios2.webp';
import imageIos1 from './assets/ios1.webp';
import imageChrome2 from './assets/chrome2.webp';
import imageChrome1 from './assets/chrome1.webp';
import imageAndroid3 from './assets/android3.webp';
import imageAndroid2 from './assets/android2.webp';
import imageAndroid1 from './assets/android1.webp';

import { StepsProps } from './Steps';
import { StepProps } from './Step';

type IStepVariant = {
    [name in StepsProps['variant']]: {
        steps: StepProps[];
        footer?: string;
    };
};

export const STEP_VARIANT: IStepVariant = {
    ios: {
        steps: [
            {
                stepNumber: '1',
                text: 'In fullscreen mode tap, the “AirPlay” button',
                img: imageIos1,
                imgAlt: 'AirPlay button',
            },
            {
                stepNumber: '2',
                text: 'Select your device',
                img: imageIos2,
                imgAlt: 'Device list',
            },
        ],
    },
    android: {
        steps: [
            {
                stepNumber: '1',
                text: 'Tap the “Menu” button in the video player',
                img: imageAndroid1,
                imgAlt: 'Menu button',
            },
            {
                stepNumber: '2',
                text: 'Press the “Cast” button',
                img: imageAndroid2,
                imgAlt: 'Cast button',
            },
            {
                stepNumber: '3',
                text: 'Select your device',
                img: imageAndroid3,
                imgAlt: 'Device list',
                width: 200,
            },
        ],
    },
    safari: {
        steps: [
            {
                stepNumber: '1',
                text: 'In fullscreen mode press, the “AirPlay” button',
                img: imageSafari1,
                imgAlt: 'AirPlay button',
            },
            {
                stepNumber: '2',
                text: 'Select your device',
                img: imageSafari2,
                imgAlt: 'Device list',
            },
        ],
        footer: 'Within a few moments, the video will appear on your TV screen.',
    },
    chrome: {
        steps: [
            {
                stepNumber: '1',
                text: 'Press the “Cast to TV” button under the video',
                img: imageChrome1,
                imgAlt: 'Menu button',
                width: 170,
            },
            {
                stepNumber: '2',
                text: 'Select your device in the “Cast tab” window',
                img: imageChrome2,
                imgAlt: 'Cast button',
                width: 261,
                style: { marginLeft: '36px' },
            },
        ],
        footer: 'Within a few moments, the video will appear on your TV screen.',
    },
};
