import { Text, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { memo, useEffect } from 'react';

import { AVAILABLE_CURRENCY } from 'constants/subscriptions';
import {
    LIFE_TIME_PRICE_INT,
    LIFE_TIME_PRICE_CENTS,
    MODAL_OVERLAY_STYLES,
    MODAL_CONTENT_STYLES,
    MOBILE_MODAL_CONTENT_STYLES,
} from './constants';

import { sendAnalyticLifeTimeQuestionScreenLoad } from 'services/analytics';

import { useSelector, useSpecialOfferModal } from 'hooks';

import { isMaleGender } from 'helpers/utils';
import { getWindowSize } from 'helpers/getWindowSize';
import { getSubscriptionTitle } from 'components/SubscriptionsTab/components/SubscriptionCard/helpers';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';

import * as S from './styled';

import women from 'assets/img/specialOfferWomen.webp';
import stars from 'assets/img/specialOfferStars.svg';
import men from 'assets/img/specialOfferMen.webp';

import LifeTimeBadge from '../LifeTimeBadge';

interface LifeTimeAccessModalProps {
    isOpen: boolean;
    externalId: string;
    onCancelSubscription: () => void;
    onGetLifeTimeAccess: () => void;
}

const LifeTimeAccessModal = ({
    isOpen,
    externalId,
    onGetLifeTimeAccess,
    onCancelSubscription,
}: LifeTimeAccessModalProps) => {
    const { t } = useTranslation();
    const gender = useSelector(({ user }) => user.gender);

    const { candidatePriceData } = useSpecialOfferModal(externalId);

    useEffect(() => {
        sendAnalyticLifeTimeQuestionScreenLoad();
    }, []);

    const isMobile = getWindowSize(376);

    const isMale = isMaleGender(gender);

    const paymentLabel = candidatePriceData && getSubscriptionTitle(candidatePriceData.period, false);

    if (!candidatePriceData) {
        return null;
    }

    const OLD_PRICE = `${AVAILABLE_CURRENCY[candidatePriceData.currency]}${candidatePriceData.integer}.${
        candidatePriceData.cents
    }`;

    return (
        <Modal
            isOpen={isOpen}
            fullscreen={!isMobile}
            style={{
                content: {
                    ...MODAL_CONTENT_STYLES,
                    ...(isMobile && MOBILE_MODAL_CONTENT_STYLES),
                },
                overlay: MODAL_OVERLAY_STYLES,
            }}
        >
            <S.ModalContainer>
                <S.TextWrapper>
                    <Text text={t('subscription.cancellation.lifeTimeAccessQuestion.title')} type="h5" medium center />
                </S.TextWrapper>
                <Image alt="offer" src={isMale ? men : women} maxWidth={375} />

                {isMobile && <S.DividerWrapper />}

                <S.ContentContainer>
                    <Image alt="rate" src={stars} maxWidth={92} mb={18} />
                    <S.PriceWrapper>
                        <S.OldPriceWrapper>
                            <S.OldPrice text={OLD_PRICE} color="text-secondary-subdued" type="h5" center mb={4} />
                            <S.OldPriceTextWrapper>
                                <Text
                                    color="text-secondary-subdued"
                                    type="overline"
                                    bold
                                    uppercase
                                    center
                                    text={t('subscription.cancellation.lifeTimeAccessQuestion.perPeriod', {
                                        context: paymentLabel?.context,
                                    })}
                                />
                            </S.OldPriceTextWrapper>
                        </S.OldPriceWrapper>
                        <S.NewPriceWrapper>
                            <LifeTimeBadge isAbsolute />
                            <S.NewPriceAmount>
                                <Text text={AVAILABLE_CURRENCY[candidatePriceData.currency]} type="small-text" bold />
                                <Text text={LIFE_TIME_PRICE_INT} type="h3" bold />
                                <Text text={LIFE_TIME_PRICE_CENTS} type="small-text" bold />
                            </S.NewPriceAmount>
                            <S.NewPriceTextWrapper>
                                <Text
                                    text={t('subscription.cancellation.lifeTimeAccessQuestion.oneTimePayment')}
                                    uppercase
                                    type="overline"
                                    center
                                />
                            </S.NewPriceTextWrapper>
                        </S.NewPriceWrapper>
                    </S.PriceWrapper>
                    <Text text={t('subscription.cancellation.specialOffer.disclaimer')} type="caption" mb={12} />
                    <OutlinedButton
                        text={t('subscription.cancellation.lifeTimeAccessQuestion.getLifeTimeAccess')}
                        onClick={onGetLifeTimeAccess}
                        mb={12}
                    />
                    <OutlinedButton text={t('subscription.text.cancel')} onClick={onCancelSubscription} />
                </S.ContentContainer>
            </S.ModalContainer>
        </Modal>
    );
};

export default memo(LifeTimeAccessModal);
