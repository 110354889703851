import { useCancelSubscription, useReturnOffer } from 'hooks';

import ReturnOfferModal from './components/ReturnOfferModal';

const ReturnOfferModalContainer = () => {
    const { resetCandidate, onResetSubscription } = useReturnOffer();
    const { cancellationCandidate } = useCancelSubscription();

    if (!cancellationCandidate && !resetCandidate) {
        return null;
    }

    return (
        <ReturnOfferModal
            externalId={cancellationCandidate?.externalId || resetCandidate?.external_id || ''}
            onSubmitDiscount={onResetSubscription}
            withBanner={!resetCandidate}
        />
    );
};

export default ReturnOfferModalContainer;
