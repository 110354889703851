import { TFunction } from 'i18next';

import { GUIDES_OFFER_FINAL_MODAL, sendAnalyticCancelModalScreenLoad } from 'services/analytics/trackers/cancelOffers';

import sandClock from '../img/sandClock.svg';
import heart from '../img/heart.svg';
import exclamationMark from '../img/exclamationMark.svg';

export const getOfferGuidesSuccessData = ({ closeModal, t }: { closeModal: () => void; t: TFunction }) => {
    return {
        img: heart,
        title: t('subscription.cancellation.guidesOffer.success.title'),
        subtitle: t('subscription.cancellation.guidesOffer.success.subtitle'),
        btnText: t('subscription.cancellation.guidesOffer.success.button'),
        btnDataLocator: 'updatingSubscriptionPlanDownloadGuidesButton',
        onBtnClick: closeModal,
        analyticScreenLoadHandler: () => sendAnalyticCancelModalScreenLoad(GUIDES_OFFER_FINAL_MODAL),
    };
};

export const getOfferGuidesErrorData = ({ onOfferGuides, t }: { onOfferGuides: () => void; t: TFunction }) => {
    return {
        img: exclamationMark,
        title: t('basics.appError'),
        mainBtnText: t('basics.tryAgain'),
        onMainBtnClick: onOfferGuides,
        mainBtnDataLocator: 'updatingSubscriptionPlanTryAgainButton',
        supportLinkDataLocator: 'updatingSubscriptionPlanContactSupportButton',
    };
};

export const getOfferGuidesLoadingData = (t: TFunction) => {
    return {
        img: sandClock,
        title: t('basics.loading'),
    };
};
