import recipe2 from './img/recipe2.webp';
import recipe1 from './img/recipe1.webp';
import food3 from './img/food3.webp';
import food2 from './img/food2.webp';
import food1 from './img/food1.webp';

export const RECIPES = [
    {
        text: 'welcome.tasteGood.recipe1',
        img: recipe1,
    },
    {
        text: 'welcome.tasteGood.recipe2',
        img: recipe2,
    },
];

export const POINTS = [
    {
        title: 'welcome.tasteGood.firstPoint.title',
        text: 'welcome.tasteGood.firstPoint.text',
        img: food1,
    },
    {
        title: 'welcome.tasteGood.secondPoint.title',
        text: 'welcome.tasteGood.secondPoint.text',
        img: food2,
    },
    {
        title: 'welcome.tasteGood.thirdPoint.title',
        text: 'welcome.tasteGood.thirdPoint.text',
        img: food3,
    },
];
