import { call, takeLatest, all, put } from 'redux-saga/effects';

import api from 'api';

import { notifyError } from 'store/notifications/actions';
import { FETCH_CHALLENGE_75 } from 'store/challenge75/actionTypes';
import { setChallenge75 } from 'store/challenge75/actions';

import { IChallengeResponse } from 'types/challenge75';

export function* getChallenge75() {
    try {
        const challengeData: IChallengeResponse = yield call(api.challenge75.getChallenge75);

        yield put(setChallenge75(challengeData));
    } catch (error: any) {
        notifyError('getChallenge75 error');
    }
}

export default function* watchChallenge75() {
    yield all([takeLatest(FETCH_CHALLENGE_75, getChallenge75)]);
}
