import { useTranslation } from 'react-i18next';

import { getPurchasedGuideTitleTranslationKey } from './helpers';

import { Guide } from 'components/Guide';

import * as S from './styled';

import { IGuide } from 'types/guide';

const GuidesList = ({ guides, dataLocator }: { guides: IGuide[]; dataLocator?: string }) => {
    const { t } = useTranslation();

    const getTitle = (name: string, internalName?: string) => {
        if (!internalName) {
            return name;
        }

        return getPurchasedGuideTitleTranslationKey({ t, internalName, name });
    };

    return (
        <S.GuidesList>
            {guides.map(({ name, preview, download_url: downloadUrl, internal_name: internalName }: IGuide, index) => (
                <S.GuidesListItem key={name}>
                    <Guide
                        title={getTitle(name, internalName)}
                        img={preview}
                        link={downloadUrl}
                        name={name}
                        dataLocator={`${dataLocator}${index}`}
                        backgroundColor="surface-main"
                    />
                </S.GuidesListItem>
            ))}
        </S.GuidesList>
    );
};

export default GuidesList;
