import { NO_CENTS_CURRENCIES } from 'constants/subscriptions';

import { getSeparatePrice } from 'hooks/subscriptions/helpers';
import { useSelector } from 'hooks';

const useSpecialOfferModal = (externalId: string) => {
    const { subscriptions, discountSubscription, isDiscountLoading } = useSelector(
        ({ subscriptions }) => subscriptions
    );

    const cancelCandidate = subscriptions.find(({ external_id }) => external_id === externalId);

    if (!cancelCandidate || !discountSubscription) {
        return { candidatePriceData: null, discountPriceData: null, isDiscountLoading };
    }

    const product = cancelCandidate.product;

    const candidatePriceData = {
        currency: product.currency,
        period: discountSubscription.subscription_period,
        initialOldPrice: product.amount,
        ...getSeparatePrice(product.amount, NO_CENTS_CURRENCIES.includes(product.currency)),
    };
    const discountPriceData = {
        currency: discountSubscription?.currency,
        initialNewPrice: discountSubscription?.amount,
        ...getSeparatePrice(discountSubscription?.amount, NO_CENTS_CURRENCIES.includes(discountSubscription?.currency)),
    };

    return { candidatePriceData, discountPriceData, isDiscountLoading };
};

export default useSpecialOfferModal;
