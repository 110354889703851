import styled from 'styled-components';

import { color } from 'theme/selectors';

export const BlockTitle = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const Icon = styled.div`
    min-width: 56px;
    height: 56px;
    background-color: ${color('surface-secondary')};
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Image = styled.img<{ width: number }>`
    width: ${({ width }) => `${width}px`};
`;
