import React from 'react';

import * as S from './styled';

const ListWithCircleNumbers = ({ list, isCustomNumbered = false }: any) => {
    return (
        <S.Container isCustomNumbered={isCustomNumbered}>
            {list.map((item: any, index: number) => (
                <li key={index}>
                    {isCustomNumbered && <S.Number>{item.number}</S.Number>}
                    {item.label}
                </li>
            ))}
        </S.Container>
    );
};

export default ListWithCircleNumbers;
