import { useEffect, useState } from 'react';

import { fetchBlockByCategory, fetchNextBlockByCategory } from 'store/insights/actions';

import { sendAnalyticInsCategoryShowMoreClick } from 'services/analytics/trackers/insights';

import { useDispatch, useSelector } from 'hooks';

const PER_PAGES = 20;

export const useBlocksByCategory = (categoryId?: string | null) => {
    const { blocksByCategory, isLoading, isPreviewLayoutLoading, isBlocksNextLoading } = useSelector(
        ({ insights }) => insights
    );
    const dispatch = useDispatch();
    const [hasMoreBlocks, setHasMoreBlocks] = useState(true);

    useEffect(() => {
        window.scroll(0, 0);

        categoryId && dispatch(fetchBlockByCategory(categoryId));
    }, []);

    useEffect(() => {
        const moduleCount = blocksByCategory.length % PER_PAGES;

        if (moduleCount < PER_PAGES && moduleCount !== 0) {
            setHasMoreBlocks(false);
        }
    }, [blocksByCategory]);

    const handleNextBlocks = () => {
        sendAnalyticInsCategoryShowMoreClick();

        const nextPage = blocksByCategory.length / PER_PAGES + 1;

        categoryId && hasMoreBlocks && dispatch(fetchNextBlockByCategory(categoryId, nextPage));
    };

    return {
        isLoading,
        isPreviewLayoutLoading,
        isBlocksNextLoading,
        blocksByCategory,
        handleNextBlocks,
        hasMoreBlocks,
    };
};
