import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Button = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 44px;
    padding: 6px 16px;
    background-color: ${color('secondary-default')};
    border-radius: 100px;

    &:hover {
        background-color: ${color('secondary-hovered')};
    }

    &:active {
        background-color: ${color('secondary-active')};
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${color('secondary-disabled')};
    }

    ${mediaQueries.laptop} {
        width: fit-content;
        align-self: end;
    }
`;

export const SVGWrap = styled.div`
    display: flex;
    padding-left: 8px;
`;
