import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ContentWrap = styled(Box)`
    margin: 0 auto;
    width: 100%;
    max-width: 375px;
    padding: 32px 16px 80px;

    ${mediaQueriesGrid.desktop} {
        max-width: 608px;
        padding: 32px 0 80px;
    }
`;

export const BackgroundImage = styled(Box)`
    display: none;

    ${mediaQueriesGrid.desktop} {
        display: block;
        position: absolute;
        top: 253px;
        left: 280px;
        right: 40px;
    }
`;

export const BtnWrap = styled(Box)`
    width: 100%;

    ${mediaQueriesGrid.desktop} {
        max-width: 360px;
        margin: 0 auto;
    }
`;
