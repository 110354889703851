import { Box, Image, Button } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled(Box)`
    padding: 12px 16px 16px;
`;

export const AccordionContainer = styled.div<{ isActive: boolean }>`
    max-height: 0;
    overflow: hidden;

    transition: all 0.3s ease;

    ${({ isActive }) =>
        isActive &&
        css`
            max-height: 1400px; ;
        `}
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
`;

export const LogoTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ArrowButton = styled.button<{ isActive: boolean }>`
    height: 32px;
    width: 32px;
`;

export const ArrowWrapper = styled(Image)<{ isActive?: boolean }>`
    transform: rotate(0);
    ${({ isActive }) =>
        isActive &&
        css`
            transform: rotate(180deg);
        `}
    transition: all 0.3s ease;
    cursor: pointer;
`;

export const GetAppButton = styled(Button)`
    :active:not(:disabled) {
        background: ${color('secondary-active')};
    }
`;
