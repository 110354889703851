import { Image as WikrImage } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ContentOptionContainer = styled.div`
    background: ${color('on-primary')};
    border-radius: 24px;
    overflow: hidden;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    transition: box-shadow 0.3s;
    height: 100%;

    &:hover {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.26);
        cursor: pointer;
    }

    ${mediaQueriesGrid.tablet} {
        max-width: 100%;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Image = styled(WikrImage)`
    width: 96px;
    margin-left: 16px;
`;

export const HoursAmount = styled.span`
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
`;
