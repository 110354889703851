import { Box, Text } from 'wikr-core-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { PATHS } from 'constants/routerPaths';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import {
    sendAnalyticNextOnboardingClick,
    sendAnalyticToggleDemoClick,
    sendAnalyticResetOnboardingClick,
} from 'services/analytics/trackers/dashboard';
import { trackScreenLoad } from 'services/analytics';

import { useWeightStatistic } from './hooks/useWeightStatistic';

import { Onboarding2 } from './components/Onboarding2/Onboarding2';
import {
    TitleBlock,
    PlateProtocolBlock,
    DashboardInsightsBlock,
    DashboardBannerCTABlock,
    WeeklyProgressBlock,
    StatisticsBlock,
    Onboarding1Modal,
} from './components';

import * as S from './styled';

export const Dashboard = () => {
    useScreenLoad();
    useWeightStatistic();

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [isDemo, setIsDemo] = useState(false);
    const [isOnboarding1, setIsOnboarding1] = useState(false);
    const [isOnboarding2, setIsOnboarding2] = useState(false);

    useEffect(() => {
        const isOnboardingPass = localStorage.getItem('onboardingsPass') === 'true';

        if (!isOnboardingPass) {
            setIsOnboarding1(true);
            setIsDemo(true);
            navigate(PATHS.DASHBOARD_ONBOARDING_1);
        }
        if (isOnboardingPass && !isOnboarding1 && !isOnboarding2) {
            setIsOnboarding2(false);
            setIsDemo(false);
            navigate(PATHS.DASHBOARD);
        }
    }, []);

    useEffect(() => {
        const isOnboardingPass = localStorage.getItem('onboardingsPass') === 'true';

        if (!isOnboardingPass && isOnboarding1) {
            trackScreenLoad();
            setIsOnboarding1(true);
            setIsDemo(true);
            navigate(PATHS.DASHBOARD_ONBOARDING_1);
        }
        if (!isOnboardingPass && !isOnboarding1 && isOnboarding2) {
            trackScreenLoad();
            navigate(PATHS.DASHBOARD_ONBOARDING_2);
        }
    }, [location.pathname]);

    const onEndOnboarding1 = () => {
        setIsOnboarding1(false);
        setIsOnboarding2(true);
        sendAnalyticNextOnboardingClick(1);
        navigate(PATHS.DASHBOARD_ONBOARDING_2);
    };

    const onEndOnboarding2 = () => {
        localStorage.setItem('onboardingsPass', 'true');
        setIsOnboarding2(false);
        setIsDemo(false);
        navigate(PATHS.DASHBOARD);
    };

    const onResetOnboarding = () => {
        sendAnalyticResetOnboardingClick();
        localStorage.setItem('onboardingsPass', 'false');
        navigate(PATHS.DASHBOARD_ONBOARDING_1);
        setIsOnboarding1(true);
        setIsDemo(true);
    };

    const onToggleDemo = (val: boolean) => {
        sendAnalyticToggleDemoClick(val ? 1 : 0);
        setIsDemo(val);
    };

    return (
        <Box backgroundColor="surface-secondary">
            <S.ContentContainer>
                <Onboarding1Modal isOpen={isOnboarding1} onDiscoverClick={onEndOnboarding1} />
                <Onboarding2 isRun={isOnboarding2} onEndOnboarding={onEndOnboarding2} />

                <TitleBlock isDemo={isDemo} setDemo={onToggleDemo} />
                <PlateProtocolBlock isDemo={isDemo} setIsDemo={setIsDemo} />
                <WeeklyProgressBlock isSkeleton={false} isDemo={isDemo} />
                <DashboardInsightsBlock />
                <StatisticsBlock isDemo={isDemo} />
                {!isDemo && <DashboardBannerCTABlock />}
                <S.ButtonResetOnboarding onClick={onResetOnboarding}>
                    <Text type="overline" text={t('dashboard.onboarding.reset.title')} />
                </S.ButtonResetOnboarding>
            </S.ContentContainer>
        </Box>
    );
};
