import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const RoundCross = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0003 2.66675C8.62699 2.66675 2.66699 8.62675 2.66699 16.0001C2.66699 23.3734 8.62699 29.3334 16.0003 29.3334C23.3737 29.3334 29.3337 23.3734 29.3337 16.0001C29.3337 8.62675 23.3737 2.66675 16.0003 2.66675ZM21.7338 21.7334C21.2138 22.2534 20.3738 22.2534 19.8538 21.7334L16.0005 17.8801L12.1471 21.7334C11.6271 22.2534 10.7871 22.2534 10.2671 21.7334C10.0175 21.4843 9.87714 21.1461 9.87714 20.7934C9.87714 20.4407 10.0175 20.1025 10.2671 19.8534L14.1205 16.0001L10.2671 12.1468C10.0175 11.8976 9.87714 11.5594 9.87714 11.2067C9.87714 10.8541 10.0175 10.5159 10.2671 10.2667C10.7871 9.74675 11.6271 9.74675 12.1471 10.2667L16.0005 14.1201L19.8538 10.2667C20.3738 9.74675 21.2138 9.74675 21.7338 10.2667C22.2538 10.7867 22.2538 11.6267 21.7338 12.1468L17.8805 16.0001L21.7338 19.8534C22.2405 20.3601 22.2405 21.2134 21.7338 21.7334Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(RoundCross);
