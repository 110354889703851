import * as actionTypes from './actionTypes';

import { CancelCandidate, RestoreCandidate } from 'hooks/subscriptions/types';

import { IDiscountSubscription, ISubscription } from 'types/subscription';
import {
    ICancelSubscription,
    IDiscountSubscriptionSaga,
    ISetSubscriptionReminder,
    IPauseSubscription,
    IResumeSubscription,
    IFetchDiscountSubscription,
    IResetSubscription,
    IRestoreSubscription,
} from './types';

export const fetchSubscriptions = () => {
    return {
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS,
    } as const;
};

export const setSubscriptions = (payload: Array<ISubscription>) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const setCancellationCandidate = (payload: CancelCandidate | null) => {
    return {
        type: actionTypes.SET_CANCELLATION_CANDIDATE,
        payload,
    } as const;
};

export const cancelSubscription = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
};

export const discardSubscriptionCancellation = () => {
    return {
        type: actionTypes.DISCARD_SUBSCRIPTION_CANCELLATION,
    } as const;
};

export const setSubscriptionsFetchingStatus = (payload: boolean) => {
    return {
        type: actionTypes.SET_FETCHING_STATUS,
        payload,
    } as const;
};

export const fetchDiscountSubscriptions = (payload: IFetchDiscountSubscription) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const setIsDiscountLoading = (payload: boolean) => {
    return {
        type: actionTypes.SET_IS_DISCOUNT_LOADING,
        payload,
    } as const;
};

export const setDiscountSubscription = (payload: IDiscountSubscription | null) => {
    return {
        type: actionTypes.SET_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const updateSubscription = (payload: IDiscountSubscriptionSaga) => {
    return {
        type: actionTypes.UPDATE_SUBSCRIPTION,
        payload,
    } as const;
};

export const resetSubscriptions = () => {
    return {
        type: actionTypes.RESET_SUBSCRIPTIONS,
    } as const;
};

export const setReminder = (payload: ISetSubscriptionReminder) => {
    return {
        type: actionTypes.SET_REMINDER,
        payload,
    } as const;
};

export const pauseSubscription = (payload: IPauseSubscription) => {
    return {
        type: actionTypes.PAUSE_SUBSCRIPTION,
        payload,
    } as const;
};

export const setFreeMonthSubscription = (payload: IPauseSubscription) => {
    return {
        type: actionTypes.SET_FREE_MONTH_SUBSCRIPTION,
        payload,
    } as const;
};

export const resumeSubscription = (payload: IResumeSubscription) => {
    return {
        type: actionTypes.RESUME_SUBSCRIPTION,
        payload,
    } as const;
};

export const resetSubscription = (payload: IResetSubscription) => {
    return {
        type: actionTypes.RESET_SUBSCRIPTION,
        payload,
    } as const;
};

export const setRestoreCandidate = (payload: RestoreCandidate | null) => {
    return {
        type: actionTypes.SET_RESTORE_CANDIDATE,
        payload,
    } as const;
};

export const restoreSubscription = (payload: IRestoreSubscription) => {
    return {
        type: actionTypes.RESTORE_SUBSCRIPTION,
        payload,
    } as const;
};

export const setResetCRMCandidateSubscriptionId = (payload: string) => {
    return {
        type: actionTypes.SET_RESET_CRM_CANDIDATE_SUBSCRIPTION_ID,
        payload,
    } as const;
};
