import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ModalName } from 'store/modals/types';
import { setOfferGuides } from 'store/guides/actions';

import { DEFAULT_SUBSCRIPTION_REQUEST_ERROR } from 'constants/subscriptions';
import { PATHS } from 'constants/routerPaths';

import { sendAnalyticGuidesOfferFinalDownloadClick } from 'services/analytics/trackers/cancelOffers';
import { sendAnalyticSubscriptionCancelError, sendAnalyticSubscriptionCancelSuccess } from 'services/analytics';

import { useDispatch, useModals } from 'hooks';

import {
    getOfferGuidesErrorData,
    getOfferGuidesLoadingData,
    getOfferGuidesSuccessData,
} from './helpers/setOfferGuides';

const useCancelGuidesOffer = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { openModal, closeModal } = useModals();

    const onSetOfferGuides = () => {
        openModal(ModalName.withLoaderModal, { modalData: getOfferGuidesLoadingData(t) });

        dispatch(
            setOfferGuides({
                onSuccess: () => {
                    sendAnalyticSubscriptionCancelSuccess();

                    openModal(ModalName.successFeedbackModal, {
                        modalData: getOfferGuidesSuccessData({
                            t,
                            closeModal: () => {
                                closeModal();
                                sendAnalyticGuidesOfferFinalDownloadClick();
                                navigate(PATHS.FITNESS_GUIDES);
                            },
                        }),
                    });
                },
                onError: (error?: string) => {
                    sendAnalyticSubscriptionCancelError(error ?? DEFAULT_SUBSCRIPTION_REQUEST_ERROR);

                    openModal(ModalName.errorFeedbackModal, {
                        modalData: getOfferGuidesErrorData({
                            t,
                            onOfferGuides: onSetOfferGuides,
                        }),
                    });
                },
            })
        );
    };

    return { onSetOfferGuides };
};

export default useCancelGuidesOffer;
