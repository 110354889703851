import { useEffect } from 'react';

import { ModalName } from 'store/modals/types';

import {
    PROGRESS_CANCEL_MODAL,
    PROGRESS_FEEDBACK_MODAL,
    PROGRESS_FREE_MONTH_MODAL,
} from './components/ProgressModal/constants';

import { sendAnalyticProgressModalScreenLoad } from 'services/analytics';

import { useCancelSubscription, useModals } from 'hooks';

import { ProgressModalContainerPropsType } from './components/ProgressModal/types';
import ProgressModal from './components/ProgressModal';

const ProgressModalContainer = ({ type }: ProgressModalContainerPropsType) => {
    const { openModal } = useModals();
    const { discardCancellation } = useCancelSubscription();

    useEffect(() => {
        sendAnalyticProgressModalScreenLoad(type);
    }, [type]);

    // trigger after animation finish
    const onSubmit = () => {
        setTimeout(() => {
            if (type === PROGRESS_CANCEL_MODAL) {
                discardCancellation();
                openModal(ModalName.cancellationSuccessModal);
            }

            if (type === PROGRESS_FREE_MONTH_MODAL) {
                openModal(ModalName.thankYouModal, {
                    withPremium: true,
                });
            }

            if (type === PROGRESS_FEEDBACK_MODAL) {
                openModal(ModalName.thankYouModal, {
                    withPremium: false,
                });
            }
        }, 1000);
    };

    return <ProgressModal onSubmit={onSubmit} type={type} />;
};

export default ProgressModalContainer;
