import { useTranslation } from 'react-i18next';

import { selectCancellationCandidate } from 'store/subscriptions/selectors';
import { setCancellationCandidate } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import {
    sendAnalyticCancelSubscriptionCancelClick,
    sendAnalyticCancelSubscriptionSubmitClick,
} from 'services/analytics';

import { CancelCandidate } from 'hooks/subscriptions/types';
import { useDispatch, useModals, useSelector } from 'hooks';

import AlertModal from './components/AlertModal';

import { getCaptions } from './config';

const AlertModalContainer = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { t } = useTranslation();

    useScreenLoad('cancel_subscription');

    const cancellationCandidate = useSelector(selectCancellationCandidate) as CancelCandidate;

    // const { onCancelSubscriptionOneClick } = useCancelSubscription();

    const captions = getCaptions(t, cancellationCandidate?.expirationDateUI);

    const onSubmit = () => {
        openModal(ModalName.reasonModal);

        sendAnalyticCancelSubscriptionSubmitClick();
    };

    const discardCancellationHandler = () => {
        closeModal();

        sendAnalyticCancelSubscriptionCancelClick();

        dispatch(setCancellationCandidate(null));
    };

    return <AlertModal isOpen onDiscard={discardCancellationHandler} onSubmit={onSubmit} captions={captions} />;
};

export default AlertModalContainer;
