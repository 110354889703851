import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const ErrorBoundaryWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    > div {
        width: 100%;
        max-width: 432px;
        padding: 0 40px;

        ${mediaQueries.tablet} {
            padding: 0 24px;
        }
    }
`;
