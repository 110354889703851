import { Text } from 'wikr-core-components';
import React from 'react';

import { Button } from './styled';

const OverlineButton = ({
    text,
    onClick,
    mb,
    dataLocator,
}: {
    text: string;
    onClick: () => void;
    mb?: string | number;
    dataLocator?: string;
}) => (
    <Button onClick={onClick} mb={mb} data-locator={dataLocator}>
        <Text type="overline" text={text} />
    </Button>
);

export default OverlineButton;
