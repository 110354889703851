import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import mediaQueries from 'theme/mediaQueries';

export const Wrapper = styled.div`
    min-height: calc(100vh - 57px);
    background-color: ${color('surface-default')};
`;

export const Container = styled.div<{ $isOpenFilters: boolean }>`
    position: relative;
    padding: 32px 16px 96px;

    ${({ $isOpenFilters }) =>
        $isOpenFilters &&
        css`
            height: calc(100vh - 57px);
            overflow: hidden;
        `}

    ${mediaQueriesGrid.tablet} {
        padding-left: 32px;
        padding-right: 32px;
    }

    ${mediaQueriesGrid.largeTablet} {
        max-width: 844px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
    }

    ${mediaQueries.theSmallestDesktop} {
        max-width: unset;
        padding-left: 64px;
        padding-right: 64px;
    }
`;

export const ContentWrapper = styled.div`
    ${mediaQueries.theSmallestDesktop} {
        display: flex;
        flex-direction: column;
    }
`;

export const CardsWrapper = styled.div`
    ${mediaQueriesGrid.tablet} {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    ${mediaQueries.theSmallestDesktop} {
        gap: 20px;
    }
`;

export const ShowMoreWrapper = styled.div`
    width: 100%;
`;

export const ShowMore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 360px;
    height: 64px;
    margin: 0 auto;
    background: ${color('on-primary')};
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
    cursor: pointer;
`;

export const ButtonTop = styled.button<{ $scrolledFirstScreen: boolean }>`
    position: fixed;
    left: 30px;
    bottom: 24px;
    display: ${({ $scrolledFirstScreen }) => ($scrolledFirstScreen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${color('on-primary')};
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;

    :hover {
        transform: scale(1.1);
    }
`;
