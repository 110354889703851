import { DATE_FORMAT_COMMON, DATE_FORMAT_AMERICAN_TYPE, DATE_FORMAT_ASIAN_TYPE } from 'constants/dateFormats';

import dayjs, { Dayjs } from 'dayjs';

const AMERICAN_TYPE_LIST = ['US', 'CA', 'GH', 'ZA', 'KE'];
const ASIAN_TYPE_LIST = ['CN', 'JP', 'KP', 'KR', 'TW', 'HU', 'BT'];

const DAYS_IN_WEEK = 7;
const DEFAULT_PAUSE_DAYS = 30;

export const getBirthdayByAge = (age: number): string => {
    const currentYear = new Date().getFullYear();

    return `${currentYear - age}-01-01`;
};

export default getBirthdayByAge;

export const getAgeFromBirthday = (birthday: string): number => {
    if (!birthday) return 0;

    const today = new Date();
    const todayYear = today.getFullYear();
    const yearOfBirth = birthday.split('-')[0];

    return todayYear - Number(yearOfBirth);
};

export const getFormattedDate = (date: string, format: string): string => {
    return dayjs(date).format(format);
};

const getDateFormatDependsOnCountry = (country: string): string => {
    if (AMERICAN_TYPE_LIST.includes(country)) {
        return DATE_FORMAT_AMERICAN_TYPE;
    }

    if (ASIAN_TYPE_LIST.includes(country)) {
        return DATE_FORMAT_ASIAN_TYPE;
    }

    return DATE_FORMAT_COMMON;
};

export const getFormattedDateByCountry = (date: string, country: string) => {
    const format = getDateFormatDependsOnCountry(country);

    return getFormattedDate(date, format);
};

export const getFormattedDateWithPauseByCountry = (date: string, country: string) => {
    const format = getDateFormatDependsOnCountry(country);

    return dayjs(date).add(DEFAULT_PAUSE_DAYS, 'day').format(format);
};

export const getWeeksDateFormatted = (firstDayDate: Dayjs, daysInWeek?: number) => {
    const weekDate = [];
    const weeksDay = daysInWeek || DAYS_IN_WEEK;

    for (let i = 0; i < weeksDay; i++) {
        const addedDate = firstDayDate.add(i, 'day');

        weekDate.push({ formatDate: addedDate.format('D.MM'), date: addedDate });
    }

    return weekDate;
};

export const isDateNotExpired = (date: string | null): boolean => {
    if (!date) return false;

    return dayjs().isBefore(date);
};
