import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { sendAnalyticFlatBellyClick } from 'services/analytics/trackers/flatBelly';

import * as S from './styled';

import { ILiveEventProps } from './types';

const LiveEvent = ({
    title,
    text,
    expertName,
    expertPosition,
    expertImg,
    previewImg,
    video264,
    video265,
    videoWebm,
}: ILiveEventProps) => {
    const { t } = useTranslation();

    return (
        <S.LiveEvent dataLocator="flatBellyEventContainer">
            <S.Player
                dataLocator="flatBellyEventBtn"
                onPosterClick={() => sendAnalyticFlatBellyClick(t(title))}
                srcSet={[
                    { src: video265, type: 'video/mp4; codecs="hvc1"' },
                    { src: videoWebm, type: 'video/webm' },
                    { src: video264, type: 'video/mp4' },
                ]}
                poster={previewImg}
                fallback="This video cannot be played in your browser. Try another browser or contact our support for help."
            />
            <S.LiveEventTextContent>
                <S.Label
                    borderRadius={4}
                    backgroundColor="warning-default"
                    paddingX={8}
                    paddingTop={2}
                    paddingBottom={2}
                    mb={10}
                    dataLocator="flatBellyEventBadge"
                >
                    <Text type="overline" text={t('flatBelly.liveEvents.label')} />
                </S.Label>
                <S.CoachInfo mb={16}>
                    <Image maxWidth={44} alt="expertImg" src={expertImg} />
                    <div>
                        <Text dataLocator="flatBellyEventCoachName" bold text={expertName} />
                        <Text dataLocator="flatBellyEventCoachSpec" type="small-text" text={t(expertPosition)} />
                    </div>
                </S.CoachInfo>
                <Text dataLocator="flatBellyEventTitle" type="h6" text={t(title)} mb={4} />
                <Text dataLocator="flatBellyEventDescription" text={t(text)} />
            </S.LiveEventTextContent>
        </S.LiveEvent>
    );
};

export default LiveEvent;
