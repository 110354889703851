import { useNavigate } from 'react-router-dom';

import { PATHS } from 'constants/routerPaths';

import { useToggle } from 'hooks';

import Logo from 'components/Logo';
import Burger from 'components/Burger';

import * as S from './styled';

import defaultUserPic from 'assets/images/default-avatar.svg';

const Header = () => {
    const navigate = useNavigate();

    const { isOpen, toggle, close } = useToggle();

    const handleRedirect = () => {
        navigate(PATHS.ACCOUNT_DEFAULT);
        close();
    };

    return (
        <S.HeaderContainer>
            <S.TitleContainer>
                <Burger onClick={toggle} isActive={isOpen} />
                <Logo />
            </S.TitleContainer>
            <S.AvatarImage onClick={handleRedirect} src={defaultUserPic} />
        </S.HeaderContainer>
    );
};

export default Header;
