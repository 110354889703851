import * as actionTypes from './actionTypes';

export const setIsOpenSideBar = (payload: boolean) => {
    return {
        type: actionTypes.SET_OPEN_SIDEBAR,
        payload,
    } as const;
};
export const setHideNavigation = (payload: boolean) => {
    return {
        type: actionTypes.SET_HIDE_NAVIGATION,
        payload,
    } as const;
};
export const setOpenFrontChat = (payload: boolean) => {
    return {
        type: actionTypes.SET_OPEN_FRONTCHAT,
        payload,
    } as const;
};

export const setIsAppWebView = (payload: boolean) => {
    return {
        type: actionTypes.SET_IS_APP_WEBVIEW,
        payload,
    } as const;
};

export const setIsWorkoutsAppWebView = (payload: boolean) => {
    return {
        type: actionTypes.SET_IS_WORKOUTS_APP_WEBVIEW,
        payload,
    } as const;
};
