import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled(Box)`
    width: 100%;
    border-radius: 20px;
`;

export const Badge = styled(Box)`
    width: 100%;
    border-radius: 20px 20px 0 0;
`;

export const InfoBlock = styled(Box)`
    display: flex;
    gap: 10px;
    margin-bottom: 12px;
    align-items: baseline;

    img {
        width: 16px;
    }
`;

export const Details = styled(Box)`
    width: 100%;
    border-radius: 0 0 20px 20px;
    background-color: ${color('on-primary')};
`;
