import { useSelector } from 'react-redux';

import { selectWorkoutsInfo } from 'store/workouts/selectors';
import { setIsCloseLocalisationInfoBanner } from 'store/workouts/actions';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import { useDispatch } from 'hooks';

import WorkoutsLibraryContainer from 'containers/WorkoutsLibraryContainer';

const WorkoutsLibrary = () => {
    const dispatch = useDispatch();
    const { isCloseLocalisationInfoBanner } = useSelector(selectWorkoutsInfo);

    useScreenLoad();

    // close banner about translations
    const onCloseBanner = () => {
        dispatch(setIsCloseLocalisationInfoBanner(true));
    };

    return (
        <WorkoutsLibraryContainer
            isCloseLocalisationInfoBanner={isCloseLocalisationInfoBanner}
            onCloseBanner={onCloseBanner}
        />
    );
};

export default WorkoutsLibrary;
