import React from 'react';

import { ProtocolFullCardProps } from 'pages/fasting-protocol/types';

import * as S from './styled';

import ProtocolCard from '../ProtocolCard';
import AdviceCard from '../AdviceCard';

const FastingProtocolItem = ({
    protocol,
    expertName,
    expertSubtitle,
    foodAdviceTitle,
    withAdvice,
}: ProtocolFullCardProps) => {
    return (
        <S.ContentBox>
            <ProtocolCard key={protocol.id} protocol={protocol} full />
            <S.AdviceBlock>
                {withAdvice && (
                    <>
                        <AdviceCard
                            title={expertName}
                            subtitle={expertSubtitle}
                            advice={protocol.expertAdvice}
                            withIcon
                        />
                        <AdviceCard title={foodAdviceTitle} advice={protocol.foodAdvice} />
                    </>
                )}
            </S.AdviceBlock>
        </S.ContentBox>
    );
};

export default FastingProtocolItem;
