import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const SuccessContainer = styled(Box)`
    position: relative;
    max-width: 432px;
    padding: 20px 24px 28px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 37%;
    transform: translateY(-50%);
`;

export const ButtonContainer = styled.div`
    position: absolute;
    bottom: 16px;
    padding: 0 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 432px;
    width: 100%;
`;
