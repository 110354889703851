import { Box, Text as WikrText } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const YourFastingBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 80px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const Text = styled(WikrText)`
    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const BannerSubTitleText = styled(WikrText)`
    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const ContentOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;

    ${mediaQueriesGrid.tablet} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, max-content);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    ${mediaQueriesGrid.desktop} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, max-content);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }
`;

export const BannerContent = styled(Box)`
    padding: 32px 20px;
    border-radius: 40px;
    overflow: hidden;
    margin-top: 48px;
    width: 100%;
    max-width: 844px;

    ${mediaQueriesGrid.desktop} {
        padding: 64px 128px 80px;
        max-width: 1416px;
    }
`;
