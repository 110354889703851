import { Text } from 'wikr-core-components';
import { useNavigate, useParams } from 'react-router-dom';

import { selectIsAppWebView } from 'store/uiEffects/selectors';

import { sendAnalyticWorkoutStart } from 'services/analytics/trackers/workouts';

import { useFetchWorkoutDetails, useSelector } from 'hooks';

import { getBodyZoneName } from './helpers';

import { SecondaryDefaultButton } from 'components/SecondaryDefaultButton/styled';
import BackButton from 'components/BackButton';
import WorkoutExercises from './components/WorkoutExercises';
import IconLevel from './components/IconLevel';

import * as S from './styled';

import { ReactComponent as SVGAlarm } from './assets/alarm.svg';

const WorkoutPreview = () => {
    const { workoutId } = useParams();
    const navigate = useNavigate();
    const isAppWebView = useSelector(selectIsAppWebView);

    const { data: workout } = useFetchWorkoutDetails(workoutId);

    const handleStartClick = () => {
        sendAnalyticWorkoutStart();

        navigate('player');
    };

    if (!workout) return null;

    return (
        <S.Wrapper>
            <S.Container data-locator="workoutPreviewPageContainer">
                <S.BackButtonWrapper isOpenHeader={isAppWebView} data-locator="workoutPreviewBackButton">
                    <BackButton onClick={() => navigate(-1)} withShadow />
                </S.BackButtonWrapper>
                <S.Header>
                    <S.CoverWrapper>
                        <S.Cover src={workout.catalog_preview_link} alt="workout" data-locator="workoutPreviewImage" />
                        <S.BodyZoneWrapper data-locator="workoutPreviewFocusArea">
                            <Text text={getBodyZoneName(workout.body_zone)} type="small-text" color="text-secondary" />
                        </S.BodyZoneWrapper>
                    </S.CoverWrapper>
                    <S.PreviewDetails data-locator="workoutPreviewDetailsContainer">
                        <div>
                            <Text
                                text={workout.type}
                                dataLocator="workoutPreviewType"
                                type="overline"
                                color="text-secondary-subdued"
                            />

                            <S.NameWrapper $workoutType={workout.type}>
                                <Text text={workout.name} dataLocator="workoutPreviewTitle" type="h3" medium />
                            </S.NameWrapper>
                            <S.LevelDurationWrapper>
                                {Boolean(workout.duration) && (
                                    <Text color="text-secondary" dataLocator="workoutPreviewDuration">
                                        <SVGAlarm />
                                        {workout.duration}:00
                                    </Text>
                                )}
                                {workout.level && (
                                    <Text color="text-secondary" dataLocator="workoutPreviewFitnessLevel">
                                        <IconLevel level={workout.level} />
                                        {workout.level}
                                    </Text>
                                )}
                            </S.LevelDurationWrapper>
                            <S.EquipmentWrapper>
                                {workout.equipments?.map((item) => (
                                    <S.EquipmentItem key={item.id} data-locator="workoutPreviewEquipment">
                                        <img
                                            data-locator="workoutPreviewEquipmentImage"
                                            src={item.preview_link}
                                            alt={item.slug}
                                        />
                                        <Text
                                            dataLocator="workoutPreviewEquipmentTitle"
                                            text={item.name}
                                            type="small-text"
                                        />
                                    </S.EquipmentItem>
                                ))}
                            </S.EquipmentWrapper>
                        </div>

                        <S.TopButtonWrapper>
                            <SecondaryDefaultButton
                                text="Start"
                                onClick={handleStartClick}
                                dataLocator="workoutPreviewStartButton"
                            />
                        </S.TopButtonWrapper>
                    </S.PreviewDetails>
                </S.Header>
                <S.Main>{workout.blocks && <WorkoutExercises exercisesBlocks={workout.blocks} />}</S.Main>
                <S.BottomButtonWrapper>
                    <SecondaryDefaultButton
                        text="Start"
                        onClick={handleStartClick}
                        dataLocator="workoutPreviewStartButton"
                    />
                </S.BottomButtonWrapper>
            </S.Container>
        </S.Wrapper>
    );
};

export default WorkoutPreview;
