import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Content = styled.div`
    padding: 14px 12px 12px;
    background-color: ${color('surface-main')};
    max-width: 432px;
    border-radius: 16px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    position: relative;
    width: 100%;
`;

export const Header = styled.div`
    margin: 0 36px 18px;
    text-align: center;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
`;

export const Action = styled.div`
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
        background-color: ${color('surface-secondary')};
    }
`;
