import { useTranslation } from 'react-i18next';

import * as S from './styled';

export const StartFastingButton = ({ btnHandler }: { btnHandler: () => void }) => {
    const { t } = useTranslation();

    return (
        <S.StartButton
            dataLocator="startFastingButton"
            onClick={btnHandler}
            backgroundColor="secondary-default"
            hoverColor="secondary-hovered"
            text={t('welcome.startFastingBtn')}
        />
    );
};
