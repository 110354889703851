import { Text } from 'wikr-core-components';
import React from 'react';

import * as S from './styled';

interface IHiddenButton {
    text: string;
    onClick: () => void;
}

const HiddenButton = ({ text, onClick }: IHiddenButton) => {
    return (
        <S.HiddenButton onClick={onClick} data-locator="hiddenButton">
            <Text type="medium-text" bold text={text} />
        </S.HiddenButton>
    );
};

export default HiddenButton;
