import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ContentContainer = styled(Box)`
    width: 100%;
    padding: 24px 16px 80px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    ${mediaQueriesGrid.tablet} {
        padding: 24px 32px 80px;
    }

    ${mediaQueriesGrid.largeTablet} {
        padding: 24px 0 80px;
        max-width: 844px;
        margin: 0 auto;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 24px 40px 80px;
        max-width: 1028px;
        margin: 0 auto;
    }

    ${mediaQueriesGrid.largeDesktop} {
        padding: 24px 0 80px;
        max-width: 1175px;
        margin: 0 auto;
    }
`;

export const ButtonResetOnboarding = styled.button`
    cursor: pointer;
`;
