import { Button } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';

import { TitleBlock, OneDefinition, OnePoint } from './subcomponents';

import * as S from './styled';

import { IIntermittentFastingBlockProps } from './types';

import { IF_DEFINITIONS, IF_POINTS } from './config';

export const IntermittentFastingBlock = ({ btnHandler, scrollRef }: IIntermittentFastingBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.IntermittentFastingBlock dataLocator="whatIsFastingSection">
            <div ref={scrollRef} />
            <S.ContentContainer>
                <TitleBlock />
                <S.IFDefinitionsBlock>
                    {IF_DEFINITIONS.map((definition, index) => (
                        <OneDefinition text={definition.text} img={definition?.img} key={index} />
                    ))}
                </S.IFDefinitionsBlock>
                <S.IFHelpsBlock borderRadius={40}>
                    <S.IFHelpsTitle type="h3" center medium>
                        <Trans
                            i18nKey="welcome.intermittentFasting.helpsTitle"
                            components={{ span: <S.IFHelpsTitleSpan /> }}
                        />
                    </S.IFHelpsTitle>
                    <S.IFHelpsSubtitle
                        type="large-text"
                        center
                        medium
                        text={t('welcome.intermittentFasting.helpsSubtitle')}
                    />
                </S.IFHelpsBlock>
                <S.IFPointsBlock>
                    {IF_POINTS.map((definition, index) => (
                        <OnePoint text={definition.text} title={definition.title} key={index} />
                    ))}
                </S.IFPointsBlock>
                <S.BtnWrap>
                    <Button
                        onClick={btnHandler}
                        textColor="action-main"
                        backgroundColor="surface-main"
                        hoverColor="surface-hovered"
                        text={t('welcome.intermittentFasting.btn')}
                    />
                </S.BtnWrap>
            </S.ContentContainer>
        </S.IntermittentFastingBlock>
    );
};
