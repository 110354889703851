import { Image } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { PATHS } from 'constants/routerPaths';

import {
    sendAnalyticStartClick,
    sendAnalyticStartInAppPopupClick,
    sendAnalyticShowDemoPopupClick,
} from 'services/analytics/trackers/dashboard';

import SkeletonPlate from './components/SkeletonPlate';
import PlateModal from './components/PlateModal';

import * as S from './styled';

import plate from './img/plate.webp';

interface IPlateComponentProps {
    onDemoShow: () => void;
    isSkeleton: boolean;
}

function PlateComponent({ onDemoShow, isSkeleton }: IPlateComponentProps) {
    const navigate = useNavigate();

    const [isOpenModal, setIsOpenModal] = useState(false);

    const { t } = useTranslation();

    const handleOpenModal = () => {
        sendAnalyticStartClick();
        setIsOpenModal(true);
    };

    const handleStartInApp = () => {
        sendAnalyticStartInAppPopupClick();
        navigate(PATHS.MAIN);
    };

    const handleShowDemo = () => {
        sendAnalyticShowDemoPopupClick();
        onDemoShow();
        setIsOpenModal(false);
    };

    return (
        <S.ComponentWrap backgroundColor="surface-hovered" dataLocator="plateContainer">
            {isSkeleton ? (
                <SkeletonPlate />
            ) : (
                <>
                    <S.ImgWrap>
                        <Image maxWidth={295} isForceVisible alt="plate" src={plate} />
                        <S.SmallButton
                            onClick={handleOpenModal}
                            backgroundColor="secondary-default"
                            hoverColor="secondary-hovered"
                            withPulsating
                            size="small"
                            text={t('dashboard.plate.btn')}
                        />
                    </S.ImgWrap>
                    <PlateModal
                        isOpen={isOpenModal}
                        onStartBtn={handleStartInApp}
                        onDemoShow={handleShowDemo}
                        onClose={() => setIsOpenModal(false)}
                    />
                </>
            )}
        </S.ComponentWrap>
    );
}

export default PlateComponent;
