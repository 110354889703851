import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const StartButton = styled(Button)`
    max-width: 360px;

    :active:not(:disabled) {
        background: ${color('secondary-active')};
    }
`;
