import React, { forwardRef, useRef, useState } from 'react';

import mergeRefs from 'helpers/mergeRefs';

import * as S from './styled';

import { ReactComponent as SVGPlay } from './assets/play.svg';

type SrcItem = {
    src?: string;
    type?: string;
};

type Props = {
    src?: string;
    srcSet?: SrcItem[];
    fallback?: React.ReactNode;
    poster?: string;
    onPosterClick?: () => void;
    videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
    loop?: boolean;
    className?: string;
    dataLocator?: string;
    isLoading?: boolean;
};

const Video = forwardRef<HTMLVideoElement, Props>(
    ({ className, src, poster, srcSet, fallback, onPosterClick, videoProps, dataLocator, isLoading }, ref) => {
        const [isVideoStarted, setIsVideoStarted] = useState(false);

        const localRef = useRef<HTMLVideoElement>(null);

        const isReady = Boolean(src || srcSet?.length) && !isLoading;

        const handleVideoStart = () => {
            setIsVideoStarted(true);
            localRef.current?.play();
            onPosterClick?.();
        };

        return (
            <S.PlayerContainer className={className} data-locator={dataLocator}>
                {isReady && (
                    <S.Player
                        ref={mergeRefs<HTMLVideoElement>([ref, localRef])}
                        controls
                        poster={poster}
                        controlsList="nodownload"
                        {...videoProps}
                    >
                        {srcSet?.map(
                            (item) => Boolean(item.src) && <source key={item.src} src={item.src} type={item.type} />
                        )}
                        {Boolean(srcSet) && fallback}
                    </S.Player>
                )}

                {!isVideoStarted && (poster || isLoading) && (
                    <S.PlayerPlaceholder
                        role="button"
                        onClick={handleVideoStart}
                        $poster={poster}
                        data-locator="workoutPlayButton"
                    >
                        {isReady && <SVGPlay />}
                    </S.PlayerPlaceholder>
                )}
            </S.PlayerContainer>
        );
    }
);

export default Video;
