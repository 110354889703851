import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const TextAreaContainer = styled.div`
    padding-bottom: 16px;
`;

export const TextArea = styled.textarea<{ hasError: boolean }>`
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    border-width: 1px;
    border-style: solid;
    resize: none;
    border-radius: ${({ theme }) => `${theme['border-radius-medium']}px`};
    background-color: ${color('surface-default')};
    border-color: ${({ hasError }) => (!hasError ? color('surface-default') : color('critical-default'))};
    color: ${color('text-main')};

    &:focus::placeholder {
        opacity: 0;
    }

    &:focus {
        background-color: ${color('surface-hovered')};
        border-color: ${({ hasError }) => (!hasError ? color('safe') : color('critical-default'))};
        outline: none;
    }

    &:disabled {
        background-color: ${color('surface-secondary')};
        border-color: transparent;
    }

    &:disabled::placeholder,
    &:disabled {
        color: ${color('text-secondary-subdued')};
    }
`;

export const TextError = styled(Text)<{ hasError: boolean }>`
    opacity: ${({ hasError }) => (hasError ? 1 : 0)};
    margin-left: 12px;
    transition: opacity 0.2s;
`;
