import Skeleton from 'components/Skeleton';

import * as S from './styled';

const SkeletonBlock = ({ skeletonAmount = 6 }) => {
    const skeletons = [];

    for (let i = 0; i < skeletonAmount; i++) {
        skeletons.push(
            <S.SkeletonItem key={i}>
                <S.ImageWrapper>
                    <Skeleton />
                </S.ImageWrapper>
                <S.InfoBlock>
                    <Skeleton width={80} height={16} mb={8} isBlockElement />
                    <Skeleton width={180} height={24} mb={12} isBlockElement />
                    <Skeleton height={20} />
                </S.InfoBlock>
            </S.SkeletonItem>
        );
    }

    return <S.SkeletonWrapper>{skeletons}</S.SkeletonWrapper>;
};

export default SkeletonBlock;
