import { Text, Box } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { PATHS } from 'constants/routerPaths';

import { sendAnalyticLogWeightClick } from 'services/analytics/trackers/dashboard';

import * as S from './styled';

import arrowUp from '../../img/arrowUp.svg';
import arrowDown from '../../img/arrowDown.svg';

interface WeightInfoBlockProps {
    targetWeight: number;
    weekLostWeight: number;
    unit: string;
    isDemo: boolean;
}

const WeightInfoBlock = ({ targetWeight, weekLostWeight, unit, isDemo }: WeightInfoBlockProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isNoDiff = weekLostWeight === 0;
    const isLost = weekLostWeight && weekLostWeight < 0;
    const imgPath = isDemo || isLost ? arrowUp : arrowDown;

    const handleStartInApp = () => {
        sendAnalyticLogWeightClick();
        navigate(PATHS.MAIN);
    };

    return (
        <S.GoalContainer>
            <Box>
                <Text text={t('dashboard.statistics.goalBlock.goal')} type="overline" color="text-secondary" mb={6} />
                <Text text={`${targetWeight} ${unit}`} type="h3" mb={18} dataLocator="goalWeight" />
            </Box>
            <S.Divider />
            <Box>
                <Text text={t('dashboard.statistics.goalBlock.result')} type="overline" color="text-secondary" mb={6} />
                <S.ResultWeightWrapper>
                    <Text text={`${Math.abs(weekLostWeight)} ${unit}`} type="h3" dataLocator="weightLost" />
                    {!isNoDiff && <S.ResultImgWrapper src={imgPath} alt="result" />}
                </S.ResultWeightWrapper>
            </Box>
            <S.Divider />
            <Box>
                <Text
                    text={t('dashboard.statistics.goalBlock.todayWeight')}
                    type="overline"
                    color="text-secondary"
                    mb={12}
                />
                <S.SmallButton
                    onClick={handleStartInApp}
                    backgroundColor="secondary-default"
                    hoverColor="secondary-hovered"
                    size="small"
                    text={t('dashboard.statistics.goalBlock.logWeight')}
                    dataLocator="logWeightButton"
                />
            </Box>
        </S.GoalContainer>
    );
};

export default WeightInfoBlock;
