import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { selectCurrentUserId } from 'store/user/selectors';
import { deleteUser } from 'store/user/actions';
import { logout } from 'store/auth/actions';

import { sendAnalyticDeleteUserSuccess } from 'services/analytics';

import { useDispatch, useSelector } from 'hooks';

import DeleteValidation from './components/DeleteValidation';
import DeleteResult from './components/DeleteResult';

import { IDeleteModal } from './types';

import Modal from '../Modal';

const DeletionModal = ({ isOpen, onDiscard, captions, onClose }: IDeleteModal) => {
    const dispatch = useDispatch();
    const userId = useSelector(selectCurrentUserId);
    const navigate = useNavigate();

    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isCloseOnOverlayClick, setIsCloseOnOverlayClick] = useState(true);

    const hasResult = isError || isSuccess;

    useEffect(() => {
        if (!isSuccess || !isLoading) {
            setIsCloseOnOverlayClick(false);
        }

        if (isError && !isLoading) {
            setIsCloseOnOverlayClick(true);
        }
    }, [isSuccess, isLoading, isError]);

    const onSubmitHandler = () => {
        // sendAnalyticDeleteUserClick();
        isValid &&
            userId &&
            dispatch(
                deleteUser({
                    onSuccess: () => {
                        sendAnalyticDeleteUserSuccess();

                        setIsSuccess(true);
                        localStorage.clear();
                    },
                    onError: () => setIsError(true),
                    onLoading: setIsLoading,
                })
            );
    };

    const onErrorHandler = () => {
        setIsValid(false);
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(false);
    };

    const onSuccessHandler = () => {
        // sendAnalyticTakeQuizClick();

        dispatch(logout());
        navigate('/');
    };

    return (
        <Modal
            isOpen={isOpen}
            padding="24px 16px"
            onClose={onClose}
            closeOnOverlayClick={isCloseOnOverlayClick}
            dataLocator={!hasResult ? 'deleteAccountConfirmationModal' : 'deletedAccountModal'}
        >
            {!hasResult && (
                <DeleteValidation
                    captions={captions}
                    isLoading={isLoading}
                    isValid={isValid}
                    setIsValid={setIsValid}
                    onDiscard={onDiscard}
                    onSubmit={onSubmitHandler}
                />
            )}
            {hasResult && (
                <DeleteResult
                    isSuccess={isSuccess}
                    isError={isError}
                    onError={onErrorHandler}
                    onSuccess={onSuccessHandler}
                />
            )}
        </Modal>
    );
};

export default DeletionModal;
