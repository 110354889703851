export const MOCK_GRAPH_DATA = [
    {
        weight: 74.5,
        weight_source: null,
        weight_bundle_id: null,
    },
    {
        weight: 74.0,
        weight_source: null,
        weight_bundle_id: null,
    },
    {
        weight: 73.6,
        weight_source: null,
        weight_bundle_id: null,
    },
    {
        weight: 74.0,
        weight_source: null,
        weight_bundle_id: null,
    },
    {
        weight: 73.4,
        weight_source: null,
        weight_bundle_id: null,
    },
    {
        weight: 73.1,
        weight_source: null,
        weight_bundle_id: null,
    },
    {
        weight: 72.8,
        weight_source: null,
        weight_bundle_id: null,
    },
];
