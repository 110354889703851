export const URL_PARAMS = {
    USER_ID: 'c',
    SIGNATURE: 'sg',
    USER_ID_ONBOARDING: 'uId',
    BUNDLES_UPSELL_ID: 'bundles_upsell_id',
    UTM_SOURCE: 'utm_source',
    RETURN_OFFER: 'return_offer',
    SUBSCRIPTION_ID: 'subscription_id',
    UUID: 'uuid',
};

export const BUNDLE_IDS = {
    WORKOUTS: 'workouts',
    FATBURN: 'fatburn',
    LIBRARY: 'library',
};
