import { Theme } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

const getLineWidth = (theme: Theme) => css`calc(100% - ${theme['default-indent']}px)`;

export const TabButton = styled.button<{ isActive: boolean }>`
    padding: 12px 18px;
    position: relative;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
        > * {
            color: ${color('text-main')};
        }

        &:after {
            width: ${({ theme }) => getLineWidth(theme)};
        }
    }


    &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
        height: 2px;
        transition: 0.3s;
        width: ${({ isActive, theme }) => (isActive ? getLineWidth(theme) : 0)};
        background: ${color('primary-active')};
    }
}
`;
