import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const ThankYouContainer = styled(Box)`
    position: relative;
    max-width: 432px;
    padding: 20px 16px 28px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 33%;
    transform: translateY(-50%);

    p {
        color: ${color('on-primary')};
    }

    button {
        background-color: ${color('on-primary')};

        p {
            color: ${color('primary-default')};
        }
    }
`;

export const Icon = styled.div`
    margin-bottom: 24px;
`;

export const ButtonContainer = styled.div`
    position: absolute;
    bottom: 16px;
    padding: 0 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 432px;
    width: 100%;

    button {
        background-color: ${color('on-primary')};

        p {
            color: ${color('primary-default')};
        }
    }
`;
