import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import { useGuides } from 'hooks';

import { Guide } from 'components/Guide';

import * as S from './styled';

import GuidesSkeleton from './GuidesSkeleton';
import GuidesList from './GuidesList';

export const Guides = () => {
    useScreenLoad();

    const { t } = useTranslation();
    const { userGuides, purchasedGuides, freeGuides, offerGuides, isLoading } = useGuides();

    return (
        <S.Container>
            <Text type="h5" center mb={8} text={t('guides.title')} />
            <Text center mb={16} text={t('guides.subtitle')} color="text-secondary" />
            <S.GuidesListWrapper>
                {isLoading && <GuidesSkeleton />}

                {Boolean(offerGuides.length) && (
                    <GuidesList guides={offerGuides} title={t('guides.subtitle.offer')} dataLocator="offerGuide" />
                )}

                {Boolean(purchasedGuides.length) && (
                    <GuidesList
                        guides={purchasedGuides}
                        title={t('guides.subtitle.purchased')}
                        dataLocator="purchasedGuide"
                    />
                )}

                {Boolean(freeGuides.length) && (
                    <GuidesList guides={freeGuides} title={t('guides.subtitle.bonus')} dataLocator="bonusGuide" />
                )}

                {!userGuides.length && !isLoading && <Guide title={t('guides.notFound')} />}
            </S.GuidesListWrapper>
        </S.Container>
    );
};
