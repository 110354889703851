// mainTrackers
import { trackClick } from './mainTrackers';

export const sendAnalyticResetPassButtonClick = () => {
    trackClick({
        eventLabel: 'send',
    });
};

export const sendAnalyticResetContactUsClick = () => {
    trackClick({
        eventLabel: 'contact_us',
    });
};

export const sendAnalyticResetLoginButtonClick = () => {
    trackClick({
        screenIdParam: 'check_mailbox',
        eventLabel: 'login',
    });
};

export const sendAnalyticResendPasswordClick = () => {
    trackClick({
        screenIdParam: 'check_mailbox',
        eventLabel: 'resend',
    });
};

export const sendAnalyticCheckMailboxContactUsClick = () => {
    trackClick({
        screenIdParam: 'check_mailbox',
        eventLabel: 'contact_us',
    });
};
