import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import { pauseSubscription, resumeSubscription, setFreeMonthSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DEFAULT_SUBSCRIPTION_REQUEST_ERROR } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';
import { SUBSCRIPTION_PAUSED } from 'components/SubscriptionsTab/components/SubscriptionCard/constants';
import {
    PROGRESS_FEEDBACK_MODAL,
    PROGRESS_FREE_MONTH_MODAL,
} from 'components/ModalContainers/ProgressModalContainer/components/ProgressModal/constants';

import {
    sendAnalyticPauseResumeClick,
    sendAnalyticPauseResumeError,
    sendAnalyticPauseResumeSuccess,
} from 'services/analytics/trackers/cancelOffers';
import {
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionExtendSuccess,
    sendAnalyticSubscriptionPauseSuccess,
} from 'services/analytics';

import { useModals, useSelector, useDispatch } from 'hooks/index';

import { getFormattedDateByCountry, isDateNotExpired } from 'helpers/date';
import { getPauseSuccessData, getPauseErrorData } from './helpers';
import { getUpdatingSubscriptionLoadingData } from '../../helpers';

const PAUSE_DAYS = 30;

const usePauseSubscription = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { subscriptions, cancellationCandidate } = useSelector(selectSubscriptions);

    const mainSubscription = subscriptions.find((subscr) => {
        return cancellationCandidate
            ? subscr.external_id === cancellationCandidate.externalId
            : subscr.status === SUBSCRIPTION_PAUSED && subscr.product_code === PRODUCT_CODES.APP_FULL_ACCESS;
    });

    const isPauseActivated = Boolean(mainSubscription?.pause_from || mainSubscription?.pause_to);
    const isPauseActive = isPauseActivated && isDateNotExpired(mainSubscription?.pause_to as string);

    const isPremiumActivated = Boolean(
        mainSubscription?.granted_one_month_free_from || mainSubscription?.granted_one_month_free_to
    );
    const isPremiumActive =
        isPremiumActivated && isDateNotExpired(mainSubscription?.granted_one_month_free_to as string);

    const isCanAddPremium = !isPremiumActivated && !isPauseActive;
    const isCanAddPause = !isPauseActivated && !isPremiumActive;

    const isSubscriptionStatusPaused = mainSubscription?.status === SUBSCRIPTION_PAUSED;

    const currentUser = useSelector(selectCurrentUser);

    // const expirationDateWithPauseUI = getFormattedDateWithPauseByCountry(
    //     mainSubscription?.expired_date || '',
    //     currentUser?.country as string
    // );

    const expirationDateUI = getFormattedDateByCountry(
        mainSubscription?.expired_date || '',
        currentUser?.country as string
    );

    const { t } = useTranslation();

    const onPauseSubscription = useCallback(() => {
        openModal(ModalName.progressModal, {
            type: PROGRESS_FEEDBACK_MODAL,
        });

        if (mainSubscription) {
            dispatch(
                pauseSubscription({
                    external_id: mainSubscription.external_id,
                    days: PAUSE_DAYS,
                    // TO-DO: add pending modals
                    onSuccess: () => {
                        sendAnalyticSubscriptionPauseSuccess();
                        /* openModal(ModalName.successFeedbackModal, {
                            modalData: getPauseSuccessData({
                                closeModal,
                                t,
                                expirationDate: expirationDateWithPauseUI,
                            }),
                        });*/
                    },
                    onError: (error?: string) => {
                        sendAnalyticSubscriptionCancelError(error ?? DEFAULT_SUBSCRIPTION_REQUEST_ERROR);

                        openModal(ModalName.errorFeedbackModal, {
                            modalData: getPauseErrorData({ onPauseSubscription, t }),
                        });
                    },
                })
            );
        }
    }, [mainSubscription]);

    const onGetFreeMonthPause = useCallback(() => {
        openModal(ModalName.progressModal, {
            type: PROGRESS_FREE_MONTH_MODAL,
        });

        if (mainSubscription) {
            dispatch(
                setFreeMonthSubscription({
                    external_id: mainSubscription.external_id,
                    days: PAUSE_DAYS,
                    onSuccess: () => {
                        sendAnalyticSubscriptionExtendSuccess();
                        //
                        // openModal(ModalName.successFeedbackModal, {
                        //     modalData: getFreeMonthSuccessData({
                        //         closeModal,
                        //         t,
                        //         expirationDate: expirationDateWithPauseUI,
                        //     }),
                        // });
                    },
                    onError: (error?: string) => {
                        sendAnalyticSubscriptionCancelError(error ?? DEFAULT_SUBSCRIPTION_REQUEST_ERROR);

                        openModal(ModalName.errorFeedbackModal, {
                            modalData: getPauseErrorData({ onPauseSubscription, t }),
                        });
                    },
                })
            );
        }
    }, [mainSubscription]);

    const onResumeSubscription = useCallback(() => {
        sendAnalyticPauseResumeClick();
        openModal(ModalName.withLoaderModal, {
            modalData: getUpdatingSubscriptionLoadingData(t),
        });

        if (mainSubscription) {
            dispatch(
                resumeSubscription({
                    onSuccess: () => {
                        sendAnalyticPauseResumeSuccess();
                        openModal(ModalName.successFeedbackModal, {
                            modalData: getPauseSuccessData({
                                closeModal,
                                t,
                                expirationDate: expirationDateUI,
                                isPaused: true,
                            }),
                        });
                    },
                    onError: (error?: string) => {
                        sendAnalyticPauseResumeError(error ?? DEFAULT_SUBSCRIPTION_REQUEST_ERROR);

                        openModal(ModalName.errorFeedbackModal, {
                            modalData: getPauseErrorData({ onPauseSubscription, t }),
                        });
                    },
                })
            );
        }
    }, [mainSubscription]);

    return {
        isSubscriptionStatusPaused,
        isPauseActivated,
        isPauseActive,
        isPremiumActivated,
        isPremiumActive,
        isCanAddPremium,
        isCanAddPause,
        onPauseSubscription,
        onResumeSubscription,
        onGetFreeMonthPause,
    };
};

export default usePauseSubscription;
