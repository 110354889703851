import styled from 'styled-components';

export const ProgramsContainer = styled.div`
    flex-wrap: wrap;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    justify-content: center;
`;

export const ProgramImage = styled.div`
    width: calc(50% - 4px);
`;
