import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import { PRODUCT_CODES } from 'constants/product';

import sentry from 'services/Sentry/SentryInstance';

import { ISubscription } from 'types/subscription';

import { ClientError } from 'welltech-web-api-client';

const MOVE_LEFT = -1;
const MOVE_RIGHT = 1;
const STAY = 0;

const DISCOUNT_OFFER_AVAILABLE_PERIODS = [31, 90, 365];

export const sortFullAccessFirst = (subscriptions: Array<ISubscription>): Array<ISubscription> =>
    subscriptions.sort((a, b) => {
        if (a.product_code === PRODUCT_CODES.APP_FULL_ACCESS) return MOVE_LEFT;
        if (b.product_code === PRODUCT_CODES.APP_FULL_ACCESS) return MOVE_RIGHT;

        if (a.product_code === PRODUCT_CODES.APP_VIP_SUPPORT) return MOVE_RIGHT;
        if (b.product_code === PRODUCT_CODES.APP_VIP_SUPPORT) return MOVE_LEFT;

        return STAY;
    });

const checkIsDiscountOfferAvailable = (subscription: ISubscription) => {
    if (subscription.product_code === PRODUCT_CODES.APP_FULL_ACCESS) {
        return DISCOUNT_OFFER_AVAILABLE_PERIODS.includes(subscription.subscription_period);
    }

    return false;
};

export const checkSubscriptionsForDiscountOffer = (subscriptions: ISubscription[]) => {
    return subscriptions.map((subscription) => {
        subscription.discounts.isDiscountOfferAvailable = checkIsDiscountOfferAvailable(subscription);

        return subscription;
    });
};

// TODO: rm after collect all analytics data || https://app.asana.com/0/1201150002098993/1205284626856190/f
export const subscriptionSentryLogger = (error: ClientError, userId: number | null) => {
    sentry.logError(new Error(error?.message), SENTRY_AXIOS, ERROR_LEVELS.WARNING, {}, [
        ['error_code', `${error?.code}`],
        ['error_status', `${error?.response?.status}`],
        ['error_message', `${error?.message}`],
        ['error_flow', 'CANCEL_FLOW'],
        ['user_ID', String(userId)],
    ]);
};
