import { Image, Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { memo } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import interview from '../../img/interview.webp';

import { IInterviewModal } from './types';

const InterviewModal = ({ onSubmit, onContinue }: IInterviewModal) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar isBackBtn={false} />
            <S.InterviewContainer dataLocator="interviewModalContainer">
                <S.Image>
                    <Image alt="interview" src={interview} dataLocator="interviewImage" />
                </S.Image>
                <Text className="lsp-m" type="h5" center mb={8}>
                    <Trans i18nKey="subscription.cancellation.interview.title" />
                </Text>
                <Text className="lsp-s" type="medium-text" center>
                    <Trans
                        i18nKey="subscription.cancellation.interview.subtitle"
                        components={{
                            span: <span className="bold" />,
                        }}
                    />
                </Text>
                <S.ButtonsWrapper>
                    <SecondaryDefaultButton
                        text={t('basics.bookCall')}
                        onClick={onSubmit}
                        dataLocator="interviewBookCallButton"
                        className="ctaBtn"
                    />
                    <SecondaryDefaultButton
                        text={t('basics.continue')}
                        onClick={onContinue}
                        dataLocator="interviewContinueButton"
                        className="ctaBtn ctaBtnTransparent"
                    />
                </S.ButtonsWrapper>
            </S.InterviewContainer>
        </Modal>
    );
};

export default memo(InterviewModal);
