import React from 'react';

import { sendAnalyticFlatBellyClick } from 'services/analytics/trackers/flatBelly';

import * as S from './styled';

import { IYogaSessionProps } from './types';

const YogaSession = ({ name, previewImg, video264, video265, videoWebm }: IYogaSessionProps) => {
    return (
        <div data-locator="flatBellyExerciseContainer">
            <S.YogaPlayer
                dataLocator="flatBellyExercisePlayBtn"
                onPosterClick={() => sendAnalyticFlatBellyClick(name)}
                srcSet={[
                    { src: video265, type: 'video/mp4; codecs="hvc1"' },
                    { src: videoWebm, type: 'video/webm' },
                    { src: video264, type: 'video/mp4' },
                ]}
                poster={previewImg}
                fallback="This video cannot be played in your browser. Try another browser or contact our support for help."
            />
        </div>
    );
};

export default YogaSession;
