import { useEffect, useMemo, useState } from 'react';

import Castjs from 'services/cast';

type Metadata = {
    title?: string;
    description?: string;
    poster?: string;
};

const useChromecast = () => {
    const [isCastReady, setIsAvailable] = useState(false);

    const cjs = useMemo(() => new Castjs(), []);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | undefined;

        const interval = () => {
            timer = setTimeout(() => {
                if (cjs.available) {
                    setIsAvailable(true);
                } else {
                    interval();
                }
            }, 500);
        };

        interval();

        return () => {
            clearTimeout(timer);
        };
    }, [cjs]);

    const toggleCast = async (url: string, metadata?: Metadata) => {
        if (cjs.available) {
            try {
                if (cjs.connected) {
                    await cjs.disconnect();
                } else {
                    await cjs.cast(url, metadata);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        }
    };

    return {
        isCastReady,
        toggleCast,
    };
};

export default useChromecast;
