import { Image, Text as TextLib } from 'wikr-core-components';
import styled from 'styled-components';

export const ProfitContainer = styled.div`
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const ProfitItem = styled.div`
    display: flex;
    gap: 12px;
`;

export const CheckMark = styled(Image)`
    width: 24px;
`;

export const Text = styled(TextLib)`
    font-weight: 400;
`;
