import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import React from 'react';

// import useFrontChat from 'hooks/frontChat/useFrontChat';
import { useSelector } from 'hooks';

import Sidebar from 'components/Sidebar/SideBar';
import Header from 'components/Header/Header';

import * as S from './styled';

import { LayoutProps } from './types';

const CabinetLayout = ({ children, titleKey }: LayoutProps) => {
    const { isAppWebView, isWorkoutsAppWebView } = useSelector(({ ui }) => ui);
    const isWeb = !(isAppWebView || isWorkoutsAppWebView);

    // useFrontChat();

    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t(titleKey)} />
            {isWeb && <Header />}
            {isWeb && <Sidebar />}
            <S.PageWrapper isWeb={isWeb}>{children}</S.PageWrapper>
        </>
    );
};

export default CabinetLayout;
