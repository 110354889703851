import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const InterviewContainer = styled(Box)`
    position: relative;
    max-width: 432px;
    padding: 0px 16px 28px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 116px;
`;

export const Image = styled.div`
    margin-bottom: 2px;
    width: calc(100% + 32px);
`;

export const ButtonsWrapper = styled.div`
    position: fixed;
    padding: 0 16px 8px;
    width: 100%;
    z-index: 100;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 12px;
    max-width: 432px;
    margin: auto;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, ${color('surface-main')} 41.89%);
    height: 184px;

    button:last-child {
        background-color: transparent;

        p {
            color: ${color('primary-default')};
        }
    }
`;
