import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Modal = styled.div<{ $isOpen: boolean }>`
    position: fixed;
    z-index: 120;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
    padding-top: 12px;
    background: rgba(0, 0, 0, 0.5);

    ${mediaQueries.theSmallestDesktop} {
        position: static;
        z-index: unset;
        display: block;
        flex-shrink: 0;
        width: 100%;
        padding: 0;
        background: none;
        margin-right: 20px;
    }
`;

export const Container = styled.div`
    position: relative;
    height: 100%;
    padding: 16px;
    background: ${color('surface-default')};
    border-radius: 20px 20px 0 0;

    ${mediaQueries.theSmallestDesktop} {
        padding: 0;
        width: 100%;
    }
`;

export const ClearButton = styled.button`
    position: absolute;
    top: 16px;
    right: 72px;
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
    padding: 5px 11px;
    cursor: pointer;

    ${mediaQueries.theSmallestDesktop} {
        top: 0;
        right: 0;
    }
`;

export const Cross = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

export const FilterWrapper = styled.div`
    ${mediaQueries.theSmallestDesktop} {
        width: 100%;
        display: flex;
        gap: 20px;
    }
`;

export const FilterRow = styled.div`
    margin-bottom: 24px;

    ${mediaQueries.theSmallestDesktop} {
        flex: 1 1 0;
    }
`;

export const Option = styled.button<{ $isActive: boolean }>`
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 6px 12px;
    background: ${({ $isActive }) => ($isActive ? color('primary-default') : color('on-primary'))};
    border-radius: 12px;
    cursor: pointer;
`;

export const ShowButton = styled.button`
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);
    padding: 8px 16px;
    background: ${color('on-primary')};
    border-radius: 12px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
`;
