import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import presentImg from './img/present.webp';

interface IFreeMonthModalProps {
    externalId: string;
    onGetFreeMonth: () => void;
    onCancelSubscription: () => void;
}

const FreeMonthModal = ({ onCancelSubscription, onGetFreeMonth }: IFreeMonthModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            fullscreen
            style={{
                content: MODAL_CONTENT_FULL_SCREEN_STYLES,
            }}
        >
            <>
                <CancelModalNavBar />
                <S.Container dataLocator="freeMonthOfferContainer">
                    <Image alt="present" src={presentImg} maxWidth={200} center />
                    <S.ContentBlock>
                        <Text
                            type="h4"
                            medium
                            text={t('subscription.cancellation.monthFreeOffer.title')}
                            mb={20}
                            center
                        />
                        <Text
                            type="large-text"
                            text={t('subscription.cancellation.monthFreeOffer.text')}
                            mb={8}
                            center
                        />
                        <Text
                            type="h6"
                            medium
                            text={t('subscription.cancellation.monthFreeOffer.offer')}
                            mb={16}
                            center
                        />
                    </S.ContentBlock>
                    <SecondaryDefaultButton
                        text={t('subscription.cancellation.monthFreeOffer.getFreeMonthBtnText')}
                        onClick={onGetFreeMonth}
                        dataLocator="freeMonthGetFree1MonthButton"
                    />
                    <OutlinedButton
                        dataLocator="freeMonthCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={onCancelSubscription}
                    />
                </S.Container>
            </>
        </Modal>
    );
};

export default memo(FreeMonthModal);
