export const PROGRESS_FEEDBACK_MODAL = 'feedback';
export const PROGRESS_CANCEL_MODAL = 'cancel';
export const PROGRESS_PAUSE_MODAL = 'pause';
export const PROGRESS_FREE_MONTH_MODAL = 'free_month';

export const PROGRESS_TYPES = {
    [PROGRESS_FEEDBACK_MODAL]: PROGRESS_FEEDBACK_MODAL,
    [PROGRESS_CANCEL_MODAL]: PROGRESS_CANCEL_MODAL,
    [PROGRESS_PAUSE_MODAL]: PROGRESS_PAUSE_MODAL,
    [PROGRESS_FREE_MONTH_MODAL]: PROGRESS_FREE_MONTH_MODAL,
};
