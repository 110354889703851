import { store } from 'store/configureStore';

import { setAuthToken, setAuthWebToken, setAuthRefreshToken } from './actions';

import { AUTH_TOKEN_NAME, AUTH_REFRESH_TOKEN_NAME, AUTH_WEB_TOKEN_NAME } from 'constants/api';

import { deleteCookie, setCookie } from 'helpers/cookie';

export const setTokenOperation = (token: string) => {
    setCookie(AUTH_TOKEN_NAME, token);
    store.dispatch(setAuthToken(token));
};

export const setRefreshTokenOperation = (refreshToken: string) => {
    setCookie(AUTH_REFRESH_TOKEN_NAME, refreshToken);
    store.dispatch(setAuthRefreshToken(refreshToken));
};

export const deleteTokenOperation = () => {
    deleteCookie(AUTH_TOKEN_NAME);
    store.dispatch(setAuthToken(null));
};

export const setWebTokenOperation = (webToken: string) => {
    setCookie(AUTH_WEB_TOKEN_NAME, webToken);
    store.dispatch(setAuthWebToken(webToken));
};

export const deleteWebTokenOperation = () => {
    deleteCookie(AUTH_WEB_TOKEN_NAME);
    store.dispatch(setAuthWebToken(''));
};

export const deleteRefreshTokenOperation = () => {
    deleteCookie(AUTH_REFRESH_TOKEN_NAME);
    store.dispatch(setAuthRefreshToken(null));
};
