import React from 'react';

import { sendAnalyticDownloadAppClick } from 'services/analytics';

import * as S from './styled';

import googlePlay from './img/GooglePlay.webp';
import appleStore from './img/AppStore.webp';

function DownloadBlock({ webToken }: { webToken: string }) {
    const applePayLink = `https://femia.onelink.me/i15q?deep_link_value=token&deep_link_sub1=${webToken}`;

    const handleLinkClick = (eventLabel: string) => () => {
        sendAnalyticDownloadAppClick(eventLabel);
    };

    return (
        <S.DownloadImages>
            <a onClick={handleLinkClick('app_store')} href={applePayLink} target="_blank" rel="noreferrer">
                <img src={appleStore} alt="apple-store" />
            </a>
            <a onClick={handleLinkClick('google_play')} href={applePayLink} target="_blank" rel="noreferrer">
                <img src={googlePlay} alt="google-play" />
            </a>
        </S.DownloadImages>
    );
}

export default DownloadBlock;
