import { useTranslation } from 'react-i18next';

import { OneRecipe, OnePoint } from './subcomponents';

import * as S from './styled';

import { ReactComponent as Recipes } from './img/recipes.svg';

import { POINTS, RECIPES } from './config';

export const TasteGoodBlock = () => {
    const { t } = useTranslation();

    return (
        <S.TasteGoodBlock dataLocator="tasteGoodSection">
            <S.ContentContainer>
                <S.Title medium text={t('welcome.tasteGood.title')} />
                <S.SubtitleWrap>
                    <S.SVGWrap>
                        <Recipes />
                    </S.SVGWrap>
                    <S.Subtitle type="large-text" medium text={t('welcome.tasteGood.subtitle')} />
                </S.SubtitleWrap>
                <S.RecipesBlock>
                    {RECIPES.map((recipe, index) => (
                        <OneRecipe text={recipe.text} img={recipe.img} key={index} />
                    ))}
                </S.RecipesBlock>
                <S.PointsBlock>
                    {POINTS.map((point, index) => (
                        <OnePoint text={point.text} title={point.title} img={point.img} key={index} />
                    ))}
                </S.PointsBlock>
            </S.ContentContainer>
        </S.TasteGoodBlock>
    );
};
