export const challengePoints = [
    {
        title: 'challenge.moreAbout.meal.title',
        titleDataLocator: 'mealPlanTitle',
        text: 'challenge.moreAbout.meal.text',
        textDataLocator: 'mealPlanDescription',
    },
    {
        title: 'challenge.moreAbout.water.title',
        titleDataLocator: 'waterBalanceTitle',
        text: 'challenge.moreAbout.water.text',
        textDataLocator: 'waterBalanceDescription',
    },
    {
        title: 'challenge.moreAbout.sleep.title',
        titleDataLocator: 'sleepTitle',
        text: 'challenge.moreAbout.sleep.text',
        textDataLocator: 'sleepDescription',
    },
    {
        title: 'challenge.moreAbout.sport.title',
        titleDataLocator: 'sportTitle',
        text: 'challenge.moreAbout.sport.text',
        textDataLocator: 'sportDescription',
    },
    {
        title: 'challenge.moreAbout.education.title',
        titleDataLocator: 'educationTitle',
        text: 'challenge.moreAbout.education.text',
        textDataLocator: 'educationDescription',
    },
    {
        title: 'challenge.moreAbout.rest.title',
        titleDataLocator: 'restDaysTitle',
        text: 'challenge.moreAbout.rest.text',
        textDataLocator: 'restDaysDescription',
    },
];

const levelsDiffPoints = [
    'challenge.moreAbout.levelsDiff.option1',
    'challenge.moreAbout.levelsDiff.option2',
    'challenge.moreAbout.levelsDiff.option3',
    'challenge.moreAbout.levelsDiff.option4',
];

export const getContent = () => {
    return {
        title: 'challenge.link.moreAbout',
        challengePoints,
        levelsDiffPoints: {
            title: 'challenge.moreAbout.levelsDiff.title',
            points: levelsDiffPoints,
        },
        howItWorks: {
            title: 'challenge.moreAbout.howItWorks.title',
            text: 'challenge.moreAbout.howItWorks.text',
        },
    };
};
