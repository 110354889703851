import { Box, Image } from 'wikr-core-components';
import styled from 'styled-components';

import { CONTENT_4 } from 'constants/customContentColors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const IntermittentFastingBlock = styled(Box)`
    background-color: ${CONTENT_4};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const BigBenefitSvg = styled(Image)`
    min-width: 40px;
`;

export const SmallBenefitsWrap = styled(Box)`
    width: 100%;
    margin-bottom: 160px;

    ${mediaQueriesGrid.desktop} {
        display: flex;
        justify-content: space-between;
        margin-bottom: 176px;
    }
`;

export const SmallBenefitsWrapSecond = styled(Box)`
    width: 100%;
    margin-bottom: 40px;

    ${mediaQueriesGrid.desktop} {
        display: flex;
        justify-content: space-between;
        margin-bottom: 176px;
    }
`;
