import styled, { keyframes } from 'styled-components';

export const BtnsContainer = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        width: calc((100% - 12px) / 2);
    }
`;

export const loaderAnimation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    padding: 42px 0;
    display: flex;
    justify-content: center;

    > div {
        animation: ${loaderAnimation} 2s linear infinite;
        width: fit-content;
        height: 24px;
    }
`;

export const ImageWrapper = styled.div`
    animation: ${loaderAnimation} 2s linear infinite;
`;
