import { ActionEventAction } from 'wikr-core-analytics';

import { PATHS } from 'constants/routerPaths';

import settings from '../img/Settings.webp';
import app from '../img/App.webp';

export const getSideBarPrimaryLinks = () => {
    return [
        // {
        //     to: PATHS.DASHBOARD,
        //     title: 'pagesTitles.dashboard',
        //     icon: dashboard,
        //     dataLocator: 'burgerDashboard',
        // },
        {
            to: PATHS.MAIN,
            title: 'sidebar.app',
            icon: app,
            dataLocator: 'burgerFemiaApp',
            analyticActionEvent: ActionEventAction.app,
        },
        // TODO:this is tem solution, we don't need to delete this code
        // {
        //     to: PRIVATE.INSIGHTS_MAIN.path,
        //     title: 'pagesTitles.insights',
        //     icon: insights,
        //     dataLocator: 'burgerInsights',
        // },
        // {
        //     to: PATHS.FITNESS_GUIDES,
        //     title: 'sidebar.guides',
        //     icon: guide,
        //     dataLocator: 'burgerGuides',
        //     analyticActionEvent: ActionEventAction.guides,
        // },
        // {
        //     to: PATHS.WELCOME,
        //     title: 'sidebar.welcome', // What is fasting
        //     icon: timer,
        //     dataLocator: 'burgerWhatIsFasting',
        // },
        // {
        //     to: PATHS.WORKOUTS,
        //     title: 'pagesTitles.workouts',
        //     icon: workouts,
        //     dataLocator: 'burgerWorkouts',
        //     isShowItem: isHideWorkouts,
        // },
        // {
        //     to: PATHS.FLAT_BELLY,
        //     title: 'pagesTitles.flatBelly',
        //     icon: weight,
        //     dataLocator: 'flatBellyBurgerBtn',
        //     isShowItem: isHideFlatBelly,
        //     analyticActionEvent: 'flat_belly',
        // },
        // {
        //     to: PATHS.MY_FASTING_PLAN,
        //     title: 'sidebar.myFastingPlan',
        //     icon: plan,
        //     dataLocator: 'burgerMyFastingPlan',
        // },
        // {
        //     to: PATHS.CHALLENGE,
        //     title: 'sidebar.challenge',
        //     icon: challenge,
        //     dataLocator: 'burger75DayChallenge',
        //     isShowItem: isHideChallenge,
        //     analyticActionEvent: '75_day_challenge',
        // },
        {
            to: PATHS.ACCOUNT_DEFAULT,
            title: 'sidebar.settings',
            icon: settings,
            dataLocator: 'burgerAccountSettings',
            analyticActionEvent: ActionEventAction.accountSettings,
        },
    ];
};
