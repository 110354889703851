export const ANSWERS = [
    {
        title: 'subscription.cancellation.survey.option1',
        value: 'yes',
    },
    {
        title: 'subscription.cancellation.survey.option2',
        value: 'no',
    },
];
