import { sendAnalyticSurveyThankYouModalCancelClick } from 'services/analytics';

import { useCancelSubscription } from 'hooks';

import SurveyThankYouModal from './SurveyThankYouModal';

const SurveyThankYouModalContainer = () => {
    const { onCancelSubscription, discardCancellation } = useCancelSubscription();

    const onSubmitHandler = () => {
        sendAnalyticSurveyThankYouModalCancelClick();

        onCancelSubscription();
    };

    return <SurveyThankYouModal onSubmit={onSubmitHandler} onCancel={discardCancellation} />;
};

export default SurveyThankYouModalContainer;
