import * as S from './styled';

import { ReactComponent as Arrow } from './img/arrowPrev.svg';

interface IPrevButtonInsightsProps {
    onClick: () => void;
    dataLocator?: string;
    disabled?: boolean;
}

const PrevButtonInsights = ({ onClick, dataLocator, disabled }: IPrevButtonInsightsProps) => {
    return (
        <S.Button data-locator={dataLocator} onClick={onClick} disabled={disabled}>
            <Arrow />
        </S.Button>
    );
};

export default PrevButtonInsights;
