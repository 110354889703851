import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Content = styled.div`
    padding: 14px 12px 12px;
    background-color: ${color('surface-main')};
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    position: relative;
    max-width: 335px;

    ${mediaQueries.laptop} {
        max-width: 432px;
    }
`;

export const Header = styled.div`
    margin: 0 36px 18px;
    text-align: center;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
`;

export const TabBar = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 8px;
    background: ${color('surface-main')};
    border-bottom: 1px solid ${color('border-default')};
`;

export const TabItem = styled.button<{ $active?: boolean }>`
    padding: 12px 18px 0;
    cursor: pointer;

    & > p {
        padding-bottom: 8px;
        border-bottom: 2px solid ${({ $active, theme }) => ($active ? theme['primary-default'] : 'transparent')};
    }
`;
