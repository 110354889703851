import { DATE_FORMAT_ASIAN_TYPE } from 'constants/dateFormats';
import { MOCK_GRAPH_DATA } from './constants';

import { Dayjs } from 'dayjs';

const CREATED_AT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const getDemoWeightData = (firstDate: Dayjs) => {
    return MOCK_GRAPH_DATA.map((item, index) => {
        const itemDate = firstDate.add(index, 'days');

        return {
            ...item,
            date: itemDate.format(DATE_FORMAT_ASIAN_TYPE),
            created_at: itemDate.format(CREATED_AT_FORMAT),
        };
    });
};
