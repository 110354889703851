import { Image, Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticLibraryDownloadMealClick } from 'services/analytics/trackers/webview';

import { useGuides } from 'hooks';

import WebViewBackArrow from 'components/WebViewNavBar';

import * as S from './styled';

import mealPlan from './img/mealPlan.webp';

const WebViewMealPlan = () => {
    useScreenLoad();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { purchasedGuides, isLoading } = useGuides(['meal_plan']);

    const handleBackClick = () => navigate(-1);

    return (
        <S.WebViewContainer>
            <WebViewBackArrow onClick={handleBackClick} text={t('basics.library')} />
            <Text type="h3" medium text={t('library.mealPlan.title')} mb={32} />
            <S.MealPlanWrapper mb={16}>
                <Image alt="meal_plan" src={mealPlan} maxWidth={250} />
            </S.MealPlanWrapper>
            {!isLoading && (
                <S.DownloadBtn
                    href={purchasedGuides[0]?.download_url}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-locator="downloadButton"
                    onClick={() => sendAnalyticLibraryDownloadMealClick(purchasedGuides[0]?.guide_code)}
                >
                    <Text color="on-primary" type="medium-button" uppercase text={t('main.progress.download')} />
                </S.DownloadBtn>
            )}
        </S.WebViewContainer>
    );
};

export default WebViewMealPlan;
