import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const PlayerContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

export const Player = styled.video`
    width: 100%;
`;

export const PlayerPlaceholder = styled.div<{
    $poster?: string;
}>`
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-image: ${({ $poster }) => `url(${$poster})`},
        linear-gradient(90deg, #e9e9ed 0%, #e9e9ed 0.01%, #f9f9fa 98.44%);

    & > svg {
        width: 56px;
        ${mediaQueriesGrid.tablet} {
            width: 80px;
        }
    }
`;
