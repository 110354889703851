import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { sendAnalyticWorkoutComplete, sendAnalyticWorkoutExit } from 'services/analytics/trackers/workouts';

import Dialog from 'components/Dialog';

import { ReactComponent as SVGCheck } from '../../assets/check.svg';
import { ReactComponent as SVGArrowLeft } from '../../assets/arrow-left.svg';

import WorkoutFinish from '../WorkoutFinish';
import CastHintModal from '../CastHintModal';

interface WorkoutPlayerModalsProps {
    isExitDialogOpen: boolean;
    isCompleteDialogOpen: boolean;
    isCastHintOpen: boolean;
    setIsExitDialogOpen: (isOpen: boolean) => void;
    setIsCompleteDialogOpen: (isOpen: boolean) => void;
    setIsCastHintOpen: (isOpen: boolean) => void;
}

const WorkoutPlayerModals = ({
    isExitDialogOpen,
    isCompleteDialogOpen,
    isCastHintOpen,
    setIsExitDialogOpen,
    setIsCompleteDialogOpen,
    setIsCastHintOpen,
}: WorkoutPlayerModalsProps) => {
    const navigate = useNavigate();

    const [isWorkoutFinished, setIsWorkoutFinished] = useState(false);

    return (
        <>
            <Dialog
                isOpen={isExitDialogOpen}
                onClose={() => setIsExitDialogOpen(false)}
                withCloseButton
                title="Are you sure you want to exit?"
                actions={[
                    {
                        text: 'Exit workout',
                        icon: <SVGArrowLeft />,
                        dataLocator: 'workoutConfirmationModalExitButton',
                        onClick: () => {
                            sendAnalyticWorkoutExit('0');

                            navigate(-1);
                        },
                    },
                    {
                        text: 'Complete workout',
                        icon: <SVGCheck />,
                        dataLocator: 'workoutConfirmationModalCompleteWorkoutButton',
                        onClick: () => {
                            setIsWorkoutFinished(true);
                            setIsExitDialogOpen(false);
                            sendAnalyticWorkoutExit('1');
                        },
                    },
                ]}
            />
            <Dialog
                isOpen={isCompleteDialogOpen}
                onClose={() => setIsCompleteDialogOpen(false)}
                withCloseButton
                title="Complete workout?"
                actions={[
                    {
                        text: 'Complete',
                        icon: <SVGCheck />,
                        dataLocator: 'workoutConfirmationModalCompleteButton',
                        onClick: () => {
                            setIsWorkoutFinished(true);
                            setIsCompleteDialogOpen(false);
                            sendAnalyticWorkoutComplete();
                        },
                    },
                    {
                        text: 'Continue training',
                        icon: <SVGArrowLeft />,
                        dataLocator: 'workoutConfirmationModalContinueTrainingButton',
                        onClick: () => setIsCompleteDialogOpen(false),
                    },
                ]}
            />
            <CastHintModal isOpen={isCastHintOpen} onClose={() => setIsCastHintOpen(false)} />
            {isWorkoutFinished && <WorkoutFinish />}
        </>
    );
};

export default WorkoutPlayerModals;
