export const CANCEL_SUBSCRIPTION = 'SUBSCRIPTION/CANCEL_SUBSCRIPTION';
export const DISCARD_SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION/DISCARD_SUBSCRIPTION_CANCELLATION';
export const FETCH_USER_SUBSCRIPTIONS = 'SUBSCRIPTION/FETCH_USER_SUBSCRIPTIONS';
export const SET_USER_SUBSCRIPTIONS = 'SUBSCRIPTION/SET_USER_SUBSCRIPTIONS';
export const UNSUBSCRIBE = 'SUBSCRIPTION/UNSUBSCRIBE';
export const SET_FETCHING_STATUS = 'SUBSCRIPTION/SET_FETCHING_STATUS';

export const FETCH_DISCOUNT_SUBSCRIPTION = 'SUBSCRIPTION/FETCH_DISCOUNT_SUBSCRIPTION';
export const SET_IS_DISCOUNT_LOADING = 'SUBSCRIPTION/SET_IS_DISCOUNT_LOADING';
export const SET_DISCOUNT_SUBSCRIPTION = 'SUBSCRIPTION/SET_DISCOUNT_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'SUBSCRIPTION/UPDATE_SUBSCRIPTION';
export const RESET_SUBSCRIPTIONS = 'SUBSCRIPTION/RESET_SUBSCRIPTIONS';
export const SET_REMINDER = 'SUBSCRIPTION/SET_REMINDER';
export const PAUSE_SUBSCRIPTION = 'SUBSCRIPTION/PAUSE_SUBSCRIPTION';
export const SET_FREE_MONTH_SUBSCRIPTION = 'SUBSCRIPTION/SET_FREE_MONTH_SUBSCRIPTION';
export const RESUME_SUBSCRIPTION = 'SUBSCRIPTION/RESUME_SUBSCRIPTION';
export const RESET_SUBSCRIPTION = 'SUBSCRIPTION/RESET_SUBSCRIPTION';

export const SET_RESTORE_CANDIDATE = 'SUBSCRIPTION/SET_RESTORE_CANDIDATE';
export const RESTORE_SUBSCRIPTION = 'SUBSCRIPTION/RESTORE_SUBSCRIPTION';

export const SET_CANCELLATION_CANDIDATE = 'SUBSCRIPTION/SET_CANCELLATION_CANDIDATE';

export const SET_RESET_CRM_CANDIDATE_SUBSCRIPTION_ID = 'SUBSCRIPTION/SET_RESET_CRM_CANDIDATE_SUBSCRIPTION_ID';
