import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticInterviewBookCallClick, sendAnalyticInterviewContinueClick } from 'services/analytics';

import { useModals } from 'hooks';

import InterviewModal from './components/InterviewModal';

const InterviewModalContainer = () => {
    const { closeModal } = useModals();

    useScreenLoad('cancel_interview');

    const onSubmit = () => {
        sendAnalyticInterviewBookCallClick();

        window.open('https://calendar.app.google/q11vWha4WekuHa3c6', '_blank')?.focus();
    };

    const onContinue = () => {
        sendAnalyticInterviewContinueClick();

        closeModal();
    };

    return <InterviewModal onSubmit={onSubmit} onContinue={onContinue} />;
};

export default InterviewModalContainer;
