import { Text } from 'wikr-core-components';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import React, { memo } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import { getFormattedDateByCountry } from 'helpers/date';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import { ICancellationSuccessModal } from './types';

const CancellationSuccessModal = ({ onSubmit }: ICancellationSuccessModal) => {
    const { t } = useTranslation();
    const { subscriptions } = useSelector(selectSubscriptions);
    const currentUser = useSelector(selectCurrentUser);

    const expirationDateUI = getFormattedDateByCountry(subscriptions[0].expired_date, currentUser?.country as string);

    return (
        <Modal isOpen fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar isBackBtn={false} />
            <S.SuccessContainer dataLocator="cancelSubscriptionModalContainer">
                <Text
                    className="lsp-m"
                    type="h5"
                    center
                    mb={8}
                    text={t('subscription.cancellation.expiresSubscription.bannerTitle')}
                />
                <Text className="lsp-s" type="medium-text" center>
                    <Trans
                        i18nKey="subscription.cancellation.expiresSubscription.bannerText1"
                        values={{
                            expirationDate: expirationDateUI,
                        }}
                        components={{
                            span: <span className="bold" />,
                        }}
                    />
                </Text>
            </S.SuccessContainer>
            <S.ButtonContainer>
                <SecondaryDefaultButton
                    text={t('basics.continue')}
                    onClick={onSubmit}
                    dataLocator="cancellationSuccessContinueButton"
                    className="ctaBtn"
                />
            </S.ButtonContainer>
        </Modal>
    );
};

export default memo(CancellationSuccessModal);
