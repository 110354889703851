import { useTranslation } from 'react-i18next';
import React from 'react';

import DashboardToggler from 'components/DashboardToggler';

import * as S from './styled';

interface TitleBlockProps {
    isDemo: boolean;
    setDemo: (status: boolean) => void;
}

const TitleBlock = ({ isDemo, setDemo }: TitleBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.TitleBlock>
            <S.Title medium type="h3" text={t('pagesTitles.dashboard')} />
            <DashboardToggler demoOn={isDemo} onClick={() => setDemo(!isDemo)} />
        </S.TitleBlock>
    );
};

export default TitleBlock;
