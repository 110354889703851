import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Wrapper = styled.div`
    cursor: pointer;
    width: 100%;
    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const Header = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const FaqButton = styled.button`
    text-align: left;
    display: flex;
    background-color: transparent;
    justify-content: center;
    cursor: pointer;
`;

export const PlusSwitcher = styled.div<{ isOpen: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: ${color('surface-main')};
    border-radius: 12px;

    > div {
        background-color: ${color('text-secondary')};
    }
    div:first-child {
        width: 12px;
        height: 2px;
    }
    div:last-child {
        transition: all 0.2s;
        width: 2px;
        height: 12px;
        position: absolute;
        margin: 0 auto;
        opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
        transform: ${({ isOpen }) => `rotate(${isOpen ? -90 : 0}deg)`};
    }
`;

export const Content = styled(Text)`
    margin-top: 24px;

    a {
        color: ${color('primary-default')};
        text-decoration: underline;
    }
`;
