import { Text } from 'wikr-core-components';

import * as S from './styled';

import { STEP_VARIANT } from './config';

import { Step } from './Step';

export interface StepsProps {
    variant: 'ios' | 'android' | 'safari' | 'chrome';
}

export const Steps = ({ variant }: StepsProps) => {
    const { steps, footer = null } = STEP_VARIANT[variant];

    return (
        <S.StepsWrapper>
            {steps.map((step) => (
                <Step key={step.stepNumber} {...step} />
            ))}
            {footer && (
                <S.StepsFooter>
                    <Text text={footer} />
                </S.StepsFooter>
            )}
        </S.StepsWrapper>
    );
};
