import { AnalyticUtils, uaParser } from 'wikr-core-analytics';

import { GENDERS } from 'constants/user';
import { DEVICE_OS } from 'constants/devices';

const UAParser = new uaParser();

export const isEmpty = (value: null | undefined | string | number | object): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && Number(value) === Infinity)
    );
};

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');

export const isMaleGender = (gender: string | null): boolean => {
    return gender === GENDERS.MALE;
};

export const getToFixedNumber = (number: string | number, numbersAfterComma = 2): number => {
    const numberValue = Number(number);

    return Number(numberValue.toFixed(numbersAfterComma));
};

export const isSafari = () => {
    const userAgent = UAParser.setUA(window.navigator.userAgent);
    const deviceBrowser = userAgent?.getBrowser().name;

    return deviceBrowser === 'Safari' || deviceBrowser === 'Mobile Safari';
};

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

export const getDeviceOS = (): string => {
    const userAgent = window.navigator.userAgent;
    const osData = UAParser.setUA(userAgent).getOS();

    return osData?.name?.toUpperCase() || DEVICE_OS.DEFAULT;
};

export const getDeviceBrowser = (): string | undefined => {
    const userAgent = window.navigator.userAgent;
    const browserData = UAParser.setUA(userAgent).getBrowser();

    return browserData?.name;
};

export const removeItemFromArrayByValue = (array: Array<any>, value: any) => {
    return array.filter((item: any) => (item.value || item) !== value);
};

const fromEntriesPolyfill = (iterable: IterableIterator<[string, string]>) => {
    // @ts-ignore
    return [...iterable].reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});
};

export const getUrlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)?.entries();

    const params = fromEntriesPolyfill(urlSearchParams);

    return isEmpty(params) ? null : params;
};

export const generateQueryParams = (): string => {
    const urlParamsFromURL = getUrlParams();
    const defaultUrlParams = AnalyticUtils.getDefaultUrlParams();

    const params = urlParamsFromURL ? urlParamsFromURL : defaultUrlParams;
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    return searchParams.toString();
};

export const startCalculateProgress = ({
    setProgress,
    onComplete,
}: {
    setProgress: (progress: number) => void;
    onComplete?: () => void;
}): void => {
    const path = window.location.href;

    let currentProgress = 0;
    let speed = 50;

    setTimeout(frame, speed);

    async function frame() {
        if (currentProgress === 100) {
            currentProgress = 0;
            onComplete && onComplete();
        } else if (path === window.location.href) {
            if (currentProgress >= 38 && currentProgress < 45) {
                speed = 250;
            } else if (currentProgress >= 45 && currentProgress < 63) {
                speed = 50;
            } else if (currentProgress >= 63 && currentProgress < 75) {
                speed = 60;
            } else if (currentProgress >= 75 && currentProgress < 82) {
                speed = 250;
            } else if (currentProgress >= 82 && currentProgress < 95) {
                speed = 70;
            } else if (currentProgress >= 96) {
                speed = 500;
            }

            setProgress(Math.round(++currentProgress));
            setTimeout(frame, speed);
        }
    }
};
