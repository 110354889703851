import { ActionEventAction } from 'wikr-core-analytics';

// mainTrackers
import { trackEventFixed } from './mainTrackers';

export const sendAnalyticSideBarElementClick = (actionEvent: ActionEventAction) => {
    trackEventFixed(`${actionEvent}__click`);
};

export const sendAnalyticSideBarLogoutClick = () => {
    trackEventFixed('logout__click');
};
