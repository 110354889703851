import { Box, Image as WikrImage } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled(Box)`
    border-bottom: 1px solid ${color('border-default')};
`;

export const FlexWrapper = styled(Box)`
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
`;

export const ContentWrapper = styled(FlexWrapper)`
    > * {
        width: 100%;
    }
`;

export const AvatarWrapper = styled(FlexWrapper)`
    gap: 24px;
`;

export const Image = styled(WikrImage)`
    width: 48px;
`;
