import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const Cross = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'border-default'];

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.81549 10.4166L3.39607 14.8361C2.90791 15.3242 2.90791 16.1157 3.39607 16.6038C3.88422 17.092 4.67568 17.092 5.16383 16.6038L9.58325 12.1844L14.0027 16.6038C14.4908 17.092 15.2823 17.092 15.7704 16.6038C16.2586 16.1157 16.2586 15.3242 15.7704 14.8361L11.351 10.4166L15.7704 5.99723C16.2586 5.50907 16.2586 4.71762 15.7704 4.22946C15.2823 3.74131 14.4908 3.74131 14.0027 4.22946L9.58325 8.64888L5.16383 4.22946C4.67568 3.74131 3.88422 3.74131 3.39607 4.22946C2.90791 4.71762 2.90791 5.50907 3.39607 5.99723L7.81549 10.4166Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(Cross);
