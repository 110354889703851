import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

import { PATHS } from 'constants/routerPaths';
import { CONTENT_4, CONTENT_6 } from 'constants/customContentColors';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticCTAButtonClick } from 'services/analytics/trackers/welcome';

import {
    WelcomeBlock,
    ColoredWithAppImageBlock,
    DiscoverBlock,
    IntermittentFastingBlock,
    BenefitsForHealthBlock,
    LifestyleBlock,
    PracticeBlock,
    TasteGoodBlock,
    WaterBlock,
    TrueCheatFastingBlock,
    QuestionsBlock,
    WasThisHelpfulBlock,
    YourFastingBlock,
    HowItWorksBlock,
    TimerBlock,
} from './components';

import * as S from './styled';

import plateDiscover from './images/plateDiscover.webp';
import calendarDiscover from './images/calendarDiscover.webp';

const Welcome = () => {
    useScreenLoad();

    const navigate = useNavigate();
    const scrollToBlock = useRef<HTMLDivElement | null>(null);

    const handleScrollTo = () => {
        window.scrollTo({
            top: (scrollToBlock?.current?.offsetTop ?? 0) - 60,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleCTAClick = (btnIndex: number) => {
        sendAnalyticCTAButtonClick(btnIndex);

        navigate(PATHS.MAIN);
    };

    return (
        <S.FullPage>
            <WelcomeBlock onClick={handleScrollTo} />
            <ColoredWithAppImageBlock
                title="welcome.alreadyFamiliar.title"
                subtitle="welcome.alreadyFamiliar.subtitle"
                backgroundColor={CONTENT_6}
                btnHandler={() => handleCTAClick(1)}
            />
            <DiscoverBlock title="welcome.discover.title1" img={plateDiscover} />
            <DiscoverBlock title="welcome.discover.title2" img={calendarDiscover} />
            <IntermittentFastingBlock btnHandler={() => handleCTAClick(2)} scrollRef={scrollToBlock} />
            <BenefitsForHealthBlock btnHandler={() => handleCTAClick(3)} />
            <LifestyleBlock />
            <PracticeBlock btnHandler={() => handleCTAClick(4)} />
            <HowItWorksBlock />
            <TimerBlock btnHandler={() => handleCTAClick(5)} />
            <YourFastingBlock />
            <TasteGoodBlock />
            <WaterBlock />
            <TrueCheatFastingBlock />
            <QuestionsBlock />
            <ColoredWithAppImageBlock
                withBigTitle
                title="welcome.readyToStart.title"
                subtitle="welcome.alreadyFamiliar.subtitle"
                backgroundColor={CONTENT_4}
                btnHandler={() => handleCTAClick(6)}
            />
            <WasThisHelpfulBlock />
        </S.FullPage>
    );
};

export default Welcome;
