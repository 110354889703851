import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_ACTIVE } from '../constants';

import { isEmpty } from 'helpers/dataStructuresUtils';

import { CheckCircle } from 'components/Icon';

import * as S from './styled';

import { IDefaultSubscription } from '../types';

import UpcomingProduct from './UpcomingProduct';
import SubscriptionData from './SubscriptionData';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import CancelButton from './CancelButton';

const DefaultSubscription = ({
    tabIcon,
    heading,
    state,
    subscriptionPeriod,
    price,
    expirationDate,
    onCancelSubscription,
    upcomingProduct,
    subscriptionType,
    withFreeMonth,
}: IDefaultSubscription) => {
    const { t } = useTranslation();

    const subscriptionData = {
        subscriptionPeriod,
        subscriptionType,
        price,
        expirationDate,
        isActive: state === SUBSCRIPTION_ACTIVE,
    };

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.DateInfo>
                <S.ActiveStatus data-locator="subscriptionStatus">
                    <CheckCircle color="on-primary" />
                    <Text type="small-text" medium color="on-primary" text={t(`subscription.status.${state}`)} />
                </S.ActiveStatus>
                {withFreeMonth && (
                    <S.TemporaryStatus data-locator="subscriptionOneMonthFreeStatus">
                        <Text type="small-text" medium text={t('subscription.status.freeMonth')} />
                    </S.TemporaryStatus>
                )}
            </S.DateInfo>
            <SubscriptionData data={subscriptionData} />
            {upcomingProduct && !isEmpty(upcomingProduct) && <UpcomingProduct upcomingProduct={upcomingProduct} />}
            <CancelButton onCancel={onCancelSubscription} />
        </SubscriptionCardWrapper>
    );
};

export default DefaultSubscription;
