import React from 'react';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import {
    PAUSE_OFFER_MODAL,
    sendAnalyticCancelModalCancelClick,
    sendAnalyticCancelModalOfferClick,
} from 'services/analytics/trackers/cancelOffers';

import { useCancelSubscription, usePauseSubscription } from 'hooks';

import PauseOfferModal from './components/PauseOfferModal';

const PauseOfferModalContainer = () => {
    const { onCancelSubscription } = useCancelSubscription();
    const { onPauseSubscription } = usePauseSubscription();

    useScreenLoad(PAUSE_OFFER_MODAL);

    const offerSubscriptionHandler = () => {
        sendAnalyticCancelModalOfferClick({ modalId: PAUSE_OFFER_MODAL, eventLabel: 'pause' });

        onPauseSubscription();
    };
    const cancelSubscriptionHandler = () => {
        sendAnalyticCancelModalCancelClick(PAUSE_OFFER_MODAL);

        onCancelSubscription();
    };

    return (
        <PauseOfferModal
            onCancelSubscription={cancelSubscriptionHandler}
            onPauseSubscription={offerSubscriptionHandler}
        />
    );
};

export default PauseOfferModalContainer;
