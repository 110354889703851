import {
    FREE_MONTH_OFFER_FINAL_MODAL,
    PAUSE_OFFER_FINAL_MODAL,
    sendAnalyticCancelModalScreenLoad,
} from 'services/analytics/trackers/cancelOffers';

import thumbUp from 'assets/img/subscriptionIcons/thumbUp.webp';
import exclamationMark from 'assets/img/subscriptionIcons/exclamationMark.webp';

import { IPauseSuccessData, IPauseErrorData } from './types';

const getPauseSuccessData = ({ closeModal, t, expirationDate, isPaused = false }: IPauseSuccessData) => {
    return {
        img: thumbUp,
        title: t(
            isPaused
                ? 'subscription.cancelFlow.pauseOffer.resume.successModal.title'
                : 'subscription.cancelFlow.pauseOffer.pause.successModal.title'
        ),
        subtitle: t('subscription.cancelFlow.pauseOffer.successModal.subtitle', {
            renewalDate: expirationDate,
        }),
        btnText: t('basics.continue'),
        btnDataLocator: 'updatingSubscriptionPlanContinueButton',
        onBtnClick: closeModal,
        analyticScreenLoadHandler: () => sendAnalyticCancelModalScreenLoad(PAUSE_OFFER_FINAL_MODAL),
    };
};

const getFreeMonthSuccessData = ({ closeModal, t, expirationDate }: IPauseSuccessData) => {
    return {
        img: thumbUp,
        title: t('subscription.cancellation.monthFreeOffer.successModal.title'),
        subtitle: t('subscription.cancelFlow.pauseOffer.successModal.subtitle', {
            renewalDate: expirationDate,
        }),
        btnText: t('basics.continue'),
        btnDataLocator: 'updatingSubscriptionPlanContinueButton',
        onBtnClick: closeModal,
        analyticScreenLoadHandler: () => sendAnalyticCancelModalScreenLoad(FREE_MONTH_OFFER_FINAL_MODAL),
    };
};

const getPauseErrorData = ({ onPauseSubscription, t }: IPauseErrorData) => {
    return {
        img: exclamationMark,
        title: t('subscription.cancelFlow.pauseOffer.errorModal.title'),
        mainBtnText: t('basics.tryAgain'),
        mainBtnDataLocator: 'updatingSubscriptionPlanTryAgainButton',
        onMainBtnClick: onPauseSubscription,
        supportLinkDataLocator: 'updatingSubscriptionPlanContactSupportButton',
    };
};

export { getPauseSuccessData, getFreeMonthSuccessData, getPauseErrorData };
