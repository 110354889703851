import clock from './img/clock.webp';
import bottle from './img/bottle.webp';

export const IF_DEFINITIONS = [
    {
        text: 'welcome.intermittentFasting.firstDefinition',
        img: bottle,
    },
    {
        text: 'welcome.intermittentFasting.secondDefinition',
    },
    {
        text: 'welcome.intermittentFasting.thirdDefinition',
        img: clock,
    },
];

export const IF_POINTS = [
    {
        title: 'welcome.intermittentFasting.firstPoint.title',
        text: 'welcome.intermittentFasting.firstPoint.text',
    },
    {
        title: 'welcome.intermittentFasting.secondPoint.title',
        text: 'welcome.intermittentFasting.secondPoint.text',
    },
    {
        title: 'welcome.intermittentFasting.thirdPoint.title',
        text: 'welcome.intermittentFasting.thirdPoint.text',
    },
];
