import { useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';

import { selectIsAppWebView } from 'store/uiEffects/selectors';

import {
    sendAnalyticWorkoutCastClick,
    sendAnalyticWorkoutCompleteBtnClick,
    sendAnalyticWorkoutPlayClick,
    sendAnalyticWorkoutPlayerBackClick,
    sendAnalyticWorkoutVideoProgress,
} from 'services/analytics/trackers/workouts';

import { useChromecast, useFetchWorkoutDetails, useSelector } from 'hooks';

import { VideoTiming } from './utils/VideoTiming';

import { getWindowSize } from 'helpers/getWindowSize';

import BackButton from 'components/BackButton';
import WorkoutPlayerModals from './components/WorkoutPlayerModals';
import CastControlsSection from './components/CastControlsSection';

import * as S from './styled';

const WorkoutPlayer = () => {
    const { workoutId } = useParams();
    const { data: workout } = useFetchWorkoutDetails(workoutId);
    const isAppWebView = useSelector(selectIsAppWebView);

    const playerRef = useRef<HTMLVideoElement>(null);
    const { toggleCast } = useChromecast();
    const videoTimingHelper = useMemo(() => new VideoTiming(), []);

    const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const [isCastHintOpen, setIsCastHintOpen] = useState(false);

    const isTablet = getWindowSize(600);

    const handleVideoStart = () => sendAnalyticWorkoutPlayClick();

    const handleCompleteClick = () => {
        setIsCompleteDialogOpen(true);
        playerRef.current?.pause();

        sendAnalyticWorkoutCompleteBtnClick();
    };

    const handleBackClick = () => {
        setIsExitDialogOpen(true);
        playerRef.current?.pause();

        sendAnalyticWorkoutPlayerBackClick();
    };

    const handleCastClick = () => {
        workout?.video_link_264 &&
            toggleCast(workout.video_link_264, {
                title: workout.name,
                poster: workout.player_preview_link,
            });

        sendAnalyticWorkoutCastClick();
    };

    const handleTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement>) => {
        const { currentTime, duration } = event.currentTarget;
        const percent = videoTimingHelper.getPercentsOnce(currentTime, duration);

        if (percent) {
            sendAnalyticWorkoutVideoProgress(percent / 100);
        }
    };

    return (
        <S.Wrapper>
            <WorkoutPlayerModals
                isExitDialogOpen={isExitDialogOpen}
                isCompleteDialogOpen={isCompleteDialogOpen}
                isCastHintOpen={isCastHintOpen}
                setIsExitDialogOpen={setIsExitDialogOpen}
                setIsCompleteDialogOpen={setIsCompleteDialogOpen}
                setIsCastHintOpen={setIsCastHintOpen}
            />
            <S.Container>
                <S.Main>
                    <S.BackButtonWrapper data-locator="workoutBackButton" isOpenHeader={isAppWebView}>
                        <BackButton withShadow onClick={handleBackClick} />
                    </S.BackButtonWrapper>
                    <S.PlayerWrapper data-locator="WorkoutPlayerContainer">
                        <S.Player
                            srcSet={
                                workout && [
                                    { src: workout?.video_link_265, type: 'video/mp4; codecs="hvc1"' },
                                    { src: workout?.video_link_webm, type: 'video/webm' },
                                    { src: workout?.video_link_264, type: 'video/mp4' },
                                ]
                            }
                            poster={workout?.player_preview_link}
                            isLoading={!workout}
                            onPosterClick={handleVideoStart}
                            fallback="This video cannot be played in your browser. Try another browser or contact our support for help."
                            ref={playerRef}
                            videoProps={{
                                onTimeUpdate: handleTimeUpdate,
                            }}
                        />
                        {!isTablet && (
                            <CastControlsSection
                                onClick={handleCastClick}
                                onHintClick={() => setIsCastHintOpen(true)}
                            />
                        )}
                    </S.PlayerWrapper>
                    <S.ActionsWrapper>
                        {isTablet && (
                            <CastControlsSection
                                onClick={handleCastClick}
                                onHintClick={() => setIsCastHintOpen(true)}
                            />
                        )}
                        <S.CompleteButton
                            onClick={handleCompleteClick}
                            backgroundColor="surface-main"
                            textColor="text-main"
                            hoverColor="surface-hovered"
                            dataLocator="workoutCompleteWorkoutButton"
                        >
                            Complete workout
                        </S.CompleteButton>
                    </S.ActionsWrapper>
                </S.Main>
            </S.Container>
        </S.Wrapper>
    );
};

export default WorkoutPlayer;
