import { analyticValues } from './constants';

import { IUserFormData } from './types';

const NUMBERED_FIELDS = ['yearOfBirth', 'defaultCycleLength', 'defaultPeriodLength'];

export const getUserUpdatedKeys = (userInitValues: IUserFormData, formValue: { [field: string]: string | number }) => {
    const changedKeys: string[] = [];

    Object.keys(userInitValues).forEach((key) => {
        if (NUMBERED_FIELDS.includes(key)) {
            if (Number(userInitValues[key as keyof IUserFormData]) !== Number(formValue[key])) {
                changedKeys.push(analyticValues[key as keyof IUserFormData]);
            }
        } else if (userInitValues[key as keyof IUserFormData] !== formValue[key]) {
            changedKeys.push(analyticValues[key as keyof IUserFormData]);
        }
    });

    return changedKeys;
};
