import { useEffect } from 'react';

// trackers
import { trackScreenLoad } from './trackers/mainTrackers';

export function useScreenLoad(screenCustomId?: string, data?: Record<string, unknown>) {
    useEffect(() => {
        trackScreenLoad(screenCustomId, data);
    }, []);
}
