import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import React from 'react';

import { LayoutProps } from './types';

const CabinetLayout = ({ children, titleKey }: LayoutProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t(titleKey)} />
            {children}
        </>
    );
};

export default CabinetLayout;
