import { useTranslation } from 'react-i18next';

import { Accordion } from 'components/Accordion';

import * as S from './styled';

import { QUESTIONS } from './config';

export const QuestionsBlock = () => {
    const { t } = useTranslation();

    return (
        <S.QuestionsBlock backgroundColor="surface-secondary" dataLocator="questionsSection">
            <S.ContentContainer>
                <S.Title type="h2" center text={t('welcome.questions.title')} medium mb={64} />
                <S.QuestionsWrap>
                    {QUESTIONS.map((question) => (
                        <Accordion
                            key={question.id}
                            title={question.title}
                            content={question.content}
                            dataLocator="questionContainer"
                        />
                    ))}
                </S.QuestionsWrap>
            </S.ContentContainer>
        </S.QuestionsBlock>
    );
};
