import { useTranslation } from 'react-i18next';

import { ResetPasswordFieldsType } from 'types/changePassword';

export const getFields = (): ResetPasswordFieldsType => {
    const { t } = useTranslation();

    return [
        {
            name: 'newPassword',
            label: t('unauthorized.change.password.newPassword'),
            type: 'password',
            dataLocator: 'newPasswordInput',
        },
        {
            name: 'confirmNewPassword',
            label: t('unauthorized.change.password.confirmPassword'),
            type: 'password',
            dataLocator: 'newPasswordConfirmationInput',
        },
    ];
};
