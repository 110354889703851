import config from 'config';

export default {
    onboarding:
        config.ENV === 'prod'
            ? 'https://femia.io?branch-name=motherhood&test-name=go1_2.9&utm_source=cabinet'
            : 'https://femia-front-stage.asqq.io',
    termsOfUse: 'https://femia.health/about-us/terms-of-use/',
    privacyPolicy: 'https://femia.health/about-us/privacy-policy/',
};
