import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { sendAnalyticDownloadChallengeClick } from 'services/analytics/trackers/challenge75';

import { Download } from 'components/Icon';

import * as S from './styled';

import newsIcon from './img/iconNews.webp';

import { IChallengeArticle } from '../../types';

const ChallengeArticle = (article: IChallengeArticle) => {
    const { t } = useTranslation();

    const handleDownloadClick = (programName: string) => {
        sendAnalyticDownloadChallengeClick(programName);
    };

    return (
        <S.Article key={article.title} data-locator="challengeArticleContainer">
            <S.ArticleTitle>
                <S.NewsIcon alt="icon" src={newsIcon} maxWidth={24} />
                <Text dataLocator="articleTitle" medium text={t(article.title)} />
            </S.ArticleTitle>
            <S.DownloadLink
                onClick={() => handleDownloadClick(article.title)}
                href={article.article_url}
                rel="noopener noreferrer"
                target="_blank"
                data-locator="articleDownloadButton"
            >
                <Text type="small-button" text={t('main.progress.download')} />
                <Download color="text-main" />
            </S.DownloadLink>
            <S.Label data-locator="articleLabel">
                <Text type="caption" color="action-main" text={t('challenge.level.article')} />
            </S.Label>
        </S.Article>
    );
};

export default ChallengeArticle;
