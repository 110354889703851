import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectWorkoutsInfo } from 'store/workouts/selectors';
import { setFiltersData, setIsShowWorkoutsNotification } from 'store/workouts/actions';
import { selectFitnessLevel } from 'store/user/selectors';

import { sendAnalyticWorkoutFilterOptionClick } from 'services/analytics/trackers/workouts';

import { useDispatch } from 'hooks/store';
import { useFetchWorkouts } from 'hooks';

import { removeItemFromArrayByValue } from 'helpers/utils';
import { getDefaultOptions } from './helpers';

import { FiltersData } from 'types/workouts/workoutsApiInterface';
import { FilterOption } from '../types';

export const useWorkoutsData = () => {
    const dispatch = useDispatch();

    const { filtersData } = useSelector(selectWorkoutsInfo);
    const userFitnessLevel = useSelector(selectFitnessLevel);

    const { fetchWorkouts, isLoadingWorkouts, isLoadingMoreWorkouts, isLastPage, localWorkouts } = useFetchWorkouts();

    const defaultLevel = userFitnessLevel ? [userFitnessLevel] : [];
    const filters = filtersData ? filtersData?.filters : ({ level: defaultLevel } as FiltersData['filters']);

    const [activeOptions, setActiveOptions] = useState<FilterOption[]>(
        filtersData?.options || getDefaultOptions(userFitnessLevel)
    );

    // first call to api
    useEffect(() => {
        dispatch(setIsShowWorkoutsNotification(false));

        fetchWorkouts(filters);
    }, []);

    // to update all states after changing filters
    const updateFiltersAndWorkouts = (options: FilterOption[], filters: FiltersData['filters']) => {
        setActiveOptions(options);
        dispatch(setFiltersData({ filters, options }));

        fetchWorkouts(filters);
    };

    // update filters (called from Filters modal)
    const setFilterValue = (option: FilterOption) => {
        let newOptions = [...activeOptions];
        const newFilters = { ...filters };

        !newFilters[option.filterKey] && (newFilters[option.filterKey] = []); // add filter if it doesn't exist

        if (activeOptions.some((e) => e.value === option.value)) {
            newOptions = removeItemFromArrayByValue(activeOptions, option.value);
            newFilters[option.filterKey] = removeItemFromArrayByValue(newFilters[option.filterKey], option.value);
        } else {
            newOptions.push(option);
            newFilters[option.filterKey].push(option.value);
        }

        sendAnalyticWorkoutFilterOptionClick(Object.values(newFilters).flat(1));

        updateFiltersAndWorkouts(newOptions, newFilters);
    };

    // to remove all filters
    const clearAll = () => {
        updateFiltersAndWorkouts([], { level: [], body_zone: [], type: [] });

        sendAnalyticWorkoutFilterOptionClick([]);
    };

    // remove options (called from AppliedFilters)
    const removeOption = (option: FilterOption) => {
        const newFilters = { ...filters };

        const newOptions = removeItemFromArrayByValue(activeOptions, option.value);

        newFilters[option.filterKey] = removeItemFromArrayByValue(newFilters[option.filterKey], option.value);

        updateFiltersAndWorkouts(newOptions, newFilters);
    };

    // show more workouts
    const showMore = () => {
        fetchWorkouts(filters, true);
    };

    return {
        isLoadingWorkouts,
        isLoadingMoreWorkouts,
        localWorkouts,
        isLastPage,
        setFilterValue,
        clearAll,
        removeOption,
        showMore,
        activeOptions,
    };
};
