import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { memo, useEffect } from 'react';

import { selectIsCalifornia } from 'store/user/selectors';

import { AVAILABLE_CURRENCY, NO_CENTS_CURRENCIES } from 'constants/subscriptions';
import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import {
    sendAnalyticReturnOfferScreenLoad,
    sendAnalyticSpecialOfferDiscountCancelClick,
    sendAnalyticSpecialOfferScreenLoad,
} from 'services/analytics';

import { useSelector, useSpecialOfferModal } from 'hooks';

import SubscriptionCancelledBanner from 'components/SubscriptionCancelledBanner';
import Modal from 'components/Modal';
import DiscountOffer from 'components/DiscountOffer';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import { ReturnOfferCRMModalProps } from './types';

const ReturnOfferCRMModal = ({ externalId, onSubmitDiscount }: ReturnOfferCRMModalProps) => {
    const { t } = useTranslation();
    const { candidatePriceData, discountPriceData } = useSpecialOfferModal(externalId);
    const isCalifornia = useSelector(selectIsCalifornia);

    useEffect(() => {
        isCalifornia ? sendAnalyticSpecialOfferScreenLoad() : sendAnalyticReturnOfferScreenLoad();
    }, []);

    if (!candidatePriceData || !discountPriceData) {
        return null;
    }

    const oldPrice = NO_CENTS_CURRENCIES.includes(candidatePriceData.currency)
        ? String(candidatePriceData.integer)
        : `${candidatePriceData.integer}.${candidatePriceData.cents}`;

    const onCloseModalHandler = () => {
        isCalifornia && sendAnalyticSpecialOfferDiscountCancelClick();
    };

    return (
        <Modal
            isOpen
            fullscreen
            style={{
                content: MODAL_CONTENT_FULL_SCREEN_STYLES,
            }}
        >
            <>
                <CancelModalNavBar isFirstModal closeModalAnalyticHandler={onCloseModalHandler} />
                <S.ModalContainer data-locator="discountOfferContainer">
                    <SubscriptionCancelledBanner />
                    <Text text={t('subscription.cancellation.specialOffer.title')} type="h4" medium center mb={12} />
                    <Text
                        text={t('subscription.cancellation.specialOffer.subtitle')}
                        type="large-text"
                        center
                        mb={24}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="text-secondary"
                        badgeText="subscription.cancellation.returnOffer.firstBadge"
                        price={oldPrice}
                        period={candidatePriceData.period}
                        currencySign={AVAILABLE_CURRENCY[candidatePriceData.currency]}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="critical-default"
                        badgeText="subscription.cancellation.specialOffer.secondBadge"
                        oldPrice={oldPrice}
                        price={
                            NO_CENTS_CURRENCIES.includes(discountPriceData.currency)
                                ? String(discountPriceData.integer)
                                : `${discountPriceData.integer}.${discountPriceData.cents}`
                        }
                        period={candidatePriceData.period}
                        isNewOffer
                        currencySign={AVAILABLE_CURRENCY[discountPriceData.currency]}
                        onSubmitDiscount={onSubmitDiscount}
                    />
                </S.ModalContainer>
            </>
        </Modal>
    );
};

export default memo(ReturnOfferCRMModal);
