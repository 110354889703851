import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const TitleBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    flex-direction: column;

    ${mediaQueriesGrid.largeTablet} {
        flex-direction: row;
    }
`;

export const Title = styled(Text)`
    margin-bottom: 32px;

    ${mediaQueriesGrid.largeTablet} {
        margin-bottom: 0;
    }
`;
