import { useReturnOffer } from 'hooks';

import ReturnOfferCRMModal from './components/ReturnOfferCRMModal';

const ReturnOfferCRMModalContainer = () => {
    const { resetCandidate, onResetSubscription } = useReturnOffer(true);

    if (!resetCandidate) {
        return null;
    }

    return (
        <ReturnOfferCRMModal externalId={resetCandidate?.external_id || ''} onSubmitDiscount={onResetSubscription} />
    );
};

export default ReturnOfferCRMModalContainer;
