import { Button } from 'wikr-core-components';

import * as S from './styled';

type OutlinedButtonPropsType = {
    text: string;
    onClick: () => void;
    dataLocator?: string;
    mb?: number;
    isLoading?: boolean;
    size?: 'small' | 'medium';
    isDisplayFlex?: boolean;
    justifyContent?: string;
    disabled?: boolean;
    isBackgroundWhite?: boolean;
};

const OutlinedButton = ({
    text,
    onClick,
    dataLocator,
    mb,
    isLoading,
    size,
    isDisplayFlex = false,
    justifyContent = 'flex-start',
    disabled,
    isBackgroundWhite,
}: OutlinedButtonPropsType) => {
    return (
        <S.Container
            isDisplayFlex={isDisplayFlex}
            justifyContent={justifyContent}
            mb={mb}
            borderRadius={12}
            borderColor="border-default"
            isBackgroundWhite={isBackgroundWhite}
        >
            <Button
                isLoading={isLoading}
                disabled={isLoading || disabled}
                text={text}
                size={size ?? 'medium'}
                textColor="text-main"
                onClick={onClick}
                dataLocator={dataLocator}
            />
        </S.Container>
    );
};

export default OutlinedButton;
