import { Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useInView } from '@react-spring/web';

import * as S from './styled';

export const OneRecipe = ({ img, text }: { img: string; text: string }) => {
    const { t } = useTranslation();

    const [ref, springs] = useInView(() => ({
        from: {
            opacity: 0,
            y: 100,
        },
        to: {
            opacity: 1,
            y: 0,
        },
        config: {
            duration: 500,
        },
    }));

    return (
        <S.OneRecipeWrap ref={ref} style={springs}>
            <S.RecipeImgWrap>
                <Image once isForceVisible src={img} alt="recipe img" />
            </S.RecipeImgWrap>
            <S.RecipeText medium text={t(text)} />
        </S.OneRecipeWrap>
    );
};
