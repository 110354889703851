import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { RULES_CONTENT } from './constants';

import Modal from 'components/Modal';

import * as S from './styled';

const ChallengeRulesModal = ({ onClose }: { onClose: () => void }) => {
    const { t } = useTranslation();

    return (
        <Modal
            dataLocator="challengeRulesModal"
            isOpen
            onClose={onClose}
            closeOnOverlayClick
            isChallengeModal
            challengeTitle={t('challenge.link.rules')}
        >
            <S.AllRulesWrap>
                {RULES_CONTENT.map(({ rules, img, imgDataLocator }, index) => (
                    <S.RuleBlock key={index}>
                        <S.RuleStatement>
                            {rules.map(({ pointNum, pointText }, id) => (
                                <S.OneRuleState key={id}>
                                    <S.PointCircle>
                                        <Text
                                            dataLocator={`rulesText${pointNum}`}
                                            type="large-text"
                                            bold
                                            text={`${pointNum}`}
                                        />
                                    </S.PointCircle>
                                    <Text type="large-text" text={t(pointText)} />
                                </S.OneRuleState>
                            ))}
                        </S.RuleStatement>
                        <S.RuleImg data-locator={imgDataLocator} alt={`blockImg${index}`} src={img} />
                    </S.RuleBlock>
                ))}
            </S.AllRulesWrap>
        </Modal>
    );
};

export default ChallengeRulesModal;
