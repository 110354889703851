import styled from 'styled-components';

export const DownloadImages = styled.div`
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    margin-bottom: 16px;
    
    & img {
        max-width: 162px;
        width: 100%;
        height: auto !important;
    }

    @media screen and (min-width: 768px) {
        justify-content: center;
        column-gap: 32px;
    }
`;