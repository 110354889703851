import { Box } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const LevelSection = styled(Box)<{ isOpen: boolean }>`
    display: flex;
    align-items: flex-start;
    ${({ isOpen }) =>
        !isOpen &&
        css`
            padding-bottom: 40px;
        `}

    ${mediaQueriesGrid.tablet} {
        align-items: center;
        ${({ isOpen }) =>
            !isOpen &&
            css`
                padding: 28px 0;
            `}
    }
`;

export const RadioBtn = styled.div<{ isActive: boolean; isOpen: boolean }>`
    padding: 5px;
    border: 2px solid ${({ isActive }) => (isActive ? color('text-main') : color('text-secondary'))};
    border-radius: 50%;
    margin-top: 5px;
    cursor: pointer;

    :before {
        display: block;
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        opacity: ${({ isActive }) => (isActive ? 1 : 0)};
        ${({ isOpen }) =>
            isOpen &&
            css`
                background: ${color('text-main')};
            `}
    }
`;

export const LevelContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
        gap: 32px;
    }
`;

export const LevelTitle = styled.div`
    display: flex;
    height: inherit;
    padding: 0 4px 0 16px;
    justify-content: space-between;
    gap: 4px;
    min-width: 140px;
    cursor: pointer;

    ${mediaQueriesGrid.tablet} {
        flex-direction: column;
        justify-content: center;
    }
`;

export const LevelTitleArrowBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const LevelContent = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const LevelCardBlock = styled.div`
    display: flex;
    padding: 24px 0 48px;
    gap: 10px;

    ${mediaQueriesGrid.tablet} {
        padding: 32px 0;
    }
`;

export const ArticlesSection = styled(Box)`
    display: flex;
    padding-bottom: 40px;
    flex-direction: column;
    gap: 32px;
    margin-left: 21px;

    ${mediaQueriesGrid.tablet} {
        margin-left: 176px;
    }
`;

const arrow = css`
    border: solid ${color('secondary-default')};
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 3px;
`;

export const ArrowDown = styled.div`
    ${arrow};
    transform: rotate(45deg);
`;

export const ArrowUp = styled.div`
    ${arrow};
    transform: rotate(-135deg);
`;
