import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import { ReactComponent as ArrowRight } from './img/arrowRight.svg';

interface IPlanCardProps {
    img: string;
    text: string;
    onClick: () => void;
}

const PlanCard = ({ img, text, onClick }: IPlanCardProps) => {
    const { t } = useTranslation();

    return (
        <S.PlanCard onClick={onClick}>
            <S.BtnBlockWrapper>
                <S.ImgWrap backgroundColor="decorative-f" borderRadius={12}>
                    <Image alt="planImg" src={img} maxWidth={48} isForceVisible once />
                </S.ImgWrap>
                <Text color="secondary-default" text={t(text)} bold />
            </S.BtnBlockWrapper>
            <ArrowRight />
        </S.PlanCard>
    );
};

export default PlanCard;
