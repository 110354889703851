import { IWorkout } from 'types/workouts/workoutsApiInterface';

export type WorkoutsLibraryContainerProps = {
    isCloseLocalisationInfoBanner: boolean;
    onCloseBanner: () => void;
};

export type WorkoutCardProps = {
    workout: IWorkout;
    defaultPreview: string;
};

export type FiltersProps = {
    isOpen: boolean;
    closeFilters: () => void;
    setFilterValue: (option: FilterOption) => void;
    activeOptions: FilterOption[];
    clearAll: () => void;
    isDesktop: boolean;
};

export type AppliedFiltersProps = {
    activeOptions: FilterOption[];
    clearAll: () => void;
    removeOption: (option: FilterOption) => void;
};

export enum FilterType {
    beginner = 'beginner',
    intermediate = 'intermediate',
    advanced = 'advanced',
    strength = 'strength',
    cardio = 'cardio',
    recovery = 'recovery',
    total = 'total',
    upper = 'upper',
    lower = 'lower',
}

export enum FilterKeyType {
    level = 'level',
    type = 'type',
    body_zone = 'body_zone',
}

export type FilterOption = {
    title: string;
    value: FilterType;
    filterKey: FilterKeyType;
    dataLocator: string;
};

export type Filters = {
    title: string;
    options: FilterOption[];
}[];
