import { css } from 'styled-components';

import { color } from '../selectors';

const common = css`
    .obTitle {
        font-size: 20px;
        line-height: normal;
        letter-spacing: 0.36px;
    }

    .ctaBtn {
        border-radius: 14px;
        min-height: 52px;
        padding: 11px 24px;

        p {
            font-size: 16px;
            letter-spacing: unset;
            text-transform: none;
        }

        &:hover {
            background-color: ${color('primary-hovered')} !important;
        }
    }

    .ctaBtnWhite {
        &:hover {
            background-color: ${color('on-primary')} !important;
        }
    }

    .ctaBtnTransparent {
        &:hover {
            background-color: transparent !important;
        }
    }

    .lsp-s {
        letter-spacing: -0.408px;
    }

    .lsp-m {
        letter-spacing: 0.36px;
    }

    .bold {
        font-weight: 600;
    }
`;

export default common;
