import Joyride from 'react-joyride';

import Tooltip from './components/Tooltip';

import { FLOATER_PROPS, getSteps, JOYRIDE_STYLES } from './config';

interface Onboarding2Props {
    isRun: boolean;
    onEndOnboarding: () => void;
}

export const Onboarding2 = ({ isRun, onEndOnboarding }: Onboarding2Props) => {
    const onSkipHandler = () => onEndOnboarding();
    const onLastClickHandler = () => onEndOnboarding();

    return (
        <div>
            <Joyride
                floaterProps={FLOATER_PROPS}
                styles={JOYRIDE_STYLES}
                steps={getSteps()}
                continuous
                showSkipButton
                spotlightClicks
                disableOverlayClose
                disableCloseOnEsc
                hideCloseButton
                scrollToFirstStep={false}
                run={isRun}
                tooltipComponent={(props) => (
                    <Tooltip {...props} onSkip={onSkipHandler} onLastClick={onLastClickHandler} />
                )}
                scrollOffset={80}
            />
        </div>
    );
};
