import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const WebViewContainer = styled(Box)`
    background: ${color('surface-default')};
    display: flex;
    padding: 52px 16px 64px;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
`;

export const CardsWrap = styled(Box)`
    max-width: 400px;
    width: 100%;
    margin: 0 auto 40px;
`;

export const GuidesListWrapper = styled.div`
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
`;
