// mainTrackers
import { customTrackDecorator } from './mainTrackers';

export const sendAnalyticGuideClick = (screenId: string) => {
    customTrackDecorator(`${screenId}__click_guide__download`);
};

export const sendAnalyticCTAButtonClickMyFastingPlan = (screenId: string) => {
    customTrackDecorator(`${screenId}__click_button__continue`);
};
