import { Text, Box, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import { IBigBenefitProps } from './type';

export const BigBenefit = ({ benefit, mbDesktop = 96 }: IBigBenefitProps) => {
    const { t } = useTranslation();
    const { benefitNumber, emoji, title, subtitle, img, descriptions = [] } = benefit;

    return (
        <S.BigBenefit mbDesktop={mbDesktop}>
            <S.ImageWrap>
                <Image once isForceVisible src={img} alt="benefitImg" />
            </S.ImageWrap>
            <S.ContentWrap>
                <Text medium type="large-text" text={`#${benefitNumber}`} mb={12} />
                <S.TitleWrap>
                    <S.SVGWrap>{emoji()}</S.SVGWrap>
                    <Text medium type="h2" text={t(title)} mb={24} />
                </S.TitleWrap>
                <S.Subtitle
                    type="large-text"
                    withDescriptions={Boolean(descriptions.length)}
                    medium
                    text={t(subtitle)}
                />
                <S.DescriptionsWrap>
                    {descriptions.map((description, index) => (
                        <S.OneDescriptionWrap key={index}>
                            <S.DescriptionNumber type="caption" text={`[${description.disNumber}]`} />
                            <Box>
                                <Text type="caption" text={t(description.text)} mb={4} />
                                <Text medium type="caption" text={t(description.authors)} />
                            </Box>
                        </S.OneDescriptionWrap>
                    ))}
                </S.DescriptionsWrap>
            </S.ContentWrap>
        </S.BigBenefit>
    );
};
