import { Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { getLanguage } from 'api/utils/languageManagment';

import { selectGender } from 'store/user/selectors';
import { selectIsAppLibraryWebView } from 'store/uiEffects/selectors';

import { DEFAULT_LANGUAGE } from 'constants/localization';

import { useScrollDirection } from 'hooks';
import { useWorkoutsData } from './hooks/useWorkoutsData';

import { getWindowSize } from 'helpers/getWindowSize';

import WebViewBackArrow from 'components/WebViewNavBar';
import WorkoutCard from './components/WorkoutCard';
import SkeletonBlock from './components/SkeletonBlock';
import InformationModal from './components/InformationModal';
import FiltersButton from './components/FiltersButton';
import Filters from './components/Filters';
import AppliedFilters from './components/AppliedFilters';

import * as S from './styled';

import previewMale from './img/previewMale.webp';
import previewFemale from './img/previewFemale.webp';
import arrow from './img/arrow.svg';

import { WorkoutsLibraryContainerProps } from './types';

const WorkoutsLibraryContainer = ({ isCloseLocalisationInfoBanner, onCloseBanner }: WorkoutsLibraryContainerProps) => {
    const { t } = useTranslation();
    const gender = useSelector(selectGender);
    const isAppLibraryWebView = useSelector(selectIsAppLibraryWebView);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const { scrollDirection, scrolledFirstScreen } = useScrollDirection();
    const {
        isLoadingWorkouts,
        isLoadingMoreWorkouts,
        localWorkouts,
        isLastPage,
        setFilterValue,
        clearAll,
        removeOption,
        showMore,
        activeOptions,
    } = useWorkoutsData();

    const navigate = useNavigate();

    const language = getLanguage();

    const isAvailableTransLanguage = language === DEFAULT_LANGUAGE;
    const isBannerShown = !isCloseLocalisationInfoBanner && !isAvailableTransLanguage;

    const defaultPreview = gender === 'male' ? previewMale : previewFemale;

    const isDesktop = getWindowSize(1240);

    const toggleFilters = () => setIsOpenFilters((prev) => !prev);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleAppBackClick = () => navigate(-1);

    return (
        <>
            {isBannerShown && <InformationModal onClose={onCloseBanner} />}
            <S.Wrapper>
                <S.Container $isOpenFilters={isOpenFilters || isBannerShown}>
                    <>
                        {isAppLibraryWebView && (
                            <WebViewBackArrow onClick={handleAppBackClick} text={t('basics.library')} />
                        )}
                        {!isDesktop && (
                            <FiltersButton toggleFilters={toggleFilters} scrollDirection={scrollDirection} />
                        )}
                        <Text text={t('pagesTitles.workoutLibrary')} type="h3" medium mb={isDesktop ? 40 : 22} />
                        {Boolean(activeOptions.length) && !isDesktop && (
                            <AppliedFilters
                                activeOptions={activeOptions}
                                clearAll={clearAll}
                                removeOption={removeOption}
                            />
                        )}
                        <S.ContentWrapper>
                            <Filters
                                isOpen={isOpenFilters}
                                closeFilters={toggleFilters}
                                setFilterValue={setFilterValue}
                                activeOptions={activeOptions}
                                clearAll={clearAll}
                                isDesktop={isDesktop}
                            />
                            <S.CardsWrapper>
                                {isLoadingWorkouts ? (
                                    <SkeletonBlock skeletonAmount={12} />
                                ) : (
                                    localWorkouts?.map((item) => (
                                        <WorkoutCard key={item.id} workout={item} defaultPreview={defaultPreview} />
                                    ))
                                )}
                                {isLoadingMoreWorkouts && <SkeletonBlock />}
                                {!isLastPage && Boolean(localWorkouts?.length) && (
                                    <S.ShowMoreWrapper>
                                        <S.ShowMore onClick={showMore} data-locator="workoutLibraryShowMoreButton">
                                            <Text text={t('workouts.button.showMore')} type="medium-button" />
                                        </S.ShowMore>
                                    </S.ShowMoreWrapper>
                                )}
                            </S.CardsWrapper>
                        </S.ContentWrapper>
                        <S.ButtonTop
                            $scrolledFirstScreen={scrolledFirstScreen}
                            onClick={scrollToTop}
                            data-locator="workoutLibraryToTheTopButton"
                        >
                            <img src={arrow} alt="to top" />
                        </S.ButtonTop>
                    </>
                </S.Container>
            </S.Wrapper>
        </>
    );
};

export default WorkoutsLibraryContainer;
