import * as action from './actions';

import * as actionTypes from './actionTypes';

import { AUTH_TOKEN_NAME, AUTH_WEB_TOKEN_NAME, AUTH_REFRESH_TOKEN_NAME } from 'constants/api';

import { getCookie } from 'helpers/cookie';

import { InferValueTypes } from 'types/commonTypes';

interface AuthState {
    isAuthenticated: boolean;
    isComplete: boolean;
    redirectUrl: string | null;
    token: string | null;
    webToken: string;
    refreshToken: string | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    isComplete: false,
    redirectUrl: null,
    token: getCookie(AUTH_TOKEN_NAME) || null,
    webToken: getCookie(AUTH_WEB_TOKEN_NAME) || '',
    refreshToken: getCookie(AUTH_REFRESH_TOKEN_NAME) || null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state = initialState, action: ActionType): AuthState => {
    switch (action.type) {
        case actionTypes.SIGN_IN_SUCCESS:
            return { ...state, isAuthenticated: true, isComplete: true, redirectUrl: null };

        case actionTypes.SET_AUTHENTICATION_STATUS:
            return { ...state, isComplete: true, isAuthenticated: action.payload };

        case actionTypes.LOG_OUT:
            return { ...state, isAuthenticated: false, isComplete: true };

        case actionTypes.RESET_AUTH:
            return { ...initialState, isComplete: true };

        case actionTypes.SET_AUTHENTICATION_REDIRECT_URL:
            return { ...state, redirectUrl: action.payload };

        case actionTypes.SET_AUTH_TOKEN:
            return { ...state, token: action.payload };

        case actionTypes.SET_AUTH_WEB_TOKEN:
            return { ...state, webToken: action.payload };

        case actionTypes.SET_AUTH_REFRESH_TOKEN:
            return { ...state, refreshToken: action.payload };

        default:
            return state;
    }
};

export default userReducer;
