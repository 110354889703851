import { Box } from 'wikr-core-components';
import styled, { css, keyframes } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const ReasonContainer = styled(Box)`
    position: relative;
    max-width: 432px;
    padding: 32px 16px 80px;
    margin: 0 auto;
`;

export const loaderAnimation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

export const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;

    > div {
        animation: ${loaderAnimation} 2s linear infinite;
        width: fit-content;
        height: 24px;
    }
`;

export const ReasonItem = styled.button<{ isSelected: boolean }>`
    background: ${color('surface-default')};
    padding: 16px 20px;
    width: 100%;
    border-radius: 12px;
    text-align: left;
    margin-bottom: 12px;
    cursor: pointer;
    height: 80px;
    overflow: hidden;
    transition: 0.2s ease;
    display: flex;
    align-items: center;
    gap: 12px;

    > * {
        transition: 0.2s ease;
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            background: ${color('primary-default')};

            > * {
                color: ${color('on-primary')};
            }
        `}
    ${mediaQueries.laptop} {
        &:hover {
            background: ${color('primary-default')};

            > * {
                color: ${color('on-primary')};
            }
        }
    }
`;

export const RadioBtn = styled.div<{ isSelected: boolean }>`
    min-width: 24px;
    min-height: 24px;
    background-color: ${color('surface-main')};
    border: 2px solid ${color('border-default')};
    border-radius: 50%;
    position: relative;

    ${({ isSelected }) =>
        isSelected &&
        css`
            border-color: ${color('primary-default')};

            :before {
                content: '';
                min-width: 12px;
                min-height: 12px;
                background-color: ${color('primary-default')};
                position: absolute;
                top: 21%;
                left: 21%;
                border-radius: 50%;
            }
        `}
`;
