import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const SliderWrap = styled.div`
    padding-bottom: 46px;

    & .slick-slider .slick-dots {
        bottom: -35px;
        display: flex !important;
        position: absolute;
        left: 45%;
        width: 30px;
        justify-content: space-between;

        ${mediaQueries.laptop} {
            left: 47%;
        }
    }

    & .slick-slider .slick-dots .slick-active div span {
        background-color: ${color('primary-default')};
    }
`;

export const SliderDot = styled.span`
    height: 6px;
    width: 6px;
    background-color: ${color('surface-hovered')};
    border-radius: 50%;
    display: inline-block;
    pointer-events: none;
`;
