import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { OnboardingClassNames } from 'constants/onboardingFlow';

import { getWindowSize } from 'helpers/getWindowSize';

import DemoInfo from './components/DemoInfo';
import CardsStack from './components/CardsStack';

import * as S from './styled';

import fastingIcon from './img/fastingIcon.webp';

const DEFAULT_PROTOCOL = '16:8';

interface FastingProtocolBlockProps {
    isDemo: boolean;
}

const isTablet = getWindowSize(600);

const FastingProtocolBlock = ({ isDemo }: FastingProtocolBlockProps) => {
    const { t } = useTranslation();

    return (
        <>
            <S.Container className={OnboardingClassNames.third}>
                <S.Banner dataLocator="recomendedFastingProtocol">
                    <S.BannerIcon maxWidth={84} alt="fasting-icon" src={fastingIcon} />
                    <S.BannerTitle>
                        <Text type="h1" medium text={DEFAULT_PROTOCOL} />
                        <Text
                            type="small-text"
                            medium
                            text={
                                isDemo
                                    ? t('dashboard.fastingProtocol.bannerTitle')
                                    : t('dashboard.fastingProtocol.bannerDemoTitle')
                            }
                        />
                    </S.BannerTitle>
                </S.Banner>
                {isDemo ? <DemoInfo /> : <CardsStack />}
            </S.Container>
            {isTablet && <S.Divider />}
        </>
    );
};

export default FastingProtocolBlock;
