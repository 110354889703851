import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: relative;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 432px;
    margin: 0 auto;
`;
