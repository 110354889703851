import { useTranslation } from 'react-i18next';
import { memo, useEffect } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';
import IntroBlock from './components/IntroBlock';
import GuidesBlock from './components/GuidesBlock';
import ExpertsBlock from './components/ExpertsBlock';

import * as S from './styled';

import { IGuidesOfferModalData } from '../../types';

interface GuidesOfferModalProps {
    modalData: IGuidesOfferModalData;
    onKeepSubscription: () => void;
    onCancelSubscription: () => void;
}

const GuidesOfferModal = ({ modalData, onKeepSubscription, onCancelSubscription }: GuidesOfferModalProps) => {
    const { title, guidesTitleImg, guidesTitle, guides, expertsTitle, experts, btnBlock, analyticScreenLoadHandler } =
        modalData;

    const { t } = useTranslation();

    useEffect(() => {
        analyticScreenLoadHandler && analyticScreenLoadHandler();
    }, []);

    return (
        <Modal isOpen fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar />
            <IntroBlock title={title} guidesTitleImg={guidesTitleImg} />
            <S.Container>
                <GuidesBlock guides={guides} guidesTitle={guidesTitle} />
                <ExpertsBlock experts={experts} expertsTitle={expertsTitle} />
                <S.BtnTitleWrapper text={btnBlock} type="h4" medium center mb={24} />
                <SecondaryDefaultButton
                    onClick={onKeepSubscription}
                    text={t('subscription.cancelDialog.discard')}
                    mb={20}
                    dataLocator="guidesOfferKeepSubscriptionButton"
                />
                <OutlinedButton
                    dataLocator="guidesOfferCancelSubscriptionButton"
                    text={t('subscription.text.cancel')}
                    onClick={onCancelSubscription}
                />
            </S.Container>
        </Modal>
    );
};

export default memo(GuidesOfferModal);
