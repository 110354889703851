import { useTranslation } from 'react-i18next';

import { PATHS } from 'constants/routerPaths';

import passwordIcon from 'assets/img/icons/passwordIcon.webp';
import emailIcon from 'assets/img/icons/emailIcon.webp';

export const getContentData = (email: string) => {
    const { t } = useTranslation();

    return {
        inputData: [
            {
                field: 'email',
                icon: emailIcon,
                value: email,
                label: t('input.email.label'),
                buttonRoute: PATHS.CHANGE_EMAIL,
                buttonLocator: 'changeEmailButton',
                eventLabel: 'email_chg',
            },
            {
                field: 'text',
                icon: passwordIcon,
                value: '••••••••',
                label: t('input.password.placeholder'),
                buttonRoute: PATHS.CHANGE_PASSWORD,
                buttonLocator: 'changePasswordButton',
                eventLabel: 'pass_chg',
            },
        ],
        deleteData: {
            title: t('account.settings.deletionModal.title'),
            description: t('account.settings.deletionModal.text'),
            submitButton: t('account.settings.deletionModal.deleteBtn'),
            discardButton: t('account.settings.deletionModal.cancelBtn'),
        },
    };
};
