import React from 'react';
import { Trans } from 'react-i18next';

import femia from './img/Femia.webp';
import star from './img/Star.svg';

import * as S from './styled';

const FemiaRatingPlate = () => {
  return (
    <S.Container>
      <img src={femia} alt='femia' />
      <S.Description>
        <div><Trans>main.ratingPlate.title</Trans></div>
        <div><Trans>main.ratingPlate.subtitle</Trans></div>
          <div>
              <img alt="star" src={star} />
              <img alt="star" src={star} />
              <img alt="star" src={star} />
              <img alt="star" src={star} />
              <img alt="star" src={star} />
          </div>
      </S.Description>
    </S.Container>
  );
};

export default FemiaRatingPlate;
