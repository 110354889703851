import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Text } from 'assets/images/logo-label.svg';

const baseLogo = css`
    display: block;
    height: 100%;
    width: auto;
`;

export const LogoContainer = styled(Link)<{ height: number | undefined }>`
    display: flex;
    align-items: center;
    gap: 8px;
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
`;

export const LogoImage = styled(Logo)`
    ${baseLogo};
`;

export const LogoText = styled(Text)`
    ${baseLogo};
    position: relative;
    top: 1px;
`;
