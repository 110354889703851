import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const ReminderOfferContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 34px;
    max-width: 432px;
    padding: 32px 16px;
    margin: 0 auto;
`;

export const ReminderOfferContent = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
`;

export const VertcalLine = styled.div`
    position: absolute;
    border-left: 2px solid ${color('surface-secondary')};
    top: 0;
    bottom: 100px;
    left: 28px;
`;

export const Title = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const Block = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const BlockContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 68px;
`;

export const Buttons = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
