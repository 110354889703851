import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { getCurrentUserRequest } from 'store/user/actions';

import { useDispatch } from 'hooks';

import AccountSettings from 'containers/AccountSettings';

export const AccountSettingsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const params: { tabId?: string } = useParams();

    useEffect(() => {
        dispatch(getCurrentUserRequest());
    }, []);

    const handleChangeTab = (tabId: string) => {
        const path = params.tabId ? location.pathname.replace(params.tabId, tabId) : `${location.pathname}/${tabId}`;

        navigate(path);
    };

    return <AccountSettings tabId={params.tabId} onChangeTab={handleChangeTab} />;
};
