import * as S from './styled';

import check from 'assets/img/check.svg';

interface ProgramProfitBlockProps {
    profits: string[];
}

const ProgramProfitBlock = ({ profits }: ProgramProfitBlockProps) => {
    return (
        <S.ProfitContainer>
            {profits.map((profit) => (
                <S.ProfitItem key={profit}>
                    <S.CheckMark alt="check" src={check} maxWidth={24} isForceVisible />
                    <S.Text text={profit} type="large-text" medium />
                </S.ProfitItem>
            ))}
        </S.ProfitContainer>
    );
};

export default ProgramProfitBlock;
