import { isMaleGender } from 'helpers/utils';

import pecsM from './img/pecsM.webp';
import legsM from './img/legsM.webp';
import legsF from './img/legsF.webp';
import buttM from './img/buttM.webp';
import buttF from './img/buttF.webp';
import breastsF from './img/breastsF.webp';
import bellyM from './img/bellyM.webp';
import bellyF from './img/bellyF.webp';

const getOneZone = (zone: string, gender: string | null) => {
    const isMale = isMaleGender(gender);

    switch (zone) {
        case 'Belly':
            return {
                img: isMale ? bellyM : bellyF,
                transKey: 'myFastingPlan.targetZones.belly',
            };

        case 'Butt':
            return {
                img: isMale ? buttM : buttF,
                transKey: 'myFastingPlan.targetZones.butt',
            };

        case 'Breasts':
            return {
                img: breastsF,
                transKey: 'myFastingPlan.targetZones.breasts',
            };

        case 'Pecs':
            return {
                img: pecsM,
                transKey: 'myFastingPlan.targetZones.pecs',
            };

        case 'Legs':
            return {
                img: isMale ? legsM : legsF,
                transKey: 'myFastingPlan.targetZones.legs',
            };

        default:
            return { img: '', transKey: '' };
    }
};

export const getTargetZonesContent = (targetZones: string[], gender: string | null) => {
    const content: { img: string; transKey: string }[] = [];

    targetZones.forEach((zone) => content.push(getOneZone(zone, gender)));

    return content;
};
