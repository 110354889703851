import { Image, Text } from 'wikr-core-components';
import React from 'react';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import FeedbackModalsNavBar from 'components/FeedbackModalsNavBar';

import * as S from './styled';

import { ISecondSubscriptionCancelModalProps } from '../../types';

const SecondSubscriptionCancelModal = ({
    modalData,
    onMainBtnClick,
    onOutlineBtnClick,
}: ISecondSubscriptionCancelModalProps) => {
    const { img, title, subtitle, mainBtnText, mainBtnDataLocator, outlineBtnText, outlineBtnDataLocator } = modalData;

    return (
        <Modal dataLocator="updatingSubscriptionPlanModal" isOpen style={{ content: { padding: '16px' } }}>
            <FeedbackModalsNavBar dataLocator="updatingSubscriptionPlanCloseButton" />
            <Image maxWidth={72} center alt="emoji" src={img} isForceVisible />
            <Text type="h5" text={title} mb={12} center />
            <S.Subtitle text={subtitle} mb={24} center />
            <SecondaryDefaultButton
                dataLocator={mainBtnDataLocator}
                text={mainBtnText}
                onClick={onMainBtnClick}
                mb={16}
            />
            <OutlinedButton text={outlineBtnText} onClick={onOutlineBtnClick} dataLocator={outlineBtnDataLocator} />
        </Modal>
    );
};

export default SecondSubscriptionCancelModal;
