import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const FastingType = styled(Box)`
    width: 100%;
    padding: 24px;
    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${mediaQueriesGrid.tablet} {
        margin-bottom: 0;
        margin-right: 16px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    ${mediaQueriesGrid.desktop} {
        padding: 56px 40px;
    }
`;

export const EmojiWrap = styled(Box)`
    margin: 0 auto 12px;
    max-width: 56px;
`;

export const Title = styled(Text)`
    margin-bottom: 32px;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 40px;
    }
`;

export const PointWrap = styled(Box)`
    margin-bottom: 32px;
    display: flex;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const SVGWrap = styled(Box)`
    margin-right: 12px;
`;
