import { Text } from 'wikr-core-components';
import { ActionEventAction, ElementEventAction, EventTrigger, Main } from 'wikr-core-analytics';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import base from 'appConfig/base';

import { DEVICE_OS } from 'constants/devices';

import { useFetchExerciseDetails } from 'hooks';

import { isEmpty, getDeviceOS } from 'helpers/utils';

import Modal from 'components/Modal';
import ExerciseInfoSkeleton from './components/ExerciseInfoSkeleton';

import * as S from './styled';

import eyesIcon from './img/eyesIcon.webp';
import alertIcon from './img/alert.webp';

import { BodyPart, Exercise } from 'types/workouts/workoutsApiInterface';

type Props = {
    currentExerciseData: Exercise;
    isOpen: boolean;
    onClose: () => void;
};

const isAndroid = getDeviceOS() === DEVICE_OS.ANDROID;

const CUSTOM_STYLES = {
    content: {
        top: '12px',
        padding: 0,
        backgroundColor: '#F3F5F7',
        ...(!isAndroid && {
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
        }),
    },
};

const ExerciseInfoModal = ({ currentExerciseData, isOpen, onClose }: Props) => {
    const { t } = useTranslation();

    const exerciseId = currentExerciseData?.id;
    const exerciseName = currentExerciseData?.name;

    const {
        data: exerciseInfo,
        isLoading: isLoadingExerciseInfo,
        exerciseHasBodyParts,
        exerciseHasInstruction,
        exerciseHasTips,
    } = useFetchExerciseDetails(exerciseId);

    const displaySkeleton = isLoadingExerciseInfo && isEmpty(exerciseInfo);

    useEffect(() => {
        if (exerciseId && exerciseName) {
            Main.trackNew({
                eventData: {
                    event: EventTrigger.screenLoad,
                    screenId: `exercise_preview_${exerciseName}`,
                },
                actionData: {
                    elementEvent: ElementEventAction.screen,
                    actionEvent: ActionEventAction.load,
                },
            });
        }
    }, [exerciseId, exerciseName]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} withCloseButton fullscreen style={CUSTOM_STYLES}>
            <S.ModalWrapper>
                <ExerciseInfoSkeleton displaySkeleton={displaySkeleton} />
                {exerciseInfo && (
                    <>
                        <S.VideoBlock>
                            <S.Player
                                dataLocator="exercisePreviewVideoPlayer"
                                $disableRounded={isAndroid}
                                srcSet={[
                                    { src: exerciseInfo?.video_link_265, type: 'video/mp4; codecs="hvc1"' },
                                    { src: exerciseInfo?.video_link_webm, type: 'video/webm' },
                                    { src: exerciseInfo?.video_link_264, type: 'video/mp4' },
                                ]}
                                fallback={
                                    <S.VideoErrorState>
                                        <S.VideoErrorStateInner>
                                            <S.AlertVideoIcon src={alertIcon} alt="alert icon" />
                                            <Text type="large-text" color="text-secondary" mb={28}>
                                                This video cannot be played in your browser. Try another browser or
                                                contact our support for help.
                                            </Text>
                                            <S.Link
                                                href={`mailto:${base.supportEmail}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                data-locator="contactUsLink"
                                            >
                                                <Text
                                                    type="overline"
                                                    color="text-secondary"
                                                    center
                                                    bold
                                                    text={t('basics.contactUs')}
                                                />
                                            </S.Link>
                                        </S.VideoErrorStateInner>
                                    </S.VideoErrorState>
                                }
                                videoProps={{
                                    loop: true,
                                }}
                            />
                        </S.VideoBlock>
                        <S.ContentBlock>
                            <Text
                                text={exerciseInfo.name}
                                dataLocator="exercisePreviewTitle"
                                type="large-text"
                                medium
                                mb={10}
                            />
                            {exerciseHasBodyParts && (
                                <S.LabelsWrapper>
                                    {exerciseInfo.body_parts?.map((item: BodyPart) => (
                                        <S.Label key={item.id}>
                                            <Text
                                                text={item.name}
                                                dataLocator="exercisePreviewMusclesBadge"
                                                type="small-text"
                                            />
                                        </S.Label>
                                    ))}
                                </S.LabelsWrapper>
                            )}
                            {exerciseHasInstruction && (
                                <S.Block data-locator="exercisePreviewInstructionContainer">
                                    <Text text="Instructions" bold mb={16} />
                                    <div>
                                        {(exerciseInfo.instruction as string[]).map((item, index) => (
                                            <S.ListWrapper key={index}>
                                                <S.ListType>
                                                    <S.ListTypeItem $ordered>{index + 1}</S.ListTypeItem>
                                                </S.ListType>
                                                <S.ListItem>
                                                    <Text color="text-secondary">{item}</Text>
                                                </S.ListItem>
                                            </S.ListWrapper>
                                        ))}
                                    </div>
                                </S.Block>
                            )}
                            {exerciseHasTips && (
                                <S.Block data-locator="ExerciseModalTips">
                                    <Text text="Tips" dataLocator="exercisePreviewTipsContainer" bold mb={16} />
                                    <div>
                                        {(exerciseInfo.tips as string[]).map((item, index) => (
                                            <S.ListWrapper key={index}>
                                                <S.ListType>
                                                    <S.ListTypeItem />
                                                </S.ListType>
                                                <S.ListItem>
                                                    <Text color="text-secondary">{item}</Text>
                                                </S.ListItem>
                                            </S.ListWrapper>
                                        ))}
                                    </div>
                                </S.Block>
                            )}
                            {!exerciseHasInstruction && !exerciseHasTips && (
                                <S.Block>
                                    <S.EmptyStateBlockInner>
                                        <S.EmptyStateIcon src={eyesIcon} alt="eyes icon" />
                                        <Text
                                            dataLocator="exercisePreviewWeAreWorkingOnIt"
                                            color="text-secondary"
                                            text={t('workouts.exercisePreviewModal.emptyState')}
                                        />
                                    </S.EmptyStateBlockInner>
                                </S.Block>
                            )}
                        </S.ContentBlock>
                    </>
                )}
            </S.ModalWrapper>
        </Modal>
    );
};

export default ExerciseInfoModal;
