import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Block = styled.div`
    & + & {
        margin-top: 40px;
    }
`;

export const BlockTitle = styled(Text)`
    display: flex;
    align-items: center;
    padding: 0 16px;
`;

export const BlockRepeats = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 20px;
`;

export const ExercisesList = styled.div`
    background-color: ${color('surface-main')};
    border-radius: 20px;
    margin-top: 12px;
    ${mediaQueries.laptop} {
        margin-top: 16px;
    }
`;

export const ExerciseWrapper = styled.div<{
    $isRest?: boolean;
}>`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    ${(props) => !props.$isRest && 'cursor: pointer;'}

    ${mediaQueries.laptop} {
        padding: 24px;
        gap: 20px;
        & + & {
            border-top: 1px solid ${color('border-default')};
        }
    }
`;

export const ExerciseDuration = styled(Text)`
    margin-left: auto;
`;

export const ExerciseImage = styled.img`
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 16px;
    ${mediaQueries.laptop} {
        width: 112px;
        height: 112px;
    }
`;

export const ExerciseRestPlaceholder = styled.div`
    width: 64px;
    height: 20px;
    background: ${color('surface-default')};
    border-radius: 8px;
    ${mediaQueries.laptop} {
        width: 112px;
    }
`;
