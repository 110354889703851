import { Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { StartFastingButton } from 'pages/welcome/components/StartFastingButton';

import * as S from './styled';

import timer from './img/timer.webp';

interface WantToTryBlockProps {
    btnHandler: () => void;
    isShow: boolean;
}

export const WantToTryBlock = ({ btnHandler, isShow }: WantToTryBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.WantToTryBlock backgroundColor="surface-secondary" isShow={isShow}>
            <S.ColoredContainer>
                <S.ImageWrap>
                    <Image once alt="timer" src={timer} isForceVisible />
                </S.ImageWrap>
                <S.Title center mb={32} medium text={t('welcome.timerBlock.wantToTry.title')} />
                <StartFastingButton btnHandler={btnHandler} />
            </S.ColoredContainer>
        </S.WantToTryBlock>
    );
};
