import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';

import { AVAILABLE_CURRENCY, NO_CENTS_CURRENCIES } from 'constants/subscriptions';
import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import { useSpecialOfferModal } from 'hooks';

import Modal from 'components/Modal';
import HiddenButton from 'components/HiddenButton';
import DiscountOffer from 'components/DiscountOffer';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import { SpecialOfferModalProps } from './types';

const SpecialOfferModal = ({
    externalId,
    onSubmitDiscount,
    onCancelSubscription,
    hideBackBtn,
}: SpecialOfferModalProps) => {
    const { t } = useTranslation();
    const { candidatePriceData, discountPriceData } = useSpecialOfferModal(externalId);

    if (!candidatePriceData || !discountPriceData) {
        return null;
    }

    const oldPrice = NO_CENTS_CURRENCIES.includes(candidatePriceData.currency)
        ? String(candidatePriceData.integer)
        : `${candidatePriceData.integer}.${candidatePriceData.cents}`;

    const newPrice = NO_CENTS_CURRENCIES.includes(discountPriceData.currency)
        ? String(discountPriceData.integer)
        : `${discountPriceData.integer}.${discountPriceData.cents}`;

    const discountPercent = Math.round(
        ((candidatePriceData.initialOldPrice - discountPriceData.initialNewPrice) /
            candidatePriceData.initialOldPrice) *
            100
    );

    return (
        <Modal
            isOpen
            fullscreen
            style={{
                content: MODAL_CONTENT_FULL_SCREEN_STYLES,
            }}
        >
            <>
                <CancelModalNavBar isFirstModal={hideBackBtn} />
                <S.ModalContainer data-locator="discountOfferContainer">
                    <Text
                        text={t('subscription.cancellation.specialOffer.withDynamicPercent.title', {
                            percent: discountPercent,
                        })}
                        className="obTitle"
                        type="h5"
                        center
                        mb={12}
                    />
                    <Text
                        text={t('subscription.cancellation.tooExpensive.subtitle')}
                        className="obSubtitle"
                        type="medium-text"
                        center
                        mb={24}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="text-secondary"
                        badgeText="subscription.cancellation.specialOffer.firstBadge"
                        price={oldPrice}
                        period={candidatePriceData.period}
                        currencySign={AVAILABLE_CURRENCY[candidatePriceData.currency]}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="discount"
                        badgeText="subscription.cancellation.specialOffer.withDynamicPercent.secondBadge"
                        badgePercent={discountPercent}
                        oldPrice={oldPrice}
                        price={newPrice}
                        period={candidatePriceData.period}
                        isNewOffer
                        currencySign={AVAILABLE_CURRENCY[discountPriceData.currency]}
                        onSubmitDiscount={onSubmitDiscount}
                    />
                    <HiddenButton text={t('subscription.text.cancel')} onClick={onCancelSubscription} />
                </S.ModalContainer>
            </>
        </Modal>
    );
};

export default memo(SpecialOfferModal);
