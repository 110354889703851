import { ModalName } from 'store/modals/types';

import { sendAnalyticGetLifeTimeAccessClick } from 'services/analytics';

import { useModals } from 'hooks';

const useLifeTimeAccess = () => {
    const { openModal } = useModals();

    const onGetLifeTimeAccess = () => {
        sendAnalyticGetLifeTimeAccessClick();
        openModal(ModalName.lifeTimeAccessFinal);
    };

    return {
        onGetLifeTimeAccess,
    };
};

export default useLifeTimeAccess;
