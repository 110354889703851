import { Text, Image } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled.div`
    max-width: 620px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;
`;

export const TextWrapper = styled(Text)`
    max-width: 344px;
`;

export const ImageWrapper = styled(Image)`
    width: 620px;
`;
