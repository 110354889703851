import { Box, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import { useState } from 'react';

import * as S from './styled';

interface AccordionProps {
    title: string;
    content: string;
    dataLocator: string;
}

export const Accordion = ({ title, content, dataLocator }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggle = () => setIsOpen(!isOpen);

    return (
        <S.Wrapper data-locator={dataLocator} onClick={handleToggle}>
            <Box backgroundColor="surface-main" borderRadius={12} paddingTop={24} paddingX={16} paddingBottom={24}>
                <S.Header>
                    <S.FaqButton>
                        <Text type="large-text" medium>
                            <Trans i18nKey={title} />
                        </Text>
                    </S.FaqButton>
                    <S.PlusSwitcher isOpen={isOpen}>
                        <div />
                        <div />
                    </S.PlusSwitcher>
                </S.Header>
                {isOpen && (
                    <S.Content type="medium-text">
                        <Trans i18nKey={content} />
                    </S.Content>
                )}
            </Box>
        </S.Wrapper>
    );
};
