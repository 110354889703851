import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const Description = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 4px;
`;
