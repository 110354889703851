import { useModals } from 'hooks';

import ChallengeRulesModal from './components/ChallengeRulesModal';

const ChallengeRulesModalContainer = () => {
    const { closeModal } = useModals();

    return <ChallengeRulesModal onClose={closeModal} />;
};

export default ChallengeRulesModalContainer;
