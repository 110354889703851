import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

export const Details = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const NavBarWrapper = styled(Box)`
    display: flex;
    align-items: center;
`;

export const DateInfo = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
`;

export const TextColumn = styled(Text)`
    flex: 1;
`;
