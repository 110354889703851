import { Image } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import bodyStatus6 from 'pages/body-status/img/bodyStatus6.webp';
import bodyStatus5 from 'pages/body-status/img/bodyStatus5.webp';
import bodyStatus4 from 'pages/body-status/img/bodyStatus4.webp';
import bodyStatus3 from 'pages/body-status/img/bodyStatus3.webp';
import bodyStatus2 from 'pages/body-status/img/bodyStatus2.webp';
import bodyStatus1 from 'pages/body-status/img/bodyStatus1.webp';

import * as S from './styled';

interface TabBarProps {
    isDashboardBlock?: boolean;
    activeId?: number;
}

const BODY_STATUSES = [bodyStatus1, bodyStatus2, bodyStatus3, bodyStatus4, bodyStatus5, bodyStatus6];

const TabBar = ({ activeId, isDashboardBlock }: TabBarProps) => {
    const navigate = useNavigate();

    const handleStatusChange = (statusId: number) => {
        const path = `/dashboard/body-status/${statusId}`;

        navigate(path);
    };

    return (
        <S.TabBarContainer isDashboardBlock={isDashboardBlock}>
            {BODY_STATUSES.map((status, index) => {
                return (
                    <S.TabButton key={status} isDashboardBlock={isDashboardBlock}>
                        <S.IconButton
                            onClick={() => handleStatusChange(index + 1)}
                            className={activeId === index + 1 ? 'active' : ''}
                            data-locator="fastingStage"
                            isDashboardBlock={isDashboardBlock}
                        >
                            <Image alt="status-icon" src={status} />
                        </S.IconButton>
                        {index !== 5 && <S.Divider isDashboardBlock={isDashboardBlock} />}
                    </S.TabButton>
                );
            })}
        </S.TabBarContainer>
    );
};

export default TabBar;
