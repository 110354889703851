import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { setReminder } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { DEFAULT_SUBSCRIPTION_REQUEST_ERROR } from 'constants/subscriptions';

import { sendAnalyticSubscriptionCancelError, sendAnalyticSubscriptionCancelSuccess } from 'services/analytics';

import { useDispatch, useModals, useSelector } from 'hooks';

import { getLoadingData, getReminderSuccessData, getReminderErrorData } from './helpers';

const useSubscriptionReminder = () => {
    const { openModal, closeModal } = useModals();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { cancellationCandidate } = useSelector((state) => state.subscriptions);

    const onSetReminder = useCallback(() => {
        openModal(ModalName.withLoaderModal, {
            modalData: getLoadingData(t),
        });

        if (cancellationCandidate) {
            dispatch(
                setReminder({
                    externalId: cancellationCandidate.externalId,
                    onSuccess: () => {
                        sendAnalyticSubscriptionCancelSuccess();

                        openModal(ModalName.successFeedbackModal, {
                            modalData: getReminderSuccessData({ closeModal, t }),
                        });
                    },
                    onError: (error?: string) => {
                        sendAnalyticSubscriptionCancelError(error ?? DEFAULT_SUBSCRIPTION_REQUEST_ERROR);

                        openModal(ModalName.errorFeedbackModal, {
                            modalData: getReminderErrorData({ onSetReminder, t }),
                        });
                    },
                })
            );
        }
    }, [cancellationCandidate]);

    return { onSetReminder };
};

export default useSubscriptionReminder;
