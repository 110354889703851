import styled, { css } from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

const rowHeader = css`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Header = styled.div<{ isDashboardBlock?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    gap: 12px;

    ${mediaQueriesGrid.largeTablet} {
        ${rowHeader}
    }

    ${({ isDashboardBlock = false }) =>
        isDashboardBlock &&
        css`
            ${mediaQueriesGrid.tablet} {
                ${rowHeader}
            }
        `};
`;

export const IconWrapper = styled.div<{ isDashboardBlock?: boolean }>`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08), 0 2px 34px rgba(0, 0, 0, 0.05);

    img {
        width: 16px;
    }

    ${({ isDashboardBlock = false }) =>
        !isDashboardBlock &&
        css`
            ${mediaQueriesGrid.tablet} {
                display: none;
            }
        `};
`;

export const CurrentStatusTitle = styled.div<{ isDashboardBlock?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;
    height: 32px;

    ${({ isDashboardBlock = false }) =>
        !isDashboardBlock &&
        css`
            ${mediaQueriesGrid.tablet} {
                gap: 0;
            }
        `};
`;

export const CurrentStatus = styled.div<{ isDashboardBlock?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 12px;
    height: 20px;

    img {
        width: 20px;
    }

    ${({ isDashboardBlock = false }) =>
        isDashboardBlock &&
        css`
            padding-left: 36px;
        `};
`;
