import { Text } from 'wikr-core-components';
import React from 'react';

import * as S from './styled';

import expert from './img/expert.webp';

interface AdviceCardProps {
    title: string;
    subtitle?: string;
    advice?: string;
    withIcon?: boolean;
}

const AdviceCard = ({ title, subtitle, advice, withIcon }: AdviceCardProps) => {
    return (
        <S.Container>
            {withIcon ? (
                <S.Expert>
                    <S.ExpertIcon alt="expert" src={expert} />
                    <S.ExpertDetails>
                        <Text type="medium-text" bold text={title} />
                        <Text type="small-text" color="text-secondary" text={subtitle} />
                    </S.ExpertDetails>
                </S.Expert>
            ) : (
                <Text type="medium-text" bold text={title} />
            )}
            <Text type="medium-text" text={advice} />
        </S.Container>
    );
};

export default AdviceCard;
