import { TFunction } from 'i18next';

import {
    REMINDER_OFFER_FINAL_MODAL,
    sendAnalyticCancelModalScreenLoad,
} from 'services/analytics/trackers/cancelOffers';

import sandClock from 'assets/img/subscriptionIcons/sandClock.webp';
import reminderSuccessIcon from 'assets/img/subscriptionIcons/reminderSuccessIcon.webp';
import exclamationMark from 'assets/img/subscriptionIcons/exclamationMark.webp';

const getLoadingData = (t: TFunction) => {
    return {
        img: sandClock,
        title: t('basics.loading'),
    };
};

const getReminderSuccessData = ({ closeModal, t }: { closeModal: () => void; t: TFunction }) => {
    return {
        img: reminderSuccessIcon,
        title: t('subscription.cancellation.reminderOffer.successModal.title'),
        subtitle: t('subscription.cancellation.reminderOffer.successModal.subtitle'),
        btnText: t('basics.continue'),
        btnDataLocator: 'updatingSubscriptionPlanContinueButton',
        onBtnClick: closeModal,
        analyticScreenLoadHandler: () => sendAnalyticCancelModalScreenLoad(REMINDER_OFFER_FINAL_MODAL),
    };
};

const getReminderErrorData = ({ onSetReminder, t }: { onSetReminder: () => void; t: TFunction }) => {
    return {
        img: exclamationMark,
        title: t('message.error.somethingWentWrong'),
        mainBtnText: t('basics.tryAgain'),
        mainBtnDataLocator: 'updatingSubscriptionPlanTryAgainButton',
        onMainBtnClick: onSetReminder,
        supportLinkDataLocator: 'updatingSubscriptionPlanContactSupportButton',
    };
};

export { getLoadingData, getReminderSuccessData, getReminderErrorData };
