import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { PATHS } from 'constants/routerPaths';

import ButtonWithArrow from 'components/ButtonWithArrow';

import * as S from './styled';

const CardsStack = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <S.CardsStack>
            <S.Cards>
                <S.Card1 />
                <S.Card2 />
                <S.Card3 />
                <S.Card4 />
                <S.CardsLabel
                    type="caption"
                    medium
                    color="secondary-default"
                    text={t('dashboard.fastingProtocol.otherProtocols')}
                />
                <ButtonWithArrow
                    btnId="otherProtocolButton"
                    onClick={() => navigate(PATHS.FASTING_PROTOCOL)}
                    dataLocator="otherProtocolButton"
                    isAbsoluteOnStack
                />
            </S.Cards>
        </S.CardsStack>
    );
};

export default CardsStack;
