import React from 'react';

import { ModalName } from 'store/modals/types';

import { useModals } from 'hooks';

import BackArrowIcon from 'components/Icon/BackArrow';

import * as S from './styled';

import { ReactComponent as LogoIcon } from 'assets/images/logo-label.svg';

interface ICancelModalNavBarProps {
    theme?: 'blue' | 'white';
    isBackBtn?: boolean;
    isFirstModal?: boolean;
    closeModalAnalyticHandler?: () => void;
    onDiscard?: () => void;
}

const CancelModalNavBar = ({
    theme,
    isBackBtn = true,
    isFirstModal,
    closeModalAnalyticHandler,
    onDiscard,
}: ICancelModalNavBarProps) => {
    const { openModal, closeModal } = useModals();

    const onCloseModal = () => {
        closeModalAnalyticHandler && closeModalAnalyticHandler();

        if (isFirstModal) {
            onDiscard && onDiscard();
            closeModal();

            return;
        }

        openModal(ModalName.reasonModal);
    };

    return (
        <S.NavBarContainer>
            {isBackBtn && (
                <S.Icon onClick={onCloseModal} data-locator="cancelSubscriptionModalBackButton">
                    <BackArrowIcon {...(theme === 'blue' && { color: 'on-primary' })} />
                </S.Icon>
            )}
            <S.NavBarLogo>
                <LogoIcon />
            </S.NavBarLogo>
            {isBackBtn && <S.EmptyDiv />}
        </S.NavBarContainer>
    );
};

export default CancelModalNavBar;
