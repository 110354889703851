import * as actionTypes from './actionTypes';

import { IGuide } from 'types/guide';

export const fetchGuides = (productCodes?: string[]) => {
    return {
        type: actionTypes.FETCH_GUIDES,
        payload: productCodes,
    } as const;
};

export const setGuides = (payload: IGuide[]) => {
    return {
        type: actionTypes.SET_GUIDES,
        payload,
    } as const;
};

export const setLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATE,
        payload,
    } as const;
};

export const setOfferGuides = (payload: { onSuccess: () => void; onError: (error?: string) => void }) => {
    return {
        type: actionTypes.SET_OFFER_GUIDES,
        payload,
    } as const;
};
