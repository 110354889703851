import styled, { css } from 'styled-components';

import Video from 'components/Video';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Block = styled.div`
    padding: 16px;
    margin-top: 20px;
    background-color: ${color('surface-main')};
    border-radius: 20px;

    ${mediaQueries.smallDesktop} {
        width: 294px;
    }
`;

export const ModalWrapper = styled.div`
    ${mediaQueries.smallDesktop} {
        display: flex;
        justify-content: space-between;
        padding: 20px 64px 40px;
    }
`;

export const VideoBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaQueries.smallDesktop} {
        align-items: flex-start;
        width: calc(100% - 294px - 20px);
        border-radius: 20px;
    }
`;

export const Player = styled(Video)<{ $disableRounded?: boolean }>`
    display: flex;
    align-items: center;
    background-color: ${color('surface-secondary')};

    ${({ $disableRounded }) =>
        !$disableRounded &&
        css`
            border-radius: 20px 20px 0 0;
            & > video {
                border-radius: 20px 20px 0 0;
            }
        `}

    ${mediaQueries.smallDesktop} {
        border-radius: 20px;
        overflow: hidden;
    }
`;

export const VideoErrorState = styled.div`
    padding: 0 16px;
    text-align: center;
`;

export const VideoErrorStateInner = styled.div`
    margin: 0 auto;
    max-width: 450px;
`;

export const AlertVideoIcon = styled.img`
    margin-bottom: 24px;
    width: 32px;
    height: 32px;
`;

export const Link = styled.a`
    display: inline-block;
`;

export const ContentBlock = styled.div`
    padding: 20px 16px 40px;
    overflow-y: scroll;

    ${mediaQueries.smallDesktop} {
        padding: 0;
        height: calc(100vh - 60px);
    }
`;

export const LabelsWrapper = styled.div`
    display: flex;
    margin-bottom: 12px;
`;

export const Label = styled.div`
    padding: 2px 8px;
    border-radius: 100px;
    background-color: ${color('surface-main')};

    &:not(:last-child) {
        margin-right: 6px;
    }
`;

export const ListWrapper = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`;

export const ListType = styled.div`
    line-height: 24px;
`;

export const ListTypeItem = styled.div<{
    $ordered?: boolean;
}>`
    ${({ $ordered }) =>
        $ordered
            ? css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 4px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  font-size: 14px;
                  color: ${color('text-secondary')};
                  background-color: ${color('surface-default')};
              `
            : css`
                  margin-top: 10px;
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background-color: ${color('text-secondary-subdued')};
              `}
`;

export const ListItem = styled.div`
    margin-left: 12px;
`;

export const EmptyStateBlockInner = styled.div`
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 222px;

    ${mediaQueries.smallDesktop} {
        height: 385px;
    }
`;

export const EmptyStateIcon = styled.img`
    margin-bottom: 10px;
    width: 24px;
    height: 24px;
`;

export const SkeletonMobile = styled.div`
    ${mediaQueries.smallDesktop} {
        display: none;
    }
`;

export const SkeletonMobileInner = styled(ContentBlock)`
    height: inherit;
`;

export const SkeletonDeskop = styled(ModalWrapper)`
    display: none;

    ${mediaQueries.smallDesktop} {
        display: flex;
        width: 100%;
    }
`;

export const SkeletonDeskopBlock = styled(Block)`
    margin-top: 0;
`;
