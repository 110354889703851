import { useNavigate } from 'react-router-dom';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import { PATHS } from 'constants/routerPaths';

import { sendAnalyticLogoClick } from 'services/analytics';

import { useSelector } from 'hooks';

import * as S from './styled';

import { ILogoProps } from './types';

const Logo = ({ withoutText, height }: ILogoProps) => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const navigate = useNavigate();

    const logoLink = isAuthenticated ? PATHS.MAIN : PATHS.LOGIN;

    const handleLoginClick = () => {
        sendAnalyticLogoClick();

        navigate(logoLink);
    };

    return (
        <S.LogoContainer onClick={handleLoginClick} to={logoLink} height={height}>
            <S.LogoImage />
            {!withoutText && <S.LogoText />}
        </S.LogoContainer>
    );
};

export default Logo;
