import { InputChangeEvent } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import * as S from './styled';

import { TextareaType } from 'types/ui';

const MIN_LENGTH = 2;
const MAX_LENGTH = 200;

const Textarea = ({
    value,
    error,
    field,
    setFieldValue,
    setIsValidate,
    placeholder,
    mb,
    isFocus,
    setIsFocus,
}: TextareaType) => {
    const { t } = useTranslation();

    const [isTouched, setIsTouched] = useState(false);

    const onChangeHandler = (inputEvent: InputChangeEvent) => {
        const inputValue = inputEvent.target.value.trimStart();

        const isValid = inputValue.length >= MIN_LENGTH && inputValue.length <= MAX_LENGTH;

        if (!isTouched) {
            setIsTouched(true);
        }

        setIsValidate(isValid);
        setFieldValue(inputValue);
    };

    const onBlurHandler = () => {
        if (!isTouched) {
            setIsTouched(true);
        }

        setIsFocus(false);
    };

    return (
        <S.StyledWrapper mb={mb}>
            <S.Textarea
                name={field}
                value={value}
                placeholder={placeholder && t(placeholder)}
                onBlur={onBlurHandler}
                onFocus={() => setIsFocus(true)}
                onChange={onChangeHandler}
                isFocus={isFocus}
            />
            {error && <div>{t(error as string)}</div>}
        </S.StyledWrapper>
    );
};

export default Textarea;
