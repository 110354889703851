import { Box, Image } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ChallengeContainer = styled(Box)`
    min-height: 100vh;
    padding-bottom: 80px;
`;

export const ChallengeContent = styled(Box)`
    min-height: 100vh;
    max-width: 828px;
    margin: 0 auto;
`;

export const BannerContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 828px;
    margin-bottom: 40px;

    ${mediaQueriesGrid.largeTablet} {
        margin-bottom: 120px;
    }
`;

export const Banner = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 32px;
    background: ${color('rating')};
    height: 320px;
`;

export const BannerBG = styled(Image)`
    position: absolute;
    border-radius: 32px;
`;

export const BannerContent = styled(Box)`
    display: flex;
    padding: 0 20px 20px;
    flex-direction: column;
    gap: 24px;
    z-index: 1;
    width: 100%;
`;

export const StatusLabel = styled.div`
    display: flex;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-radius: 100px;
    background: ${color('surface-secondary')};
`;

export const LinksWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;

    ${mediaQueriesGrid.largeTablet} {
        flex-direction: row;
        gap: 32px;
    }
`;

export const Link = styled.div`
    display: flex;
    cursor: pointer;
    padding: 8px 0;
`;
