import styled from 'styled-components';

import { color } from 'theme/selectors';

export const RatingContainer = styled.div`
    display: flex;
    padding: 16px 12px 12px;
    border: 2px solid ${color('border-subdued')};
    border-radius: 20px;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const RatingItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 138px;
    padding: 0 4px;
`;
