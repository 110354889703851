import { IButton } from 'wikr-core-components';

import * as S from './styled';

const SecondaryDefaultButton = ({
    text,
    onClick,
    dataLocator,
    withPulsating,
    mb,
    disabled,
    isLoading,
    restProps,
    className,
}: IButton) => {
    return (
        <S.SecondaryDefaultButton
            text={text}
            onClick={onClick}
            withPulsating={withPulsating}
            mb={mb}
            dataLocator={dataLocator}
            disabled={disabled}
            isLoading={isLoading}
            restProps={restProps}
            className={className}
        />
    );
};

export default SecondaryDefaultButton;
