import React from 'react';

import Skeleton from 'components/Skeleton';

import { TITLE_WIDTH, TITLE_HEIGHT, GUIDE_HEIGHT } from './contsants';

const GuidesSkeleton = () => (
    <>
        <Skeleton width={TITLE_WIDTH} height={TITLE_HEIGHT} mb={8} />
        <Skeleton height={GUIDE_HEIGHT} mb={12} />
        <Skeleton height={GUIDE_HEIGHT} mb={12} />
    </>
);

export default GuidesSkeleton;
