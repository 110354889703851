import { Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const StepsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Step = styled.section`
    padding: 12px;
    background: ${color('on-primary')};
    border-radius: 12px;
    border: 1px solid ${color('border-default')};
`;

export const StepHeader = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 10px;

    ${mediaQueries.laptop} {
        margin-bottom: 16px;
    }
`;

export const StepNumber = styled(Text)`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${color('secondary-default')};
    border-radius: 50%;
    flex-shrink: 0;
`;

export const StepImage = styled.img`
    max-width: 100%;
`;

export const StepsFooter = styled.div`
    margin-top: 24px;
    text-align: center;
`;
