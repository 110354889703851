import { Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';

const Pause = ({ theme }: { theme: Theme }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.14445" cy="7.93742" r="5.90484" stroke={theme['text-main']} strokeWidth="1.28" />
            <rect x="5.94922" y="5.28906" width="1.271" height="5.42212" rx="0.635498" fill={theme['text-main']} />
            <rect x="9.06934" y="5.28906" width="1.271" height="5.42212" rx="0.635498" fill={theme['text-main']} />
        </svg>
    );
};

export default withTheme(Pause);
