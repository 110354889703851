import * as actionTypes from './actionTypes';

import { UserWeightStatistic } from 'types/user/userApiInterface';

export const fetchWeightStatistic = (fromDate: string) => {
    return {
        type: actionTypes.FETCH_WEIGHT_STATISTIC,
        payload: { fromDate },
    } as const;
};

export const setWeightStatistic = (weightStatistic: UserWeightStatistic[]) => {
    return {
        type: actionTypes.SET_WEIGHT_STATISTIC,
        payload: weightStatistic,
    } as const;
};

export const setWeightStatisticStatus = (status: boolean) => {
    return {
        type: actionTypes.SET_WEIGHT_STATISTIC_STATUS,
        payload: status,
    } as const;
};
