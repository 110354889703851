import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

export const Container = styled(Box)`
    margin: 32px auto 0;
    max-width: 432px;
    padding: 0 16px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const BtnTitleWrapper = styled(Text)`
    padding-top: 48px;
`;
