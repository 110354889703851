/* eslint-disable max-lines */
import { isAppLibraryWebview, isChallenge75, isFlatBelly, isPrivate } from 'router/validators/validators';

import { PATHS } from 'constants/routerPaths';

import WorkoutsLibrary from 'pages/workoutsLibrary';
import WorkoutPreview from 'pages/workoutPreview';
import WorkoutPlayer from 'pages/workoutPlayer';
import Welcome from 'pages/welcome';
import MealPlan from 'pages/web-view-meal-plan';
import Library from 'pages/web-view-library';
import MyFastingPlan from 'pages/my-fasting-plan';
import { Login } from 'pages/login';
import { InstallApp } from 'pages/install-app';
import { Guides } from 'pages/guides';
import { AuthorisedForgotPassword } from 'pages/forgot-password-a';
import { ForgotPassword } from 'pages/forgot-password';
import FlatBelly from 'pages/flat-belly';
import { FastingProtocol } from 'pages/fasting-protocol';
import { EmailConfirmation } from 'pages/email-confirmation';
import { Dashboard } from 'pages/dashboard';
import { UnauthorizedChangePassword } from 'pages/change-password-un';
import { ChangePassword } from 'pages/change-password';
import { ChangeEmail } from 'pages/change-email';
import Challenge from 'pages/challenge';
import BodyStatus from 'pages/body-status';
import { AccountSettingsPage } from 'pages/account';

import { ErrorPageContent } from 'components/ErrorPage/ErrorPageContent';

import { RouteList } from './types';

import { AppWebviewLayout, AuthLayout, CabinetLayout } from './layouts';

// import { InsightsMain } from 'pages/insights-main/InsightsMain';
// import InsightsCategory from 'pages/insights-category';

const PUBLIC_COMMON = {
    redirectPath: PATHS.MAIN,
    validators: [() => !isPrivate(), () => !isAppLibraryWebview()],
};

const PRIVATE_COMMON = {
    redirectPath: PATHS.LOGIN,
    validators: [() => isPrivate(), () => !isAppLibraryWebview()],
};

const PRIVATE_WORKOUTS_COMMON = {
    redirectPath: PATHS.LOGIN,
    validators: [() => isPrivate()],
};

const PRIVATE_CHALLENGE_75_COMMON = {
    redirectPath: PATHS.LOGIN,
    validators: [() => isPrivate(), () => isChallenge75()],
};

const PRIVATE_FLAT_BELLY_COMMON = {
    redirectPath: PATHS.LOGIN,
    validators: [() => isPrivate(), () => isFlatBelly()],
};

export const PUBLIC: RouteList = {
    LOGIN: {
        path: PATHS.LOGIN,
        component: Login,
        layout: AuthLayout,
        titleKey: 'pagesTitles.login',
        ...PUBLIC_COMMON,
    },
    FORGOT_PASSWORD: {
        path: PATHS.FORGOT_PASSWORD,
        component: ForgotPassword,
        layout: AuthLayout,
        titleKey: 'pagesTitles.forgotPassword',
        ...PUBLIC_COMMON,
    },
    CHANGE_PASSWORD_UN: {
        path: PATHS.UNAUTHORIZED_CHANGE_PASSWORD,
        component: UnauthorizedChangePassword,
        layout: AuthLayout,
        titleKey: 'pagesTitles.unauthorizedChangePassword',
        ...PUBLIC_COMMON,
    },
    EMAIL_CONFIRMATION: {
        path: PATHS.EMAIL_CONFIRMATION,
        component: EmailConfirmation,
        layout: AuthLayout,
        titleKey: 'pagesTitles.emailConfirmation',
        ...PUBLIC_COMMON,
    },
    ERROR_PAGE: {
        path: PATHS.ERROR_PAGE,
        component: ErrorPageContent,
        layout: AppWebviewLayout,
        titleKey: 'pagesTitles.errorPage',
        ...PUBLIC_COMMON,
    },
};

export const PRIVATE: RouteList = {
    ACCOUNT: {
        path: PATHS.ACCOUNT,
        component: AccountSettingsPage,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.account',
        ...PRIVATE_COMMON,
    },
    ACCOUNT_DEFAULT: {
        path: PATHS.ACCOUNT_DEFAULT,
        component: AccountSettingsPage,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.account',
        ...PRIVATE_COMMON,
    },
    CHANGE_EMAIL: {
        path: PATHS.CHANGE_EMAIL,
        component: ChangeEmail,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.changeEmail',
        ...PRIVATE_COMMON,
    },
    CHANGE_PASSWORD: {
        path: PATHS.CHANGE_PASSWORD,
        component: ChangePassword,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.changePassword',
        ...PRIVATE_COMMON,
    },
    AUTHORISED_FORGOT_PASSWORD: {
        path: PATHS.AUTHORISED_FORGOT_PASSWORD,
        component: AuthorisedForgotPassword,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.forgotPassword',
        ...PRIVATE_COMMON,
    },
    MAIN: {
        path: PATHS.MAIN,
        component: InstallApp,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.installApp',
        ...PRIVATE_COMMON,
    },
    FITNESS_GUIDES: {
        path: PATHS.FITNESS_GUIDES,
        component: Guides,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.guides',
        ...PRIVATE_COMMON,
    },
    // TODO:this is tem solution, we don't need to delete this code
    // INSIGHTS_MAIN: {
    //     path: '/insights-main',
    //     component: InsightsMain,
    // layout: CabinetLayout,
    //     titleKey: 'pagesTitles.insights',
    // ...PRIVATE_COMMON,
    // },
    // INSIGHTS_MAIN_ITEM: {
    //     path: '/insights-main/:categoryId/:blockId',
    //     component: InsightsMain,
    // layout: CabinetLayout,
    //     titleKey: 'pagesTitles.insights',
    // ...PRIVATE_COMMON,
    // },
    // INSIGHTS_CATEGORY: {
    //     path: '/insights-category/:categoryId',
    //     component: InsightsCategory,
    // layout: CabinetLayout,
    //     titleKey: 'pagesTitles.insights',
    // ...PRIVATE_COMMON,
    // },
    // INSIGHTS_CATEGORY_BLOCK: {
    //     path: '/insights-category/:categoryId/:blockId',
    //     component: InsightsCategory,
    // layout: CabinetLayout,
    //     titleKey: 'pagesTitles.insights',
    // ...PRIVATE_COMMON,
    // },
    MY_FASTING_PLAN: {
        path: PATHS.MY_FASTING_PLAN,
        component: MyFastingPlan,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.fastingPlan',
        ...PRIVATE_COMMON,
        validators: [],
        redirectPath: PATHS.DASHBOARD,
    },
    WELCOME: {
        path: PATHS.WELCOME,
        component: Welcome,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.welcome', // TODO: needs clarification about tab title
        ...PRIVATE_COMMON,
    },
    DASHBOARD: {
        path: PATHS.DASHBOARD,
        component: Dashboard,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.dashboard',
        ...PRIVATE_COMMON,
    },
    DASHBOARD_ONBOARDING_1: {
        path: PATHS.DASHBOARD_ONBOARDING_1,
        component: Dashboard,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.dashboard',
        ...PRIVATE_COMMON,
    },
    DASHBOARD_ONBOARDING_2: {
        path: PATHS.DASHBOARD_ONBOARDING_2,
        component: Dashboard,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.dashboard',
        ...PRIVATE_COMMON,
    },
    DASHBOARD_ITEM: {
        path: PATHS.DASHBOARD_ITEM,
        component: Dashboard,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.dashboard',
        ...PRIVATE_COMMON,
    },
    BODY_STATUS: {
        path: PATHS.BODY_STATUS,
        component: BodyStatus,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.bodyStatus',
        ...PRIVATE_COMMON,
    },
    FASTING_PROTOCOL: {
        path: PATHS.FASTING_PROTOCOL,
        component: FastingProtocol,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.fastingProtocol',
        ...PRIVATE_COMMON,
    },
    FASTING_PROTOCOL_ITEM: {
        path: PATHS.FASTING_PROTOCOL_ITEM,
        component: FastingProtocol,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.fastingProtocol',
        ...PRIVATE_COMMON,
    },
    WORKOUTS: {
        path: PATHS.WORKOUTS,
        component: WorkoutsLibrary,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.workouts',
        ...PRIVATE_WORKOUTS_COMMON,
    },
    WORKOUTS_PREVIEW: {
        path: PATHS.WORKOUTS_PREVIEW,
        component: WorkoutPreview,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.workoutPreview',
        ...PRIVATE_WORKOUTS_COMMON,
    },
    WORKOUTS_PLAYER: {
        path: PATHS.WORKOUTS_PLAYER,
        component: WorkoutPlayer,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.workoutPlayer',
        ...PRIVATE_WORKOUTS_COMMON,
    },
    WEB_VIEW_LIBRARY: {
        path: PATHS.LIBRARY,
        component: Library,
        layout: AppWebviewLayout,
        titleKey: 'basics.library',
        redirectPath: PATHS.LOGIN,
        validators: [() => isPrivate(), () => isAppLibraryWebview()],
    },
    WEB_VIEW_MEAL_PLAN: {
        path: PATHS.MEAL_PLAN,
        component: MealPlan,
        layout: AppWebviewLayout,
        titleKey: 'pagesTitles.workoutPlayer',
        redirectPath: PATHS.LOGIN,
        validators: [() => isPrivate(), () => isAppLibraryWebview()],
    },
    CHALLENGE: {
        path: PATHS.CHALLENGE,
        component: Challenge,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.challenge',
        ...PRIVATE_CHALLENGE_75_COMMON,
    },
    FLAT_BELLY: {
        path: PATHS.FLAT_BELLY,
        component: FlatBelly,
        layout: CabinetLayout,
        titleKey: 'pagesTitles.flatBelly',
        ...PRIVATE_FLAT_BELLY_COMMON,
    },
};

export const ROUTES = { ...PUBLIC, ...PRIVATE };

export default {
    PRIVATE,
    PUBLIC,
};
