import React from 'react';

import { SKELETON_INSIGHT_FIRST_COLOR, SKELETON_INSIGHT_SECOND_COLOR } from 'constants/theme';

import * as S from './styled';

function SkeletonOneDay() {
    return (
        <>
            <S.SkeletonOneDayCircle
                foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                animate
            >
                <rect x="0" y="0" width="100%" height="100%" />
            </S.SkeletonOneDayCircle>
            <S.SkeletonOneDayText
                foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                animate
            >
                <rect x="0" y="0" width="100%" height="100%" />
            </S.SkeletonOneDayText>
        </>
    );
}

export default SkeletonOneDay;
