export const PRODUCT_CODES = {
    APP_FULL_ACCESS: 'app_full_access',
    APP_VIP_SUPPORT: 'vip_support',
    APP_GUIDE_WEIGHT_LOSS: 'weight_loss_gd',
    APP_GUIDE_FAT_BURNING: 'fat_burning_gd',
    APP_GUIDE_ALL: 'fat_burning_gd__weight_loss_gd',
    APP_GUIDE_WEIGHT_LOSS_FULL: 'weight_loss_gd_full',
    APP_GUIDE_FAT_BURNING_FULL: 'fat_burning_gd_full',
    APP_GUIDE_ALL_FULL: 'fat_burning_gd__weight_loss_gd_full',
    APP_MEAL_FATBURN: 'meal_fatburn',
    APP_MEAL: 'meal',
    APP_FATBURN: 'fatburn',
    APP_RECIPE_LIBRARY: 'recipe_library',
    APP_ALL_COURSES: 'all_courses',
    APP_MEAL_FATBURN_GUIDES: 'meal_fatburn_guides_bundle',
};
