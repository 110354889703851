export const reasons = [
    {
        title: 'subscription.cancellationReason.forgetToCancelLater',
        shortKey: 'forget_to_cancel_later',
    },
    {
        title: 'subscription.cancellationReason.tooExpensive2',
        shortKey: 'too_expensive',
    },
    {
        title: 'subscription.cancellationReason.noTime',
        shortKey: 'no_time',
    },
    {
        title: 'subscription.cancellationReason.noAppNeed2',
        shortKey: 'no_app_need',
    },
    {
        title: 'subscription.cancellationReason.disappointed',
        shortKey: 'disappointed_with_the_content',
    },
    {
        title: 'subscription.cancellationReason.notMeetsExpectations2',
        shortKey: 'app_not_meets_expectations',
    },
    {
        title: 'subscription.cancellationReason.other',
        shortKey: 'other',
    },
];

export const PRESELECTED_REASON = 'too_expensive';
