import { Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { CONTENT_3, CONTENT_4, CONTENT_6, CONTENT_7 } from 'constants/customContentColors';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

const Card = css`
    position: absolute;
    border: 2px solid ${color('surface-main')};
    border-radius: 16px;
`;

export const CardsStack = styled.div`
    min-height: 84px;
    flex: 0;

    ${mediaQueriesGrid.tablet} {
        min-width: 160px;
    }
`;

export const Cards = styled.div`
    position: relative;
    min-width: 239px;

    ${mediaQueriesGrid.tablet} {
        min-height: 84px;
        min-width: 160px;
    }
`;

export const Card1 = styled.div`
    ${Card};
    top: 0;
    left: 12px;
    opacity: 0.5;
    z-index: 0;
    width: 215px;
    height: 44px;
    background: ${CONTENT_4};

    ${mediaQueriesGrid.tablet} {
        left: 45px;
        width: 113px;
        height: 68px;
        top: 10px;
    }
`;

export const Card2 = styled.div`
    ${Card};
    top: 10px;
    left: 8px;
    z-index: 1;
    width: 223px;
    height: 44px;
    background: ${CONTENT_3};

    ${mediaQueriesGrid.tablet} {
        left: 30px;
        width: 115px;
        height: 76px;
        top: 6px;
    }
`;

export const Card3 = styled.div`
    ${Card};
    top: 20px;
    left: 4px;
    z-index: 2;
    width: 231px;
    height: 44px;
    background: ${CONTENT_6};

    ${mediaQueriesGrid.tablet} {
        left: 15px;
        width: 117px;
        height: 80px;
        top: 3px;
    }
`;

export const Card4 = styled.div`
    ${Card};
    top: 30px;
    z-index: 3;
    width: 239px;
    height: 56px;
    background: ${CONTENT_7};

    ${mediaQueriesGrid.tablet} {
        left: 0;
        width: 117px;
        height: 84px;
        top: 1px;
    }
`;

export const CardsLabel = styled(Text)`
    position: absolute;
    z-index: 4;
    top: 50px;
    left: 20px;

    ${mediaQueriesGrid.tablet} {
        top: 57px;
        left: 12px;
    }
`;
