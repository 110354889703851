import { Text, Box, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState, memo } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';
import { reasons, PRESELECTED_REASON } from './constants';

import { sendAnalyticCancelReasonContinueClick } from 'services/analytics';

import SubscriptionCancelledBanner from 'components/SubscriptionCancelledBanner';
import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import loader from 'assets/img/subscriptionIcons/loader.svg';

export interface ICaliforniaCancellationReasonModal {
    isLoading: boolean;
    onSubmit: (reason: string) => void;
    onClose: () => void;
}

const CaliforniaCancellationReasonModal = ({ isLoading, onSubmit, onClose }: ICaliforniaCancellationReasonModal) => {
    const { t } = useTranslation();

    const [reason, setReason] = useState(PRESELECTED_REASON);

    const handleSelectReason = (shortKey: string) => () => setReason(shortKey);

    const handleCancel = () => {
        if (reason) {
            sendAnalyticCancelReasonContinueClick(reason);

            onSubmit(reason);
        }
    };

    return (
        <Modal isOpen onClose={onClose} fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar isFirstModal />
            <S.ReasonContainer dataLocator="cancelSubscriptionModalContainer">
                {isLoading && (
                    <S.LoaderContainer>
                        <Image maxWidth={24} center alt="loader" src={loader} mb={24} isForceVisible />
                    </S.LoaderContainer>
                )}
                <SubscriptionCancelledBanner />
                <Text type="h5" center mb={24} text={t('subscription.cancellationReason.keyReason')} />
                <Box mb={32}>
                    {reasons.map(({ title, shortKey }) => (
                        <S.ReasonItem
                            key={shortKey}
                            onClick={handleSelectReason(shortKey)}
                            isSelected={shortKey === reason}
                            data-locator="option"
                        >
                            <S.RadioBtn isSelected={shortKey === reason} />
                            <Text type="large-text" medium text={t(title)} />
                        </S.ReasonItem>
                    ))}
                </Box>

                <SecondaryDefaultButton
                    disabled={!reason}
                    onClick={handleCancel}
                    mb={20}
                    text={t('basics.continue')}
                    dataLocator="continueButton"
                />
            </S.ReasonContainer>
        </Modal>
    );
};

export default memo(CaliforniaCancellationReasonModal);
