import { Image } from 'wikr-core-components';

import iconYes from './img/iconYes.svg';
import iconNo from './img/iconNo.svg';

export const HELPFUL_ANSWERS = [
    {
        svg: () => <Image maxWidth={40} isForceVisible alt="yes" src={iconYes} />,
        value: 'Yes',
        text: 'welcome.wasThisHelpful.yes',
        dataLocator: 'likeButton',
    },
    {
        svg: () => <Image maxWidth={40} isForceVisible alt="no" src={iconNo} />,
        value: 'No',
        text: 'welcome.wasThisHelpful.no',
        dataLocator: 'dislikeButton',
    },
];
