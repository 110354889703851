import { useNavigate, useParams, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';

import { PATHS } from 'constants/routerPaths';

import { trackScreenLoad } from 'services/analytics';

import PrevButtonInsights from 'components/PrevButtonInsights';
import NextButtonInsights from 'components/NextButtonInsights';
import ButtonWithArrow from 'components/ButtonWithArrow';
import TabBar from './subcomponents/TabBar';
import Points from './subcomponents/Points';
import Header from './subcomponents/Header';
import Description from './subcomponents/Description';

import * as S from './styled';

import { getContent } from './config';

const FIRST_STATUS_KEY = '1';
const LAST_STATUS_KEY = '6';

const BodyStatus = () => {
    const navigate = useNavigate();
    const params: { statusId?: string } = useParams();

    useEffect(() => {
        trackScreenLoad();
    }, [params.statusId]);

    const { title, benefitLabel, howToMaxLabel, exercisesLabel, statusIndLabel, statusIndIcon, statuses } =
        getContent();

    const handleBackClick = () => {
        navigate(PATHS.DASHBOARD);
    };

    const handleStatusChange = (statusId: number) => {
        const path = `/dashboard/body-status/${statusId}`;

        navigate(path);
    };

    const curStatus = statuses[params.statusId as keyof typeof statuses];

    return curStatus ? (
        <S.MainContainer>
            <S.InnerContainer>
                <S.TitleBox paddingTop={26} paddingBottom={22}>
                    <ButtonWithArrow onClick={handleBackClick} backArrow dataLocator="backButton" />
                    <S.Title text={title} medium type="h4" dataLocator="pageTitle" />
                </S.TitleBox>
                <S.ContentBox>
                    <TabBar activeId={Number(params.statusId)} />
                    <Header
                        title={curStatus.title}
                        icon={curStatus.icon}
                        statusIndLabel={statusIndLabel}
                        statusIndIcon={statusIndIcon}
                    />
                    <Description hours={curStatus.hours} meaning={curStatus.meaning} />
                    <Points
                        benefit={{
                            label: benefitLabel,
                            value: curStatus.benefit,
                        }}
                        howToMax={{
                            label: howToMaxLabel,
                            value: curStatus.howToMax,
                        }}
                        exercises={{
                            label: exercisesLabel,
                            value: curStatus.exercises,
                        }}
                    />
                </S.ContentBox>
                <S.ButtonsBox>
                    <S.PreviewNavigation>
                        <S.ButtonWrapper>
                            <PrevButtonInsights
                                disabled={params.statusId === FIRST_STATUS_KEY}
                                onClick={() => handleStatusChange(Number(params.statusId) - 1)}
                                dataLocator="previousStageButton"
                            />
                        </S.ButtonWrapper>
                        <S.ButtonWrapper>
                            <NextButtonInsights
                                disabled={params.statusId === LAST_STATUS_KEY}
                                onClick={() => handleStatusChange(Number(params.statusId) + 1)}
                                dataLocator="nextStageButton"
                            />
                        </S.ButtonWrapper>
                    </S.PreviewNavigation>
                </S.ButtonsBox>
            </S.InnerContainer>
        </S.MainContainer>
    ) : (
        <Navigate to={PATHS.DASHBOARD} />
    );
};

export default BodyStatus;
