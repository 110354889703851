import { useSelector } from 'react-redux';
import { useState } from 'react';

import api from 'api';

import { selectGender } from 'store/user/selectors';

import { getWindowSize } from 'helpers/getWindowSize';

import { IWorkout, IWorkoutsData } from 'types/workouts/workoutsApiInterface';

const useFetchWorkouts = () => {
    const gender = useSelector(selectGender);

    const [isLoadingWorkouts, setIsLoadingWorkouts] = useState(true);
    const [isLoadingMoreWorkouts, setIsLoadingMoreWorkouts] = useState(false);
    const [localWorkouts, setLocalWorkouts] = useState<IWorkout[] | null>(null);
    const [pagesAmount, setPagesAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const isTablet = getWindowSize(600);
    const workoutsPerLoad = isTablet ? 18 : 10;

    const fetchWorkouts = async (filters: Record<string, string[]>, isShowMore?: boolean) => {
        isShowMore ? setIsLoadingMoreWorkouts(true) : setIsLoadingWorkouts(true);

        const data: Partial<IWorkoutsData> = await api.workouts.getWorkouts({
            page: isShowMore ? currentPage + 1 : 1,
            per_page: workoutsPerLoad,
            gender: gender || 'female',
            ...(filters.level?.length && { fitness_level: filters.level }),
            ...(filters.body_zone?.length && { body_zone: filters.body_zone }),
            ...(filters.type?.length && { workout_type: filters.type }),
        });

        const savedWorkouts = localWorkouts || [];
        const newWorkouts = data.workouts || [];
        const updatedWorkouts = isShowMore ? [...savedWorkouts, ...newWorkouts] : newWorkouts;

        setPagesAmount(data.pages || 0);
        setLocalWorkouts(updatedWorkouts);
        setCurrentPage(data.page || 1);
        isShowMore ? setIsLoadingMoreWorkouts(false) : setIsLoadingWorkouts(false);
    };

    return {
        fetchWorkouts,
        isLoadingWorkouts,
        isLoadingMoreWorkouts,
        pagesAmount,
        localWorkouts,
        currentPage,
        isLastPage: pagesAmount === currentPage,
    };
};

export default useFetchWorkouts;
