import * as action from './actions';

import * as actionTypes from './actionTypes';

import { InferValueTypes } from 'types/commonTypes';
import { IChallenge75 } from 'types/challenge75';

export type Challenge75Store = IChallenge75[] | null;

const initialState: Challenge75Store = null;

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const challenge75Reducer = (state = initialState, action: ActionType): Challenge75Store => {
    switch (action.type) {
        case actionTypes.SET_CHALLENGE_75:
            return action.payload;

        default:
            return state;
    }
};

export default challenge75Reducer;
