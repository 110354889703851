import { css } from 'styled-components';

const reset = css`
    /* prettier-ignore */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
        box-sizing: border-box;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }

    /* prettier-ignore */

    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
        overscroll-behavior: none;
    }

    /* prettier-ignore */

    blockquote, q {
        quotes: none;
    }

    /* prettier-ignore */

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    button {
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    input {
        border: 0;
        border-radius: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        margin: 0;
        padding: 0;
        outline: none;
    }

    * {
        box-sizing: border-box;
        font-family: 'Inter', sans-serif !important;
    }

    p[type='h1'],
    p[type='h2'],
    p[type='h3'],
    p[type='h4'],
    p[type='h5'],
    p[type='h6'] {
        font-family: 'Sora', sans-serif !important;
    }

    /* prettier-ignore */

    ul, ol {
        list-style: none;
    }
`;

export default reset;
