import { ComponentProps } from 'react';

import { ModalName, ModalsMapType } from 'store/modals/types';

import WaitingModalContainer from 'components/ModalContainers/WaitingModalContainer';
import TooExpensiveModalContainer from 'components/ModalContainers/TooExpensiveModal';
import ThankYouModalContainer from 'components/ModalContainers/ThankYouModalContainer';
import SurveyThankYouModalContainer from 'components/ModalContainers/SurveyThankYouModalContainer';
import SurveyModalContainer from 'components/ModalContainers/SurveyModalContainer';
import SuccessFeedbackModalContainer from 'components/ModalContainers/SuccessFeedbackModalContainer';
import SpecialOfferModalContainer from 'components/ModalContainers/SpecialOfferModalContainer';
import SecondSubscriptionCancelModalContainer from 'components/ModalContainers/SecondSubscriptionCancelModalContainer/SecondSubscriptionCancelModalContainer';
import ReturnOfferModalContainer from 'components/ModalContainers/ReturnOfferModalContainer';
import ReturnOfferCRMModalContainer from 'components/ModalContainers/ReturnOfferCRMModalContainer';
import RestoringSubscriptionModalContainer from 'components/ModalContainers/RestoringSubscriptionModalContainer';
import ReminderOfferModalContainer from 'components/ModalContainers/ReminderOfferModalContainer';
import ProgressModalContainer from 'components/ModalContainers/ProgressModalContainer';
import ProductProposeContainer from 'components/ModalContainers/ProductProposeContainer';
import PremiumFeedbackModalContainer from 'components/ModalContainers/PremiumFeedbackModalContainer';
import PauseOfferModalContainer from 'components/ModalContainers/PauseOfferModalContainer';
import NoLongerNeedModalContainer from 'components/ModalContainers/NoLongerNeedModal';
import ModalWithLoaderContainer from 'components/ModalContainers/ModalWithLoaderContainer';
import LifeTimeAccessQuestionContainer from 'components/ModalContainers/LifeTimeAccessQuestionContainer';
import LifeTimeAccessFinalModalContainer from 'components/ModalContainers/LifeTimeAccessFinalModalContainer';
import InterviewModalContainer from 'components/ModalContainers/IterviewModalContainer';
import GuidesOfferContainer from 'components/ModalContainers/GuidesOfferContainer';
import FreeMonthModalContainer from 'components/ModalContainers/FreeMonthModalContainer';
import ErrorFeedbackModalContainer from 'components/ModalContainers/ErrorFeedbackModalContainer';
import CheckOurAppsContainer from 'components/ModalContainers/CheckOurAppsContainer';
import ChallengeRulesModalContainer from 'components/ModalContainers/ChallengeRulesModalContainer';
import ChallengeMoreModalContainer from 'components/ModalContainers/ChallengeMoreModalContainer';
import CancellationSuccessModalContainer from 'components/ModalContainers/CancellationSuccessModalContainer';
import CancellationReasonModalContainer from 'components/ModalContainers/CancellationReasonModalContainer';
import CaliforniaCancellationReasonModalContainer from 'components/ModalContainers/CaliforniaCancellationReasonModalContainer';
import AlertModalContainer from 'components/ModalContainers/AlertModalContainer';
import AdditionalQuestionContainer from 'components/ModalContainers/AdditionalQuestionContainer';

export const MODAL_LIST: ModalsMapType = {
    [ModalName.alertModal]: AlertModalContainer,
    [ModalName.reasonModal]: CancellationReasonModalContainer,
    [ModalName.progressModal]: ProgressModalContainer,
    [ModalName.specialOfferModal]: SpecialOfferModalContainer,
    [ModalName.waitingModal]: WaitingModalContainer,
    [ModalName.productPropose]: ProductProposeContainer,
    [ModalName.checkOurApps]: CheckOurAppsContainer,
    [ModalName.survey]: SurveyModalContainer,
    [ModalName.surveyThankYou]: SurveyThankYouModalContainer,
    [ModalName.lifeTimeAccessQuestion]: LifeTimeAccessQuestionContainer,
    [ModalName.lifeTimeAccessFinal]: LifeTimeAccessFinalModalContainer,
    [ModalName.additionalQuestion]: AdditionalQuestionContainer,
    [ModalName.successFeedbackModal]: SuccessFeedbackModalContainer,
    [ModalName.errorFeedbackModal]: ErrorFeedbackModalContainer,
    [ModalName.premiumFeedbackModal]: PremiumFeedbackModalContainer,
    [ModalName.secondSubscriptionCancelModal]: SecondSubscriptionCancelModalContainer,
    [ModalName.withLoaderModal]: ModalWithLoaderContainer,
    [ModalName.freeMonthOffer]: FreeMonthModalContainer,
    [ModalName.guidesOfferModal]: GuidesOfferContainer,
    [ModalName.reminderOffer]: ReminderOfferModalContainer,
    [ModalName.pauseModal]: PauseOfferModalContainer,
    [ModalName.californiaReasonModal]: CaliforniaCancellationReasonModalContainer,
    [ModalName.returnOfferModal]: ReturnOfferModalContainer,
    [ModalName.returnOfferCRMModal]: ReturnOfferCRMModalContainer,
    [ModalName.restoringSubscriptionModal]: RestoringSubscriptionModalContainer,
    [ModalName.challengeRulesModal]: ChallengeRulesModalContainer,
    [ModalName.challengeMoreModal]: ChallengeMoreModalContainer,
    [ModalName.thankYouModal]: ThankYouModalContainer,
    [ModalName.interviewModal]: InterviewModalContainer,
    [ModalName.cancellationSuccessModal]: CancellationSuccessModalContainer,
    [ModalName.noLongerNeedModal]: NoLongerNeedModalContainer,
    [ModalName.tooExpensiveModalModal]: TooExpensiveModalContainer,
};
