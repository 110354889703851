import { useEffect, useState } from 'react';

import api from 'api';

import { WorkoutDetails } from 'types/workouts/workoutsApiInterface';

const useFetchWorkoutDetails = (workoutId?: string) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown>();
    const [data, setData] = useState<Partial<WorkoutDetails>>();

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
                if (!workoutId) {
                    setError('workoutId is not defined');

                    return;
                }

                const response = workoutId && (await api.workouts.getWorkoutDetails(workoutId));

                setData({ ...response });
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return {
        isLoading,
        error,
        data,
    };
};

export default useFetchWorkoutDetails;
