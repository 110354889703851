import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import RatingBlock from './components/RatingBlock';
import ProgramsBlock from './components/ProgramsBlock';
import ProgramProfitBlock from './components/ProgramProfitBlock';
import AppPreviewBlock from './components/AppPreviewBlock';

import * as S from './styled';

import arrowCircle from 'assets/img/arrowCircle.svg';

import { IProductCard } from './types';

interface ProductCardProps extends IProductCard {
    onContinue: (projectName: string) => void;
    onExpand: (projectName: string) => void;
}

const ProductCard = ({
    header,
    rating,
    appPreviewData,
    programs,
    programsDisclaimer,
    profits,
    onContinue,
    onExpand,
}: ProductCardProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const onClickHandler = () => {
        setIsOpen((prev) => !prev);

        if (!isOpen) {
            onExpand(header.projectName);
        }
    };

    return (
        <S.Container backgroundColor="surface-main" borderRadius={16}>
            <S.HeaderWrapper>
                <S.LogoTextWrapper>
                    <Image alt="logo" src={header.logo} maxWidth={40} />
                    <div>
                        <Text text={header.projectName} type="h6" />
                        <Text text={header.title} type="small-text" medium />
                    </div>
                </S.LogoTextWrapper>
                <S.ArrowButton onClick={onClickHandler} isActive={isOpen}>
                    <S.ArrowWrapper alt="arrow" src={arrowCircle} maxWidth={32} isActive={isOpen} />
                </S.ArrowButton>
            </S.HeaderWrapper>
            <S.AccordionContainer isActive={isOpen}>
                <RatingBlock rating={rating} />
                <AppPreviewBlock image={appPreviewData.img} title={appPreviewData.title} />
                <ProgramsBlock programs={programs} />
                {programsDisclaimer && <Text text={programsDisclaimer} type="small-text" center mb={24} />}
                <ProgramProfitBlock profits={profits} />
            </S.AccordionContainer>
            <S.GetAppButton
                text={t('subscription.cancellation.checkOurApps.button')}
                backgroundColor="secondary-default"
                hoverColor="secondary-hovered"
                onClick={() => onContinue(header.projectName)}
            />
        </S.Container>
    );
};

export default ProductCard;
