import { Text, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { sendAnalyticDownloadChallengeClick } from 'services/analytics/trackers/challenge75';

import { getWindowSize } from 'helpers/getWindowSize';

import { Download } from 'components/Icon';

import * as S from './styled';

import alarmIcon from './img/alarm.webp';

import { IChallengeLevel } from '../../types';

const isBigScreen = getWindowSize(600);

const ChallengeLevelCard = (challengeLevel: IChallengeLevel) => {
    const { t } = useTranslation();

    const handleDownloadClick = (programName: string) => {
        sendAnalyticDownloadChallengeClick(programName);
    };

    return (
        <S.LevelCard data-locator="challengeContainer">
            <S.LevelGuide isActive={challengeLevel.active}>
                <S.Cover alt="cover" src={challengeLevel.image} maxWidth={180} />
                <S.LevelGuideTitle>
                    <Text
                        dataLocator="challengeTitle"
                        type="h6"
                        medium
                        text={t(challengeLevel.title)}
                        mb={isBigScreen ? 12 : 8}
                    />
                    <Text
                        dataLocator="challengeSubtitle"
                        type="large-text"
                        text={t(challengeLevel.description)}
                        mb={isBigScreen ? 32 : 20}
                    />
                    {challengeLevel.active ? (
                        <S.DownloadProgramLink
                            onClick={() => handleDownloadClick(challengeLevel.title)}
                            href={challengeLevel.challenge_url}
                            rel="noopener noreferrer"
                            target="_blank"
                            data-locator="challengeDownloadButton"
                        >
                            <Text type="small-button" color="on-primary" text={t('basics.downloadProgram')} />
                            <Download color="on-primary" />
                        </S.DownloadProgramLink>
                    ) : (
                        <S.AvailableSoonLabel data-locator="challengeAvailableSoonStatus">
                            <Image alt="icon" src={alarmIcon} maxWidth={20} />
                            <Text medium text={t('basics.availableSoon')} />
                        </S.AvailableSoonLabel>
                    )}
                </S.LevelGuideTitle>
            </S.LevelGuide>
        </S.LevelCard>
    );
};

export default ChallengeLevelCard;
