import { Image } from 'wikr-core-components';
import Slider from 'react-slick';

import * as S from './styled';

const SLIDER_SETTINGS = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: false,
    arrows: false,
    draggable: false,
    swipe: false,
    pauseOnHover: false,
    accessibility: false,
    touchMove: false,

    // eslint-disable-next-line react/display-name
    customPaging: () => (
        <div>
            <S.SliderDot data-locator="onboardingModalStep" />
        </div>
    ),
};

const Ob1Slider = ({ imagesForSlider }: { imagesForSlider: string[] }) => {
    return (
        <S.SliderWrap>
            <Slider {...SLIDER_SETTINGS}>
                {imagesForSlider.map((img, index) => (
                    <Image
                        dataLocator="onboardingModalImage"
                        center
                        key={index}
                        alt="slideImg"
                        src={img}
                        isForceVisible
                        once
                        maxWidth={491}
                    />
                ))}
            </Slider>
        </S.SliderWrap>
    );
};

export default Ob1Slider;
