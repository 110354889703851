import { Box, Switcher as WikrSwitcher } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const RadioContainer = styled(Box)`
    display: flex;
    justify-content: start;
    gap: 71px;
`;

// todo: remove after whiteSpace label issue for Swithcer component will be fixed:
// https://gitlab.asqq.io/vendor-react/components/-/issues/26
export const Switcher = styled(WikrSwitcher)`
    margin: 0;

    > label {
        white-space: nowrap;
    }
`;

export const ShortInputsWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    gap: 16px;

    > * {
        max-width: calc(50% - 8px);
    }
`;

export const SelectWrapper = styled.label`
    position: relative;
    margin-bottom: 40px;
`;

export const InputWrapper = styled.div<{ touched: boolean }>`
    position: relative;

    select,
    optgroup {
        padding: 16px 13px;
        width: 100%;
        color: ${color('text-main')};
        font-size: 16px;
        letter-spacing: -0.408px;
        border-radius: 10px;
        background: ${color('surface-default')};
        border-width: 1px;
        border-style: solid;
        border-color: ${({ touched }) => (!touched ? color('border-default') : color('success-highlight'))};
    }

    optgroup {
        font-size: 16px !important;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        //text-overflow: '';
        background-color: ${color('surface-default')};
    }

    select:focus,
    select:focus-visible {
        outline: none;
        border-width: 1px;
        border-color: ${color('primary-active')};
        border-style: solid;
    }
`;

export const SuccessMessage = styled.div<{ visible: boolean }>`
    position: absolute;
    bottom: 0;
    padding-left: 12px;
    transform: translateY(calc(100% + 4px));
    color: ${color('success-default')};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: ${({ visible }) => (visible ? 'block' : 'none')};
`;
