import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_TRIAL } from '../constants';

import { getSubscriptionTitle } from '../helpers';

import * as S from './styled';

interface ISubscriptionData {
    subscriptionPeriod: number;
    subscriptionType: string;
    price: string;
    expirationDate?: string;
    isActive?: boolean;
    isCancelled?: boolean;
}

interface ISubscriptionDataProps {
    data: ISubscriptionData;
}

const SubscriptionData = ({ data }: ISubscriptionDataProps) => {
    const { subscriptionPeriod, subscriptionType, price, expirationDate, isActive = false, isCancelled = false } = data;

    const { t } = useTranslation();

    const paymentLabel = getSubscriptionTitle(subscriptionPeriod, false);

    const isTrial = subscriptionType === SUBSCRIPTION_TRIAL;
    const expirationDateText =
        isActive && isTrial ? t('subscription.text.expires') : t('subscription.text.nextChargeDate');

    return (
        <S.GeneralInfo mb={20} mt={8}>
            <Text
                type="large-text"
                medium
                text={t('subscription.period', {
                    count: Number(paymentLabel?.count),
                    context: paymentLabel?.context,
                    subscriptionType,
                })}
                dataLocator="subscriptionPeriod"
            />
            <S.DateInfo>
                <S.InfoTitle text={t('subscription.cancellation.text.price')} />
                <Text medium text={price} dataLocator="subscriptionPrice" />
            </S.DateInfo>
            {!isTrial && (
                <S.DateInfo>
                    <S.InfoTitle text={t('subscription.cancellation.subscriptionCard.billingPeriod.title')} />
                    <Text
                        medium
                        text={t('subscription.cancellation.subscriptionCard.billingPeriod.value', {
                            count: Number(paymentLabel?.count),
                            context: paymentLabel?.context,
                        })}
                        dataLocator="subscriptionBillingPeriod"
                    />
                </S.DateInfo>
            )}
            {!isCancelled && expirationDate && (
                <S.DateInfo>
                    <S.InfoTitle text={expirationDateText} />
                    <Text
                        medium
                        text={t('subscription.cancellation.expirationDate', {
                            expirationDate: String(expirationDate),
                        })}
                        dataLocator="subscriptionNextChargeDate"
                    />
                </S.DateInfo>
            )}
        </S.GeneralInfo>
    );
};

export default SubscriptionData;
