import { EventTriggerFixed } from 'wikr-core-analytics';

// mainTrackers
import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticEmailUpdate = () => {
    trackEventFixed('email__changed');
};

export const sendAnalyticPasswordUpdate = () => {
    trackEventFixed('password__changed');
};

export const sendAnalyticProfileUpdateClick = (updatedFields: string[]) => {
    trackEventFixed('account__changed', updatedFields);
};

export const sendAnalyticProfileUpdateError = () => {
    trackEventFixed(EventTriggerFixed.profileUpdateError);
};

export const sendAnalyticDeleteUserClick = () => {
    trackClick({
        screenIdParam: 'settings',
        eventLabel: 'delete_account',
    });
};

export const sendAnalyticDeleteUserSuccess = () => {
    trackEventFixed('account__deleted');
};

export const sendAnalyticChangeUserClick = (eventLabel: string) => {
    trackClick({
        screenIdParam: 'settings',
        eventLabel,
    });
}