import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const WorthTrying = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px 20px;

    ${mediaQueriesGrid.desktop} {
        padding: 64px 128px 80px;
    }
`;

export const WorthText = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;
