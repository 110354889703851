import { Image, Text } from 'wikr-core-components';
import React, { useEffect } from 'react';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import Modal from 'components/Modal';
import FeedbackModalsNavBar from 'components/FeedbackModalsNavBar';

import { ISuccessFeedbackModalProps } from '../../types';

const SuccessFeedbackModal = ({
    img,
    imgMb,
    title,
    subtitle,
    btnText,
    btnDataLocator,
    onBtnClick,
    analyticScreenLoadHandler,
}: ISuccessFeedbackModalProps) => {
    useEffect(() => {
        analyticScreenLoadHandler && analyticScreenLoadHandler();
    }, []);

    return (
        <Modal dataLocator="updatingSubscriptionPlanModal" isOpen style={{ content: { padding: '16px' } }}>
            <FeedbackModalsNavBar dataLocator="updatingSubscriptionPlanCloseButton" />
            <Image maxWidth={72} center alt="emoji" src={img} mb={imgMb} isForceVisible />
            <Text type="h5" text={title} mb={12} center />
            <Text text={subtitle} mb={24} center />
            <SecondaryDefaultButton dataLocator={btnDataLocator} text={btnText} onClick={onBtnClick} />
        </Modal>
    );
};

export default SuccessFeedbackModal;
