/* eslint-disable max-lines */
import * as actionTypes from './actionTypes';

import { IBlockById, IBlockItem, ICategories } from 'types/insights';

export const fetchInsights = () => {
    return {
        type: actionTypes.FETCH_INSIGHTS,
    } as const;
};

export const fetchBlockByCategory = (categoryId: string) => {
    return {
        type: actionTypes.FETCH_BLOCKS_BY_CATEGORY,
        payload: categoryId,
    } as const;
};

export const fetchNextBlockByCategory = (categoryId: string, pageNumber: number) => {
    return {
        type: actionTypes.FETCH_NEXT_BLOCKS_BY_CATEGORY,
        payload: { categoryId, pageNumber },
    } as const;
};

export const setNextBlockByCategoryLoading = (status: boolean) => {
    return {
        type: actionTypes.SET_NEXT_BLOCKS_IS_LOADING,
        payload: status,
    } as const;
};

export const fetchBlockPreview = (blockId: number) => {
    return {
        type: actionTypes.FETCH_BLOCK_PREVIEW,
        payload: blockId,
    } as const;
};

export const fetchBlockPreviewLayout = (blockId: number) => {
    return {
        type: actionTypes.FETCH_BLOCK_PREVIEW_LAYOUT,
        payload: blockId,
    } as const;
};

export const fetchNextBlockPreview = (blockId: number) => {
    return {
        type: actionTypes.FETCH_NEXT_BLOCK_PREVIEW,
        payload: blockId,
    } as const;
};

export const fetchPrevBlockPreview = (blockId: number) => {
    return {
        type: actionTypes.FETCH_PREV_BLOCK_PREVIEW,
        payload: blockId,
    } as const;
};

export const changeCurrentNextBlockPreview = () => {
    return {
        type: actionTypes.CHANGE_CURRENT_NEXT_BLOCK_PREVIEW,
    } as const;
};

export const changeCurrentPrevBlockPreview = () => {
    return {
        type: actionTypes.CHANGE_CURRENT_PREV_BLOCK_PREVIEW,
    } as const;
};

export const runResetBlockPreview = () => {
    return {
        type: actionTypes.RUN_RESET_PREVIEW,
    } as const;
};

export const resetBlockPreview = () => {
    return {
        type: actionTypes.RESET_PREVIEW,
    } as const;
};

export const setInsights = (payload: ICategories) => {
    return {
        type: actionTypes.SET_INSIGHTS,
        payload,
    } as const;
};

export const setCategoryBlocks = (payload: IBlockItem[]) => {
    return {
        type: actionTypes.SET_BLOCKS_BY_CATEGORY,
        payload,
    } as const;
};

export const setCategoryNextBlocks = (payload: IBlockItem[]) => {
    return {
        type: actionTypes.SET_NEXT_BLOCKS_BY_CATEGORY,
        payload,
    } as const;
};

export const setBlockPreview = (payload: IBlockById | null) => {
    return {
        type: actionTypes.SET_BLOCK_PREVIEW,
        payload,
    } as const;
};

export const setBlockPreviewLayout = (payload: IBlockById | null) => {
    return {
        type: actionTypes.SET_BLOCK_PREVIEW_LAYOUT,
        payload,
    } as const;
};

export const setNextBlockPreview = (payload: IBlockById | null) => {
    return {
        type: actionTypes.SET_NEXT_BLOCK_PREVIEW,
        payload,
    } as const;
};

export const setPrevBlockPreview = (payload: IBlockById | null) => {
    return {
        type: actionTypes.SET_PREV_BLOCK_PREVIEW,
        payload,
    } as const;
};

export const setLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATE,
        payload,
    } as const;
};

export const setPreviewLayoutLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_PREVIEW_LAYOUT_LOADING_STATE,
        payload,
    } as const;
};

export const setNextLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_NEXT_LOADING_STATE,
        payload,
    } as const;
};

export const setPrevLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_PREV_LOADING_STATE,
        payload,
    } as const;
};
