import yogaLogo from 'assets/projectsLogo/yogaLogo.webp';
import mbLogo from 'assets/projectsLogo/mbLogo.webp';
import fcLogo from 'assets/projectsLogo/fcLogo.webp';

export const LOGO_LIST = [
    {
        logo: fcLogo,
        maxWidth: 36,
    },
    {
        logo: yogaLogo,
        maxWidth: 48,
    },
    {
        logo: mbLogo,
        maxWidth: 36,
    },
];
