import styled, { css } from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const PageWrapper = styled.div<{ isWeb: boolean }>`
    ${mediaQueriesGrid.desktop} {
        ${({ isWeb }) =>
            isWeb &&
            css`
                margin-left: 240px;
            `};
`;
