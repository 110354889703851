import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const TitleBlock = styled(Box)`
    display: block;
    margin-bottom: 64px;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 96px;
    }
`;

export const FirstOfAll = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const Title = styled(Text)`
    margin-bottom: 24px;

    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
        margin-bottom: 8px;
    }
`;

export const ReviewedByBlock = styled(Box)`
    display: block;

    ${mediaQueriesGrid.desktop} {
        display: flex;
        align-items: center;
    }
`;

export const ReviewedTip = styled(Box)`
    padding: 4px 8px;
    margin-bottom: 16px;
    width: fit-content;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 0;
        margin-right: 16px;
    }
`;

export const ReviewerBlock = styled(Box)`
    display: flex;
    align-items: center;
`;

export const ReviewerImageWrap = styled.div`
    margin-right: 12px;

    & > div > img {
        width: 48px;
    }
`;
