export const GUIDE_FOR_MY_FASTING_PLAN: Record<string, string> = {
    en: 'https://mobile.appscdn.io/!VIDEO/FastingApp/Public/Guides4/Fasting%20Guide_EN_Lose%20Weight_BR_LNCH.pdf',
    de: 'https://mobile.appscdn.io/!VIDEO/FastingApp/Public/Guides4/Fasting%20Guide_DE_Lose%20Weight_BR_LNCH-01.pdf',
    pt: 'https://mobile.appscdn.io/!VIDEO/FastingApp/Public/Guides4/Fasting%20Guide_PT_Lose_Weight_BR_LNCH.pdf',
    es: 'https://mobile.appscdn.io/!VIDEO/FastingApp/Public/Guides4/Fasting%20Guide_ES_Lose%20Weight_BR_LNCH-01.pdf',
    fr: 'https://mobile.appscdn.io/!VIDEO/FastingApp/Public/Guides4/Fasting%20Guide_FR_Lose%20Weight_BR_LNCH.pdf',
    it: 'https://mobile.appscdn.io/!VIDEO/FastingApp/Public/Guides4/Fasting%20Guide_IT_Lose%20Weight_BR_LNCH-03.pdf',
};

export const getGuideForMyFastingPlan = (language: string) => {
    return GUIDE_FOR_MY_FASTING_PLAN[language];
};
