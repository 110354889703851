import { useEffect } from 'react';

import { fetchWeightStatistic } from 'store/dashboard/actions';

import { DATE_FORMAT_ASIAN_TYPE } from 'constants/dateFormats';

import { useDispatch } from 'hooks';

import dayjs from 'dayjs';

export const useWeightStatistic = () => {
    const dispatch = useDispatch();

    const firstDayOfWeek = dayjs().day(1);

    useEffect(() => {
        dispatch(fetchWeightStatistic(firstDayOfWeek.format(DATE_FORMAT_ASIAN_TYPE)));
    }, []);
};
