import { Text } from 'wikr-core-components';
import React from 'react';

import * as S from './styled';

interface IBlockTitleProps {
    icon: string;
    text: string;
    iconWidth: number;
    dataLocator: string;
}

const BlockTitle = ({ icon, iconWidth, text, dataLocator }: IBlockTitleProps) => {
    return (
        <S.BlockTitle>
            <S.Icon>
                {/* TO-DO: replace with LocalImage component */}
                <S.Image alt="icon" src={icon} width={iconWidth} />
            </S.Icon>
            <Text type="h6" medium text={text} dataLocator={dataLocator} />
        </S.BlockTitle>
    );
};

export default BlockTitle;
