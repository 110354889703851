import { selectCurrentUser } from 'store/user/selectors';
import { selectDashboard } from 'store/dashboard/selectors';

import { measure } from 'constants/units';

import { useSelector } from 'hooks';

import { getWindowSize } from 'helpers/getWindowSize';
import { getDemoWeightData } from './helpers';
import { convertWeight, getWeeksDateFormatted, getYAxisStep } from '../helpers/helpers';

import type { ChartConfiguration } from 'chart.js/dist/types';

import dayjs from 'dayjs';

const DEFAULT_TARGET_WEIGHT = 70;

export const useGraphicData = (isDemo?: boolean) => {
    const { target_weight, units: unitsStore, weight } = useSelector(selectCurrentUser);
    const units = unitsStore || 'metric';
    const firstDayOfWeek = dayjs().day(1);
    const { weightStatistic: weightStatisticData } = useSelector(selectDashboard);
    const weightStatisticMock = getDemoWeightData(firstDayOfWeek);

    const weightStatistic = !isDemo ? weightStatisticData : weightStatisticMock;
    const targetWeight = Number(!isDemo ? target_weight : DEFAULT_TARGET_WEIGHT);
    const currentUnit = measure.WEIGHT_UNIT[units || 'metric'];

    const dateLabels = getWeeksDateFormatted(firstDayOfWeek);

    const weightDates = weightStatistic?.map(({ weight }) => convertWeight({ weight, units })) || [];

    const convertedWeights = dateLabels.map(({ date }) => {
        const tipsWeight = weightStatistic?.find((data) => date.isSame(dayjs(data.date), 'day'));

        return tipsWeight?.weight ? convertWeight({ weight: tipsWeight.weight, units }) : null;
    });

    const isSmallScreen = getWindowSize(768);
    const dateLabelsFormat = dateLabels.map(({ formatDate }) => formatDate);
    const labelsFormatted = isSmallScreen ? [, ...dateLabelsFormat, , ,] : dateLabelsFormat;
    const datasetsDataFormatted = isSmallScreen ? [, ...convertedWeights, , ,] : convertedWeights;

    const weightDiff = Number(weight) - targetWeight;
    const weightDiffConverted = convertWeight({ weight: weightDiff, units });

    const startWeekWeight = weightStatistic[0]?.weight;
    const endWeekWeight = weightStatistic?.at(-1)?.weight;

    const convertedTargetWeight = convertWeight({ weight: targetWeight, units });

    const minWeightDates = Math.min(...weightDates);
    const maxWeightDates = Math.max(...weightDates);

    const data = {
        labels: labelsFormatted,
        datasets: [
            {
                data: datasetsDataFormatted,
                spanGaps: true,
                lineTension: 0.3,
                borderColor: '#F09F8F',
                backgroundColor: 'transparent',
                pointBorderColor: '#F09F8F',
                pointBackgroundColor: '#FEF1F1',
                pointRadius: 5,
                legend: {
                    display: false,
                },
            },
        ],
    };

    const options: ChartConfiguration<'line'>['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    color: '#767A86',
                    font: {
                        size: 10,
                    },
                },
            },
            y: {
                min: convertedTargetWeight <= minWeightDates ? convertedTargetWeight : minWeightDates,
                max: weightDates.length ? Math.round(maxWeightDates + 1) : convertedTargetWeight + 10,
                ticks: {
                    stepSize: getYAxisStep(weightDiffConverted),
                    padding: 24,
                    callback: (tick) => `${tick} ${currentUnit}`,
                    color: '#767A86',
                },
                border: {
                    display: false,
                    dash: (context) => {
                        if (context.tick.value === targetWeight) {
                            return [4, 8];
                        }

                        return [5, 10];
                    },
                },
                grid: {
                    tickWidth: 4,
                    drawTicks: false,
                    color: '#D6D7DB',
                },
                offset: true,
            },
        },
        plugins: {
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: (context) => `${context.formattedValue} ${currentUnit}`,
                    title: () => '',
                },
                backgroundColor: '#252D48',
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: convertedTargetWeight,
                        yMax: convertedTargetWeight,
                        borderColor: '#252D48',
                        borderWidth: 1,
                        borderDash: [4, 8],
                    },
                },
            },
        },
    };

    const convertedWeekLostWeight = convertWeight({ weight: startWeekWeight - (endWeekWeight || 0), units });
    const weekLostWeight = Number(convertedWeekLostWeight.toFixed(2));

    return {
        data,
        options,
        unit: currentUnit,
        targetWeight: convertedTargetWeight,
        startWeekDate: dateLabels[0].formatDate,
        endWeekDate: dateLabels.at(-1)?.formatDate,
        weekLostWeight,
    };
};
