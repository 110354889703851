import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

import * as S from './styled';

interface IHelpfulOptionProps {
    svg: () => ReactElement;
    value: string;
    text: string;
    checked: boolean;
    onChange: () => void;
    dataLocator: string;
}

export const HelpfulOption = ({ svg, value, text, checked, onChange, dataLocator }: IHelpfulOptionProps) => {
    const { t } = useTranslation();

    return (
        <S.HelpfulOption data-locator={dataLocator} htmlFor={value}>
            <S.OptionInput onChange={onChange} type="radio" id={value} value={value} checked={checked} />
            <S.CustomOption>
                <S.SVGWrap>{svg()}</S.SVGWrap>
            </S.CustomOption>
            <S.OptionText center medium text={t(text)} />
        </S.HelpfulOption>
    );
};
