export const getBlockName = (type: string, index: number) => {
    switch (type) {
        case 'warm_up':
            return 'Warm up';

        case 'training':
            return `Block ${index}`;

        case 'cool_down':
            return 'Cool down';

        default:
            return '';
    }
};

export const BLOCK_DATA_LOCATOR: Record<string, string> = {
    warm_up: 'workoutPreviewWarmUpContainer',
    training: 'workoutPreviewExerciseSection',
    cool_down: 'workoutPreviewCoolDownSection',
};
