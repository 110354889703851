import { Box, Text, Button } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

const flexBlock = css`
    display: flex;
    align-items: center;
`;

const statusBadge = css`
    display: flex;
    align-items: center;
    width: fit-content;
    height: 24px;
    padding: 2px 6px;
    border-radius: 4px;
    gap: 4px;

    svg {
        width: 16px;
    }
`;

const banner = css`
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const SubscriptionCardHeader = styled.div<{ isExpired?: boolean }>`
    ${flexBlock};
    margin-bottom: 16px;

    ${({ isExpired }) =>
        isExpired &&
        css`
            margin-bottom: 20px;

            img {
                opacity: 0.5;
            }
        `}
`;

export const Name = styled.div`
    ${flexBlock};
    gap: 12px;
`;

export const ActiveStatus = styled.div`
    ${statusBadge};
    background-color: ${color('decorative-b')};
`;

export const InactiveStatus = styled.div`
    ${statusBadge};
    background-color: ${color('decorative-c')};
`;

export const TemporaryStatus = styled.div`
    ${statusBadge};
    background-color: ${color('success-highlight')};
`;

export const AwaitingStatus = styled.div`
    ${statusBadge};
    background-color: ${color('warning-default')};
`;

export const PriceRow = styled.div`
    ${flexBlock};
`;

export const DateInfo = styled.div`
    ${flexBlock};
    gap: 6px;
`;

export const GeneralInfo = styled.div<{ mb?: number; mt?: number }>`
    margin-bottom: ${({ mb = 24 }) => mb}px;
    margin-top: ${({ mt = 0 }) => mt}px;
    border-bottom: 1px solid ${color('border-default')};
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
`;

export const ExpiredCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
`;

export const ExpiresOnBanner = styled(Box)`
    ${banner};
    border: 1px solid ${color('critical-default')};
    background-color: ${color('critical-surface')};
`;

export const PausedBanner = styled(Box)`
    ${banner};
    border: 1px solid ${color('success-default')};
    background-color: ${color('success-surface')};
`;

export const PendingBanner = styled(Box)`
    ${banner};
    background-color: ${color('surface-secondary')};
`;

export const AwaitingBanner = styled(Box)`
    ${banner};
    border: 1px solid ${color('warning-default')};
    background-color: ${color('warning-surface')};
    margin-bottom: 10px;
`;

export const ResumeButton = styled(Button)`
    min-height: 44px;
    height: 44px;
    padding: 0;
    border-radius: 8px;

    :disabled {
        background-color: ${color('secondary-disabled')};
    }

    p {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1px;
    }
`;

export const CancelButton = styled.button`
    margin-top: 4px;
    margin-bottom: 12px;
    cursor: pointer;
`;

export const InfoTitle = styled(Text)`
    width: 180px;
`;

export const RestoreHint = styled(Box)`
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid ${color('surface-secondary')};
    background: ${color('surface-secondary')};
`;
