import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import { SKELETON_INSIGHT_FIRST_COLOR, SKELETON_INSIGHT_SECOND_COLOR } from 'constants/theme';

import * as S from './styled';

import { getContent } from './config';

interface IHoursComponentProps {
    isDemo: boolean;
    isSkeleton: boolean;
}

function HoursComponent({ isDemo, isSkeleton }: IHoursComponentProps) {
    const items = getContent(isDemo);

    return (
        <S.ComponentWrap backgroundColor="surface-main">
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    <S.OneHoursPart dataLocator={item.dataLocator}>
                        {isSkeleton ? (
                            <S.HourSkeleton
                                foregroundColor={SKELETON_INSIGHT_FIRST_COLOR}
                                backgroundColor={SKELETON_INSIGHT_SECOND_COLOR}
                                animate
                            >
                                <rect x="0" y="0" width="100%" height="100%" />
                            </S.HourSkeleton>
                        ) : (
                            <Text mb={8} type="h3" bold>
                                <Trans
                                    i18nKey="dashboard.weeklyProgress.hour.hours"
                                    components={{ span: item.i18nComponentSpan() }}
                                    values={{ hours: item.hours }}
                                />
                            </Text>
                        )}
                        <Text bold type="overline" color="text-secondary" center uppercase>
                            <Trans i18nKey={item.caption} components={{ br: <br /> }} />
                        </Text>
                    </S.OneHoursPart>
                    {index !== 2 && <S.VerticalLine />}
                </React.Fragment>
            ))}
        </S.ComponentWrap>
    );
}

export default HoursComponent;
