import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const PlateProtocolBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${mediaQueriesGrid.desktop} {
        flex-direction: row;
    }
`;

export const ProtocolBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    gap: 32px;
    background: ${color('surface-main')};
    border-radius: 24px;

    ${mediaQueriesGrid.largeTablet} {
        padding: 24px;
    }

    ${mediaQueriesGrid.desktop} {
        width: 100%;
    }
`;
