import styled from 'styled-components';

import { CONTENT_3 } from 'constants/customContentColors';

import { color } from 'theme/selectors';

export const Container = styled.div`
    background: ${CONTENT_3};
    border-radius: 16px;
    padding: 16px 20px 20px;
    max-width: 344px;
`;

export const ButtonsWrapper = styled.div`
    max-height: 44px;
    display: flex;
    justify-content: space-between;
`;

export const ButtonSkip = styled.button`
    color: ${color('text-main')};
    text-transform: uppercase;
    font-weight: 700;
`;
