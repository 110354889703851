import { Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { getStepperPercent } from './helpers';

import * as S from './styled';

import { ReactComponent as GraphBig } from './img/graphBig.svg';
import { ReactComponent as Graph } from './img/graph.svg';

const BmiChartBlock = ({ bmi, isDesktop }: { bmi: number; isDesktop: boolean }) => {
    const { t } = useTranslation();

    const stepperPercent = getStepperPercent(bmi);

    return (
        <Box paddingTop={12} paddingX={12} paddingBottom={12} backgroundColor="on-primary" borderRadius={12}>
            <S.ChartHeader>
                <Text type="small-text" text={t('myFastingPlan.bmi.whiteBlock.title')} bold />
                <Text type="caption" color="text-secondary" text={t('myFastingPlan.bmi.whiteBlock.normalBmi')} />
            </S.ChartHeader>
            <S.BmiChartWrapper>
                {isDesktop ? <GraphBig /> : <Graph />}
                <S.BmiChart>
                    <S.DotWrapper stepperPercent={stepperPercent}>
                        <S.BmiTooltip stepperPercent={stepperPercent}>
                            <Text
                                type="caption"
                                color="surface-main"
                                text={t('myFastingPlan.bmi.whiteBlock.yourBmi', { bmi })}
                            />
                        </S.BmiTooltip>
                        <S.BmiDot />
                    </S.DotWrapper>
                </S.BmiChart>
            </S.BmiChartWrapper>
            <S.BmiTitles>
                <Text text={t('myFastingPlan.bmi.whiteBlock.normal')} color="text-secondary" type="overline" />
                <Text text={t('myFastingPlan.bmi.whiteBlock.overweight')} color="text-secondary" type="overline" />
                <Text text={t('myFastingPlan.bmi.whiteBlock.obese')} color="text-secondary" type="overline" />
            </S.BmiTitles>
        </Box>
    );
};

export default BmiChartBlock;
