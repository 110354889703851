import { fromKgToLbs } from 'helpers/convert';

import { UnitTypes } from 'types/user/userApiInterface';

import { Dayjs } from 'dayjs';

const DAYS_IN_WEEK = 7;

export const getWeeksDateFormatted = (firstDayDate: Dayjs, daysInWeek?: number) => {
    const weekDate = [];
    const weeksDay = daysInWeek || DAYS_IN_WEEK;

    for (let i = 0; i < weeksDay; i++) {
        const addedDate = firstDayDate.add(i, 'day');

        weekDate.push({ formatDate: addedDate.format('D.MM'), date: addedDate });
    }

    return weekDate;
};

export const getYAxisStep = (diff: number) => {
    if (diff <= 10) {
        return 2;
    } else if (diff > 10 && diff <= 20) {
        return 4;
    } else if (diff > 20 && diff <= 30) {
        return 6;
    } else if (diff > 30 && diff <= 50) {
        return 10;
    } else {
        return 20;
    }
};

export const convertWeight = ({ weight, units }: { weight: number; units: string }) => {
    if (units === UnitTypes.Metric) {
        return Number(weight);
    }

    return fromKgToLbs(Number(weight));
};
