import level2 from '../../img/level2.svg';
import level1 from '../../img/level1.svg';
import level0 from '../../img/level0.svg';

import { IWorkout } from 'types/workouts/workoutsApiInterface';

export const getVariableCardData = (workout: IWorkout) => {
    let badgeColor = '#FE3838';

    switch (workout.type) {
        case 'cardio':
            badgeColor = '#FF8501';
            break;

        case 'recovery':
            badgeColor = '#53E19D';
    }

    let levelIcon = level0;

    switch (workout.level) {
        case 'intermediate':
            levelIcon = level1;
            break;

        case 'advanced':
            levelIcon = level2;
            break;
    }

    let bodyZone = 'Total body';

    switch (workout.body_zone) {
        case 'upper':
            bodyZone = 'Upper body';
            break;

        case 'lower':
            bodyZone = 'Lower body';
            break;
    }

    return {
        badgeColor,
        levelIcon,
        bodyZone,
    };
};
