import { InputChangeEvent, Input, Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface IDeleteInput {
    setIsValidate: (IsValid: boolean) => void;
    label: string;
    placeholder: string;
    initialValue?: string;
    theme: Theme;
}

const DeleteInput = ({ theme, setIsValidate, label, placeholder, initialValue = '' }: IDeleteInput) => {
    const { t } = useTranslation();

    const [value, setValue] = useState(initialValue);
    const [isValidValue, setIsValidValue] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const onChangeHandler = (inputEvent: InputChangeEvent) => {
        const inputValue = inputEvent.target.value;
        const isValid = inputValue === 'DELETE';

        if (!isTouched) {
            setIsTouched(true);
        }

        setIsValidValue(isValid);
        setIsValidate(isValid);
        setValue(inputValue);
    };

    const onBlurHandler = () => {
        if (!isTouched) {
            setIsTouched(true);
        }

        setIsFocus(false);
    };

    return (
        <Input
            dataLocator="deleteAccountConfirmationInput"
            theme={theme}
            onClear={() => setValue(initialValue)}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            onFocus={() => setIsFocus(true)}
            label={label}
            placeholder={placeholder}
            value={value}
            initialValue={initialValue}
            type="text"
            name="delete"
            isFocus={false}
            successStatus={isValidValue}
            errorStatus={!isValidValue}
            errorMessage={!isFocus && isTouched ? t('account.settings.deletionModal.validationError') : ''}
        />
    );
};

export default withTheme(DeleteInput);
