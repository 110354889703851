import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { useState, memo, useEffect } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import { startCalculateProgress } from 'helpers/utils';

import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import { ProgressModalType } from './types';

import SvgProgressBar from '../SvgProgressBar';

const ProgressModal = ({ onSubmit, type }: ProgressModalType) => {
    const { t } = useTranslation();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        startCalculateProgress({ setProgress, onComplete: onSubmit });
    }, []);

    const title =
        type === 'feedback' ? 'subscription.cancellation.sendingFeedback' : 'subscription.cancellation.updating';

    return (
        <Modal isOpen fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar isBackBtn={false} />
            <S.UpdatingContainer dataLocator="cancelSubscriptionModalContainer">
                <SvgProgressBar progress={progress} size={180} strokeWidth={20} />
                <Text type="small-text" center mb={12} text={t(title)} />
            </S.UpdatingContainer>
        </Modal>
    );
};

export default memo(ProgressModal);
