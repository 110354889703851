import { Box } from 'wikr-core-components';
import styled from 'styled-components';

interface IContainer {
    isDisplayFlex?: boolean;
    justifyContent?: string;
}

export const Container = styled(Box)<IContainer>`
    width: 100%;
    display: ${({ isDisplayFlex = false }) => (isDisplayFlex ? 'flex' : 'block')};
    justify-content: ${({ isDisplayFlex = false, justifyContent = 'flex-start' }) => isDisplayFlex && justifyContent};
`;
