import { selectCurrentUser } from 'store/user/selectors';
import { setCancellationCandidate, setRestoreCandidate } from 'store/subscriptions/actions';

import { SUBSCRIPTION_HEADINGS, SUBSCRIPTION_TITLE, NO_CENTS_CURRENCIES } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';
import { DATE_FORMAT_COMMON } from 'constants/dateFormats';
import {
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_CANCELLED,
    SUBSCRIPTION_CHARGEBACK,
    SUBSCRIPTION_REDEMPTION,
} from './constants';

import { sendAnalyticsRestoreSubscriptionClick, sendAnalyticSubscriptionCancelClick } from 'services/analytics';

import { useDispatch, useSelector } from 'hooks/store';
import { usePauseSubscription } from 'hooks';

import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceFromCents } from 'helpers/prices';
import { getFormattedDate, getFormattedDateByCountry, getFormattedDateWithPauseByCountry } from 'helpers/date';

import VipSupportIcon from 'assets/img/icons/VipSupportBiggerLogo.svg';
import LogoIcon from 'assets/img/icons/Logo.svg';

import { ISubscriptionCard } from './types';

const TRIAL_NAME = 'trial';
const SUBSCRIPTION_NAME = 'subscription';

const useSubscriptionCard = ({ onCancelSubscription, showRestoreCandidate, subscription }: ISubscriptionCard) => {
    const {
        product: { amount, currency, trial_amount: trialAmount, id },
        period: subscriptionPeriod,
        trial_period: subscriptionTrialPeriod,
        status,
        is_trial: isTrial,
        expired_date: expiredDate,
        internal_cancel_at: cancelledAt,
        product_code: productCode,
        external_id: externalId,
        upcoming_product: upcomingProduct,
        discount_granted_at: discountGrantedAt,
        pause_to,
    } = subscription;

    const dispatch = useDispatch();

    // const { openModal } = useModals();

    const currentUser = useSelector(selectCurrentUser);

    const { isPauseActive, isPremiumActive } = usePauseSubscription();

    const isCancelledSubscription = status === SUBSCRIPTION_CANCELLED || Boolean(cancelledAt);
    const isSubscriptionActive = status === SUBSCRIPTION_ACTIVE;
    const isSubscriptionRedemption = status === SUBSCRIPTION_REDEMPTION;

    const isDiscount = Boolean(discountGrantedAt);

    const isExpiredSubscription =
        (isCancelledSubscription && !isSubscriptionActive) || status === SUBSCRIPTION_CHARGEBACK;
    const isExpiresSubscription = isCancelledSubscription && isSubscriptionActive;

    const expirationDate = getFormattedDate(expiredDate, DATE_FORMAT_COMMON);
    const cancelledAtDate = getFormattedDate(cancelledAt, DATE_FORMAT_COMMON);

    const expirationDateWithPauseUI = getFormattedDateWithPauseByCountry(expiredDate, currentUser?.country as string);
    const expirationPauseDateUI = getFormattedDateByCountry(pause_to as string, currentUser?.country as string);

    const expirationDateUI = getFormattedDateByCountry(expiredDate, currentUser?.country as string);
    const cancelledAtDateUI = getFormattedDateByCountry(cancelledAt, currentUser?.country as string);

    const tabIcon = productCode === PRODUCT_CODES.APP_VIP_SUPPORT ? VipSupportIcon : LogoIcon;
    const heading = SUBSCRIPTION_HEADINGS[productCode] || SUBSCRIPTION_TITLE.OTHER;
    const price = getPriceFromCents(isTrial ? trialAmount : amount, NO_CENTS_CURRENCIES.includes(currency));

    const isMainSubscription = productCode === PRODUCT_CODES.APP_FULL_ACCESS;

    const trackCardExpand = (isTabExpanded: boolean) => {
        console.info('trackCardExpand', isTabExpanded);
    };

    const handleCancelSubscription = () => {
        const cancellationCandidate = {
            expirationDate,
            expirationDateUI,
            externalId,
            productCode,
            status,
            isDiscount,
        };

        sendAnalyticSubscriptionCancelClick({ tariff: id });
        dispatch(setCancellationCandidate(cancellationCandidate));
        // openModal(ModalName.alertModal);
        onCancelSubscription(cancellationCandidate);
    };

    const handleRestoreSubscription = () => {
        const restoreCandidate = {
            subscriptionPeriod,
            price,
            externalId,
            productCode,
            currency,
        };

        sendAnalyticsRestoreSubscriptionClick(isMainSubscription);

        dispatch(setRestoreCandidate(restoreCandidate));

        showRestoreCandidate(restoreCandidate);
    };

    return {
        price: `${price} ${currency}`,
        status: isPremiumActive ? SUBSCRIPTION_ACTIVE : status,
        tabIcon,
        heading,
        upcomingProduct,
        isSubscriptionRedemption,
        isExpiresSubscription,
        isExpiredSubscription,
        isPremiumActive,
        isPauseActive,
        cancelledAtDate,
        trackCardExpand,
        handleCancelSubscription,
        handleRestoreSubscription,
        subscriptionType: isTrial ? TRIAL_NAME : SUBSCRIPTION_NAME,
        expirationDate: replaceUnderscoreToDash(expirationDate ? expirationDate : cancelledAtDate),
        expirationDateUI: replaceUnderscoreToDash(expirationDateUI ? expirationDateUI : cancelledAtDateUI),
        expirationDateWithPause: replaceUnderscoreToDash(expirationDateWithPauseUI),
        expirationPauseDateUI: replaceUnderscoreToDash(expirationPauseDateUI),
        subscriptionPeriod: isTrial ? subscriptionTrialPeriod : subscriptionPeriod,
        isMainSubscription,
    };
};

export default useSubscriptionCard;
