import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import ButtonWithArrow from 'components/ButtonWithArrow';

import * as S from './styled';

const DetailsButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <S.ButtonWrapper>
            <ButtonWithArrow
                btnId="detailsButton"
                dataLocator="detailsButton"
                text={t('dashboard.bodyStatus.details')}
                onClick={() => navigate('/dashboard/body-status/1')}
            />
        </S.ButtonWrapper>
    );
};

export default DetailsButton;
