import styled from 'styled-components';

import Video from 'components/Video';

import { color } from 'theme/selectors';

export const YogaPlayer = styled(Video)`
    max-width: 404px;
    min-width: 343px;
    border-radius: 20px;
    border: solid 2px ${color('surface-main')};
    aspect-ratio: 1920 / 1080;
`;
