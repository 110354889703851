import React, { useEffect, useState, useRef, ReactElement } from 'react';

import * as S from './styled';

import { IProgressBar } from './types';

const DEFAULT_RADIUS_COEFFICIENT = 0;
const DEFAULT_STROKE_WIDTH = 34;

const ProgressBar = ({
    size,
    progress,
    strokeWidth = DEFAULT_STROKE_WIDTH,
    radiusCoefficient = DEFAULT_RADIUS_COEFFICIENT,
    textCentered = false,
}: IProgressBar): ReactElement => {
    const [offset, setOffset] = useState(0);
    const circleRef = useRef(null);
    //
    // const textClasses = cx({
    //     textComponent: !textStyles,
    //     ...(textStyles && { [textStyles]: textStyles }),
    // });

    const center = size / 2;
    const radius = center - strokeWidth / 2;
    const circumference = 2 * Math.PI * (radius - radiusCoefficient);

    useEffect(() => {
        const progressOffset = circumference - (progress / 100) * circumference;

        if (progress > 1) {
            // @ts-ignore
            circleRef.current.style = 'stroke: #4C61C7; transition: 0.3s stroke-dashoffset';
        }

        setOffset(progressOffset);
    }, [progress, circumference, offset]);

    return (
        <S.SvgContainer>
            <svg width={size} height={size}>
                <circle
                    stroke="#4C61C7"
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    className="innerCircle"
                />
                <circle
                    ref={circleRef}
                    stroke="#4C61C7"
                    cx={center}
                    cy={center}
                    r={radius - radiusCoefficient}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    className="animatedCircle"
                    strokeLinecap="round"
                />
                <text
                    x={`${center}`}
                    y={`${center}`}
                    alignmentBaseline={textCentered ? 'central' : 'middle'}
                    textAnchor="middle"
                    className="textClasses"
                    style={{ fill: '#4C61C7' }}
                >
                    {progress}%
                </text>
            </svg>
        </S.SvgContainer>
    );
};

export default ProgressBar;
