import { Text } from 'wikr-core-components';

import { GuideContent } from 'components/ModalContainers/GuidesOfferContainer/types';
import ImageLocal from 'components/ImageLocal';

import * as S from './styled';

interface GuidesBlockProps {
    guides: GuideContent[];
    guidesTitle: string;
}

const GuidesBlock = ({ guides, guidesTitle }: GuidesBlockProps) => {
    return (
        <S.Container dataLocator="guidesIncludedBlock">
            <Text text={guidesTitle} type="h4" medium center mb={32} />
            <S.FlexContainer>
                {guides.map(({ img, title }) => (
                    <S.GuideWrapper dataLocator="guide" key={title} borderRadius={16} borderColor="border-default">
                        <ImageLocal alt="guide" src={img} maxWidth={100} />
                        <Text text={title} center />
                    </S.GuideWrapper>
                ))}
            </S.FlexContainer>
        </S.Container>
    );
};

export default GuidesBlock;
