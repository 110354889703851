export const PATHS = {
    LOGIN: '/',
    FORGOT_PASSWORD: '/forgot-password',
    ACCOUNT: '/account/:tabId',
    ACCOUNT_DEFAULT: '/account',
    CHANGE_EMAIL: '/change-email',
    CHANGE_PASSWORD: '/change-password',
    UNAUTHORIZED_CHANGE_PASSWORD: '/change-password-un',
    AUTHORISED_FORGOT_PASSWORD: '/forgot-password-a',
    MAIN: '/main',
    FITNESS_GUIDES: '/guides',
    MY_FASTING_PLAN: '/my-fasting-plan',
    WELCOME: '/welcome',
    DASHBOARD: '/dashboard',
    DASHBOARD_ONBOARDING_1: '/dashboard/onboarding1',
    DASHBOARD_ONBOARDING_2: '/dashboard/onboarding2',
    DASHBOARD_ITEM: '/dashboard/:categoryId/:blockId',
    BODY_STATUS: '/dashboard/body-status/:statusId',
    FASTING_PROTOCOL: '/dashboard/fasting-protocol/',
    FASTING_PROTOCOL_ITEM: '/dashboard/fasting-protocol/:protocolId',
    WORKOUTS: '/workouts',
    WORKOUTS_PREVIEW: '/workouts/:workoutId',
    WORKOUTS_PLAYER: '/workouts/:workoutId/player',
    LIBRARY: '/library',
    MEAL_PLAN: '/library/meal-plan',
    CHALLENGE: '/challenge',
    FLAT_BELLY: '/flat-belly',
    EMAIL_CONFIRMATION: '/email-confirmation',
    ERROR_PAGE: '/error-page',
};
