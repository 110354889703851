import { useTranslation } from 'react-i18next';
import React from 'react';

import * as S from './styled';

import { getColor } from './config';

export const PasswordValidationList = ({ value }: { value: string }) => {
    const { t } = useTranslation();

    return (
        <S.ValidationList>
            <span>{t('validation.password.common.text')}</span>
            <span style={{ color: getColor('minLength', value) }}>{t('validation.password.characters')}</span>
            <span style={{ color: getColor('uppercase', value) }}>{t('validation.password.uppercases')}</span>
            <span style={{ color: getColor('lowercase', value) }}>{t('validation.password.lowercases')}</span>
            <span style={{ color: getColor('number', value) }}>{t('validation.password.number')}</span>
        </S.ValidationList>
    );
};
