import { TFunction } from 'i18next';

import { AVAILABLE_CURRENCY, NO_CENTS_CURRENCIES } from 'constants/subscriptions';

import handsUp from 'assets/img/subscriptionIcons/handsUp.webp';
import exclamationMark from 'assets/img/subscriptionIcons/exclamationMark.webp';

import { IDiscountSubscription } from 'types/subscription';

export const getUpdatingSuccessData = ({
    closeModal,
    discountSubscription,
    t,
}: {
    closeModal: () => void;
    discountSubscription: IDiscountSubscription;
    t: TFunction;
}) => {
    return {
        img: handsUp,
        title: t('subscription.cancelFlow.specialOffer.discount.successModal.title'),
        subtitle: t('subscription.cancelFlow.specialOffer.discount.successModal.subtitle', {
            price: NO_CENTS_CURRENCIES.includes(discountSubscription.currency)
                ? Number(discountSubscription.amount)
                : Number(discountSubscription.amount) / 100,
            period: discountSubscription.subscription_period,
            currencySign: AVAILABLE_CURRENCY[discountSubscription.currency],
        }),
        btnText: t('basics.continue'),
        btnDataLocator: 'updatingSubscriptionPlanContinueButton',
        onBtnClick: closeModal,
    };
};

export const getUpdatingErrorData = ({
    onUpdateSubscription,
    t,
}: {
    onUpdateSubscription: () => void;
    t: TFunction;
}) => {
    return {
        img: exclamationMark,
        title: t('subscription.cancelFlow.specialOffer.discount.errorModal.title'),
        mainBtnText: t('basics.tryAgain'),
        mainBtnDataLocator: 'updatingSubscriptionPlanTryAgainButton',
        onMainBtnClick: onUpdateSubscription,
        supportLinkDataLocator: 'updatingSubscriptionPlanContactSupportButton',
    };
};
