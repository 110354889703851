import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { CONTENT_2 } from 'constants/customContentColors';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const TasteGoodBlock = styled(Box)`
    background-color: ${CONTENT_2};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const Title = styled(Text)`
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 24px;

    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const SubtitleWrap = styled(Text)`
    display: flex;
    align-items: center;
    margin-bottom: 64px;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 96px;
    }
`;

export const SVGWrap = styled(Box)`
    margin-right: 16px;
`;

export const Subtitle = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const RecipesBlock = styled(Box)`
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 98px;
    }
`;

export const PointsBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        align-items: baseline;
        flex-direction: row;
    }
`;
