import React, { Fragment } from 'react';

import usePauseSubscription from 'hooks/subscriptions/hooks/usePauseSubscription';
import { useCancelSubscription } from 'hooks';

import SpecialOfferModalContainer from '../SpecialOfferModalContainer';
import PremiumFeedbackModalContainer from '../PremiumFeedbackModalContainer';

const TooExpensiveModalContainer = () => {
    const { cancellationCandidate } = useCancelSubscription();
    const { isSubscriptionStatusPaused } = usePauseSubscription();
    const isDiscount = cancellationCandidate?.isDiscount;

    return (
        <Fragment>
            {isDiscount || isSubscriptionStatusPaused ? (
                <PremiumFeedbackModalContainer reasonLocal="too_expensive" />
            ) : (
                <SpecialOfferModalContainer />
            )}
        </Fragment>
    );
};

export default TooExpensiveModalContainer;
