import { Provider } from 'wikr-core-analytics';
import { call, takeLatest, all, put, delay } from 'redux-saga/effects';

import { GeneralResponse } from 'api/types/response';
import api from 'api';

import { notifyError, notifySuccess } from 'store/notifications/actions';

import {
    getCurrentUserRequest,
    getCurrentUserSuccess,
    getCurrentUserError,
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordError,
    updateUserRequest,
    updateUserSuccess,
    updateUserError,
    changePasswordRequest,
    changePasswordError,
    changePasswordSuccess,
    updateUserEmail,
    deleteUser,
    unauthorizedChangePasswordRequest,
    unauthorizedChangePasswordSuccess,
    unauthorizedChangePasswordError,
    emailConfirmationRequest,
} from './actions';

import {
    GET_CURRENT_USER_REQUEST,
    UPDATE_USER_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    UPDATE_USER_EMAIL,
    DELETE_USER_DATA,
    UNAUTHORIZED_CHANGE_PASSWORD_REQUEST,
    EMAIL_CONFIRMATION_REQUEST,
} from './actionTypes';

import { UserStore } from 'types/store/userStore';

export function* getCurrentUser({ payload }: ReturnType<typeof getCurrentUserRequest>) {
    try {
        const response: GeneralResponse<UserStore> = yield call(api.user.getUser);
        const userData = response.data;

        yield put(getCurrentUserSuccess(userData));

        userData?.id && Provider.setUserId(userData.id);

        payload?.onSuccess?.(userData);
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(getCurrentUserError(error.error));
        payload?.onError?.(error);
    }
}

function* forgotPassword({ payload: { email, onSuccess, onError } }: ReturnType<typeof forgotPasswordRequest>) {
    try {
        const userData: UserStore = yield call(api.user.resetPassword, { email });

        yield put(forgotPasswordSuccess(userData));

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(forgotPasswordError(error.error));

        onError && onError('message.error.somethingWentWrong');
    }
}

function* changePassword({ payload: { payload, onSuccess, onError } }: ReturnType<typeof changePasswordRequest>) {
    try {
        yield call(api.user.changePassword, payload);

        yield put(changePasswordSuccess());

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(changePasswordError(error.error));

        onError && onError();
    }
}

function* unauthorizedChangePassword({
    payload: { payload, onSuccess, onError },
}: ReturnType<typeof unauthorizedChangePasswordRequest>) {
    try {
        yield call(api.user.unauthorizedChangePassword, payload);

        yield put(unauthorizedChangePasswordSuccess());

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(unauthorizedChangePasswordError(error.error));

        onError && onError();
    }
}

function* emailConfirmation({ payload: { payload, onSuccess, onError } }: ReturnType<typeof emailConfirmationRequest>) {
    try {
        yield call(api.user.emailConfirmation, payload);

        onSuccess && onSuccess();
    } catch (error: any) {
        onError && onError();
    }
}

function* updateUser({ payload: { payload, onSuccess } }: ReturnType<typeof updateUserRequest>) {
    try {
        const response: GeneralResponse<UserStore> = yield call(api.user.update, payload);
        const userData = response.data;

        userData?.id && Provider.setUserId(userData.id);

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess('message.success.changesSaved'));

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError('message.error.changesNotSaved'));
    }
}

function* updateUserEmailRequest({ payload: { email, onSuccess, onError } }: ReturnType<typeof updateUserEmail>) {
    try {
        const response: GeneralResponse<UserStore> = yield call(api.user.updateEmail, {
            email,
        });
        const userData = response.data;

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess('change.email.success'));

        // Fix closing notification on redirect
        yield delay(500);

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError(error?.data?.error || 'change.email.error'));
        // yield put(notifyError('change.email.error'));

        onError && onError();
    }
}

function* deleteUserData({ payload }: ReturnType<typeof deleteUser>) {
    const { onError, onLoading, onSuccess } = payload;

    onLoading(true);
    try {
        const userData: GeneralResponse<string> = yield call(api.user.deleteUser);

        userData.status === 204 ? onSuccess() : onError();

        onLoading(false);
    } catch (error: any) {
        onError();
        onLoading(false);
    }
}

export const userTestData = {
    getCurrentUser,
    updateUser,
    forgotPassword,
    changePassword,
    updateUserEmailRequest,
    deleteUserData,
};

export default function* watchUser() {
    yield all([
        takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser),
        takeLatest(UPDATE_USER_REQUEST, updateUser),
        takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeLatest(CHANGE_PASSWORD_REQUEST, changePassword),
        takeLatest(UNAUTHORIZED_CHANGE_PASSWORD_REQUEST, unauthorizedChangePassword),
        takeLatest(UPDATE_USER_EMAIL, updateUserEmailRequest),
        takeLatest(DELETE_USER_DATA, deleteUserData),
        takeLatest(EMAIL_CONFIRMATION_REQUEST, emailConfirmation),
    ]);
}
