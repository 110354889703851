import { Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { StartFastingButton } from 'pages/welcome/components/StartFastingButton';

import * as S from './styled';

import phonesWithApp from './img/phonesWithApp.webp';

import { IColoredWithAppImageBlockProps } from './types';

export const ColoredWithAppImageBlock = ({
    backgroundColor,
    withBigTitle = false,
    title,
    subtitle,
    img = phonesWithApp,
    btnHandler,
}: IColoredWithAppImageBlockProps) => {
    const { t } = useTranslation();

    return (
        <S.BlockWrap backgroundColor="surface-secondary">
            <S.ColoredContainer backgroundColor={backgroundColor}>
                <S.ContentBlock>
                    {withBigTitle && <S.TitleBig type="h2" mb={12} medium text={t(title)} />}
                    {!withBigTitle && <S.Title type="h5" mb={12} medium text={t(title)} />}
                    <S.Subtitle type="large-text" text={t(subtitle)} />
                    <S.ImageWrap>
                        <Image once alt="phonesWithApp" src={img} isForceVisible />
                    </S.ImageWrap>
                    <StartFastingButton btnHandler={btnHandler} />
                </S.ContentBlock>
            </S.ColoredContainer>
        </S.BlockWrap>
    );
};
