import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

export const Container = styled(Box)`
    width: 100%;
    padding: 40px 16px;
`;

export const ContentContainer = styled(Box)`
    margin: 0 auto;
    min-width: 340px;
    max-width: 420px;

    ${mediaQueries.laptop} {
        max-width: calc(576px + 32px);
    }
`;
