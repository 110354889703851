import { TFunction } from 'i18next';

import exclamationMark from 'assets/img/subscriptionIcons/exclamationMark.webp';
import smilingFace from '../img/smilingFace.webp';

export const getCancelSuccessData = ({ closeModal, t }: { closeModal: () => void; t: TFunction }) => {
    return {
        img: smilingFace,
        title: t('subscription.cancelFlow.specialOffer.cancel.successModal.title'),
        subtitle: t('subscription.cancelFlow.specialOffer.cancel.successModal.subtitle'),
        btnText: t('basics.continue'),
        btnDataLocator: 'updatingSubscriptionPlanContinueButton',
        onBtnClick: closeModal,
    };
};

export const getCancelErrorData = ({ onCancelSubscription, t }: { onCancelSubscription: () => void; t: TFunction }) => {
    return {
        img: exclamationMark,
        title: t('subscription.cancelFlow.specialOffer.cancel.errorModal.title'),
        mainBtnText: t('basics.tryAgain'),
        mainBtnDataLocator: 'updatingSubscriptionPlanTryAgainButton',
        onMainBtnClick: onCancelSubscription,
        supportLinkDataLocator: 'updatingSubscriptionPlanContactSupportButton',
    };
};
