import { useEffect } from 'react';

import { ModalName } from 'store/modals/types';

import {
    sendAnalyticProductProposeModalCancelCancellationClick,
    sendAnalyticProductProposeModalScreenLoad,
    sendAnalyticProductProposeModalViewProductsClick,
} from 'services/analytics';

import { useModals, useCancelSubscription } from 'hooks';

import ProductProposeModal from './components/ProductProposeModal';

const ProductProposeContainer = () => {
    const { onCancelSubscription, discardCancellation } = useCancelSubscription();
    const { openModal } = useModals();

    useEffect(() => {
        sendAnalyticProductProposeModalScreenLoad();
    }, []);

    const onViewProducts = () => {
        openModal(ModalName.checkOurApps);

        sendAnalyticProductProposeModalViewProductsClick();
    };

    const onSubmitHandler = () => {
        onCancelSubscription();

        sendAnalyticProductProposeModalCancelCancellationClick();
    };

    return (
        <ProductProposeModal
            onSubmit={onSubmitHandler}
            onCancel={discardCancellation}
            onViewProducts={onViewProducts}
        />
    );
};

export default ProductProposeContainer;
