import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

// mainTrackers
import { trackClick, trackClickCustom } from './mainTrackers';

const sendAnalyticInsStoryClick = (categoryId: number, blockId: number) => {
    trackClickCustom({
        event_action: 'button__story',
        event_label: `${categoryId}__${blockId}`,
    });
};

const sendAnalyticInsMainSeeAllClick = (categoryId: number) => {
    trackClickCustom({
        event_action: 'button__see_all',
        event_label: String(categoryId),
    });
};

const sendAnalyticInsCategoryNextClick = (categoryId?: string, blockId?: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.next,
        eventLabel: `${categoryId}__${blockId}`,
    });
};

const sendAnalyticInsCategoryPrevClick = (categoryId?: string, blockId?: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.back,
        eventLabel: `${categoryId}__${blockId}`,
    });
};

const sendAnalyticInsCategoryShowMoreClick = () => {
    trackClickCustom({
        event_action: 'button__more',
    });
};

export {
    sendAnalyticInsStoryClick,
    sendAnalyticInsMainSeeAllClick,
    sendAnalyticInsCategoryNextClick,
    sendAnalyticInsCategoryPrevClick,
    sendAnalyticInsCategoryShowMoreClick,
};
