import React from 'react';

import SubscriptionRedemption from './components/SubscriptionRedemption';
import PausedSubscription from './components/PausedSubscription';
import ExpiresSubscription from './components/ExpiresSubscription';
import ExpiredSubscription from './components/ExpiredSubscription';
import DefaultSubscription from './components/DefaultSubscription';

import { ISubscriptionCard } from './types';

import useSubscriptionCard from './useSubscriptionCard';

const SubscriptionCard = ({ onCancelSubscription, showRestoreCandidate, subscription }: ISubscriptionCard) => {
    if (!subscription.product) {
        return null;
    }

    const {
        isExpiredSubscription,
        isExpiresSubscription,
        isSubscriptionRedemption,
        trackCardExpand,
        handleCancelSubscription,
        handleRestoreSubscription,
        status,
        upcomingProduct,
        tabIcon,
        heading,
        subscriptionPeriod,
        subscriptionType,
        price,
        expirationDateUI,
        expirationPauseDateUI,
        isMainSubscription,
        isPauseActive,
        isPremiumActive,
    } = useSubscriptionCard({
        subscription,
        onCancelSubscription,
        showRestoreCandidate,
    });

    const commonProps = {
        tabIcon,
        heading,
        subscriptionPeriod,
        subscriptionType,
        price,
    };

    if (isExpiredSubscription) {
        return (
            <ExpiredSubscription
                {...commonProps}
                expirationDate={expirationDateUI}
                productCode={subscription.product_code}
                onRestoreSubscription={handleRestoreSubscription}
            />
        );
    }

    if (isExpiresSubscription) {
        return (
            <ExpiresSubscription {...commonProps} isDiscountOfferAvailable={false} expirationDate={expirationDateUI} />
        );
    }

    if (isPauseActive) {
        return (
            <PausedSubscription
                {...commonProps}
                expirationDate={expirationPauseDateUI}
                isMainSubscription={isMainSubscription}
                onCancelSubscription={handleCancelSubscription}
            />
        );
    }

    if (isSubscriptionRedemption) {
        return (
            <SubscriptionRedemption
                {...commonProps}
                trackCardExpand={trackCardExpand}
                onCancelSubscription={handleCancelSubscription}
            />
        );
    }

    return (
        <DefaultSubscription
            {...commonProps}
            expirationDate={expirationDateUI}
            state={status}
            upcomingProduct={upcomingProduct}
            onCancelSubscription={handleCancelSubscription}
            withFreeMonth={isPremiumActive}
        />
    );
};

export default SubscriptionCard;
