export const reasons = [
    {
        title: 'subscription.cancellationReason.technicalIssue',
        shortKey: 'technical_issue',
    },
    {
        title: 'subscription.cancellationReason.didntKnowAutorenewal',
        shortKey: 'didnt_know_autorenewal',
    },
    {
        title: 'subscription.cancellationReason.notWhatExpected',
        shortKey: 'not_what_expected',
    },
    {
        title: 'subscription.cancellationReason.expensive',
        shortKey: 'expensive',
    },
    {
        title: 'subscription.cancellationReason.noLongerNeed',
        shortKey: 'no_longer_need',
    },
    {
        title: 'subscription.cancellationReason.purchasedByMistake',
        shortKey: 'purchased_by_mistake',
    },
    {
        title: 'subscription.cancellationReason.other',
        shortKey: 'other',
    },
];
