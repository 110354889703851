import { Text } from 'wikr-core-components';

import { ExpertContent } from 'components/ModalContainers/GuidesOfferContainer/types';

import * as S from './styled';

import { ExpertCard } from './ExpertCard';

interface ExpertsBlockProps {
    expertsTitle: string;
    experts: ExpertContent[];
}

const ExpertsBlock = ({ expertsTitle, experts }: ExpertsBlockProps) => {
    return (
        <S.Container dataLocator="expertBlock">
            <Text text={expertsTitle} type="h4" medium center mb={24} />
            <S.FlexContainer>
                {experts.map((expert) => (
                    <ExpertCard expert={expert} key={expert.img} />
                ))}
            </S.FlexContainer>
        </S.Container>
    );
};

export default ExpertsBlock;
