import config from 'config';

import UserApi from './components/User';
import Subscriptions from './components/Subscriptions';

import ApiClient from '../services/ApiClient';

function ApiInstance() {
    const apiClient = new ApiClient({ apiUrl: config.API_URL });
    const user = new UserApi({ apiClient });
    const subscriptions = new Subscriptions({ apiClient });

    return {
        user,
        subscriptions,
    };
}

export default ApiInstance();
