import { Button } from 'wikr-core-components';
import React from 'react';

import * as S from './styled';

type FilledButtonPropsType = {
    text: string;
    onClick: () => void;
    dataLocator?: string;
    mb?: number;
    isLoading?: boolean;
    size?: 'small' | 'medium';
    isDisplayFlex?: boolean;
    disabled?: boolean;
    justifyContent?: string;
};

const FilledButton = ({
    text,
    onClick,
    dataLocator,
    mb,
    isLoading,
    size,
    isDisplayFlex = false,
    justifyContent = 'flex-start',
    disabled,
}: FilledButtonPropsType) => {
    return (
        <S.Container isDisplayFlex={isDisplayFlex} justifyContent={justifyContent} mb={mb} borderRadius={12}>
            <Button
                isLoading={isLoading}
                disabled={disabled || isLoading}
                text={text}
                size={size ?? 'medium'}
                textColor="on-primary"
                onClick={onClick}
                backgroundColor="primary-default"
                hoverColor="critical-default"
                dataLocator={dataLocator}
            />
        </S.Container>
    );
};

export default FilledButton;
