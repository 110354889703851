import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { getWindowSize } from 'helpers/getWindowSize';

import BmiChartBlock from 'components/BmiChartBlock';

import * as S from './styled';

const BmiBlock = ({ bmi }: { bmi: number }) => {
    const { t } = useTranslation();

    const isDesktop = getWindowSize(1240);

    return (
        <S.BmiBlock mb={48}>
            <Text type="large-text" medium center text={t('myFastingPlan.bmi.title')} mb={20} />
            <BmiChartBlock bmi={bmi} isDesktop={isDesktop} />
        </S.BmiBlock>
    );
};

export default BmiBlock;
