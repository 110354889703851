import * as Yup from 'yup';

const YEAR_OF_BIRTH_CONSTRAINS = {
    currentYear: new Date().getFullYear(),
    lastYear: new Date().getFullYear() - 90,
};

const CYCLE_DURATION_CONSTRAINTS = {
    durationMinValue: 20,
    durationMaxValue: 99,
};

const PERIOD_DURATION_CONSTRAINTS = {
    durationMinValue: 2,
    durationMaxValue: 12,
};

// TODO any issue
const getValidationSchema = (t: any) => {
    const requiredHint = t('validation.required');

    const baseShape = {
        name: Yup.string()
            .trim()
            .min(2, t('validation.length.min', { count: 2 }))
            .max(25, t('validation.length.max', { count: 25 }))
            .required(requiredHint),
        yearOfBirth: Yup.number()
            .required(requiredHint)
            .min(
                YEAR_OF_BIRTH_CONSTRAINS.lastYear,
                t('validation.age', {
                    min: YEAR_OF_BIRTH_CONSTRAINS.lastYear,
                    max: YEAR_OF_BIRTH_CONSTRAINS.currentYear,
                })
            )
            .max(
                YEAR_OF_BIRTH_CONSTRAINS.currentYear,
                t('validation.age', {
                    min: YEAR_OF_BIRTH_CONSTRAINS.lastYear,
                    max: YEAR_OF_BIRTH_CONSTRAINS.currentYear,
                })
            ),
        defaultCycleLength: Yup.number()
            .required(requiredHint)
            .min(
                CYCLE_DURATION_CONSTRAINTS.durationMinValue,
                t('validation.age', {
                    min: CYCLE_DURATION_CONSTRAINTS.durationMinValue,
                    max: CYCLE_DURATION_CONSTRAINTS.durationMaxValue,
                })
            )
            .max(
                CYCLE_DURATION_CONSTRAINTS.durationMaxValue,
                t('validation.age', {
                    min: CYCLE_DURATION_CONSTRAINTS.durationMinValue,
                    max: CYCLE_DURATION_CONSTRAINTS.durationMaxValue,
                })
            ),
        defaultPeriodLength: Yup.number()
            .required(requiredHint)
            .min(
                PERIOD_DURATION_CONSTRAINTS.durationMinValue,
                t('validation.age', {
                    min: PERIOD_DURATION_CONSTRAINTS.durationMinValue,
                    max: PERIOD_DURATION_CONSTRAINTS.durationMaxValue,
                })
            )
            .max(
                PERIOD_DURATION_CONSTRAINTS.durationMaxValue,
                t('validation.age', {
                    min: PERIOD_DURATION_CONSTRAINTS.durationMinValue,
                    max: PERIOD_DURATION_CONSTRAINTS.durationMaxValue,
                })
            ),
        defaultCycleRegularity: Yup.string().required(requiredHint),
    };

    return Yup.object().shape(baseShape);
};

export default getValidationSchema;
