import { Text, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import smile from './img/smile.webp';

const SubscriptionCancelledBanner = () => {
    const { t } = useTranslation();

    return (
        <S.Container paddingX={12} paddingBottom={12} paddingTop={12} borderRadius={20} mb={24}>
            <S.BannerTitle>
                <Image alt="smileIcon" src={smile} maxWidth={36} />
                <Text
                    type="large-text"
                    medium
                    mb={8}
                    text={`${t('subscription.cancellation.expiresSubscription.bannerTitle')}.`}
                />
            </S.BannerTitle>
            <Text text={t('subscription.cancellation.cancelConfirmBanner.text')} />
        </S.Container>
    );
};

export default SubscriptionCancelledBanner;
