import { Text } from 'wikr-core-components';
import { useState } from 'react';

import { getHintVariant } from './helpers';

import { IModal } from 'components/Modal/types';
import Modal from 'components/Modal';

import * as S from './styled';

import { ReactComponent as SVGClose } from './img/close.svg';

import { Steps } from '../Steps/Steps';

type CastHintModalProps = Pick<IModal, 'isOpen' | 'onClose'>;

const CastHintModal = ({ isOpen, onClose }: CastHintModalProps) => {
    const [customVariant, setCustomVariant] = useState<'safari' | 'chrome'>('chrome');
    const hintVariant = getHintVariant();

    const variant = hintVariant ?? customVariant;

    return (
        <Modal
            isOpen={isOpen}
            closeOnOverlayClick={Boolean(onClose)}
            contentElement={(props, content) => <S.Content {...props}>{content}</S.Content>}
            onRequestClose={onClose}
            dataLocator="workoutHowToCastModalContainer"
            style={{ overlay: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }}
        >
            <S.CloseButton onClick={onClose} data-locator="workoutHowToCastModalCloseButton">
                <SVGClose />
            </S.CloseButton>
            <S.Header>
                <Text type="large-text" bold>
                    How to cast to TV?
                </Text>
            </S.Header>
            {!hintVariant && (
                <S.TabBar>
                    <S.TabItem
                        data-locator="workoutHowToCastModalSafariTab"
                        onClick={() => setCustomVariant('safari')}
                        $active={customVariant === 'safari'}
                    >
                        <Text
                            text="Safari"
                            bold
                            color={customVariant === 'safari' ? 'text-secondary' : 'text-secondary-subdued'}
                        />
                    </S.TabItem>
                    <S.TabItem onClick={() => setCustomVariant('chrome')} $active={customVariant === 'chrome'}>
                        <Text
                            text="Chrome"
                            bold
                            color={customVariant === 'chrome' ? 'text-secondary' : 'text-secondary-subdued'}
                        />
                    </S.TabItem>
                </S.TabBar>
            )}
            <div>{variant && <Steps variant={variant} />}</div>
        </Modal>
    );
};

export default CastHintModal;
