import styled from 'styled-components';

import { color } from 'theme/selectors';

export const NavBarContainer = styled.div`
    height: 44px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const Icon = styled.button`
    min-width: 44px;
    height: 44px;
    padding: 12px;
    border-radius: 100px;
    background-color: ${color('surface-secondary')};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${color('surface-hovered')};

        svg path {
            fill: ${color('action-hovered')};
        }
    }
`;
