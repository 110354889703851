import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled.ul<{isCustomNumbered: boolean}>`
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
    counter-reset: li;
    list-style-type: none;
    
    & > li {
        position: relative;
        padding: 8px 0 0 42px;
        margin-bottom: 16px;
    }
    
    & > li:before {
        position: absolute;
        left: 0;
        top: 0;
        content: counter(li); 
        counter-increment: li;
        height: 32px;
        width: 32px;
        border: 2px solid ${color('text-secondary-subdued')};
        border-radius: 50%;
        color: #706D8C;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        background-color: ${color('on-primary')};
    }
    
    ${({ isCustomNumbered }) => isCustomNumbered && css`
        & > li:before {
            content: '';
        }
        
        & > li {
            ${Number} {
                position: absolute;
                left: 13px;
                top: 8px;
                font-weight: 700;
                font-size: 14px;
                color: #706D8C;
            }
        }
        
        & > li:after {
            content: '';
            position: absolute;
            border-left: 2px solid ${color('text-secondary-subdued')};
            left: 17px;
            bottom: -17px;
            z-index: 1;
            height: 10px;
        }
        
        & > li:last-child:after {
            content: none;
        }
    `}
`;

export const Number = styled.div``;