import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const SkeletonOneDayCircle = styled(ContentLoader)`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-bottom: 4px;

    ${mediaQueriesGrid.tablet} {
        width: 40px;
        height: 40px;
    }
`;

export const SkeletonOneDayText = styled(ContentLoader)`
    width: 24px;
    height: 12px;
    border-radius: 6px;

    ${mediaQueriesGrid.tablet} {
        width: 40px;
        height: 16px;
    }
`;
