import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import ChallengeLevelCard from './subcomponents/ChallengeLevelCard';
import ChallengeArticle from './subcomponents/ChallengeArticle';

import * as S from './styled';

import { IChallengeLevelBlockProps } from './types';

const LevelBlock = ({ challengeLevel, isOpen, onToggle }: IChallengeLevelBlockProps) => {
    const { t } = useTranslation();

    return (
        <div data-locator="75DayChallengeLevelContainer">
            <S.LevelSection isOpen={isOpen}>
                <S.RadioBtn
                    data-locator={challengeLevel.active ? 'activeLevel' : 'inactiveLevel'}
                    isActive={challengeLevel.active}
                    isOpen={isOpen}
                    onClick={onToggle}
                />
                <S.LevelContainer>
                    <S.LevelTitle onClick={onToggle}>
                        <Text
                            type="h5"
                            medium
                            color={challengeLevel.active ? 'text-main' : 'text-secondary'}
                            text={t(challengeLevel.level)}
                            dataLocator="75DayChallengeLevelTitle"
                        />
                        <S.LevelTitleArrowBlock>
                            <Text
                                type="large-text"
                                color="text-secondary"
                                dataLocator="75DayChallengeLevelDays"
                                text={`Day ${challengeLevel.period}`}
                            />
                            {isOpen ? (
                                <S.ArrowUp data-locator="75DayChallengeExpandButton" />
                            ) : (
                                <S.ArrowDown data-locator="75DayChallengeExpandButton" />
                            )}
                        </S.LevelTitleArrowBlock>
                    </S.LevelTitle>
                    <S.LevelContent>
                        {isOpen && (
                            <S.LevelCardBlock>
                                <ChallengeLevelCard {...challengeLevel} />
                            </S.LevelCardBlock>
                        )}
                    </S.LevelContent>
                </S.LevelContainer>
            </S.LevelSection>
            {isOpen && challengeLevel.active && (
                <S.ArticlesSection>
                    {challengeLevel.articles.map((article) => (
                        <ChallengeArticle key={article.title} {...article} />
                    ))}
                </S.ArticlesSection>
            )}
        </div>
    );
};

export default LevelBlock;
