import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import Video from 'components/Video';

import { color } from 'theme/selectors';

export const LiveEvent = styled(Box)`
    width: 100%;
    max-width: 404px;
    min-width: 343px;
    display: flex;
    flex-direction: column;
`;

export const Player = styled(Video)`
    max-height: 800px;
    border-radius: 20px 20px 0 0;
    aspect-ratio: 1920 / 1080;
`;

export const LiveEventTextContent = styled(Box)`
    padding: 16px;
    background-color: ${color('surface-main')};
    border-radius: 0 0 20px 20px;
    flex: 1;
`;

export const Label = styled(Box)`
    width: fit-content;
`;

export const CoachInfo = styled(Box)`
    display: flex;
    align-items: center;
    gap: 10px;
`;
