import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

export const DiscountWrapper = styled.div<{ isAbsolute: boolean }>`
    display: flex;
    justify-content: center;
    ${({ isAbsolute = false }) =>
        isAbsolute &&
        css`
            position: absolute;
            top: -6px;
        `};
    margin-bottom: 17px;
`;

export const DiscountTextWrapper = styled.div`
    background: ${color('warning-default')};
    border-radius: 3px;
    padding: 1px 6px;
    display: flex;
    justify-content: center;
    max-width: 145px;
`;
