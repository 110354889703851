import { TFunction } from 'i18next';

import { GUIDES_OFFER_MODAL, sendAnalyticCancelModalScreenLoad } from 'services/analytics/trackers/cancelOffers';

import guidesTitle from './img/guidesTitle.webp';
import guide7 from './img/guide7.webp';
import guide6 from './img/guide6.webp';
import guide5 from './img/guide5.webp';
import guide4 from './img/guide4.webp';
import guide3 from './img/guide3.webp';
import guide2 from './img/guide2.webp';
import guide1 from './img/guide1.webp';
import expert4 from './img/expert4.webp';
import expert3 from './img/expert3.webp';
import expert2 from './img/expert2.webp';
import expert1 from './img/expert1.webp';

import { IGuidesOfferModalData } from './types';

export const getConfig = (t: TFunction): IGuidesOfferModalData => {
    return {
        title: t('subscription.cancellation.guidesOffer.title'),
        guidesTitle: t('subscription.cancellation.guidesOffer.guideList.title'),
        guidesTitleImg: guidesTitle,
        guides: [
            { img: guide1, title: t('subscription.cancellation.guidesOffer.guideList.guide1.title') },
            { img: guide2, title: t('subscription.cancellation.guidesOffer.guideList.guide2.title') },
            { img: guide3, title: t('subscription.cancellation.guidesOffer.guideList.guide3.title') },
            { img: guide4, title: t('subscription.cancellation.guidesOffer.guideList.guide4.title') },
            { img: guide5, title: t('subscription.cancellation.guidesOffer.guideList.guide5.title') },
            { img: guide6, title: t('subscription.cancellation.guidesOffer.guideList.guide6.title') },
            { img: guide7, title: t('subscription.cancellation.guidesOffer.guideList.guide7.title') },
        ],
        expertsTitle: t('subscription.cancellation.guidesOffer.expertList.title'),
        experts: [
            {
                img: expert1,
                name: 'Nicolle Cucco',
                description: t('subscription.cancellation.guidesOffer.expertList.expert1'),
            },
            {
                img: expert2,
                name: 'Bruno Pontesv',
                description: t('subscription.cancellation.guidesOffer.expertList.expert2'),
            },
            {
                img: expert3,
                name: 'Milana Nalin',
                description: t('subscription.cancellation.guidesOffer.expertList.expert3'),
            },
            {
                img: expert4,
                name: 'Lily Wilhelm',
                description: t('subscription.cancellation.guidesOffer.expertList.expert4'),
            },
        ],
        btnBlock: t('subscription.cancellation.guidesOffer.btnBlock.title'),
        analyticScreenLoadHandler: () => sendAnalyticCancelModalScreenLoad(GUIDES_OFFER_MODAL),
    };
};
