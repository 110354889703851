import { useModals } from 'hooks';

import CancellationSuccessModal from './components/CancellationSuccessModal';

const CancellationSuccessModalContainer = () => {
    const { closeModal } = useModals();

    const onSubmit = () => {
        closeModal();
    };

    return <CancellationSuccessModal onSubmit={onSubmit} />;
};

export default CancellationSuccessModalContainer;
