import { Image } from 'wikr-core-components';

import trueEmoji from './img/TrueEmoji.svg';
import { ReactComponent as Cross } from './img/Cross.svg';
import { ReactComponent as Check } from './img/Check.svg';
import cheatEmoji from './img/CheatEmoji.svg';

export const FASTING_TYPES = [
    {
        emoji: () => <Image isForceVisible alt="trueEmoji" src={trueEmoji} />,
        name: 'welcome.trueCheat.fastingType1.title',
        points: [
            'welcome.trueCheat.fastingType1.point1',
            'welcome.trueCheat.fastingType1.point2',
            'welcome.trueCheat.fastingType1.point3',
        ],
        svg: () => <Check />,
    },
    {
        emoji: () => <Image isForceVisible alt="cheatEmoji" src={cheatEmoji} />,
        name: 'welcome.trueCheat.fastingType2.title',
        points: [
            'welcome.trueCheat.fastingType2.point1',
            'welcome.trueCheat.fastingType2.point2',
            'welcome.trueCheat.fastingType2.point3',
        ],
        svg: () => <Cross />,
    },
];
