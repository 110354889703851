import { accountTabs } from 'containers/AccountSettings/constants';
import { PATHS } from 'constants/routerPaths';

const redirectFlows: Record<string, string> = {
    workouts: '/workouts',
    library: '/library',
};

export const getRedirectPage = (redirectFlow: string | null) => {
    const defaultRedirectPage = `${PATHS.ACCOUNT_DEFAULT}/${accountTabs.Subscription}`;

    if (!redirectFlow) {
        return defaultRedirectPage;
    }
    const redirectPage = redirectFlows[redirectFlow];

    return redirectPage ?? defaultRedirectPage;
};
