import React from 'react';

import { BodyStatus, FastingProtocol, PlateComponent, TimerComponent } from './components';

import * as S from './styled';

interface PlateProtocolBlockProps {
    isDemo: boolean;
    setIsDemo: (status: boolean) => void;
}

const PlateProtocolBlock = ({ isDemo, setIsDemo }: PlateProtocolBlockProps) => {
    return (
        <S.PlateProtocolBlock>
            {isDemo ? <TimerComponent /> : <PlateComponent isSkeleton={false} onDemoShow={() => setIsDemo(true)} />}
            <S.ProtocolBlock dataLocator="fastingProtocolContainer">
                <FastingProtocol isDemo={isDemo} />
                <BodyStatus isDemo={isDemo} />
            </S.ProtocolBlock>
        </S.PlateProtocolBlock>
    );
};

export default PlateProtocolBlock;
