export const AUTHENTICATE = 'AUTH/AUTHENTICATE';
export const SET_AUTHENTICATION_STATUS = 'AUTH/SET_AUTHENTICATION_STATUS';
export const LOG_OUT = 'AUTH/LOG_OUT';
export const SET_AUTHENTICATION_REDIRECT_URL = 'AUTH/SET_AUTHENTICATION_REDIRECT_URL';
export const AUTHENTICATE_BY_SIGNATURE = 'AUTH/AUTHENTICATE_BY_SIGNATURE';
export const SIGN_IN = 'AUTH/SIGN_IN';
export const SIGN_IN_REQUEST = 'AUTH/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'AUTH/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'AUTH/SIGN_IN_ERROR';

export const RESET_AUTH = 'AUTH/RESET_AUTH';

export const SET_AUTH_TOKEN = 'AUTH/SET_AUTH_TOKEN';
export const SET_AUTH_REFRESH_TOKEN = 'AUTH/SET_AUTH_REFRESH_TOKEN';

export const WEB_TOKEN_REQUEST = 'AUTH/WEB_TOKEN_REQUEST';
export const SET_AUTH_WEB_TOKEN = 'AUTH/SET_AUTH_WEB_TOKEN';