export const FETCH_INSIGHTS = 'INSIGHTS/FETCH';
export const FETCH_BLOCKS_BY_CATEGORY = 'INSIGHTS/FETCH_BLOCKS_BY_CATEGORY';
export const FETCH_NEXT_BLOCKS_BY_CATEGORY = 'INSIGHTS/FETCH_NEXT_BLOCKS_BY_CATEGORY';
export const FETCH_BLOCK_PREVIEW = 'INSIGHTS/FETCH_BLOCK_PREVIEW';
export const FETCH_BLOCK_PREVIEW_LAYOUT = 'INSIGHTS/FETCH_BLOCK_PREVIEW_LAYOUT';
export const FETCH_NEXT_BLOCK_PREVIEW = 'INSIGHTS/FETCH_NEXT_BLOCK_PREVIEW';
export const FETCH_PREV_BLOCK_PREVIEW = 'INSIGHTS/FETCH_PREV_BLOCK_PREVIEW';

export const SET_INSIGHTS = 'INSIGHTS/SET';
export const SET_BLOCKS_BY_CATEGORY = 'INSIGHTS/SET_BLOCKS_BY_CATEGORY';
export const SET_NEXT_BLOCKS_IS_LOADING = 'INSIGHTS/SET_NEXT_BLOCKS_IS_LOADING';
export const SET_NEXT_BLOCKS_BY_CATEGORY = 'INSIGHTS/SET_NEXT_BLOCKS_BY_CATEGORY';
export const SET_BLOCK_PREVIEW = 'INSIGHTS/SET_BLOCK_PREVIEW';
export const SET_BLOCK_PREVIEW_LAYOUT = 'INSIGHTS/SET_BLOCK_PREVIEW_LAYOUT';
export const SET_NEXT_BLOCK_PREVIEW = 'INSIGHTS/SET_NEXT_BLOCK_PREVIEW';
export const SET_PREV_BLOCK_PREVIEW = 'INSIGHTS/SET_PREV_BLOCK_PREVIEW';

export const SET_LOADING_STATE = 'INSIGHTS/SET_LOADING_STATE';
export const SET_PREVIEW_LAYOUT_LOADING_STATE = 'INSIGHTS/SET_PREVIEW_LAYOUT_LOADING_STATE';
export const SET_NEXT_LOADING_STATE = 'INSIGHTS/SET_NEXT_LOADING_STATE';
export const SET_PREV_LOADING_STATE = 'INSIGHTS/SET_PREV_LOADING_STATE';

export const CHANGE_CURRENT_NEXT_BLOCK_PREVIEW = 'INSIGHTS/CHANGE_CURRENT_NEXT_BLOCK_PREVIEW';
export const CHANGE_CURRENT_PREV_BLOCK_PREVIEW = 'INSIGHTS/CHANGE_CURRENT_PREV_BLOCK_PREVIEW';
export const RESET_PREVIEW = 'INSIGHTS/RESET_PREVIEW';
export const RUN_RESET_PREVIEW = 'INSIGHTS/RUN_RESET_PREVIEW';
