import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const Modal = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0 0 0 / 25%);
    z-index: 111;
`;

export const Banner = styled.div`
    position: fixed;
    top: 72px;
    right: 50%;
    transform: translateX(50%);
    width: 343px;
    background: ${color('surface-main')};
    border: 1px solid ${color('border-subdued')};
    border-radius: 16px;
    padding: 16px;

    ${mediaQueriesGrid.tablet} {
        right: 32px;
        transform: unset;
    }
`;

export const CloseImg = styled.img`
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const TitleIcon = styled.img`
    width: 20px;
    margin-right: 8px;
`;

export const WrapperContent = styled.div`
    display: flex;
    width: 100%;
`;
