import { OnboardingClassNames } from 'constants/onboardingFlow';

import WeekComponent from './components/WeekComponent';
import HoursComponent from './components/HoursComponent';

import * as S from './styled';

interface IWeeklyProgressBlockProps {
    isDemo: boolean;
    isSkeleton: boolean;
}

function WeeklyProgressBlock({ isDemo, isSkeleton }: IWeeklyProgressBlockProps) {
    return (
        <S.ComponentWrap className={OnboardingClassNames.fifth}>
            <WeekComponent isDemo={isDemo} isSkeleton={isSkeleton} />
            <HoursComponent isDemo={isDemo} isSkeleton={isSkeleton} />
        </S.ComponentWrap>
    );
}

export default WeeklyProgressBlock;
