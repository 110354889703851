import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ComponentWrap = styled(Box)`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    gap: 16px;

    ${mediaQueriesGrid.tablet} {
        gap: 20px;
    }
    ${mediaQueriesGrid.largeTablet} {
        flex-direction: row;
        gap: 20px;
    }
`;
