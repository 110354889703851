import { Text } from 'wikr-core-components';
import { useState } from 'react';

import { sendAnalyticWorkoutCardContinue } from 'services/analytics/trackers/workouts';

import { getVariableCardData } from './helpers';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

import clock from '../../img/clock.svg';

import { WorkoutCardProps } from '../../types';

const WorkoutCard = ({ workout, defaultPreview }: WorkoutCardProps) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const variableData = getVariableCardData(workout);

    const handleClick = () => sendAnalyticWorkoutCardContinue(workout.name);

    const handleImageLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <S.Card onClick={handleClick} to={workout.id.toString()} data-locator="workoutLibraryWorkoutCard">
            <S.BodyZone data-locator="workoutCardFocusAreaBadge">
                <Text text={variableData.bodyZone} type="caption" color="text-secondary" />
            </S.BodyZone>
            <S.ImageWrapper>
                {!isLoaded && <Skeleton />}
                <S.Image
                    $isShown={isLoaded}
                    src={workout.catalog_preview_link || defaultPreview}
                    onLoad={handleImageLoaded}
                    alt="workout preview"
                    data-locator="workoutCardImage"
                />
            </S.ImageWrapper>
            <S.InfoBlock>
                <Text
                    text={workout.type}
                    type="overline"
                    color="text-secondary-subdued"
                    mb={4}
                    dataLocator="workoutCardType"
                />
                <S.TitleWrapper $badgeColor={variableData.badgeColor} data-locator="workoutCardTitle">
                    <Text text={workout.name} type="h6" medium mb={8} />
                </S.TitleWrapper>
                <S.InfoWrapper>
                    {workout.duration && (
                        <S.InfoItem>
                            <img src={clock} alt="clock" data-locator="workoutCardDurationIcon" />
                            <Text
                                text={`${workout.duration}:00`}
                                dataLocator="workoutCardDuration"
                                type="small-text"
                                color="text-secondary"
                                medium
                            />
                        </S.InfoItem>
                    )}
                    <S.InfoItem>
                        <img src={variableData.levelIcon} alt="level icon" data-locator="workoutCardFitnessLevelIcon" />
                        <S.LevelTitle
                            text={workout.level}
                            dataLocator="workoutCardFitnessLevel"
                            type="small-text"
                            color="text-secondary"
                            medium
                        />
                    </S.InfoItem>
                </S.InfoWrapper>
            </S.InfoBlock>
        </S.Card>
    );
};

export default WorkoutCard;
