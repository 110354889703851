import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const LifestyleBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 80px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const Subtitle = styled(Text)`
    margin-bottom: 8px;

    ${mediaQueriesGrid.desktop} {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    }
`;

export const Title = styled(Text)`
    margin-bottom: 40px;

    ${mediaQueriesGrid.tablet} {
        margin-bottom: 0;
    }

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 40px;
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const ContentWrap = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: column;

    ${mediaQueriesGrid.tablet} {
        width: 43%;
        align-self: flex-end;
    }

    ${mediaQueriesGrid.desktop} {
        flex-direction: row;
        width: 821px;
    }
`;

export const ImgWrap = styled(Box)`
    width: 100%;
    max-width: 255px;
    margin-bottom: 12px;

    ${mediaQueriesGrid.tablet} {
        max-width: 166px;
        margin-bottom: 16px;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 297px;
        margin-bottom: 0;
        margin-right: 20px;
    }
`;

export const MainText = styled(Text)`
    width: 254px;
    align-self: flex-end;

    ${mediaQueriesGrid.tablet} {
        width: 100%;
        align-self: flex-start;
    }

    ${mediaQueriesGrid.desktop} {
        font-size: 32px;
        line-height: 40px;
        align-self: center;
    }
`;
