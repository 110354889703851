import { useModals } from 'hooks';

import ChallengeMoreModal from './components/ChallengeMoreModal';

const ChallengeMoreModalContainer = () => {
    const { closeModal } = useModals();

    return <ChallengeMoreModal onClose={closeModal} />;
};

export default ChallengeMoreModalContainer;
