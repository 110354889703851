import { getSideBarPrimaryLinks } from './getSideBarPrimaryLinks';

const usePrimaryLinks = () => {
    const primarySideBarLinks = getSideBarPrimaryLinks();

    return {
        primarySideBarLinks,
        filteredPrimarySideBarLinks: primarySideBarLinks.filter(({ isShowItem = true }) => isShowItem),
    };
};

export default usePrimaryLinks;
