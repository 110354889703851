import { Image } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const LevelCard = styled.div`
    display: flex;
    padding-top: 20px;
    flex-direction: column;

    ${mediaQueriesGrid.tablet} {
        padding-top: 0;
    }
`;

export const LevelGuide = styled.div<{ isActive: boolean }>`
    display: flex;
    padding: 0 16px 24px;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    border-radius: 20px 40px 20px 20px;
    background: ${color('surface-main')};

    ${mediaQueriesGrid.tablet} {
        flex-direction: row;
        gap: 32px;
        padding: 20px 40px 20px 0;
    }

    ${({ isActive }) =>
        !isActive &&
        css`
            background: ${color('surface-secondary')};
            border: 2px dashed #c5aaaf;
        `}
`;

export const LevelGuideTitle = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Cover = styled(Image)`
    margin-top: -20px;

    ${mediaQueriesGrid.tablet} {
        width: 150px;
        margin-top: 0;
        margin-left: -20px;
    }
`;

export const DownloadProgramLink = styled.a`
    min-height: 44px;
    padding: 4px 16px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    background: ${color('secondary-default')};
    justify-content: center;

    ${mediaQueriesGrid.tablet} {
        width: fit-content;
    }
`;

export const AvailableSoonLabel = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
