import { Text } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { UserStore } from 'store/user/reducer';

import { PATHS } from 'constants/routerPaths';
import { FEEDBACK_OPTIONS } from './constants';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticWorkoutFinishClick } from 'services/analytics/trackers/workouts';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';

import * as S from './styled';

import illustration from './assets/illustration.webp';

const WorkoutFinish = () => {
    const currentUser: UserStore = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const [selectedFeedback, setSelectedFeedback] = useState<string>('unset');

    useScreenLoad('workout_finish');

    const handleFeedbackClick = (feedback: string) => {
        setSelectedFeedback(feedback);
    };

    const handleFinishClick = () => {
        sendAnalyticWorkoutFinishClick(selectedFeedback);

        navigate(PATHS.WORKOUTS);
    };

    return (
        <S.Wrapper id="workoutCompleteContainer" data-locator="workoutCompleteContainer">
            <S.Container>
                <S.ImageWrapper>
                    <S.Image src={illustration} alt="Workout finished" data-locator="workoutCompleteImage" />
                </S.ImageWrapper>
                <S.ActionsWrapper>
                    <Text
                        text={`You are awesome, ${currentUser?.name ?? ''}!`}
                        type="h4"
                        medium
                        mb={16}
                        dataLocator="workoutCompleteCongratsTitle"
                    />
                    <Text text="How was the workout?" type="large-text" mb={16} />
                    <S.FeedbackWrapper>
                        {FEEDBACK_OPTIONS.map((option) => (
                            <S.FeedbackButton
                                key={option.value}
                                onClick={() => handleFeedbackClick(option.value)}
                                data-locator={option.dataLocator}
                            >
                                <S.FeedbackIconWrapper $active={selectedFeedback === option.value}>
                                    <img src={option.icon} alt={option.label} />
                                </S.FeedbackIconWrapper>
                                <Text text={option.label} />
                            </S.FeedbackButton>
                        ))}
                    </S.FeedbackWrapper>
                    <SecondaryDefaultButton
                        text="Finish"
                        onClick={handleFinishClick}
                        dataLocator="workoutCompleteFinishButton"
                    />
                </S.ActionsWrapper>
            </S.Container>
        </S.Wrapper>
    );
};

export default WorkoutFinish;
