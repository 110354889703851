import { Text } from 'wikr-core-components';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';
import mediaQueries from 'theme/mediaQueries';

export const Card = styled(NavLink)`
    position: relative;
    display: block;
    margin: 0 auto 16px;
    background: ${color('on-primary')};
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;

    ${mediaQueriesGrid.tablet} {
        width: calc(50% - 8px);
        margin: unset;
    }

    ${mediaQueries.theSmallestDesktop} {
        width: calc(33.3% - 14px);
    }
`;

export const BodyZone = styled.div`
    position: absolute;
    white-space: nowrap;
    top: 16px;
    left: 16px;
    padding: 4px 10px;
    background: ${color('surface-secondary')};
    border-radius: 12px;
`;

export const ImageWrapper = styled.div`
    width: 100%;
    aspect-ratio: 16 / 9;
`;

export const Image = styled.img<{ $isShown: boolean }>`
    display: ${({ $isShown }) => ($isShown ? 'block' : 'none')};
    width: 100%;
`;

export const InfoBlock = styled.div`
    padding: 16px 20px 20px;
`;

export const TitleWrapper = styled.div<{ $badgeColor: string }>`
    position: relative;

    :before {
        content: '';
        position: absolute;
        top: 50%;
        left: -20px;
        width: 10px;
        height: 16px;
        margin-top: -8px;
        border-radius: 0 5px 5px 0;
        background-color: ${({ $badgeColor }) => $badgeColor};
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

export const InfoItem = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const LevelTitle = styled(Text)`
    text-transform: capitalize;
`;
