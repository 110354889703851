import { Button } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const OutlinedBtn = styled(Button)`
    border-radius: 12px;
    border: 1px solid ${color('border-default')};

    :disabled {
        background-color: transparent !important;
    }

    :active {
        background-color: ${color('surface-hovered')} !important;
    }
`;
