import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const TimerBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 80px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 38px 64px 0;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    position: relative;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const TextTitleContainer = styled.div<{ isShow: boolean }>`
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    transition: opacity 0.5s;
`;

export const Title = styled(Text)`
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 44px;
    }
`;

export const Subtitle = styled(Box)`
    padding: 8px 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08), 0 2px 34px rgba(0, 0, 0, 0.05);
    margin: 0 auto 32px;
    width: fit-content;

    ${mediaQueriesGrid.desktop} {
        margin-bottom: 40px;
    }
`;

export const TitlesContainer = styled.div`
    display: grid;
    grid-template: 1fr / 1fr;
    max-width: 550px;
`;

export const TitleWrapper = styled(Title)<{ isShow: boolean }>`
    grid-row-start: 1;
    grid-column-start: 1;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    transition: opacity 0.5s;
`;

export const StickyContent = styled(Box)`
    position: sticky;
    top: 38px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    z-index: 100;

    ${mediaQueriesGrid.desktop} {
        background: transparent;
        width: fit-content;
        padding: 80px 0 12px;
    }
`;

export const StickyGradientDesktop = styled(Box)`
    display: none;

    ${mediaQueriesGrid.desktop} {
        display: flex;
        position: sticky;
        top: 56px;
        z-index: 50;
        width: 100%;
        height: 220px;
        background: linear-gradient(180deg, #f2efee 28.37%, rgba(242, 239, 238, 0) 100%);
    }
`;

export const StickyContentWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-start: 1;
    grid-column-start: 1;
    padding: 44px 0 12px;
    background: linear-gradient(
        180deg,
        #f2efee 92.03%,
        #f2efee 94.67%,
        rgba(242, 239, 238, 0.97) 95.92%,
        rgba(242, 239, 238, 0.85) 97.42%,
        rgba(242, 239, 238, 0.74) 98.26%,
        rgba(242, 239, 238, 0.52) 99.09%,
        rgba(242, 239, 238, 0) 100%
    );

    ${mediaQueriesGrid.desktop} {
        background: transparent;
    }
`;

export const ImgWrapGrid = styled.div<{ isShow: boolean }>`
    display: grid;
    grid-template: 1fr / 1fr;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    transition: opacity 0.5s;
`;

export const ImgWrap = styled(Box)<{ isShow: boolean }>`
    width: 100%;
    max-width: 343px;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    transition: opacity 0.5s;
    grid-row-start: 1;
    grid-column-start: 1;

    ${mediaQueriesGrid.desktop} {
        max-width: 402px;
    }
`;
