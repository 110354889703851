import { Image, Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

export const NavButton = styled.button<{ isActive?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    margin-bottom: 12px;
    border-radius: 16px;

    &:hover {
        cursor: pointer;
        background: ${color('surface-secondary')};
    }

    ${({ isActive = false }) =>
        isActive &&
        css`
            background: ${color('surface-main')};
        `}
`;

export const Icon = styled(Image)`
    width: 20px;
`;

export const BtnText = styled(Text)`
    text-align: left;
`;
