import { Action } from 'types/commonInterfaces';

export type LoadingState = { [key: string]: boolean };

export const loadingTypeRegExp = /(.*)_(REQUEST|SUCCESS|ERROR)$/;

export default (state: LoadingState = {}, action: Action): LoadingState => {
    const matches = loadingTypeRegExp.exec(action.type);

    if (matches) {
        const [, requestName, requestState] = matches;

        return {
            ...state,
            [requestName]: requestState === 'REQUEST',
        };
    }

    return state;
};
