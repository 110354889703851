import { Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';

import * as S from './styled';

import closeImg from './img/close.svg';
import bannerIcon from './img/bannerIcon.webp';

type InformationModalProps = {
    onClose: () => void;
};

const InformationModal = ({ onClose }: InformationModalProps) => {
    const { t } = useTranslation();

    return (
        <S.Modal onClick={onClose}>
            <S.Banner data-locator="modalContainer">
                <S.CloseImg src={closeImg} onClick={onClose} data-locator="modalCloseButton" />
                <S.TitleWrapper data-locator="modalTitle">
                    <S.TitleIcon src={bannerIcon} alt="tech works" />
                    <Text text={t('workouts.banner.title')} type="large-text" medium />
                </S.TitleWrapper>
                <S.WrapperContent data-locator="modalDescription">
                    <Text>
                        <Trans i18nKey="workouts.banner.text" components={{ 1: <b /> }} />
                    </Text>
                </S.WrapperContent>
            </S.Banner>
        </S.Modal>
    );
};

export default InformationModal;
