import { withTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';
import React from 'react';

import { SkeletonContainer } from './styled';

import { ISkeleton } from './types';

const WIDTH = 400;
const HEIGHT = 400;

const Skeleton = ({ width, height, borderRadius, mb, theme, isBlockElement }: ISkeleton) => (
    <SkeletonContainer
        width={width}
        height={height}
        mb={mb}
        borderRadius={borderRadius || Number(theme['border-radius-medium'])}
        isBlockElement={isBlockElement}
    >
        <ContentLoader foregroundColor={theme['surface-main']} backgroundColor={theme['border-subdued']} animate>
            <rect x={0} y={-100} width={WIDTH} height={HEIGHT} />
        </ContentLoader>
    </SkeletonContainer>
);

export default withTheme(Skeleton);
