import * as S from './styled';

interface IntroBlockProps {
    title: string;
    guidesTitleImg: string;
}

const IntroBlock = ({ title, guidesTitleImg }: IntroBlockProps) => {
    return (
        <S.Container data-locator="guidesOfferContainer">
            <S.TextWrapper text={title} type="h4" medium center />
            <S.ImageWrapper alt={title} src={guidesTitleImg} />
        </S.Container>
    );
};

export default IntroBlock;
