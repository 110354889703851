import { Text, Box } from 'wikr-core-components';

import { selectCurrentUser } from 'store/user/selectors';

import { accountTabs, TABS } from './constants';

import { useSelector } from 'hooks/store';

import { Tabs } from 'components/Tabs';
import SubscriptionsTab from 'components/SubscriptionsTab';
import SettingsTab from 'components/SettingsTab';
import ProfileTab from 'components/ProfileTab';

import * as S from './styled';

import defaultUserPic from 'assets/images/default-avatar.svg';

// import { UserStore } from 'types/store/userStore';

interface IAccountSettings {
    tabId?: string;
    onChangeTab: (tabId: string) => void;
}

const getCurrentTab = (currentTab: string) => {
    switch (currentTab) {
        case accountTabs.Subscription:
            return <SubscriptionsTab />;

        case accountTabs.Settings:
            return <SettingsTab />;

        default:
            return <ProfileTab />;
    }
};

const AccountSettings = ({ tabId, onChangeTab }: IAccountSettings) => {
    const currentUser = useSelector(selectCurrentUser);

    const currentTabId = tabId || accountTabs.Profile;

    return (
        <Box mb={80}>
            <S.Container>
                <S.AvatarWrapper paddingTop={24} paddingBottom={16} paddingX={16}>
                    <S.Image alt="avatar" src={defaultUserPic} />
                    <Text type="h5" text={`${currentUser?.name}`} />
                </S.AvatarWrapper>
                <S.ContentWrapper>
                    <Tabs tabs={TABS} currentTab={currentTabId} onChange={onChangeTab} />
                </S.ContentWrapper>
            </S.Container>
            <S.ContentWrapper paddingX={16}>{getCurrentTab(currentTabId)}</S.ContentWrapper>
        </Box>
    );
};

export default AccountSettings;
