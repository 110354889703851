import { useEffect } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';

import {
    REMINDER_OFFER_MODAL,
    sendAnalyticCancelModalCancelClick,
    sendAnalyticCancelModalOfferClick,
    sendAnalyticCancelModalScreenLoad,
} from 'services/analytics/trackers/cancelOffers';

import { useSelector, useCancelSubscription, useSubscriptionReminder } from 'hooks';

import ReminderOfferModal from './components/ReminderOfferModal';

const ReminderOfferModalContainer = () => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription } = useCancelSubscription();
    const { onSetReminder } = useSubscriptionReminder();

    if (!cancellationCandidate) {
        return null;
    }

    const cancelSubscriptionHandler = () => {
        sendAnalyticCancelModalCancelClick(REMINDER_OFFER_MODAL);

        onCancelSubscription();
    };

    const setReminderHandler = () => {
        sendAnalyticCancelModalOfferClick({ modalId: REMINDER_OFFER_MODAL });

        onSetReminder();
    };

    useEffect(() => {
        sendAnalyticCancelModalScreenLoad(REMINDER_OFFER_MODAL);
    }, []);

    return <ReminderOfferModal onCancelSubscription={cancelSubscriptionHandler} onSetReminder={setReminderHandler} />;
};

export default ReminderOfferModalContainer;
