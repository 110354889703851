import thirdYoga from './img/thirdYoga.webp';
import stephanieRedman from './img/stephanieRedman.webp';
import secondYoga from './img/secondYoga.webp';
import secondEventPreview from './img/secondEventPreview.webp';
import firstYoga from './img/firstYoga.webp';
import firstEventPreview from './img/firstEventPreview.webp';
import brunoPontes from './img/brunoPontes.webp';

export const LIVE_EVENTS = [
    {
        title: 'pagesTitles.flatBelly',
        text: 'flatBelly.liveEvents.firstEvent.text',
        expertImg: stephanieRedman,
        expertName: 'Stephanie Redman',
        expertPosition: 'flatBelly.liveEvents.healthCoach',
        previewImg: firstEventPreview,
        video264:
            'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Event_with_Stephanie.mp4',
        video265:
            'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Event_with_Stephanie_h265.mp4',
        videoWebm:
            'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Event_with_Stephanie.webm',
    },
    {
        title: 'flatBelly.liveEvents.secondEvent.title',
        text: 'flatBelly.liveEvents.secondEvent.text',
        expertImg: brunoPontes,
        expertName: 'Bruno Pontes',
        expertPosition: 'flatBelly.liveEvents.personalTrainer',
        previewImg: secondEventPreview,
        video264:
            'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/belly_fat_exercises_bruno_pontes.mp4',
        video265:
            'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/belly_fat_exercises_bruno_pontes_h265.mp4',
        videoWebm:
            'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/belly_fat_exercises_bruno_pontes.webm',
    },
];

export const YOGA_SESSIONS = [
    {
        name: 'yoga1',
        previewImg: firstYoga,
        video264: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_1.mp4',
        video265: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_1_h265.mp4',
        videoWebm: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_1.webm',
    },
    {
        name: 'yoga2',
        previewImg: secondYoga,
        video264: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_2.mp4',
        video265: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_2_h265.mp4',
        videoWebm: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_2.webm',
    },
    {
        name: 'yoga3',
        previewImg: thirdYoga,
        video264: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_3.mp4',
        video265: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_3_h265.mp4',
        videoWebm: 'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/Flat%20Belly%20/FE_Workout_3.webm',
    },
];
