import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const SvgContainer = styled(Box)`
    margin-bottom: 20px;

    svg {
        fill: transparent;
    }

    circle.innerCircle {
        opacity: 0.2;
    }

    circle.animatedCircle {
        transform: rotate(-90deg);
        transform-origin: center;
        stroke: transparent;
    }

    text.textClasses {
        font-size: 32px;
        font-weight: 800;
    }
`;
