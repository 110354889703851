import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const ArrowTop = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1295 15L18.5 13.6069L12 7L5.5 13.6069L6.87054 15L12 9.78617L17.1295 15Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(ArrowTop);
