import { store } from 'store/configureStore';

export const getAuthStoreData = () => {
    return store.getState()?.auth;
};

export const getAuthToken = () => {
    return store.getState()?.auth.token;
};

export const getAuthRefreshToken = () => {
    return store.getState()?.auth.refreshToken;
}