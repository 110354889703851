import { createSelector } from 'reselect';

import { iUiStore } from 'types/ui';

import { RootReducer } from '../rootReducer';

const selectUi = (state: RootReducer) => state.ui;

const selectIsOpenSidebar = createSelector(selectUi, (ui: iUiStore) => ui.isOpenSidebar);
const selectHideFrontChat = createSelector(selectUi, (ui: iUiStore) => ui.hideFrontChat);

const selectIsAppLibraryWebView = createSelector(selectUi, (ui: iUiStore) => ui.isAppWebView);
const selectIsAppWebView = createSelector(selectUi, (ui: iUiStore) => ui.isAppWebView || ui.isWorkoutsAppWebView);

export { selectIsOpenSidebar, selectHideFrontChat, selectIsAppLibraryWebView, selectIsAppWebView };
