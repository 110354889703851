import { useTranslation } from 'react-i18next';

import timerIcon from 'assets/img/icons/Timer.svg';
import statisticsIcon from 'assets/img/icons/Statistics.svg';
import recipesIcon from 'assets/img/icons/Recipes.svg';
import insightsIcon from 'assets/img/icons/Insights.svg';
import dashboardIcon from 'assets/img/icons/Dashboard.svg';

export const getConfig = () => {
    const { t } = useTranslation();

    const FULL_ACCESS_FEATURES = [
        {
            title: t('main.featureBlock.feature1'),
            text: t('dashboard.onboarding1.subtitle'),
            icon: dashboardIcon,
        },
        {
            title: t('main.featureBlock.feature2'),
            text: t('dashboard.onboarding2.content2'),
            icon: timerIcon,
        },
        {
            title: t('main.featureBlock.feature3'),
            text: t('welcome.tasteGood.recipe1'),
            icon: recipesIcon,
        },
        {
            title: t('main.featureBlock.feature4'),
            text: t('dashboard.onboarding2.content6'),
            icon: insightsIcon,
        },
        {
            title: t('main.featureBlock.feature5'),
            text: t('dashboard.onboarding2.content7'),
            icon: statisticsIcon,
        },
    ];

    return {
        title: t('subscription.cancellation.reminderOffer.title'),
        subtitle: t('subscription.cancellation.reminderOffer.subtitle'),
        fullAccessBlock: {
            title: t('subscription.cancellation.reminderOffer.fullAccessBlock.title'),
            features: FULL_ACCESS_FEATURES,
        },
        reminderBlock: {
            title: t('subscription.cancellation.reminderOffer.reminderBlock.title'),
            text: t('subscription.cancellation.reminderOffer.reminderBlock.text'),
        },
        chargeBlock: {
            title: t('subscription.cancellation.reminderOffer.chargeBlock.title'),
            text: t('subscription.cancellation.reminderOffer.chargeBlock.text'),
        },
        buttons: {
            setReminderBtnText: t('subscription.cancellation.reminderOffer.btnText'),
            cancelBtnText: t('subscription.text.cancel'),
        },
    };
};
