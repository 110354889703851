import { selectIsOpenSidebar } from 'store/uiEffects/selectors';
import { setIsOpenSideBar } from 'store/uiEffects/actions';

import { useDispatch, useSelector } from 'hooks/store';

import { getWindowSize } from 'helpers/getWindowSize';

const isDesktop = getWindowSize(1240);

const useToggle = () => {
    const dispatch = useDispatch();
    const isOpenSidebar = isDesktop || useSelector(selectIsOpenSidebar);

    const open = () => {
        dispatch(setIsOpenSideBar(true));
    };

    const toggle = () => {
        dispatch(setIsOpenSideBar(!isOpenSidebar));
    };

    const close = () => {
        dispatch(setIsOpenSideBar(false));
    };

    return { open, close, toggle, isOpen: isOpenSidebar };
};

export default useToggle;
