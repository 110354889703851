import { Image, Text } from 'wikr-core-components';
import React from 'react';

import Modal from 'components/Modal';

import * as S from './styled';

import loader from './img/loader.svg';

import { IModalWithLoaderProps } from '../../types';

const ModalWithLoader = ({ img, title }: IModalWithLoaderProps) => {
    return (
        <Modal dataLocator="updatingSubscriptionPlanModal" isOpen style={{ content: { padding: '16px 16px 32px' } }}>
            <S.LoaderContainer>
                <Image maxWidth={72} center alt="emoji" src={img} isForceVisible />
                <Text type="h5" text={title} mb={24} center />
                <Image
                    dataLocator="updatingSubscriptionPlanLoader"
                    maxWidth={32}
                    center
                    alt="loader"
                    src={loader}
                    isForceVisible
                />
            </S.LoaderContainer>
        </Modal>
    );
};

export default ModalWithLoader;
