import { Theme, Colors } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import React from 'react';

const DownloadIcon = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const iconColor = theme[color || 'primary-default'];

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.9945 17V22.585L18.8225 20.757L20.2375 22.172L15.9945 26.414L11.7515 22.172L13.1665 20.757L14.9945 22.585V17H16.9945ZM15.9945 6C17.7115 6.00008 19.3686 6.63111 20.6507 7.77312C21.9328 8.91512 22.7506 10.4885 22.9485 12.194C24.1928 12.5333 25.2782 13.2991 26.0152 14.3575C26.7521 15.416 27.0937 16.6997 26.9801 17.9844C26.8666 19.2691 26.3052 20.473 25.3941 21.3858C24.483 22.2986 23.28 22.8622 21.9955 22.978L21.9945 21C21.9961 19.4271 21.38 17.9166 20.2789 16.7934C19.1777 15.6703 17.6796 15.0245 16.107 14.995C14.5345 14.9655 13.0132 15.5547 11.8707 16.6357C10.7282 17.7168 10.0559 19.2032 9.99852 20.775L9.99452 21V22.978C8.70997 22.8623 7.50693 22.2989 6.59566 21.3862C5.68438 20.4735 5.12285 19.2696 5.0092 17.9848C4.89554 16.7001 5.237 15.4163 5.97389 14.3578C6.71078 13.2993 7.79623 12.5334 9.04052 12.194C9.23823 10.4884 10.0559 8.91491 11.3381 7.77287C12.6203 6.63082 14.2775 5.99986 15.9945 6Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default withTheme(DownloadIcon);
