import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    overflow-x: scroll;
    margin-bottom: 24px;
`;

export const ClearButton = styled.button`
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
    padding: 6px 12px;
    flex-shrink: 0;
    cursor: pointer;
`;

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    background-color: ${color('on-primary')};
    padding: 6px 12px;
    border-radius: 12px;
    user-select: none;
`;
