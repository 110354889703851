import React from 'react';

import Skeleton from 'components/Skeleton';

import * as S from '../styled';

const ExerciseInfoSkeleton = ({ displaySkeleton }: { displaySkeleton: boolean }) => {
    return (
        <>
            {displaySkeleton && (
                <>
                    <S.SkeletonMobile>
                        <Skeleton height={375} />
                        <S.SkeletonMobileInner>
                            <S.Block>
                                <Skeleton height={28} mb={10} />
                                <Skeleton height={24} />
                            </S.Block>
                            <S.Block>
                                <Skeleton width={87} height={24} mb={16} />
                                <Skeleton height={48} mb={6} />
                                <Skeleton height={48} mb={6} />
                                <Skeleton height={48} />
                            </S.Block>
                        </S.SkeletonMobileInner>
                    </S.SkeletonMobile>
                    <S.SkeletonDeskop>
                        <S.VideoBlock>
                            <Skeleton height={519} />
                        </S.VideoBlock>
                        <div>
                            <S.SkeletonDeskopBlock>
                                <Skeleton width={87} height={24} mb={16} />
                                <Skeleton height={72} mb={16} />
                                <Skeleton height={48} mb={16} />
                                <Skeleton height={72} mb={16} />
                                <Skeleton height={72} />
                            </S.SkeletonDeskopBlock>
                            <S.Block>
                                <Skeleton width={87} height={24} mb={16} />
                                <Skeleton height={72} mb={16} />
                                <Skeleton height={48} />
                            </S.Block>
                        </div>
                    </S.SkeletonDeskop>
                </>
            )}
        </>
    );
};

export default ExerciseInfoSkeleton;
