import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const ComponentWrap = styled(Box)`
    position: relative;
    border-radius: 64px;
    min-height: 335px;
    min-width: 342px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        width: fit-content;
    }
`;

export const ImgWrap = styled(Box)`
    position: relative;
`;

export const TimerTextWrap = styled(Box)`
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -47%);
    max-width: initial;
    width: fit-content;
    padding: 14px 16px;
    min-height: 44px;
    border-radius: 100px;
`;
