import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Image } from 'wikr-core-components';

import HaveAccount from './img/HaveAccount.png';
import Login from './img/Login.png';
import weirdHand from './img/WeirdHand.svg';

import * as S from './styled';

const LoginInstructions = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Header>
          <Trans i18nKey="main.loginInstructions.title1" />
      </S.Header>
      <S.NumberedItem>
        <S.Title>
          <S.Number>1</S.Number>
          <div>
              <Trans i18nKey="main.loginInstructions.title2" />
          </div>
        </S.Title>
        <Image src={HaveAccount} alt={t('loginInstructions.img1.alt')} />
      </S.NumberedItem>
      <S.NumberedItem>
        <S.Title>
          <S.Number>2</S.Number>
          <div>
              <Trans i18nKey="main.loginInstructions.title3" />
          </div>
        </S.Title>
        <Image src={Login} alt={t('loginInstructions.img2.alt')} />
      </S.NumberedItem>
        <S.WeirdHand>
            <img src={weirdHand} alt="weirdHand" />
        </S.WeirdHand>
    </S.Container>
  );
};

export default LoginInstructions;
