import CategoryModal from 'components/CategoryModal';

import { IDashboardInsightsModalProps } from './types';

import { useDashboardInsightsModal } from './useDashboardInsightsModal';

function DashboardInsightsModal({ blocks, blockId, setBlockId }: IDashboardInsightsModalProps) {
    const { blockPreview, handleCloseModal } = useDashboardInsightsModal(setBlockId);

    return (
        <CategoryModal
            isOpenModal={Boolean(blockId)}
            onClose={handleCloseModal}
            blocksByCategory={blocks}
            articles={blockPreview?.articles || []}
            blockPreviewColor={blockPreview?.color || ''}
            skeletonColor={blocks?.find((el) => el.id === blockId)?.color || ''}
            blockId={blockId}
        />
    );
}

export default DashboardInsightsModal;
