import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import cross from '../../img/cross.svg';

import { FiltersProps } from '../../types';

import { filters } from './config';

const Filters = ({ isOpen, closeFilters, setFilterValue, activeOptions, clearAll, isDesktop }: FiltersProps) => {
    const { t } = useTranslation();

    return (
        <S.Modal $isOpen={isOpen}>
            <S.Container data-locator="filtersContainer">
                <Text text={t('workouts.filters.title')} type="h6" mb={isDesktop ? 32 : 40} />
                {Boolean(activeOptions.length > 1) && (
                    <S.ClearButton onClick={clearAll} data-locator="selectedFilterClearAllButton">
                        <Text text={t('workouts.filters.clearAll')} type="small-text" />
                    </S.ClearButton>
                )}
                {!isDesktop && (
                    <S.Cross src={cross} onClick={closeFilters} data-locator="filtersContainerCloseButton" />
                )}
                <S.FilterWrapper data-locator="workoutLibraryFilterContainer">
                    {filters.map((item, index) => (
                        <S.FilterRow key={index}>
                            <Text text={t(item.title)} type="overline" mb={16} />
                            {item.options.map((option, index) => {
                                const isActive = activeOptions.some((e) => e.title === option.title);

                                return (
                                    <S.Option
                                        onClick={() => setFilterValue(option)}
                                        $isActive={isActive}
                                        key={index}
                                        data-locator={option.dataLocator}
                                    >
                                        <Text
                                            text={t(option.title)}
                                            type="small-text"
                                            color={isActive ? 'on-primary' : 'text-secondary'}
                                        />
                                    </S.Option>
                                );
                            })}
                        </S.FilterRow>
                    ))}
                </S.FilterWrapper>

                {!isDesktop && (
                    <S.ShowButton onClick={closeFilters} data-locator="filtersContainerShowWorkoutsButton">
                        <Text text={t('workouts.filters.showWorkouts')} type="large-text" bold />
                    </S.ShowButton>
                )}
            </S.Container>
        </S.Modal>
    );
};

export default Filters;
