import { Text } from 'wikr-core-components';

import { ExpertContent } from 'components/ModalContainers/GuidesOfferContainer/types';
import ImageLocal from 'components/ImageLocal';

import * as S from './styled';

interface ExpertCardProps {
    expert: ExpertContent;
}

export const ExpertCard = ({ expert }: ExpertCardProps) => {
    const { img, name, description } = expert;

    return (
        <S.ExpertWrapper
            dataLocator="expertContainer"
            key={description}
            backgroundColor="surface-secondary"
            borderRadius={20}
        >
            <ImageLocal alt="guide" src={img} maxWidth={77} />
            <Text text={name} center bold />
            <Text text={description} center />
        </S.ExpertWrapper>
    );
};
