import { selectSubscriptions } from 'store/subscriptions/selectors';

import { useSelector, useReturnOffer, useModals } from 'hooks';

import CaliforniaCancellationReasonModal from './components/CaliforniaCancellationReasonModal';

const CancellationReasonModalContainer = () => {
    const { isDiscountLoading } = useSelector(selectSubscriptions);
    const { closeModal } = useModals();
    const { onShowReturnOffer } = useReturnOffer();

    return (
        <CaliforniaCancellationReasonModal
            isLoading={isDiscountLoading}
            onSubmit={onShowReturnOffer}
            onClose={closeModal}
        />
    );
};

export default CancellationReasonModalContainer;
