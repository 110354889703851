import { FilterKeyType, Filters, FilterType } from '../../types';

export const filters: Filters = [
    {
        title: 'workouts.filters.category.title1',
        options: [
            {
                title: 'workouts.filters.category.option1',
                value: FilterType.beginner,
                filterKey: FilterKeyType.level,
                dataLocator: 'fitnessLevelBeginner',
            },
            {
                title: 'workouts.filters.category.option2',
                value: FilterType.intermediate,
                filterKey: FilterKeyType.level,
                dataLocator: 'fitnessLevelIntermediate',
            },
            {
                title: 'workouts.filters.category.option3',
                value: FilterType.advanced,
                filterKey: FilterKeyType.level,
                dataLocator: 'fitnessLevelAdvanced',
            },
        ],
    },
    {
        title: 'workouts.filters.category.title2',
        options: [
            {
                title: 'workouts.filters.category.option4',
                value: FilterType.strength,
                filterKey: FilterKeyType.type,
                dataLocator: 'workoutTypeStrength',
            },
            {
                title: 'workouts.filters.category.option5',
                value: FilterType.cardio,
                filterKey: FilterKeyType.type,
                dataLocator: 'workoutTypeCardio',
            },
            {
                title: 'workouts.filters.category.option6',
                value: FilterType.recovery,
                filterKey: FilterKeyType.type,
                dataLocator: 'workoutTypeRecovery',
            },
        ],
    },
    {
        title: 'workouts.filters.category.title3',
        options: [
            {
                title: 'workouts.filters.category.option7',
                value: FilterType.total,
                filterKey: FilterKeyType.body_zone,
                dataLocator: 'focusAreaTotalBody',
            },
            {
                title: 'workouts.filters.category.option8',
                value: FilterType.upper,
                filterKey: FilterKeyType.body_zone,
                dataLocator: 'focusAreaUpperBody',
            },
            {
                title: 'workouts.filters.category.option9',
                value: FilterType.lower,
                filterKey: FilterKeyType.body_zone,
                dataLocator: 'focusAreaLowerBody',
            },
        ],
    },
];
