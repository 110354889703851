import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import mediaQueries from 'theme/mediaQueries';

export const ModalContentWrapper = styled.div`
    overflow: hidden;
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s;

    ${mediaQueries.smallDesktop} {
        max-width: 1300px;
        padding: 0 10px;
        justify-content: space-between;
    }
`;

export const BlockPreviewWrap = styled.div`
    width: 100%;
    height: 100%;

    ${mediaQueries.laptop} {
        margin: 0 32px;
        width: auto;
        height: auto;
    }
`;

export const BlockSmallPreviewWrapper = styled.div`
    display: none;

    ${mediaQueries.laptop} {
        display: flex;
        flex-direction: column;
        max-width: 264px;
        transition: all 0.3s;

        :hover {
            transform: rotate(1deg) scale(1.02);
        }
    }
`;

export const BlockSmallPreview = styled.div<{ background: string }>`
    background: ${({ background }) => `${background}`};
    display: flex;
    flex-direction: column;
    width: 264px;
    height: 264px;
    border-radius: 20px;
    overflow: hidden;
    justify-content: space-between;
`;

export const ImageWrapper = styled.div`
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
`;

export const TextWrapper = styled.div<{ background: string }>`
    display: flex;
    align-items: baseline;
    margin-top: 16px;

    > :first-child {
        background: ${({ background }) => `${background}`};
        padding: 1px 6px;
        border-radius: 10px;
        margin-right: 8px;
    }
`;

export const SkeletonPrevNextImg = styled(ContentLoader)`
    width: 100%;
    height: 264px;
    border-radius: 20px;
    margin-bottom: 16px;
`;

export const SkeletonPrevNextTextWrap = styled.div`
    display: flex;
`;

export const SkeletonPrevNextTextSmall = styled(ContentLoader)`
    width: 54px;
    height: 19px;
    border-radius: 10px;
    margin-right: 8px;
`;

export const SkeletonPrevNextTextBig = styled(ContentLoader)`
    width: 160px;
    height: 40px;
    border-radius: 6px;
`;
