import { customTrackDecorator, trackClickCustom, trackScreenLoad } from './mainTrackers';

const ACCOUNT_SUBSCRIPTION = 'account_subscription';
const RESTORE_MAIN_SUBSCRIPTION_LABEL = 'button__restore_main_subscription';
const RESTORE_SECOND_SUBSCRIPTION_LABEL = 'button__restore_second_subscription';
const RESTORE_MAIN_SUBSCRIPTION = 'restore_main_subscription';
const RESTORE_SECOND_SUBSCRIPTION = 'restore_second_subscription';
const RESTORE_MAIN_SUBSCRIPTION_FINAL = 'restore_main_subscription_f';
const RESTORE_SECOND_SUBSCRIPTION_FINAL = 'restore_second_subscription_f';
const MAIN_SUBSCRIPTION_RESTORE_ERROR = 'main_subscription_restore_error';
const SECOND_SUBSCRIPTION_RESTORE_ERROR = 'second_subscription_restore_error';
const SUBSCRIPTION_RESTORE_SUCCESS = 'subscription_restore_success';

export const sendAnalyticsRestoreSubscriptionClick = (isMainSubscription: boolean) => {
    trackClickCustom(
        {
            event_action: isMainSubscription ? RESTORE_MAIN_SUBSCRIPTION_LABEL : RESTORE_SECOND_SUBSCRIPTION_LABEL,
        },
        ACCOUNT_SUBSCRIPTION
    );
};

export const sendAnalyticsRestoreSubscriptionScreenLoad = (isMainSubscription: boolean) => {
    trackScreenLoad(isMainSubscription ? RESTORE_MAIN_SUBSCRIPTION : RESTORE_SECOND_SUBSCRIPTION);
};

export const sendAnalyticsRestoreSubscriptionConfirmClick = (isMainSubscription: boolean) => {
    trackClickCustom(
        {
            event_action: isMainSubscription ? RESTORE_MAIN_SUBSCRIPTION_LABEL : RESTORE_SECOND_SUBSCRIPTION_LABEL,
        },
        isMainSubscription ? RESTORE_MAIN_SUBSCRIPTION : RESTORE_SECOND_SUBSCRIPTION
    );
};

export const sendAnalyticsRestoreSubscriptionSuccess = (isMainSubscription: boolean) => {
    trackScreenLoad(isMainSubscription ? RESTORE_MAIN_SUBSCRIPTION_FINAL : RESTORE_SECOND_SUBSCRIPTION_FINAL);
    customTrackDecorator(SUBSCRIPTION_RESTORE_SUCCESS);
};

export const sendAnalyticsRestoreSubscriptionError = (isMainSubscription: boolean, eventLabel?: string) => {
    customTrackDecorator(
        isMainSubscription ? MAIN_SUBSCRIPTION_RESTORE_ERROR : SECOND_SUBSCRIPTION_RESTORE_ERROR,
        eventLabel
    );
};

export const sendAnalyticsRestoreSubscriptionTryAgain = (isMainSubscription: boolean) => {
    trackClickCustom(
        {
            event_action: 'button__try_again',
        },
        isMainSubscription ? MAIN_SUBSCRIPTION_RESTORE_ERROR : SECOND_SUBSCRIPTION_RESTORE_ERROR
    );
};

export const sendAnalyticsRestoreSubscriptionContactSupport = (isMainSubscription: boolean) => {
    trackClickCustom(
        {
            event_action: 'button__support',
        },
        isMainSubscription ? MAIN_SUBSCRIPTION_RESTORE_ERROR : SECOND_SUBSCRIPTION_RESTORE_ERROR
    );
};
