import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import filtersIcon from '../../img/filtersIcon.svg';

interface FiltersButtonProps {
    toggleFilters: () => void;
    scrollDirection: string;
}

const FiltersButton = ({ toggleFilters, scrollDirection }: FiltersButtonProps) => {
    const { t } = useTranslation();

    return (
        <S.FiltersButton
            onClick={toggleFilters}
            $scrollDirection={scrollDirection}
            data-locator="workoutLibraryFiltersButton"
        >
            <Text text={t('workouts.filters.title')} type="large-text" bold />
            <img src={filtersIcon} alt="filters" />
        </S.FiltersButton>
    );
};

export default FiltersButton;
