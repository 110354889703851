import { useEffect, useState } from 'react';

import api from 'api';

import { isEmpty } from 'helpers/utils';

import { ExerciseDetails } from 'types/workouts/workoutsApiInterface';

const useFetchExerciseDetails = (exerciseId?: number) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown>();
    const [data, setData] = useState<Partial<ExerciseDetails> | null>(null);

    const [exerciseHasBodyParts, setExerciseHasBodyParts] = useState(false);
    const [exerciseHasInstruction, setExerciseHasInstruction] = useState(false);
    const [exerciseHasTips, setExerciseHasTips] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
                if (!exerciseId) {
                    setError('exerciseId is not defined');

                    return;
                }

                const response = await api.workouts.getExerciseDetails(exerciseId);

                setData({ ...response });

                if (!isEmpty(response?.body_parts)) setExerciseHasBodyParts(true);
                if (!isEmpty(response?.instruction)) setExerciseHasInstruction(true);
                if (!isEmpty(response?.tips)) setExerciseHasTips(true);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();

        return () => {
            setData(null);

            setExerciseHasBodyParts(false);
            setExerciseHasInstruction(false);
            setExerciseHasTips(false);
        };
    }, [exerciseId]);

    return {
        isLoading,
        error,
        data,
        exerciseHasBodyParts,
        exerciseHasInstruction,
        exerciseHasTips,
    };
};

export default useFetchExerciseDetails;
