import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled.div`
    position: relative;
    margin-bottom: 48px;
    padding: 24px;
    border: 1px solid #D4DAE0;
    border-radius: 14px;
`;

export const WeirdHand = styled.div`
    & > img {
        display: block;
        position: absolute;
        bottom: 30px;
        right: 0;
    } 
`;

export const Header = styled.div`
    padding-bottom: 16px;
    margin: 0 8px 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.408px;
    border-bottom: 1px solid #C8C7CC;
`;

export const Title = styled.div``;

export const Number = styled.div``;

export const NumberedItem = styled.div`
    & img {
        width: 100%;
        height: auto !important;
    }
    
    width: 100%;
    height: auto !important;
    ${Title} {
        display: flex;
        margin-bottom: 32px;
        align-items: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
    }
    
    ${Number} {
        width: 21px;
        height: 21px;
        margin-right: 12px;
        padding: 3px 7px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: ${color('on-primary')};
        border-radius: 50%;
        background-color: #4C61C7;
    }
`;
