import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const AllRulesWrap = styled(Box)`
    display: flex;
    padding: 0 0 64px;
    flex-direction: column;
    gap: 40px;

    ${mediaQueriesGrid.largeTablet} {
        padding: 0 24px 0;
    }
`;

export const RuleBlock = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;

    ${mediaQueriesGrid.largeTablet} {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;

        &:nth-of-type(2) {
            flex-direction: row;
        }
    }
`;

export const RuleStatement = styled(Box)`
    display: flex;
    padding: 24px;
    width: 100%;
    max-width: 343px;
    flex-direction: column;
    gap: 24px;
    border-radius: 32px;
    background: ${color('surface-hovered')};
`;

export const OneRuleState = styled(Box)`
    display: flex;
    align-items: flex-start;
    gap: 16px;
`;

export const PointCircle = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    border-radius: 50%;
    padding: 2px 4px;
    background-color: ${color('surface-main')};
`;

export const RuleImg = styled.img`
    max-width: 343px;
`;
