import { Text } from 'wikr-core-components';

import { sendAnalyticWorkoutInfoClick } from 'services/analytics/trackers/workouts';

import { useChromecast } from 'hooks';

import * as S from './styled';

import { ReactComponent as SVGInfo } from '../../assets/info.svg';
import { ReactComponent as SVGCastIcon } from '../../assets/castIcon.svg';

interface CastControlsSectionProps {
    onClick: () => void;
    onHintClick: () => void;
}

const CastControlsSection = ({ onClick, onHintClick }: CastControlsSectionProps) => {
    const { isCastReady } = useChromecast();

    return (
        <S.CastControlsWrapper>
            {isCastReady && (
                <S.ChromecastButton onClick={onClick} data-locator="workoutCastToTvButton">
                    <SVGCastIcon /> Cast to TV
                </S.ChromecastButton>
            )}
            <S.CastHintButton
                onClick={() => {
                    onHintClick();

                    sendAnalyticWorkoutInfoClick();
                }}
                data-locator="workoutHowToCastToTvButton"
            >
                <Text type="small-text" color="text-secondary-subdued">
                    <SVGInfo /> How to cast to TV?
                </Text>
            </S.CastHintButton>
        </S.CastControlsWrapper>
    );
};

export default CastControlsSection;
