import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectRestoreCandidate } from 'store/subscriptions/selectors';
import { restoreSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { PRODUCT_CODES } from 'constants/product';

import {
    sendAnalyticsRestoreSubscriptionContactSupport,
    sendAnalyticsRestoreSubscriptionError,
    sendAnalyticsRestoreSubscriptionScreenLoad,
    sendAnalyticsRestoreSubscriptionSuccess,
    sendAnalyticsRestoreSubscriptionTryAgain,
} from 'services/analytics';

import { useModals, useDispatch, useSelector } from 'hooks/index';

import { getRestoreCandidateData, getRestoreSuccessData, getRestoreErrorData } from './helpers';
import { getUpdatingSubscriptionLoadingData } from '../../helpers';

import { RestoreCandidate } from '../../types';

const useRestoreSubscription = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();
    const { t } = useTranslation();

    const restoreCandidate = useSelector(selectRestoreCandidate);

    const showRestoreCandidate = (restoreCandidate: RestoreCandidate) => {
        openModal(ModalName.restoringSubscriptionModal, {
            modalData: getRestoreCandidateData(restoreCandidate),
        });

        sendAnalyticsRestoreSubscriptionScreenLoad(restoreCandidate.productCode === PRODUCT_CODES.APP_FULL_ACCESS);
    };

    const onRestoreSubscription = useCallback(() => {
        const isMainSubscription = restoreCandidate?.productCode === PRODUCT_CODES.APP_FULL_ACCESS;

        openModal(ModalName.withLoaderModal, {
            modalData: getUpdatingSubscriptionLoadingData(t),
        });

        if (restoreCandidate) {
            dispatch(
                restoreSubscription({
                    external_id: restoreCandidate.externalId,
                    onSuccess: () => {
                        sendAnalyticsRestoreSubscriptionSuccess(isMainSubscription);

                        openModal(ModalName.successFeedbackModal, {
                            modalData: getRestoreSuccessData({
                                closeModal,
                                t,
                            }),
                        });
                    },
                    onError: (error?: string) => {
                        sendAnalyticsRestoreSubscriptionError(isMainSubscription, error);

                        const onTryAgain = () => {
                            sendAnalyticsRestoreSubscriptionTryAgain(isMainSubscription);

                            onRestoreSubscription();
                        };

                        const onSupportLinkClick = () => {
                            sendAnalyticsRestoreSubscriptionContactSupport(isMainSubscription);
                        };

                        openModal(ModalName.errorFeedbackModal, {
                            modalData: getRestoreErrorData({ onTryAgain, onSupportLinkClick, t }),
                        });
                    },
                })
            );
        }
    }, [restoreCandidate]);

    return {
        showRestoreCandidate,
        onRestoreSubscription,
    };
};

export default useRestoreSubscription;
