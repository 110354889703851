export const QUESTIONS = [
    {
        id: 1,
        title: 'welcome.questions.q1.title',
        content: 'welcome.questions.q1.content',
    },
    {
        id: 2,
        title: 'welcome.questions.q2.title',
        content: 'welcome.questions.q2.content',
    },
    {
        id: 3,
        title: 'welcome.questions.q3.title',
        content: 'welcome.questions.q3.content',
    },
    {
        id: 4,
        title: 'welcome.questions.q4.title',
        content: 'welcome.questions.q4.content',
    },
    {
        id: 5,
        title: 'welcome.questions.q5.title',
        content: 'welcome.questions.q5.content',
    },
    {
        id: 6,
        title: 'welcome.questions.q6.title',
        content: 'welcome.questions.q6.content',
    },
    {
        id: 7,
        title: 'welcome.questions.q7.title',
        content: 'welcome.questions.q7.content',
    },
    {
        id: 8,
        title: 'welcome.questions.q8.title',
        content: 'welcome.questions.q8.content',
    },
];
