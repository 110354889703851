import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

// mainTrackers
import { trackClick } from './mainTrackers';

export const sendAnalyticDownloadGuideClick = (guideName: string) => {
    trackClick({
        elementEvent: ElementEventAction.guide,
        actionEvent: ActionEventAction.download,
        eventLabel: guideName,
    });
};
