import iconPerfect from './assets/perfect.webp';
import iconHard from './assets/hard.webp';
import iconEasy from './assets/easy.webp';

export const FEEDBACK_OPTIONS = [
    {
        icon: iconEasy,
        label: 'Easy',
        value: 'easy',
        dataLocator: 'workoutCompleteEasy',
    },
    {
        icon: iconPerfect,
        label: 'Perfect',
        value: 'perfect',
        dataLocator: 'workoutCompletePerfect',
    },
    {
        icon: iconHard,
        label: 'Hard',
        value: 'hard',
        dataLocator: 'workoutCompleteHard',
    },
];
