import React, { FC } from 'react';

import { getWindowSize } from 'helpers/getWindowSize';

import * as S from './styled';

interface BurgerProps {
    isActive: boolean;
    onClick: () => void;
}

const isDesktop = getWindowSize(1240);

const Burger: FC<BurgerProps> = ({ isActive, onClick }) => (
    <S.Burger
        onClick={onClick}
        data-locator={isActive ? 'burgerCloseMenuBtn' : 'burgerOpenMenuBtn'}
        isActive={isActive}
        isHidden={isDesktop}
    >
        <S.Glyph />
    </S.Burger>
);

export default Burger;
