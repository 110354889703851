import * as action from './actions';

import * as actionTypes from './actionTypes';

import { UserWeightStatistic } from 'types/user/userApiInterface';
import { InferValueTypes } from 'types/commonTypes';

export interface DashboardStore {
    isWeightStatisticLoading: boolean;
    weightStatistic: UserWeightStatistic[];
}

const initialState: DashboardStore = {
    isWeightStatisticLoading: false,
    weightStatistic: [],
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state = initialState, action: ActionType): DashboardStore => {
    switch (action.type) {
        case actionTypes.SET_WEIGHT_STATISTIC:
            return { ...state, weightStatistic: action.payload };

        case actionTypes.SET_WEIGHT_STATISTIC_STATUS:
            return { ...state, isWeightStatisticLoading: action.payload };

        default:
            return state;
    }
};

export default userReducer;
