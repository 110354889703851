import { formValues } from './types';

export const initialValues: formValues = {
    name: '',
    yearOfBirth: 0,
    defaultCycleLength: 28,
    defaultPeriodLength: 5,
    defaultCycleRegularity: 'regular',
};

export const analyticValues = {
    name: 'name',
    yearOfBirth: 'birth_year',
    defaultCycleLength: 'cycle_length',
    defaultPeriodLength: 'period_length',
    defaultCycleRegularity: 'cycle_regularity',
}