import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const QuestionsBlock = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;

    ${mediaQueriesGrid.tablet} {
        padding: 64px 32px;
    }

    ${mediaQueriesGrid.desktop} {
        padding: 120px 64px;
    }
`;

export const ContentContainer = styled(Box)`
    width: 100%;
    max-width: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueriesGrid.desktop} {
        max-width: 1416px;
    }
`;

export const Title = styled(Text)`
    ${mediaQueriesGrid.desktop} {
        font-size: 56px;
        line-height: 80px;
        letter-spacing: -1px;
    }
`;

export const QuestionsWrap = styled(Box)`
    width: 100%;
    max-width: 600px;
    align-self: center;
`;
