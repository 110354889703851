import { Box, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import mediaQueriesGrid from 'theme/mediaQueriesGrid';

export const MainContainer = styled(Box)`
    padding: 0 16px;
    max-width: 100vw;
    margin: 0 auto;
    background: ${({ theme }) => theme['surface-secondary']};
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mediaQueriesGrid.tablet} {
        padding: 0 32px;
        min-height: calc(100vh - 57px);
    }
`;

export const InnerContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 343px;

    ${mediaQueriesGrid.tablet} {
        max-width: 768px;
        margin-bottom: 80px;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 1028px;
    }
`;

export const TitleBox = styled(Box)`
    display: flex;
    width: 100%;
`;

export const Title = styled(Text)`
    margin-left: 20px;
`;

export const ContentBox = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 32px;
    background: ${color('surface-main')};
    border-radius: 24px;
    margin-bottom: -28px;

    ${mediaQueriesGrid.tablet} {
        padding: 32px;
        max-width: 768px;
        margin-bottom: 0;
    }

    ${mediaQueriesGrid.desktop} {
        max-width: 1028px;
    }
`;

export const ButtonsBox = styled.div`
    padding: 44px 0 16px 0;
    position: sticky;
    height: 108px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(242, 239, 238, 0) 0%,
        rgba(242, 239, 238, 0.11) 6.25%,
        rgba(242, 239, 238, 0.21) 11.98%,
        #f2efee 38.54%
    );
    max-width: 343px;
    width: 100%;
    margin: 0 auto;

    ${mediaQueriesGrid.tablet} {
        display: none;
    }
`;

export const PreviewNavigation = styled.div`
    display: flex;
`;

export const ButtonWrapper = styled.div`
    margin-right: 20px;
`;
